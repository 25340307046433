import React from "react";
import "./styles.scss";

interface ColorCircle {
  color?: string;
  colorSelected?: string;
  size?: 40 | 48 | 56;
  hasPinkBorder?: boolean;
  hasPadding?: boolean;
  noBorder?: boolean;
  noSpaceBetweenBorder?: boolean;
  className?: string;
  onClick?: (a: any) => void;
}

const colorCircle: React.FC<ColorCircle> = (props) => {
  const {
    color,
    colorSelected,
    size = 48,
    hasPinkBorder,
    hasPadding,
    noSpaceBetweenBorder,
    noBorder,
    className,
    onClick,
  } = props;

  const colorCircleClass = [onClick ? "clickable" : "", className ?? ""]
    .join(" ")
    .trim();

  const imageSize = noBorder ? size : size - 2;

  const colorClass = [
    "image-circle-border__center-image",
    hasPinkBorder ? "pink-border" : "transparent-border",
    noBorder ? "no-border" : "",
    noSpaceBetweenBorder ? "no-space-between-border" : "",
    hasPadding ? "padding-border" : "",
  ]
    .filter(Boolean)
    .join(" ")
    .trim();

  return (
    <div className="border">
      <div
        onClick={onClick}
        className="circle-color"
        style={{ background: color }}
      >
        {colorSelected === color && (
          <span className="icon icon-check icon-md selected-icon " />
        )}
      </div>
    </div>
  );
};

export default colorCircle;
