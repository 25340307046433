import React, { useEffect, useState } from "react";
import "./styles.scss";

import { shortClassNames } from "utils/shortClassNames";

import ProfileIcon from "assets/icons/profile-icon-gray.svg";

interface ImageCircleBorderProps {
  className?: string;
  centerImage: string | null | undefined;
  centerImageAlt: string;
  hasPinkBorder?: boolean;
  hasCharcoalBorder?: boolean;
  size?: 22 | 26 | 36 | 40 | 48 | 56 | 64;
  noBorder?: boolean;
  onClick?: (a: any) => void;
}

const ImageCircleBorder: React.FC<ImageCircleBorderProps> = (props) => {
  const {
    className,
    centerImage,
    centerImageAlt,
    hasPinkBorder,
    hasCharcoalBorder,
    size = 40,
    noBorder,
    onClick,
  } = props;

  const [hasAvatarToShow, setHasAvatarToShow] = useState(false);

  useEffect(() => {
    if (centerImage && centerImage !== "undefined" && centerImage !== "null") {
      setHasAvatarToShow(true);
    } else {
      setHasAvatarToShow(false);
    }
  }, [centerImage]);

  const imageSrc = hasAvatarToShow && centerImage ? centerImage : ProfileIcon;

  const imageSize = !centerImage
    ? size - 16
    : hasPinkBorder || hasCharcoalBorder
      ? size - 4
      : size;

  return hasAvatarToShow ? (
    <figure
      id="image-circle-border"
      className={shortClassNames([
        className ?? "",
        hasPinkBorder ? "pink-border" : "",
        hasCharcoalBorder ? "charcoal-border" : "",
        noBorder ? "no-border" : "",
        !!!centerImage ? "no-avatar" : "",
        onClick ? "cursor-pointer" : "",
      ])}
      onClick={onClick}
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
    >
      <img
        src={imageSrc}
        alt={centerImageAlt}
        loading="eager"
        // style={{ width: imageSize, height: imageSize }}
        className={shortClassNames([
          "image-circle-border__center-image",
          !hasAvatarToShow ? "empty-avatar-inner" : "",
        ])}
      />
    </figure>
  ) : (
    <div
      id="image-circle-border"
      className="image-circle__no-img"
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
      onClick={onClick}
    >
      <img
        src={ProfileIcon}
        alt={centerImageAlt}
        loading="eager"
        style={{ width: "47.4%", height: "47.4%" }}
      />
    </div>
  );
};

export default ImageCircleBorder;
