import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";
import patchUserPreferences from "../../../api/userPreferences/patchUserPreferences";
import { useUserInfo } from "../../../hooks/userInfo";
import InputCheckbox from "../../Inputs/InputCheckbox";
import { useModal } from "../../../hooks/useModal";
import { useToast } from "hooks/useToast";
import WomanImage from "assets/images/gender-connect/woman.jpg";
import ManImage from "assets/images/gender-connect/man.jpg";
import TransImage from "assets/images/gender-connect/trans.jpg";
import AnimeImage from "assets/images/gender-connect/anime.jpg";
import { shortClassNames } from "utils/shortClassNames";

type Genders = "WOMAN" | "MAN" | "TRANS" | "ANIME";

interface ModalInitialPreferencesProps {}

const ModalInitialPreferences: React.FC<ModalInitialPreferencesProps> = (
  props
) => {
  const {} = props;
  const { userInfo } = useUserInfo();
  const { setOpenUseModal } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const genderConnect = [
    {
      id: "gender-connect-woman",
      inputLabel: "Woman",
      inputValue: "WOMAN",
      imageCard: WomanImage,
    },
    {
      id: "gender-connect-man",
      inputLabel: "Man",
      inputValue: "MAN",
      imageCard: ManImage,
    },

    {
      id: "gender-connect-trans",
      inputLabel: "Trans",
      inputValue: "TRANS",
      imageCard: TransImage,
    },
    {
      id: "gender-connect-anime",
      inputLabel: "Anime",
      inputValue: "ANIME",
      imageCard: AnimeImage,
    },
  ];

  const [selectedGenres, setSelectedGenres] = useState<Genders[]>([]);
  const [selectGenresTrigger, setSelectGenresTrigger] = useState(false);

  useEffect(() => {
    if (selectedGenres.length === 4) {
      addGenderSubmit();
    }
  }, [selectGenresTrigger]);

  const handleSkip = () => {
    setSelectedGenres(["WOMAN", "MAN", "ANIME", "TRANS"]);
    setSelectGenresTrigger((prev) => !prev);
  };

  const addGenderSubmit = async () => {
    if (!selectedGenres.length) {
      showToast({
        description: "Select one or more genres or click skip",
        title: "Empty genres",
        type: "info",
      });
    } else {
      setLoading(true);

      try {
        let createdUserData = selectedGenres?.map((gender) =>
          gender.toUpperCase()
        );

        //TO DO => REMOVE THIS LINE BELLOW AFTER BACK IMPLEMENTATION
        createdUserData = createdUserData.filter(
          (gender) => gender !== "ANIME"
        );

        const response = await patchUserPreferences(userInfo.access_token, {
          preferedGenders: createdUserData,
        });
        switch (response.status) {
          case 200:
            setOpenUseModal(false);
            break;
          default:
            showToast({
              description: "Please try again",
              title: "Unexpected error",
              type: "error",
            });

            break;
        }
      } catch (error) {
        console.error(error);
        showToast({
          description: "Please try again",
          title: "Unexpected error",
          type: "error",
        });
      }
    }

    setLoading(false);
  };

  const selectGender = (gender: Genders) => {
    const hasGenderInLst = selectedGenres.includes(gender);

    if (hasGenderInLst) {
      const newList = selectedGenres.filter((genre) => genre !== gender);
      setSelectedGenres(newList);
    } else {
      setSelectedGenres((prev) => [...prev, gender]);
    }
  };

  return (
    <div id="modal-initial-preferences" className="wrapper">
      <div className="modal-initial-preferences__inner inner">
        <div className="inner__header-wrapper">
          <p className="header-wrapper__title">Select Preference</p>
          <p className="header-wrapper__paragraph">Select subscription below</p>
        </div>
        <section className="inner__cards-gender-selection-wrapper">
          {genderConnect.map((card) => {
            return (
              <div
                onClick={() => selectGender(card.inputValue as Genders)}
                key={card.id}
                className={shortClassNames([
                  "card-gender-selection-wrapper__card",
                  `${
                    selectedGenres.includes(card.inputValue as Genders)
                      ? "selected-card"
                      : ""
                  }`,
                ])}
              >
                <img
                  className="card__image-card"
                  src={card.imageCard}
                  loading="eager"
                  alt={card.inputLabel}
                />

                <div className="card__texts-wrapper">
                  <p className="texts-wrapper__principal-text">
                    {card.inputLabel}
                  </p>
                </div>
              </div>
            );
          })}
        </section>

        <div className="inner_buttons-wrapper">
          <Button
            buttonStyle="primary"
            onClick={addGenderSubmit}
            children={"Apply"}
          />
          <Button
            buttonStyle="quaternary"
            onClick={handleSkip}
            children={"Skip"}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalInitialPreferences;
