import React, { Suspense, useContext, useEffect, useState } from "react";
import { NavigateFunction, useParams } from "react-router-dom";
import "./styles.scss";

import Button from "components/Buttons/Button";
import ButtonIcon from "components/Buttons/ButtonIcon";
import Category from "components/Category";
import ImageCircleBorder from "components/ImageCircleBorder";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";

import dollar from "assets/icons/dollar-white.svg";
import roses from "assets/icons/logos/logo-fill-pink.svg";

import { useAvailableRoses } from "contexts/availableRosesContext";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useUserInfo } from "hooks/userInfo";

import { CreatorDetailsContext } from "contexts/creatorDetails";

import { IGoal } from "types/goal";

import { textCapitalize } from "utils/textCapitalize";
import { thousandsFormat } from "utils/thousandsFormat";
import { genderToFront } from "utils/genderHelper";
import MediaModal, { ICreatorMediaInfo } from "components/Modals/MediaModal";
import HorizontalScroll from "components/HorizontalScroll";
import { shortClassNames } from "utils/shortClassNames";
import { PhotosProfile } from "types/catalog";
import LazyImage from "components/LazyLoadedImage";
import getPublicDetails from "api/publicDetails/getProfileDetails";
import { ImagePlaceholder } from "components/LazyLoadedImage/ImagePlaceholder";

interface ProfileDetailsProps {
  className?: string;
  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "inverse"
    | "transparent"
    | "transparentActive";
  icon: string;
  iconSecondary: string;
  goal?: IGoal;
  goalProgress?: string;
  sendMessageClick: (a: any) => void;
  heartButtonClick: (a: any) => any;
  openModal?: () => void;
  openFeed?: () => void;
  navigate: NavigateFunction;
}

interface EssentialCard {
  icon: string;
  label: string;
  info: string;
}

const ProducerInfo: React.FC<ProfileDetailsProps> = (props) => {
  const {
    buttonStyle,
    icon,
    iconSecondary,
    goal,
    goalProgress,
    heartButtonClick,
    sendMessageClick,
    openModal,
    openFeed,
    navigate,
    className,
  } = props;

  const { userInfo } = useUserInfo();
  const { availableRoses } = useAvailableRoses();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setFeedScreen, params } = useDeskNavigation();
  const { userId } = params;
  const { showToast } = useToast();
  const { creatorDetails, setCreatorDetails } = useContext(
    CreatorDetailsContext
  );
  const paramsUrl = useParams();
  const [essentialCards, setEssentialCards] = useState<EssentialCard[]>([]);
  const [videoPublic, setVideoPublic] = useState<string | null>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [userInterests, setUserInterests] = useState<string[]>();
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const checkAuth = useAuthCheckOpenModal({ navigate, paramsUrl });
  const [currentIndex, setCurrentIndex] = useState(0);

  const [mediasToShow, setMediasToShow] = useState<PhotosProfile[]>([]);

  useEffect(() => {
    getProfile();

    const medias = [];
    if (creatorDetails?.profile?.cover) {
      medias.push(creatorDetails.profile.cover);
    }
    if (creatorDetails?.profile?.photos?.length) {
      medias.push(...creatorDetails.profile.photos);
    }

    setMediasToShow(medias);
  }, [creatorDetails]);

  const changePrevious = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0 && hasVideo) {
        return prevIndex;
      }
      if (prevIndex === 0) return prevIndex;

      const newIndex = prevIndex - 1;
      return newIndex;
    });
  };

  const changeNext = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex >= mediasToShow.length - 1) return prevIndex;

      const newIndex = prevIndex + 1;
      return newIndex;
    });
  };

  const formatHeight = (height: string) => {
    if (height !== "null") {
      const feetConverter = Number(height) * 0.0328084;
      const meters = `${height.toString()[0]},${height.toString().slice(1)}`;
      const feet = `${feetConverter.toString()[0]}’${feetConverter
        .toString()
        .slice(1)
        .substring(1, 3)}”`;
      return `${feet} / ${meters}m`;
    }

    return "";
  };

  const getProfile = async () => {
    try {
      if (creatorDetails) {
        const { profile } = creatorDetails;
        const videoUrl = profile.cover?.url;
        setVideoPublic(videoUrl);
        setHasVideo(!!videoUrl);
        setUserInterests(profile.interests || []);

        const cards: EssentialCard[] = [
          {
            icon: "icon-gender",
            label: "Gender",
            info: profile.gender
              ? genderToFront[profile.gender.toUpperCase()]
              : "Not informed",
          },
          {
            icon: "icon-earth",
            label: "Nationality",
            info: textCapitalize(profile.nationality) || "Not informed",
          },
          {
            icon: "icon-measurement",
            label: "Height",
            info: formatHeight(String(profile.height)) || "Not informed",
          },
          {
            icon: "icon-gym",
            label: "Body Style",
            info: profile.bodyStyle
              ? textCapitalize(profile.bodyStyle.replace("_", " "))
              : "Not informed",
          },
          {
            icon: "icon-zodiac",
            label: "Zodiac",
            info: textCapitalize(profile.zodiac) || "Not informed",
          },
          {
            icon: "icon-language",
            label: "Languages",
            info: profile.languages?.length
              ? profile.languages
                  .map(
                    (lang) =>
                      lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase()
                  )
                  .join(", ")
              : "Not informed",
          },
        ].filter((card) => card.info !== "Not informed");

        setEssentialCards(cards);
      } else {
        const request = await getPublicDetails(userInfo.access_token, userId);
        setCreatorDetails(request.res);
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      showToast({
        type: "error",
        title: "Profile Error",
        description: "Could not load profile details",
      });
    }
  };

  const imageMessageModalHandler = (index: number) => {
    const prepareMedias = creatorDetails?.profile?.photos.map((media) => {
      return {
        fileDuration: 0,
        hasPaid: false,
        isPaidContent: false,
        mediaSrc: media?.url || "",
        type: "photo",
        unlockPrice: 0,
      };
    });
    setModalContent(
      <MediaModal
        creatorId={userId}
        medias={prepareMedias as ICreatorMediaInfo[]}
        initialIndex={index}
      />
    );
    setOpenUseModal(true);
  };

  const handlerFeedButtonClick = () => {
    if (!checkAuth()) return;
    setFeedScreen("/feed", { userId });
  };

  const currentMedia = mediasToShow[currentIndex];

  return (
    <div className={shortClassNames(["profile-slide", `${className ?? ""}`])}>
      <div id="profile">
        <div className="profile_dots">
          {/*  <img className="icon-dot" src={dot} alt="" /> */}
        </div>
        <div className="profile-banner">
          <div className="img-change-buttons">
            {currentIndex > 0 && (
              <div className="img-change-button-left" onClick={changePrevious}>
                <span className="icon icon-chevron-left icon-md icon-primary-75" />
              </div>
            )}

            {currentIndex !== mediasToShow.length - 1 && (
              <div className="img-change-button-right" onClick={changeNext}>
                <span className="icon icon-chevron-right icon-md icon-primary-75" />
              </div>
            )}
            <div
              className="img-change-buttons__cover
								element-footer-overlay-50 
								element-header-overlay-35"
            >
              {currentMedia && currentMedia.url ? (
                currentMedia.url.endsWith("mp4") ? (
                  <video
                    src={mediasToShow[currentIndex]?.url}
                    loop
                    muted
                    playsInline
                    disablePictureInPicture
                    autoPlay={true}
                    controls={false}
                    preload="auto"
                    onCanPlay={() => setIsLoaded(true)}
                    className="profile-banner"
                  />
                ) : mediasToShow[currentIndex]?.url.endsWith("gif") ? (
                  <video
                    src={mediasToShow[currentIndex]?.url}
                    autoPlay={true}
                    controls={false}
                    preload="auto"
                    loop
                    muted
                    playsInline
                    disablePictureInPicture
                    onCanPlay={() => setIsLoaded(true)}
                    className="profile-banner"
                  />
                ) : mediasToShow[currentIndex].url ? (
                  <Suspense fallback={<ImagePlaceholder />}>
                    <LazyImage
                      placeholderSrc={mediasToShow[currentIndex].thumbnailUrl}
                      src={mediasToShow[currentIndex].url}
                      loading="eager"
                      alt="profile cover"
                      className="profile-banner"
                    />
                  </Suspense>
                ) : (
                  <></>
                )
              ) : (
                false
              )}
            </div>

            <div className="profile_dots">
              <div className="right_dots">
                {creatorDetails?.isVip && (
                  <span
                    className="profile__icon icon
								 icon-burn icon-md icon-primary-75"
                  />
                )}
                {creatorDetails?.profile?.isAI && (
                  <div className="ia-icon">
                    <span className="profile-ia-box">AI</span>
                  </div>
                )}
              </div>

              <div className="statistics-info left_dots">
                <span className="icon icon-heart-solid icon-md icon-primary-75" />
                {/* TO DO */}
                <p className="counter">12.3k</p>
              </div>
            </div>

            <div className={`profile-title`}>
              <div className="user-info">
                <ImageCircleBorder
                  centerImageAlt="profile avatar"
                  centerImage={creatorDetails?.profile?.avatar?.thumbnailUrl}
                  size={56}
                  onClick={handlerFeedButtonClick}
                  className="user-info__profile-avatar"
                />

                <div className="user-title">
                  <p className="profile-info">{creatorDetails?.displayname}</p>
                  {creatorDetails?.userId ===
                  "ef7187fe-7428-484f-bc2f-a6e06e947788" ? (
                    <span className="icon icon-blue icon-verified-1 icon-md" />
                  ) : (
                    false
                  )}
                </div>
                {creatorDetails?.profile?.occupation ? (
                  <p className="counter">
                    {creatorDetails.profile.age} |{" "}
                    {textCapitalize(
                      creatorDetails?.profile?.occupation.replaceAll("_", " ")
                    )}{" "}
                  </p>
                ) : (
                  <p className="counter">{creatorDetails?.profile?.age}</p>
                )}
              </div>
              <div className="profile-banner-buttons">
                <ButtonIcon
                  onClick={heartButtonClick}
                  buttonStyle={buttonStyle}
                  icon={icon}
                />

                <Button buttonStyle="tertiary" onClick={openFeed}>
                  VIEW FEED
                </Button>

                <ButtonIcon
                  onClick={sendMessageClick}
                  buttonStyle={"transparent"}
                  icon={iconSecondary}
                />
              </div>
            </div>
          </div>

          <div className="profile-bio">
            {creatorDetails?.profile.bio && (
              <p className="profile-bio__bio-text">
                {creatorDetails?.profile.bio
                  ? creatorDetails?.profile.bio
                  : "Not informed"}
              </p>
            )}

            {creatorDetails?.profile?.photos ? (
              <>
                {creatorDetails?.profile?.photos.length > 0 && (
                  <div>
                    <div className="media">
                      <div className="media_scroll">
                        <HorizontalScroll
                          gap={16}
                          itemWidth={120}
                          itemsPerScroll={6}
                        >
                          <div
                            className="media-photo
													 		element-header-overlay-35
															element-footer-overlay-35"
                          >
                            {creatorDetails?.profile?.photos?.map(
                              (photo, index) => {
                                const preventImageDrag = (
                                  e: React.DragEvent<HTMLImageElement>
                                ) => e.preventDefault();
                                return (
                                  <img
                                    onDragStart={preventImageDrag}
                                    className="photo"
                                    key={index}
                                    src={photo?.url}
                                    alt="profile"
                                    loading="eager"
                                    onClick={() =>
                                      imageMessageModalHandler(index)
                                    }
                                  />
                                );
                              }
                            )}
                          </div>
                        </HorizontalScroll>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              false
            )}
            {essentialCards.length > 0 && (
              <div className="essentials-list">
                {essentialCards.map((card, index) => (
                  <div className="essential-card" key={index}>
                    <span
                      className={`icon ${card.icon} icon-md icon-gray icon-round`}
                    ></span>
                    <div className="essential-wrapper">
                      <label className="essential-label">{card.label}</label>
                      <p className="essential-info">
                        {Array.isArray(card.info)
                          ? textCapitalize(card.info.join(", "))
                          : textCapitalize(card.info)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {goal && (
              <div>
                <div className="my-goals">
                  <div>
                    <p className="my-goals_meta white-p bold-p">
                      <img
                        src={roses}
                        alt="Logo Roses"
                        className="my-goals_logo"
                      />
                      {thousandsFormat(Number(goal?.target))}
                    </p>
                    <p className="description">{goal?.title}</p>
                    <div className="line_progress">
                      <div
                        className="line_progress_complete"
                        style={{ width: goalProgress ?? 0 }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div
                      className="button"
                      onClick={() => {
                        if (availableRoses > 0) openModal?.();
                        else
                          showToast({
                            type: "info",
                            title: "Unable to complete your donate",
                            description: "You don't have roses",
                          });
                      }}
                    >
                      <img src={dollar} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userInterests && userInterests.length > 0 ? (
              <div>
                <div className="category-wrapper">
                  {userInterests?.map((info, index) => (
                    <Category
                      key={index}
                      children={textCapitalize(info).replace("_", " ")}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProducerInfo;
