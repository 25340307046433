import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

import FeedCards from "components/Cards/FeedCards";
import PageTitle from "components/PageTitles";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import ButtonIcon from "components/Buttons/ButtonIcon";
import Button from "components/Buttons/Button";
import ImageCircleBorder from "components/ImageCircleBorder";
import Loading from "components/Loading";
import SlidUp from "components/Modals/SlidUp";
import Modal from "components/Modals/Modal";
import SubscriptionPlan from "components/SubscriptionPlan";
import CardFallback from "components/Cards/CardFallback";
import CoverImage from "components/CoverImage";

import { useDeskNavigation, Params } from "hooks/useDeskNavigation";
import { useUserInfo } from "hooks/userInfo";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useUnlockContent } from "hooks/useUnlockContent";
import { useToast } from "hooks/useToast";
import { useOpenChat } from "hooks/useOpenChat";
import { useSubscribePlan } from "hooks/useSubscribePlan";
import { usePullToRefreshHandler } from "hooks/usePullToRefreshHandler";

import { useIconContext } from "contexts/iconNavContext";
import { MomentsTriggerContext } from "contexts/momentTrigger";
import { FavoritesListContext } from "contexts/favoritesListTrigger";
import { CreatorDetailsContext } from "contexts/creatorDetails";
import { PullToRefreshContext } from "contexts/pullToRefreshContext";

import postAddToFavorite from "api/favorite/postAddToFavorite";
import deleteRemoveFromFavorites from "api/favorite/deleteRemoveFromFavorites";
import getPublicDetails from "api/publicDetails/getProfileDetails";
import getPostsUserById from "api/posts/getPostsUserById";
import deletePosts from "api/posts/deletePosts";
import postPostsView from "api/posts/postPostsView";
import getPostsById from "api/posts/getPostsById";
import getCheckSubscription from "api/subscribe/getCheckSubscription";
import getSubscribePlans from "api/subscribe/getSubscribePlans";
import postSubscribe from "api/subscribe/postSubscribe";
import getUserInfoByUsername from "api/userInfo/getUserInfoByUsername";

import { textCapitalize } from "utils/textCapitalize";
import { hexToRgba } from "utils/hexToRgba";
import { shortClassNames } from "utils/shortClassNames";

import { PlanDuration } from "types/enum";
import { ICatalogResponse } from "types/catalog";

interface IPlan {
  months: number;
  billingCycle: PlanDuration;
  price: number;
  discount: number;
  priceWithoutDiscount: number;
}

const FeedPage: React.FC = () => {
  const {
    setMainScreen,
    setSecondaryScreen,
    setFeedScreen,
    componentPrincipal,
    componentSecondary,
    params,
  } = useDeskNavigation();

  const { userId } = params;
  const { userInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();
  const { handleButtonClick } = useIconContext();

  const { setMomentTrigger } = useContext(MomentsTriggerContext);
  const { setFavoritesListTrigger } = useContext(FavoritesListContext);
  const { creatorDetails, setCreatorDetails } = useContext(
    CreatorDetailsContext
  );
  const { unlockContentSubmit } = useUnlockContent();

  const { showToast } = useToast();
  const openChat = useOpenChat(userId);

  const divRef = useRef<HTMLDivElement | null>(null);
  const feedRef = useRef<HTMLDivElement | null>(null);
  const headerDiv = useRef<HTMLHeadElement | null>(null);

  const feedCardsContainer = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  const lastPostRef = useRef<HTMLDivElement | null>(null);
  const pullToRefresh = usePullToRefreshHandler(feedCardsContainer);

  const [windowWidthSize, setWindowWidthSize] = useState(0);
  const [classWidth, setClassWidth] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);
  const [isSub, setIsSub] = useState(false);
  const [postsTotal, setPostsTotal] = useState("0");
  const [photosTotal, setPhotosTotal] = useState("0");
  const [videosTotal, setVideosTotal] = useState("0");
  const [isLoaded, setIsLoaded] = useState(false);
  const [fallbackText, setFallbackText] = useState({
    text1: "No Content Here Yet!",
    text2: "This creator hasn’t uploaded any content yet. Check back soon!",
  });

  const [creatorPosts, setCreatorPosts] = useState<any[]>([]);
  const [creatorInfos, setCreatorInfos] = useState<ICatalogResponse | null>(
    null
  );
  const [isOpenPostsModal, seIsOpenPostsModal] = useState(false);
  const [selectedPostIndex, setSelectedPostIndex] = useState<number | null>(
    null
  );
  const modalPostsContainerRef = useRef<HTMLDivElement | null>(null);
  const [pageParams, setPageParams] = useState({} as Params);
  const [profileAvatar, setProfileAvatar] = useState<string | null>(null);
  const [lastPost, setLastPost] = useState<{ postId: string } | undefined>();

  //Subscribe
  const [isLoadingSubscribe, setIsLoadingSubscribe] = useState(false);
  const [isDisableSubscribe, setIsDisableSubscribe] = useState(false);
  const [billingCycle, setBillingCycle] = useState("");
  const [isOpenSubscribe, setIsOpenSubscribe] = useState(false);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [oneMonthPrice, setOneMonthPrice] = useState(0);
  const { subscribePlan } = useSubscribePlan();
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);

  const paramsUrl = useParams();
  const navigate = useNavigate();
  const { creator_name } = paramsUrl;

  const options = {
    root: divRef.current,
    rootMargin: "0px",
    threshold: 0.5,
  };
  useEffect(() => {
    if (!creatorDetails && params.userId) {
      publicDetails();
    }

    // setIsLoading(true);
    // const getUserInfo = async () => {
    //   if (!creator_name || !userInfo.access_token) return;
    //   try {
    //     const request = await getUserInfoByUsername(
    //       userInfo.access_token,
    //       creator_name
    //     );
    //     switch (request.status) {
    //       case 200:
    //         setCreatorDetails(request.res);
    //         if (!request.res?.userId) return;
    //         userPosts(request.res?.userId);
    //         // setFeedScreen("/feed", { userid: request.res.userId });
    //         // navigate("/app");
    //         break;
    //       case 404:
    //         navigate("*");
    //         break;
    //       default:
    //         break;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   setIsLoading(false);
    // };
    // getUserInfo();
  }, [, userInfo.access_token]);

  const scrollToModalPostsItem = useCallback(() => {
    if (selectedPostIndex === null) {
      return;
    }
    const item = itemsRef.current[selectedPostIndex];
    const container = modalPostsContainerRef.current;

    if (item && container) {
      const itemTop = item.offsetTop;

      container.scrollTo({
        top: itemTop,
      });
    }
  }, [selectedPostIndex]);

  const scrollToPostsItem = useCallback(() => {
    if (selectedPostIndex === null) {
      return;
    }
    const item = itemsRef.current[selectedPostIndex];
    const container = feedCardsContainer.current;

    if (item && container) {
      const itemTop = item.offsetTop;

      container.scrollTo({
        top: itemTop,
      });
    }
  }, [selectedPostIndex]);

  const onOpenPostsModal = useCallback((selectedIndex: number) => {
    setSelectedPostIndex(selectedIndex);
    seIsOpenPostsModal(true);
  }, []);

  const onClosePostsModal = useCallback((selectedIndex: number) => {
    setSelectedPostIndex(selectedIndex);
    seIsOpenPostsModal(false);
  }, []);

  const handlerDeletePost = useCallback(
    async (postId: string) => {
      try {
        await deletePosts(userInfo.access_token, { postId });
        setCreatorPosts((prevPosts) =>
          prevPosts.filter((post) => post.postId !== postId)
        );
      } catch (error) {
        showToast({
          type: "error",
          title: "No Content Here Yet!",
          description:
            "This creator hasn’t uploaded any content yet. Check back soon!",
        });
      }
    },
    [userInfo.access_token]
  );

  const handlerUnlockPost = useCallback(
    async (postId: string, price: number) => {
      try {
        await new Promise<void>((resolve, reject) => {
          unlockContentSubmit(
            postId,
            price,
            "feed",
            () => {
              resolve();
            },
            true
          );
        });

        const { res } = await getPostsById(userInfo.access_token, { postId });

        setCreatorPosts((prevPosts) => {
          return prevPosts.map((post) => {
            if (post.postId === postId) {
              return { ...post, ...res };
            }
            return post;
          });
        });
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, try again",
          type: "error",
        });
      }
    },
    [unlockContentSubmit, userInfo.access_token]
  );

  // useEffect(() => {
  //   setCreatorDetails(null);
  // }, [setCreatorDetails]);

  useEffect(() => {
    if (selectedPostIndex === null) {
      return;
    }
    if (isOpenPostsModal) {
      scrollToModalPostsItem();
    } else {
      scrollToPostsItem();
    }
  }, [
    isOpenPostsModal,
    scrollToModalPostsItem,
    scrollToPostsItem,
    selectedPostIndex,
  ]);

  useEffect(() => {
    userPosts(userId);
  }, [userInfo.access_token, userInfo.user_id]);

  const userPosts = async (userId: string) => {
    try {
      const { res } = await getPostsUserById(userInfo.access_token, {
        userId,
      });
      setCreatorInfos(res.posts[0].autor);
      setIsProfileFavorite(creatorDetails?.isFavorite ?? false);
      setProfileAvatar(
        res?.posts[0].autor.profile.avatar?.thumbnailUrl ||
          res?.posts[0].autor.profile.avatar?.mediumUrl ||
          res?.posts[0].autor.profile.avatar?.url
      );
      setCreatorPosts((prevMessages) => [...prevMessages, ...res.posts]);
      setIsSub(res.posts[0]?.hasActiveSubscription);
      setPostsTotal(res.total);
      setPhotosTotal(res.photosCount);
      setVideosTotal(res.videosCount);
    } catch (error) {
      setFallbackText({
        text1: "No Content Here Yet!",
        text2: "This creator hasn’t uploaded any content yet. Check back soon!",
      });
    } finally {
      setIsLoaded(!isLoaded);
    }
  };

  useEffect(() => {
    if (creatorPosts.length > 0) {
      setLastPost(creatorPosts.at(-1));
    }
  }, [creatorPosts]);

  useEffect(() => {
    if (itemsRef.current.length === 0) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const beholder = entry.target;

          const postId = beholder.id;

          if (postId === lastPostRef?.current?.id) {
            loadCards(postId, "bottom");
          }

          const cards = creatorPosts.find(
            (feedCard) => feedCard.postId === postId
          );
          if (cards && !cards.hasSeen && userInfo.user_id !== cards.userId) {
            markFeedCardAsRead(postId);
          }

          observer.unobserve(entry.target);
        }
      });
    }, options);

    itemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);
      }
    });

    return () => observer.disconnect();
  }, [creatorPosts]);

  useEffect(() => {
    if (creatorPosts.length > 0) {
      setLastPost(creatorPosts[creatorPosts.length - 1]);
    }
  }, [creatorPosts]);

  const loadCards = async (
    cursor: string | undefined,
    direction: "top" | "bottom"
  ) => {
    if (isLoading || cursor === undefined) return;

    setIsLoading(true);

    const cardsParams = {
      userId,
      take: direction === "top" ? 0 : 10,
      cursor,
    };

    try {
      const { res } = await getPostsUserById(
        userInfo.access_token,
        cardsParams
      );

      if (res.posts.length > 0) {
        setCreatorPosts((prevMessages) => {
          if (
            !res.posts.some(
              (newPost: { postId: any }) =>
                newPost.postId === prevMessages.at(-1)?.postId
            )
          ) {
            return [...prevMessages, ...res.posts];
          }
          return prevMessages;
        });
      }
    } catch (error) {
      setFallbackText({
        text1: "No Content Here Yet!",
        text2: "This creator hasn’t uploaded any content yet. Check back soon!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const markFeedCardAsRead = async (id: string) => {
    try {
      await postPostsView(userInfo.access_token, { postId: id });
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          const size = Math.floor(width + 48);
          setClassWidth(size);
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;
          const pageTotalPaddingAndMargin = windowSize <= 768 ? 16 : 32;
          setWindowWidthSize(Math.floor(width + pageTotalPaddingAndMargin));
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const checkSubscription = async () => {
      if (creatorDetails && userInfo.access_token) {
        const { res } = await getCheckSubscription(
          userInfo.access_token,
          creatorDetails.userId
        );
        setIsSub(res.hasSubscription);
      }
    };
    checkSubscription();
  }, [creatorDetails, userInfo.access_token]);

  useEffect(() => {
    // publicDetails();
    setPageParams(params);
  }, [params]);

  const publicDetails = async () => {
    setIsLoading(true);

    try {
      const request = await getPublicDetails(
        userInfo.access_token,
        params.userId
      );
      setCreatorDetails(request.res);
      setIsProfileFavorite(request.res?.isFavorite ?? false);
      setProfileAvatar(
        request.res?.profile.avatar.thumbnailUrl ||
          request.res?.profile.avatar?.mediumUrl ||
          request.res?.profile.avatar?.url ||
          ""
      );
    } catch (error) {
      showToast({
        title: "Error",
        description: "We were unable to process the request. Please, try again",
        type: "error",
      });
    }

    setIsLoading(false);
  };

  const clickLikeHandler = async () => {
    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(userInfo.access_token, userId);
      switch (response.status) {
        case 201:
        case 204:
          setFavoritesListTrigger((prev) => !prev);
          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, try again",
            type: "error",
          });
          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        userId
      );

      switch (response.status) {
        case 204:
        case null:
          setFavoritesListTrigger((prev) => !prev);

          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, try again",
            type: "error",
          });
          break;
      }
    }
    setMomentTrigger((prev) => !prev);
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        if (creatorDetails) {
          const { res, status } = await getSubscribePlans(
            userInfo.access_token,
            creatorDetails.userId
          );
          if (status !== 200) {
            throw new Error(`Failed to fetch subscription plans ${res}`);
          }
          res.plans.map((value: IPlan) => {
            if (value.billingCycle === "ONE_MONTH") {
              setOneMonthPrice(value.price);
            }
          });
          const planList = res.plans.filter(
            (value: IPlan) => value.billingCycle !== "ONE_MONTH"
          );
          setPlans(planList);
        }
      } catch (error) {
        console.error("Error getSubscribePlans:", error);
      } finally {
        setIsLoadingSubscribe(false);
      }
    };

    fetchPackages();
  }, [userInfo.access_token, creatorDetails]);

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "Posts",
      textRight: postsTotal,
      // iconLeft: "star-outline",
      clickAction: () => {},
      id: 1,
    },
    {
      text: "Photos",
      textRight: photosTotal,
      // iconLeft: "photo",
      clickAction: () => {},
      id: 2,
    },
    {
      text: "Videos",
      textRight: videosTotal,
      // iconLeft: "video",
      clickAction: () => {},
      id: 3,
    },
    // {
    //   text: "Requests",
    //   textRight: requestsTotal,
    //   iconLeft: "gifts",
    //   clickAction: () => {},
    //   id: 4,
    // },
  ];

  const closeFeed = () => {
    if (creator_name) {
      navigate("/app");
    }
    setFeedScreen("");

    if (!componentPrincipal && componentSecondary) {
      setSecondaryScreen("");
    }
    setMainScreen("/catalog");
    handleButtonClick("grid");
  };

  const subscribeHandler = async (customBilling?: string) => {
    setIsDisableSubscribe(true);
    try {
      if (!creatorDetails) {
        throw new Error("You need to insert a creator.");
      }
      if (!customBilling && !billingCycle) {
        throw new Error("You need to choose a subscription plan.");
      }
      const data = {
        creatorId: creatorDetails.userId,
        billingCycle: customBilling || billingCycle,
      };
      await subscribePlan(data.creatorId, data.billingCycle);
      showToast({
        type: "success",
        title: "Successful subscription!",
        description: `You are now a ${creatorDetails.displayname} subscriber.`,
      });
      setIsSub(true);
    } catch (error: any) {
      // console.error("Error postSubscribe:", error);
      showToast({
        type: "error",
        title: "No Content Here Yet!",
        description: error.message,
      });
    } finally {
      setIsOpenSubscribe(false);
      setIsDisableSubscribe(false);
    }
  };

  const subscribeOneMonthHandler = async () => {
    subscribeHandler("ONE_MONTH");
  };

  const backgroundColor = creatorDetails?.profile.feedHexColor
    ? hexToRgba(creatorDetails?.profile?.feedHexColor, 0.35)
    : "rgba(91, 207, 255, 0.35)";

  const backgroundImageUrl = creatorInfos?.profile?.banner?.url ?? "";

  const backgroundStyle = {
    backgroundImage: `
    linear-gradient(rgba(91, 207, 255, 0) 0, ${backgroundColor}100%), linear-gradient(to top, rgba(0, 0, 0, 0.86) 0px, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0) 300px), linear-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0) 40%), 
    url("${backgroundImageUrl}")`,
    backgroundPosition: creatorInfos?.profile?.banner?.url ? "top" : undefined,
  };

  const chatClickHandler = () => {
    openChat();
  };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsHeaderVisible(true);
  //       } else {
  //         setIsHeaderVisible(false);
  //       }
  //     },
  //     { threshold: 0 }
  //   );

  //   if (headerDiv.current) {
  //     observer.observe(headerDiv.current);
  //   }

  //   return () => observer.disconnect();
  // }, []);

  if (creator_name && !creatorInfos?.profile?.banner?.url) {
    return <Loading className="feed-page__loading" />;
  }

  return (
    <div
      ref={divRef}
      className={shortClassNames([
        "feed-page",
        "standard-page-layout",
        "feed-page-wrapper",
        "wrapper",
        componentPrincipal && componentSecondary
          ? "vertical-separator-left"
          : "",
      ])}
    >
      <PageTitle
        onBackClick={closeFeed}
        className="feed-page__page-title"
        hasCloseIcon={windowSize >= 768}
        // degradeMidnight
      />
      <div className="feed-page-inner inner">
        {/* <CoverImage
        creatorInfo={{
          creatorCoverImage: CoverPhoto,
          creatorName: creatorDetails?.displayname || "",
					}}
					/> */}
        <main ref={feedCardsContainer} className={`feed-page-main`}>
          <header ref={headerDiv} className="feed__label">
            <div
              style={backgroundStyle}
              className={`feed-page__interaction-nav inner ${
                windowWidthSize <= 768 ? "mobile-size" : ""
              }`}
            >
              <div className="feed__label-top">
                <div className="feed__label-left">
                  {creatorDetails?.isVip && (
                    <span
                      className="profile__icon icon
										 icon-burn icon-md icon-primary-75"
                    />
                  )}
                  {creatorDetails?.profile?.isAI && (
                    <div className="ia-icon">
                      <span className="profile-ia-box">AI</span>
                    </div>
                  )}
                </div>

                <div className="feed__label-statistics">
                  <span
                    className="icon icon-heart-solid
									 icon-md icon-primary-75"
                  />
                  <p className="counter">12.3k</p>
                </div>
              </div>

              <div className="feed__label-bottom">
                <div
                  className={`interaction-nav__profile-info ${
                    windowWidthSize <= 768 ? "info-column" : ""
                  }`}
                >
                  <ImageCircleBorder
                    centerImage={
                      profileAvatar ??
                      creatorDetails?.profile?.avatar?.thumbnailUrl
                    }
                    centerImageAlt="user profile"
                    size={40}
                  />

                  <div className="profile-info__name">
                    <p className="profile-info__text">
                      {creatorDetails?.displayname.replaceAll("_", " ")}
                      {/* {creatorDetails?.isVip && (
                  <span className="icon icon-burn icon-md  " />
                )} */}
                    </p>
                    {creatorDetails?.profile && (
                      <p className="profile-info__text">
                        {creatorDetails.profile.age}{" "}
                        {creatorDetails.profile.occupation &&
                          `| ${textCapitalize(
                            creatorDetails.profile.occupation.replaceAll(
                              "_",
                              " "
                            )
                          )}`}
                      </p>
                    )}
                  </div>
                </div>

                <div className="interaction-nav__interaction">
                  <ButtonIcon
                    key={0}
                    onClick={clickLikeHandler}
                    buttonStyle={
                      isProfileFavorite ? "transparentActive" : "transparent"
                    }
                    icon={
                      isProfileFavorite
                        ? "icon-heart-solid"
                        : "icon-heart-outline icon-gray-light"
                    }
                  />
                  {[
                    <ButtonIcon
                      key={1}
                      onClick={chatClickHandler}
                      icon={"icon-messages icon-gray-light"}
                      buttonStyle="transparent"
                    />,
                    <Button
                      buttonStyle={isSub ? "tertiary" : "primary"}
                      key={2}
                      disabled={isSub}
                      onClick={() => setIsOpenSubscribe(true)}
                    >
                      {isSub ? "Subscribed" : "Subscribe"}
                    </Button>,
                  ][windowWidthSize <= 768 ? "reverse" : "slice"]()}
                </div>
              </div>
            </div>
            <div className="buttons-navigation-feed-container">
              <ButtonNavigation
                buttons={buttonsGroup}
                horizontalMargin={false}
              />
            </div>
          </header>
          {!isLoaded ? (
            <Loading className="feed-global-loading" />
          ) : (
            <div
              ref={feedRef}
              className={`feed__posts ${!isHeaderVisible ? "padding-for-navigation" : ""}`}
            >
              {creatorPosts.length > 0 ? (
                creatorPosts.map((post, index) => {
                  return (
                    <FeedCards
                      className={`${!isHeaderVisible ? "padding-for-navigation" : ""}`}
                      key={post.postId}
                      post={post}
                      isExpanded={isOpenPostsModal}
                      handleExpand={() => onOpenPostsModal(index)}
                      onDelete={() => handlerDeletePost(post.postId)}
                      onUnlockedPost={handlerUnlockPost}
                      isGlobal={false}
                      ref={(el) => {
                        if (el) {
                          itemsRef.current[index] = el;
                        }
                        if (index === creatorPosts.length - 1) {
                          lastPostRef.current = el;
                        }
                      }}
                    />
                  );
                })
              ) : (
                <CardFallback
                  title={fallbackText.text1}
                  desc={fallbackText.text2}
                  onClick={closeFeed}
                />
              )}
            </div>
          )}
          {isOpenPostsModal && (
            <Modal
              className="feed-posts-expand-modal"
              isOpen={isOpenPostsModal}
              onClose={() => onClosePostsModal(0)}
              isFullWidth
            >
              <div
                className="feed-posts-expand-modal-list"
                ref={modalPostsContainerRef}
              >
                {!isLoaded ? (
                  <Loading className="feed-global-loading" />
                ) : creatorPosts.length > 0 ? (
                  creatorPosts.map((post, index) => {
                    return (
                      <div
                        className="feed-posts-expand-modal-list-item"
                        key={`modal-posts-${post.postId}`}
                      >
                        <FeedCards
                          post={post}
                          isExpanded={isOpenPostsModal}
                          handleExpand={() => onClosePostsModal(index)}
                          onDelete={() => handlerDeletePost(post.postId)}
                          onUnlockedPost={handlerUnlockPost}
                          isGlobal={false}
                          ref={(el) => {
                            if (el) {
                              itemsRef.current[index] = el;
                            }
                            if (index === creatorPosts.length - 1) {
                              lastPostRef.current = el;
                            }
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <CardFallback
                    title={fallbackText.text1}
                    desc={fallbackText.text2}
                    onClick={closeFeed}
                  />
                )}
              </div>
            </Modal>
          )}
        </main>
      </div>

      {windowSize <= 768 ? (
        <SlidUp
          isOpen={isOpenSubscribe}
          classNameContainer="split-up-container"
          classNameChildren="split-up__slide-children"
          closeArea={() => {
            setIsOpenSubscribe(false);
          }}
          header={{
            title: "Subscription Plan",
            subtitle: "Select subscription below",
          }}
        >
          <SubscriptionPlan
            onClose={() => setIsOpenSubscribe(false)}
            onSelectItem={(item) => {
              setBillingCycle(item.billingCycle);
            }}
            onSubscribe={subscribeHandler}
            onSubscribeMonth={subscribeOneMonthHandler}
            plans={plans}
            isLoading={isLoadingSubscribe}
            disabled={isDisableSubscribe}
            oneMonthPrice={oneMonthPrice}
          />
        </SlidUp>
      ) : (
        <Modal
          className="feed-page__modal"
          isOpen={isOpenSubscribe}
          onClose={() => setIsOpenSubscribe(false)}
          header={{
            title: "Subscription Plan",
            subtitle: "Select subscription below",
          }}
        >
          <SubscriptionPlan
            onClose={() => setIsOpenSubscribe(false)}
            onSelectItem={(item) => {
              setBillingCycle(item.billingCycle);
            }}
            onSubscribe={subscribeHandler}
            onSubscribeMonth={subscribeOneMonthHandler}
            plans={plans}
            isLoading={isLoadingSubscribe}
            disabled={isDisableSubscribe}
            oneMonthPrice={oneMonthPrice}
          />
        </Modal>
      )}
    </div>
  );
};

export default FeedPage;
