import PageTitle from "components/PageTitles";
import "./styles.scss";

import Button from "components/Buttons/Button";
import ListItemLink from "components/Lists/ListItemLink";
import ListItemToggle from "components/Lists/ListItemToggle";
import ProgressbarWithoutAnimation from "components/ProgressbarWithoutAnimation";

import { useIconContext } from "contexts/iconNavContext";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useToast } from "hooks/useToast";

import PrivacyIconGray from "assets/icons/privacy-icon-gray.svg";
import Reorder from "assets/icons/reorder-gray.svg";

const MyRequestPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { windowSize } = useWindowWidthSize();

  const { showToast } = useToast();

  const pageBack = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings-profile-customize");
    } else {
      setPrincipalScreen("/user-settings-profile-customize");
    }

    handleButtonClick("grid");
  };

  const listRequest = [
    {
      title: "Dick Rating 🔥",
      text: "Send me a picture",
      value: 10,
      isGold: false,
      to: "/",
    },
    {
      title: "Voice Rating 💋",
      text: "Send me a picture",
      value: 55,
      isGold: false,
      to: "/",
    },
    {
      title: "One more 🌹",
      text: "Send me a picture",
      value: 100,
      isGold: false,
      to: "/",
    },
    {
      title: "Maybe one",
      text: "Send me a picture",
      value: 1,
      isGold: true,
      to: "/",
    },
  ];

  return (
    <div className="my-request my-request-wrapper wrapper standard-page-layout bottom-spacing">
      <div className="my-request-inner inner">
        <PageTitle
          title={"My Request"}
          subtitle={"Request List"}
          hasCloseIcon={windowSize >= 768}
          onBackClick={pageBack}
        />

        <div className="my-request__main">
          <div className="my-request__bars">
            {[...Array(6)].map((_, i) => (
              <ProgressbarWithoutAnimation
                key={i}
                index={i}
                activeIndex={0}
                isPink
              />
            ))}
          </div>

          <div className="my-request__list">
            <div>
              {listRequest &&
                listRequest.map((item, index, arr) => {
                  const { title, text, value, isGold, to } = item;

                  return (
                    <ListItemLink
                      key={`my-request-${index}`}
                      highlightText={title}
                      simpleText={text}
                      imageLeft={Reorder}
                      hasArrowRight
                      hasSeparator={arr.length > index + 1}
                      isCompact
                      rightNodeContent={
                        <span
                          className={`my-request__value ${
                            isGold ? "my-request__value--gold" : ""
                          }`}
                        >
                          {value}
                        </span>
                      }
                      className="my-request__list-item-link"
                      classImageCircle="my-request__list-icon"
                    />
                  );
                })}
            </div>

            <Button
              children={"Add Request"}
              buttonStyle={"tertiary"}
              onClick={() => setPrincipalScreen("/create-request")}
            />
          </div>

          <ListItemToggle
            highlightText="Deactivate all requests"
            simpleText="Don’t allow any requests"
            isImageCircle
            imageLeft={PrivacyIconGray}
            toggleCallBack={() =>
              showToast({
                type: "info",
                title: "Coming soon!",
                description: "We appreciate your patience.",
              })
            }
          />

          <div className="my-request__btns">
            <Button
              children={"Skip"}
              buttonStyle={"tertiary"}
              onClick={pageBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRequestPage;
