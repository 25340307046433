import React from "react";
import "./styles.scss";
import PageTitle from "components/PageTitles";
import InputText from "components/Inputs/InputText";
import ListItemLink from "components/Lists/ListItemLink";
import Button from "components/Buttons/Button";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";

interface CollaboratorsListProps {
  addCollaboratorInListToSend: (id: string) => void;
  collaboratorsListBackButton: () => void;
  searchInCollaboratorList: (e: string) => void;
  inputSearchCollaborator: string;
  filteredCollaborator: Collaborator[];
  setChatIdsForSendContent: (value: React.SetStateAction<string[]>) => void;
  chatIdsForSendContent: string[];
  collaboratorList: Collaborator[];
  shareWihAllHandler: () => void;
  finishSelectionHandler: () => void;
  collaboratorChatIdToSend?: string;
}

type Collaborator = {
  id: string;
  image: string;
  name: string;
};

const CollaboratorsList: React.FC<CollaboratorsListProps> = (props) => {
  const {
    addCollaboratorInListToSend,
    collaboratorsListBackButton,
    searchInCollaboratorList,
    inputSearchCollaborator,
    filteredCollaborator,
    setChatIdsForSendContent,
    chatIdsForSendContent,
    collaboratorList,
    shareWihAllHandler,
    finishSelectionHandler,
    collaboratorChatIdToSend,
  } = props;

  const { windowSize } = useWindowWidthSize();
  const removeAllIdsToSend = () => {
    setChatIdsForSendContent([]);
  };

  return (
    <section className="collaborator-list">
      <PageTitle
        title={"Message"}
        subtitle={"Share privately to people"}
        onBackClick={collaboratorsListBackButton}
        hasCloseIcon={false}
        // degradeMidnight
        className="add-media-page__page-title"
        hasButtonNavigation={
          windowSize < 700 || (windowSize > 700 && !collaboratorChatIdToSend)
        }
      />

      <section className="collaborator-list__list add-media-page__inner">
        <InputText
          searchInput
          inputType="text"
          placeholder="Search"
          onChangeInput={searchInCollaboratorList}
          value={inputSearchCollaborator}
        />

        <div className="list__users-items">
          {filteredCollaborator
            .sort((a, b) => {
              if (a.id === collaboratorChatIdToSend) return -1;
              if (b.id === collaboratorChatIdToSend) return 1;
              return 0;
            })
            .map((consumer, index, arr) => (
              <ListItemLink
                key={index}
                highlightText={consumer.name}
                hasSeparator={arr.length > index + 1}
                iconFill
                isImageCircle
                imageLeft={consumer.image}
                avatarImgStyle
                rightNodeContent={
                  <div className="collaborator-list__button-send-container">
                    <button
                      onClick={() => addCollaboratorInListToSend(consumer.id)}
                      className={`button-send ${
                        chatIdsForSendContent.includes(consumer.id)
                          ? "sended-button"
                          : ""
                      }`}
                    >
                      {chatIdsForSendContent.includes(consumer.id) ? (
                        <span className="icon icon-check icon-green icon-lg" />
                      ) : (
                        "Add"
                      )}
                    </button>
                  </div>
                }
              />
            ))}
        </div>
        <div className="add-media-page__buttons-container">
          {collaboratorList.length === chatIdsForSendContent.length &&
          chatIdsForSendContent.length !== 0 ? (
            <Button buttonStyle="tertiary" onClick={removeAllIdsToSend}>
              Remove all from list
            </Button>
          ) : (
            <Button buttonStyle="tertiary" onClick={shareWihAllHandler}>
              Share With All
            </Button>
          )}

          <Button buttonStyle="primary" onClick={finishSelectionHandler}>
            Done
          </Button>
        </div>
      </section>
    </section>
  );
};

export default CollaboratorsList;
