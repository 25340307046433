import React, { ReactNode, useContext, useEffect, useState } from "react";
import "./styles.scss";

import HeaderHomePage from "../../Headers/HeaderHomePage";

import { ToggleChatOpenContext } from "contexts/toggleChatOpen";
import { useIconContext } from "contexts/iconNavContext";

import { useDeskNavigation } from "../../../hooks/useDeskNavigation";

import { shortClassNames } from "utils/shortClassNames";

interface DeskProps {
  principalMain?: ReactNode;
  secondaryMain?: ReactNode;
  tertiaryMain?: ReactNode;
  catalogMain?: ReactNode;
  feedMain?: ReactNode;
  profileAvatar: string | undefined | null;
  hasANewMessage: boolean;
  hasANotification: boolean;
  clickProfileAvatar: (a: any) => void;
  clickFavorites: (a: any) => void;
  clickChatList: (a: any) => void;
  clickOnGrid: () => void;
}

const Desk: React.FC<DeskProps> = ({
  principalMain,
  secondaryMain,
  tertiaryMain,
  catalogMain,
  feedMain,
  profileAvatar,
  hasANewMessage,
  hasANotification,
  clickProfileAvatar,
  clickFavorites,
  clickChatList,
  clickOnGrid,
}) => {
  const {
    params,
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setMainScreen,
  } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { fullscreen } = params;
  const [isPrincipalMainVisible, setIsPrincipalMainVisible] = useState(false);
  const [isSecondaryMainVisible, setIsSecondaryMainVisible] = useState(false);
  const [isTertiaryMainVisible, setIsTertiaryMainVisible] = useState(false);
  const [isFeedMainVisible, setIsFeedMainVisible] = useState(false);
  const [isPageFullScreen, setIsPageFullScreen] = useState(false);

  const { setIsChatPageOpen } = useContext(ToggleChatOpenContext);

  useEffect(() => {
    if (params && fullscreen) {
      setIsPageFullScreen(JSON.parse(fullscreen));
    }
  }, [fullscreen, params]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [isPrincipalMainVisible, isSecondaryMainVisible, isTertiaryMainVisible]);

  useEffect(() => {
    if (principalMain) {
      setIsPrincipalMainVisible(true);
    } else {
      setIsPrincipalMainVisible(false);
    }
  }, [principalMain]);

  useEffect(() => {
    if (secondaryMain) {
      setIsSecondaryMainVisible(true);
    } else {
      setIsSecondaryMainVisible(false);
    }
  }, [secondaryMain]);

  useEffect(() => {
    if (tertiaryMain) {
      setIsTertiaryMainVisible(true);
    } else {
      setIsTertiaryMainVisible(false);
    }
  }, [tertiaryMain]);

  useEffect(() => {
    if (feedMain) {
      setIsFeedMainVisible(true);
    } else {
      setIsFeedMainVisible(false);
    }
  }, [feedMain]);

  const logoClick = () => {
    setIsChatPageOpen(false);
    setFeedScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("");
    setMainScreen("");
    handleButtonClick("grid");
    clickOnGrid();
  };

  const clickGridButton = () => {
    clickOnGrid();
  };

  return (
    <div id="desk-wrapper">
      {!isPageFullScreen && (
        <HeaderHomePage
          hasANewMessage={hasANewMessage}
          hasANotification={hasANotification}
          profileAvatar={profileAvatar}
          logoClick={logoClick}
          clickProfileAvatar={clickProfileAvatar}
          clickFavorites={clickFavorites}
          clickChatList={clickChatList}
          clickOnGrid={clickGridButton}
        />
      )}

      <main
        className={shortClassNames([
          "main-page-desk",
          `${
            isFeedMainVisible
              ? "grid-template-feed"
              : isPrincipalMainVisible || isSecondaryMainVisible
                ? `space-without-header ${
                    isTertiaryMainVisible ? "grid-template-changed" : ""
                  }`
                : ""
          }`,
        ])}
      >
        <div
          className={shortClassNames([
            "catalog-main",
            `${
              isFeedMainVisible
                ? "hide-catalog-for-feed"
                : isSecondaryMainVisible &&
                    isTertiaryMainVisible &&
                    isPrincipalMainVisible
                  ? "hide-catalog-for-gallery"
                  : isPrincipalMainVisible && !isSecondaryMainVisible
                    ? "hide-catalog"
                    : isSecondaryMainVisible && isPrincipalMainVisible
                      ? "secondary-hide-catalog"
                      : isSecondaryMainVisible && !isPrincipalMainVisible
                        ? "only-secondary-main-is-show"
                        : "show-catalog"
            }`,
          ])}
        >
          {catalogMain}
        </div>

        <div
          className={shortClassNames([
            `feed-main`,
            `${
              isFeedMainVisible &&
              !isPrincipalMainVisible &&
              !isSecondaryMainVisible
                ? "feed-main-show"
                : isFeedMainVisible &&
                    isPrincipalMainVisible &&
                    !isSecondaryMainVisible
                  ? "feed-principal-main-show"
                  : isFeedMainVisible &&
                      !isPrincipalMainVisible &&
                      isSecondaryMainVisible
                    ? "feed-secondary-main-show"
                    : isFeedMainVisible &&
                        isPrincipalMainVisible &&
                        isSecondaryMainVisible
                      ? "feed-principal-secondary-main-show"
                      : ""
            }`,
          ])}
        >
          {feedMain}
        </div>

        <div
          className={shortClassNames([
            "principal-main",
            `${
              isFeedMainVisible &&
              isPrincipalMainVisible &&
              !isSecondaryMainVisible
                ? "principal-feed-show principal-main-feed "
                : isFeedMainVisible &&
                    isPrincipalMainVisible &&
                    isSecondaryMainVisible
                  ? "principal-feed-secondary-show"
                  : isPrincipalMainVisible && !isSecondaryMainVisible
                    ? "show-principal-main"
                    : isPrincipalMainVisible &&
                        isSecondaryMainVisible &&
                        !isTertiaryMainVisible
                      ? "show-principal-main show-principal-and-secondary-main"
                      : isPrincipalMainVisible &&
                          isSecondaryMainVisible &&
                          isTertiaryMainVisible
                        ? "show-principal-main hide-principal-main-mobile"
                        : "hide-principal-main"
            }`,
          ])}
        >
          {principalMain}
        </div>

        <div
          className={shortClassNames([
            "secondary-main",
            `${
              isFeedMainVisible &&
              isPrincipalMainVisible &&
              isSecondaryMainVisible
                ? "secondary-feed-principal-show"
                : isFeedMainVisible &&
                    !isPrincipalMainVisible &&
                    isSecondaryMainVisible
                  ? "secondary-feed-main-show"
                  : isSecondaryMainVisible && !isTertiaryMainVisible
                    ? "show-secondary-main"
                    : isSecondaryMainVisible &&
                        isTertiaryMainVisible &&
                        isPrincipalMainVisible
                      ? "show-secondary-main show-secondary-and-tertiary-main"
                      : isSecondaryMainVisible &&
                          isTertiaryMainVisible &&
                          !isPrincipalMainVisible
                        ? "show-secondary-main only-secondary-and-tertiary-main-show"
                        : "hide-secondary-main"
            }`,
          ])}
        >
          {secondaryMain}
        </div>

        <div
          className={shortClassNames([
            "tertiary-main",
            `${
              isFeedMainVisible
                ? "hide-tertiary-main-for-feed"
                : isTertiaryMainVisible &&
                    isSecondaryMainVisible &&
                    isPrincipalMainVisible
                  ? "show-tertiary-main"
                  : !isPrincipalMainVisible &&
                      isSecondaryMainVisible &&
                      isTertiaryMainVisible
                    ? "show-all-main"
                    : "hide-tertiary-main"
            }`,
          ])}
        >
          {tertiaryMain}
        </div>
      </main>
    </div>
  );
};

export default Desk;
