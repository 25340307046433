import "./styles.scss";

import { shortClassNames } from "utils/shortClassNames";

import roses from "assets/icons/logos/logo-fill-pink.svg";
import goldenRoses from "assets/icons/logos/logo-fill-gold.svg";
import tokenRose from "assets/icons/navigationIcons/token-rose.svg";
import tokenGold from "assets/icons/navigationIcons/token-gold.svg";

interface IRoseCredit {
  text: string;
  value?: string | number;
  originalPrice?: string | number;
  discount?: number;
  active?: boolean;
  buttonStyle?: "gold" | "default";
  isPopular?: boolean;
  isGold?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

const RosesCredit: React.FC<IRoseCredit> = (props) => {
  const {
    text,
    value,
    originalPrice,
    discount,
    active,
    buttonStyle = "default",
    isPopular,
    isGold,
    disabled,
    className,
    onClick,
  } = props;
  return (
    <div
      className={shortClassNames([
        `${isPopular ? "roses-credit-container-popular" : ""}`,
        `${isPopular && active ? "roses-credit-container-popular-active" : ""}`,
        `${disabled ? "roses-credit__disabled" : ""}`,
      ])}
      data-gold={isGold}
    >
      {isPopular && (
        <p
          className={shortClassNames([
            `roses-credit__title`,
            `${active ? "roses-credit__title-active" : ""}`,
          ])}
        >
          Most Popular
        </p>
      )}
      <button
        onClick={onClick}
        disabled={disabled}
        className={shortClassNames([
          `roses-credit roses-credit--${buttonStyle}`,
          `${active ? "roses-credit--active" : ""}`,
          `${isGold && active ? "roses-credit--active-gold" : ""}`,
          `${isPopular ? "roses-credit__popular" : ""}`,
          `${isPopular && active ? "roses-credit__popular-active" : ""}`,
          ` ${className ?? ""}`,
        ])}
        data-is-gold={isGold}
      >
        {/* <div className="roses-credit__iconContainer">
<span className="roses-credit__icon" />
</div> */}
        <div className={`roses-credit__content inner`}>
          <div className="roses-credit__main">
            <div className="roses-credit__token">
              <img
                className="roses-credit__logo"
                src={!isGold ? tokenRose : tokenGold}
                alt=""
              />
              <span className={`roses-credit__text`}>{text}</span>
            </div>

            {value && (
              <div className="roses-credit__values">
                {value}
                {originalPrice && (
                  <>
                    <br />
                    <span className="roses-credit__values--old  ">
                      {originalPrice}
                    </span>
                  </>
                )}
              </div>
            )}
          </div>

          {discount && (
            <span className="roses-credit__discount margin-top-8">
              Save {discount}%
            </span>
          )}
        </div>
      </button>
    </div>
  );
};
export default RosesCredit;
