import { useContext, useState } from "react";

import { useDeskNavigation } from "./useDeskNavigation";
import { useChat } from "contexts/openedChatContext";
import { ToggleChatOpenContext } from "contexts/toggleChatOpen";
import { useUserInfo } from "./userInfo";

import CreateChatContact from "api/postCreateChatContact";
import verifyToken from "utils/verifyToken";

type OpenChatResponse = {
  status: number;
  res?: { chatId: string };
};

const useOpenChat = (userId?: string) => {
  const { setSecondaryScreen, params } = useDeskNavigation();
  const { setActiveChatId } = useChat();
  const toggleChatContext = useContext(ToggleChatOpenContext);
  const { userInfo } = useUserInfo();

  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);

  if (!toggleChatContext) {
    throw new Error(
      "useOpenChat must be used inside a ToggleChatOpenContext.Provider"
    );
  }

  const { setIsChatPageOpen } = toggleChatContext;

  const openChat = async (): Promise<OpenChatResponse | null> => {
    if (userInfo.account_type === "CREATOR" || userInfo.user_id === userId) {
      return null;
    }

    const creatorId = userId && userId !== "0" ? userId : params?.userId;
    if (!creatorId) return null;

    if (!verifyToken()) {
      setSecondaryScreen("/personal-chat", {
        chatId: "unauthenticated",
        userId: creatorId,
        isLocked: "false",
      });
      return { status: 401 };
    }

    const isOpenedChat = openedChatUserIds.includes(creatorId);
    setIsChatPageOpen(true);

    try {
      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        creatorId,
        userInfo.access_token
      );

      if (response.status === 201 && response.res?.chatId) {
        setActiveChatId(response.res.chatId);
        setSecondaryScreen("/personal-chat", {
          chatId: response.res.chatId,
          userId: creatorId,
          isLocked: JSON.stringify(isOpenedChat),
        });

        if (!isOpenedChat) {
          setOpenedChatUserIds((prev) => [...prev, creatorId]);
        }

        return response;
      }

      return { status: response.status };
    } catch (error) {
      console.error(
        "Error opening chat:",
        error instanceof Error ? error.message : error
      );
      return { status: 500 };
    }
  };

  return openChat;
};

export { useOpenChat };
