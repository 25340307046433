import React, { useEffect, useState } from "react";
import "./styles.scss";

import Button from "components/Buttons/Button";
import VerifyDocumentMessage from "components/VerifyDocumentMessage";

import { useUserInfo } from "hooks/userInfo";

import { IVerifyMessage } from "types/interfaces";

const verifyMessage: IVerifyMessage[] = [
  {
    icon: "Loading",
    title: "Verifying Document",
    text: "This may take a few seconds",
    status: "PENDING",
  },
  {
    icon: "icon-match",
    title: "Document Verified",
    text: "Your document was verified successfully",
    status: "APPROVED",
  },
  {
    icon: "icon-cancel",
    title: "Document Not Verifiable",
    text: "There was a little problem verifying your document. Please try again",
    status: "DENIED",
  },
];

const VerifyDocument: React.FC = () => {
  const { userInfo } = useUserInfo();

  const [applyStatus, setApplyStatus] = useState(
    userInfo.creator_application_status
  );

  const [message, setMessage] = useState<IVerifyMessage | null>(null);

  useEffect(() => {
    const index = verifyMessage.findIndex(
      (item) => item.status === applyStatus
    );
    setMessage(index >= 0 ? verifyMessage[index] : verifyMessage[0]);
  }, [applyStatus]);

  return (
    <div className="verify-document verify-document-wrapper wrapper">
      <div className="verify-document-inner inner">
        <main className="verify-document__body">
          {message && <VerifyDocumentMessage message={message} />}
        </main>

        {message && message.status === "DENIED" && (
          <Button children={"Try Again"} buttonStyle={"quaternary"} />
        )}
      </div>
    </div>
  );
};

export default VerifyDocument;
