import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import "./styles.scss";

import { useModal } from "hooks/useModal";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { usePublishContent } from "hooks/usePublishContent";
import { useUserInfo } from "hooks/userInfo";
import { useToast } from "hooks/useToast";

import { useWebSocket } from "contexts/webSocketContext";
import { MomentsTriggerContext } from "contexts/momentTrigger";

import { Chat } from "types/chatInfo";

import LoadingPage from "components/LoadingPage";
import ButtonPill from "components/Buttons/ButtonPill";
import PreviewMediaCardsList from "components/Cards/PreviewMediaCardsList";
import MediaStorageGallery from "./MediaStorageGallery";
import CreatorContent from "./CreatorContent";
import ExclusiveList from "./ExclusiveList";
import ButtonMediaLocation from "./ButtonMediaLocation";
import OpenedCamera from "./OpenedCamera";
import PreviewCapturedMedia from "./PreviewCapturedMedia";

import {
  addZMax,
  removeZMax,
  uploadFileInFeed,
  uploadFileInMoment,
  uploadFilesToChat,
} from "./utils";
import CollaboratorsList from "./CollaboratorsList";

interface AddMediaPageProps {
  previousPage?: "none" | "chat" | "home";
  chatIdToSendContent?: string;
  onUploadComplete?: () => void;
}

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

type Consumer = {
  id: string;
  image: string;
  name: string;
};

type Collaborator = {
  id: string;
  image: string;
  name: string;
};

const AddMediaPage: React.FC<AddMediaPageProps> = (props) => {
  const {
    previousPage = "none",
    onUploadComplete,
    chatIdToSendContent,
  } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { setMomentTrigger } = useContext(MomentsTriggerContext);

  //HOOKS
  const { setCreatorContent } = usePublishContent();
  const { setOpenUseModal, setModalContent } = useModal();
  const { windowSize } = useWindowWidthSize();
  const { getChats } = useWebSocket();
  const { userInfo } = useUserInfo();
  const { showToast } = useToast();

  //STATE
  const [files, setFile] = useState<File | null>(null);
  const [showLocationMediaUploadPills, setShowLocationMediaUploadPills] =
    useState(false);
  const [showButtonGallery, setShowButtonGallery] = useState(false);

  const [mediaToShow, setMediaToShow] = useState<ICapturedMedia | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [capturedMedia, setCapturedMedia] = useState<ICapturedMedia[] | []>([]);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [rosesAmount, setRosesAmount] = useState(0);
  const [shareContentLocation, setShareContentLocation] = useState("");
  const [consumerList, setConsumerList] = useState<Consumer[]>([]);
  const [collaboratorList, setCollaboratorList] = useState<Collaborator[]>([]);
  const [filteredConsumerList, setFilteredConsumerList] = useState<Consumer[]>(
    []
  );

  const [filteredCollabList, setFilteredCollabList] = useState<Consumer[]>([]);
  const [selectedIdentityPill, setSelectedIdentityPill] = useState(false);

  const [chatIdsForSendContent, setChatIdsForSendContent] = useState<string[]>(
    []
  );

  const [collabChatIdsForSendContent, setCollabChatIdsForSendContent] =
    useState<string[]>([]);
  const [showShieldPills, setShowShieldPills] = useState(false);
  const [inputSearchConsumer, setInputSearchConsumer] = useState<string>("");
  const [inputSearchCollaborator, setInputSearchCollaborator] =
    useState<string>("");
  const [showUploadButtons, setShowUploadButtons] = useState(false);
  const [uploadLocation, setUploadLocation] = useState("Teaser");
  const [currencySelected, setCurrencySelected] = useState("USD");
  const [createdTags, setCreatedTags] = useState<string[] | []>([]);
  const [createdTag, setCreatedTag] = useState<string>("");
  const [uploadType, setUploadType] = useState<"moment" | "feed">("feed");

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [screenShowing, setScreenShowing] = useState<
    "library" | "preview-captured" | "camera" | ""
  >("");

  const [secondaryScreenShowing, setSecondaryScreenShowing] = useState<
    "config" | "exclusive-list" | "collaborator-list" | "collaborator-list" | ""
  >("");

  useEffect(() => {
    // const header = document.getElementById("header-home-page");
    // header?.classList.add("show-header");

    getListChats();
    setScreenShowing("library");
    if (previousPage === "chat") {
      setSecondaryScreenShowing("exclusive-list");
    }

    if (chatIdToSendContent) {
      addConsumerInListToSend(chatIdToSendContent);
    }
  }, []);

  useEffect(() => {
    if (screenShowing !== "camera") {
      stopStream();
      stopRecording();
    }

    if (screenShowing === "camera" || screenShowing === "preview-captured") {
      addZMax();
    } else {
      removeZMax();
    }
  }, [screenShowing]);

  useEffect(() => {
    if (windowSize > 700) {
      if (secondaryScreenShowing !== "exclusive-list") {
        setSecondaryScreenShowing("config");
        if (previousPage === "chat") {
          setSecondaryScreenShowing("exclusive-list");
        }
      } else {
        setSecondaryScreenShowing("");
      }
    } else {
      setSecondaryScreenShowing("");
    }
  }, [windowSize]);

  const getListChats = useCallback(() => {
    getChats({}, (res: Chat[]) => {
      const prepareList = res.map((chat: Chat) => {
        return {
          id: chat.chatId,
          image: chat.chatParticipants[0]?.user?.profile.avatar?.url,
          name: chat.chatParticipants[0]?.user?.displayname,
        };
      });

      setConsumerList(prepareList);
      setFilteredConsumerList(prepareList);
    });
  }, [getChats]);

  const closeModal = () => {
    // const header = document.getElementById("header-home-page");
    // header?.classList.remove("show-header");

    let count = 0;
    setShowUploadButtons(false);

    const streamStopped = stopStream();
    if (streamStopped) {
      stopRecording();

      setSecondaryScreenShowing("");
      setOpenUseModal(false);
      return;
    } else {
      setTimeout(() => {
        if (count >= 10) {
          setOpenUseModal(false);
          return;
        }
        closeModal();
        count++;
      }, 100);
    }
  };

  const searchInConsumerList = (e: string) => {
    const newList =
      consumerList?.filter((consumer) =>
        consumer.name.toLowerCase().includes(e.toLowerCase())
      ) || [];
    setInputSearchConsumer(e);
    setFilteredConsumerList(newList);
  };

  const searchInCollaboratorList = (e: string) => {
    const newList =
      collaboratorList?.filter((collab) =>
        collab.name.toLowerCase().includes(e.toLowerCase())
      ) || [];
    setInputSearchCollaborator(e);
    setFilteredCollabList(newList);
  };

  const shareContentBackButtonHandler = () => {
    if (windowSize < 700) {
      setScreenShowing("library");
      setSecondaryScreenShowing("");
    } else {
      setSecondaryScreenShowing("config");
    }
  };

  const removeMediaFromSharing = (media: ICapturedMedia) => {
    const newMediaList = capturedMedia.filter((file) => file !== media);
    setCapturedMedia(newMediaList);

    if (newMediaList.length === 0) {
      if (screenShowing === "preview-captured" && windowSize < 700) {
        startCamera();
        setScreenShowing("camera");
      } else {
        setScreenShowing("library");
      }
    }
  };

  const addRosesInputHandler = (e: string) => {
    const inputValue = e.replace("Roses: ", "");
    const numericValue = inputValue.replace(/\D/g, "");
    setRosesAmount(Number(numericValue));
  };

  const shareContentHandler = async () => {
    if (capturedMedia.length === 0) {
      showToast({
        title: "Oops...",
        description: "There is no content to send",
        type: "info",
      });

      return;
    }

    setModalContent(<LoadingPage />);

    const price = rosesAmount;
    const token = userInfo.access_token;
    const isPaidContent = rosesAmount > 0;

    const chatsId = chatIdsForSendContent;

    if (previousPage === "chat") {
      await uploadFilesToChat(
        token,
        chatsId,
        capturedMedia,
        descriptionContent,
        isPaidContent,
        price,
        onUploadComplete
      );
    } else {
      if (uploadType === "feed") {
        await uploadFileInFeed(
          capturedMedia,
          price,
          token,
          userInfo.display_name
        );
      }

      if (uploadType === "moment") {
        await uploadFileInMoment(capturedMedia, price, token);
        setMomentTrigger((prev) => !prev);
      }

      if (chatIdsForSendContent.length > 0) {
        await uploadFilesToChat(
          token,
          chatIdsForSendContent,
          capturedMedia,
          descriptionContent,
          isPaidContent,
          price,
          onUploadComplete
        );
      }
    }

    setCreatorContent({
      collaborators: [],
      isExclusiveContent:
        previousPage === "chat" ||
        uploadLocation === "secret" ||
        uploadLocation === "Members Only",
      listConsumer: chatIdsForSendContent,
      medias: [],
      message: descriptionContent,
      rosesContentPrice: rosesAmount,
      shareLocation: shareContentLocation,
    });

    closeModal();
  };

  const addConsumerInListToSend = (id: string) => {
    if (id && chatIdsForSendContent.includes(id)) {
      const newList = chatIdsForSendContent.filter((list) => list !== id);
      setChatIdsForSendContent(newList);
    } else {
      setChatIdsForSendContent((prev) => [...prev, id]);
    }
  };

  const addCollaboratorInListToSend = (id: string) => {
    if (id && collabChatIdsForSendContent.includes(id)) {
      const newList = collabChatIdsForSendContent.filter((list) => list !== id);
      setCollabChatIdsForSendContent(newList);
    } else {
      setCollabChatIdsForSendContent((prev) => [...prev, id]);
    }
  };

  const selectedMedias = (media: {
    media: string;
    type: string;
    id: string;
  }) => {
    setCapturedMedia((prev) => {
      if (prev.some((item) => item.media === media.media)) {
        return prev.filter((item) => item.media !== media.media);
      }
      return [
        ...prev,
        {
          media: media.media,
          type: media.type.toLowerCase(),
        },
      ];
    });
  };

  const uploadFilesMode = (location: string[]) => {
    setUploadLocation(location[0]);
  };

  const currencySelectedHandler = (currency: string[]) => {
    setCurrencySelected(currency[0]);
  };

  const shareWihAllHandler = () => {
    setChatIdsForSendContent(consumerList.map((consumer) => consumer.id));
  };

  const shareWihCollabAllHandler = () => {
    setCollabChatIdsForSendContent(collaboratorList.map((collab) => collab.id));
  };

  const finishSelectionHandler = () => {
    // if (previousPage === "home") {
    setSecondaryScreenShowing("config");
    // } else {
    //   shareContentHandler();
    // }
  };

  const privateAndCollabListBackButton = () => {
    if (previousPage === "home") {
      setSecondaryScreenShowing("config");
    } else {
      setScreenShowing("library");
      setSecondaryScreenShowing("");
    }
  };

  const addMediaClick = () => {
    setShowUploadButtons((prev) => !prev);
  };

  const stopStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);

      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setScreenShowing("preview-captured");
      return true;
    } else {
      // setScreenShowing("library");

      return true;
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      videoRef.current?.pause();
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const mediaButtonHandler = () => {
    stopStream();
    stopRecording();
    setScreenShowing("library");
  };

  const saveForLaterHandler = () => {};

  const startCamera = async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        setStream(stream);

        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          videoRef.current.muted = true;

          setTimeout(() => {
            videoRef.current?.play();
          }, 100);
        }
      } else {
        console.error("Error");
      }
    } catch (err) {
      console.error((err as Error).message);
    }
  };

  const openConfigHandler = () => {
    setScreenShowing("");
    if (previousPage === "home") {
      setSecondaryScreenShowing("config");
    } else {
      setSecondaryScreenShowing("exclusive-list");
    }
  };

  return (
    <div className="add-media-page">
      <nav className="add-media-page__navbar inner">
        <p>Share Content</p>
        <span
          className="icon icon-close icon-white icon-md
					 image-container__close-icon"
          onClick={closeModal}
        />
      </nav>

      {screenShowing ? (
        <section className="media-storage-wrapper">
          {screenShowing === "camera" ||
          screenShowing === "preview-captured" ? (
            <>
              <button
                className="image-container__pixel-shield-button"
                onClick={() => setShowShieldPills((prev) => !prev)}
              >
                <span className={`icon icon-filter icon-white icon-md`} />
              </button>
              {showShieldPills ? (
                <div className="shield-pills">
                  <ButtonPill
                    clickPill={() => setSelectedIdentityPill((prev) => !prev)}
                    iconName="icon-digitalprint"
                    isPillSelected={selectedIdentityPill}
                    textPill="Identity Shield"
                    className=""
                  />
                </div>
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}

          {screenShowing === "camera" && windowSize < 700 ? (
            <OpenedCamera
              stream={stream}
              canvasRef={canvasRef}
              mediaRecorderRef={mediaRecorderRef}
              videoRef={videoRef}
              setCapturedMedia={setCapturedMedia}
              setFile={setFile}
              setMediaToShow={setMediaToShow}
              setScreenShowing={setScreenShowing}
              showButtonGallery={showButtonGallery}
            />
          ) : (
            false
          )}

          {screenShowing === "library" ? (
            <MediaStorageGallery
              selectedMedias={selectedMedias}
              capturedMedia={capturedMedia}
            />
          ) : (
            false
          )}

          {screenShowing === "preview-captured" ? (
            <PreviewCapturedMedia
              mediaToShow={mediaToShow}
              descriptionContent={descriptionContent}
              setDescriptionContent={setDescriptionContent}
              setScreenShowing={setScreenShowing}
              setSecondaryScreenShowing={setSecondaryScreenShowing}
              previousPage={previousPage}
            />
          ) : (
            false
          )}

          {screenShowing === "library" ||
          screenShowing === "preview-captured" ? (
            <>
              <ButtonMediaLocation
                buttonCameraAction={startCamera}
                setScreenShowing={setScreenShowing}
                setShowUploadButtons={setShowUploadButtons}
                showUploadButtons={showUploadButtons}
                showBtnCamera={windowSize < 700}
                showBtnStorage={screenShowing === "preview-captured"}
                setCapturedMedia={setCapturedMedia}
                capturedMedia={capturedMedia}
                setFile={setFile}
                setMediaToShow={setMediaToShow}
                setShowLocationMediaUploadPills={
                  setShowLocationMediaUploadPills
                }
                buttonMediaAction={mediaButtonHandler}
                screenShowing={screenShowing}
              />
              {windowSize < 700 &&
              capturedMedia.length &&
              screenShowing === "library" ? (
                <button className="continue-button" onClick={openConfigHandler}>
                  <span className="icon icon-md icon-white icon-arrow-right" />
                </button>
              ) : (
                false
              )}

              <PreviewMediaCardsList
                className={screenShowing === "library" ? " " : ""}
                screenShowing={screenShowing}
                capturedMedia={capturedMedia}
                removeMediaFromSharing={removeMediaFromSharing}
                addMediaClick={addMediaClick}
                setScreenShowing={setScreenShowing}
                setMediaToShowCallBack={setMediaToShow}
                bottomPosition={
                  windowSize < 768 && screenShowing === "preview-captured"
                    ? 100
                    : 0
                }
              />
            </>
          ) : (
            false
          )}
        </section>
      ) : (
        false
      )}

      {secondaryScreenShowing === "config" ? (
        <CreatorContent
          addRosesInputHandler={addRosesInputHandler}
          setSecondaryScreenShowing={setSecondaryScreenShowing}
          createdTag={createdTag}
          createdTags={createdTags}
          descriptionContent={descriptionContent}
          previousPage={previousPage}
          rosesAmount={String(rosesAmount)}
          setCreatedTag={setCreatedTag}
          setCreatedTags={setCreatedTags}
          setDescriptionContent={setDescriptionContent}
          shareContentBackButtonHandler={shareContentBackButtonHandler}
          shareContentHandler={shareContentHandler}
          uploadFilesMode={uploadFilesMode}
          currencySelectedHandler={currencySelectedHandler}
          uploadLocation={uploadLocation}
          currencySelected={currencySelected}
          setUploadType={setUploadType}
          saveForLaterHandler={saveForLaterHandler}
        />
      ) : (
        false
      )}

      {secondaryScreenShowing === "exclusive-list" ? (
        <ExclusiveList
          addConsumerInListToSend={addConsumerInListToSend}
          chatIdsForSendContent={chatIdsForSendContent}
          consumerList={consumerList}
          filteredConsumerList={filteredConsumerList}
          finishSelectionHandler={finishSelectionHandler}
          inputSearchConsumer={inputSearchConsumer}
          privateListBackButton={privateAndCollabListBackButton}
          searchInConsumerList={searchInConsumerList}
          setChatIdsForSendContent={setChatIdsForSendContent}
          shareWihAllHandler={shareWihAllHandler}
          chatIdToSend={chatIdToSendContent}
        />
      ) : (
        false
      )}
      {/* TODO => MOCK */}
      {secondaryScreenShowing === "collaborator-list" ? (
        <CollaboratorsList
          addCollaboratorInListToSend={addCollaboratorInListToSend}
          chatIdsForSendContent={collabChatIdsForSendContent}
          collaboratorList={collaboratorList}
          collaboratorsListBackButton={privateAndCollabListBackButton}
          filteredCollaborator={filteredCollabList}
          finishSelectionHandler={finishSelectionHandler}
          inputSearchCollaborator={inputSearchCollaborator}
          searchInCollaboratorList={searchInCollaboratorList}
          setChatIdsForSendContent={setCollabChatIdsForSendContent}
          shareWihAllHandler={shareWihCollabAllHandler}
          collaboratorChatIdToSend={chatIdToSendContent}
        />
      ) : (
        false
      )}
    </div>
  );
};

export default AddMediaPage;
