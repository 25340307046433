import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

import ImageCircleBorder from "components/ImageCircleBorder";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";

import { CreatorDetailsContext } from "contexts/creatorDetails";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useUserInfo } from "hooks/userInfo";
import { useToast } from "hooks/useToast";

import getPublicDetails from "api/publicDetails/getProfileDetails";

interface IFeedProfileLabel {
  showTime?: boolean;
  showUsername?: boolean;
  userId: string;
  profilePhoto: string;
  displayName: string;
  userName: string;
  time?: string;
  windowWidthSize?: number;
  className?: string;
}

const FeedProfileLabel: React.FC<IFeedProfileLabel> = (props) => {
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const {
    showTime = false,
    showUsername = false,
    userId,
    profilePhoto,
    displayName,
    userName,
    time,
    windowWidthSize,
    className,
  } = props;

  const checkAuth = useAuthCheckOpenModal({ navigate, paramsUrl });

  const { setSecondaryScreen } = useDeskNavigation();
  const { setCreatorDetails } = useContext(CreatorDetailsContext);

  const { showToast } = useToast();

  const { userInfo } = useUserInfo();

  const openProfile = async () => {
    if (!checkAuth()) return;
    try {
      const { res } = await getPublicDetails(userInfo.access_token, userId);
      setCreatorDetails(res);

      setSecondaryScreen("/producer-summary", {
        userId: userId,
      });
    } catch (error) {
      showToast({
        type: "error",
        title: "A problem occurred",
        description: "Please try again.",
      });
    }
  };

  return (
    <div
      className={`feed-profile-label ${
        windowWidthSize && windowWidthSize <= 560
          ? "feed-profile-label--column"
          : ""
      } ${className ?? ""}`}
      onClick={openProfile}
    >
      <ImageCircleBorder
        centerImage={profilePhoto}
        centerImageAlt="user profile"
        size={56}
      />

      <div className="feed-profile-label__name">
        <p className="feed-profile-label__text  ">
          {displayName}{" "}
          {showTime && (
            <span className="feed-profile-label__text  ">{time}</span>
          )}
        </p>
        {showUsername && (
          <span className="feed-profile-label__text  ">{userName}</span>
        )}
      </div>
    </div>
  );
};

export default FeedProfileLabel;
