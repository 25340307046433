import fetchApi from "../fetchApi";

export type IGetPostsById = {
  postId: string;
};

const getPostsById = async (token: string, data: IGetPostsById) => {
  const { postId } = data;

  return await fetchApi({
    service: "posts",
    method: "GET",
    endPoint: `/${postId}`,
    token,
  });
};

export default getPostsById;
