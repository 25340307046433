import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import ColorCircle from "components/ColorCircle";
import Button from "components/Buttons/Button";
import ImageCircleBorder from "components/ImageCircleBorder";

import { CreatorDetailsContext } from "contexts/creatorDetails";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

import postAvatarProfile from "api/user/postAvatarProfile";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import getPublicDetails from "api/publicDetails/getProfileDetails";
import getUserInfo from "../../api/userInfo/getUserInfo";

import { UserInfoRequest } from "../../types/userInfoRequest";

import { getOptions, Option } from "./options";

interface ListItemBioProps {
  tokenUser?: string;
  key?: string;
  value?: string;
}

const UserSettingsProfileCustomizePage: React.FC<ListItemBioProps> = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const { setCreatorDetails } = useContext(CreatorDetailsContext);
  const { showToast } = useToast();

  const toggleDefault = () => {
    showToast({
      type: "info",
      title: "Coming soon!",
      description: "We appreciate your patience.",
    });
  };

  const options: Option[] = getOptions(
    setPrincipalScreen,
    toggleDefault,
    userInfo
  );

  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);
  const [circleColors, setCircleColors] = useState<any[]>();
  const [circleSelect, setCircleSelect] = useState<string>();

  const changeColorBanner = (color: string) => {
    setCircleSelect(color);
    patchDetails("feedHexColor", color);
    console.log(color);
    requestUserInfo();
  };
  const [imageProfileFile, setImageProfileFile] = useState<string>("");

  const patchDetails = async (
    key: string,
    value: string | string[] | number
  ) => {
    const response = await patchProfileDetails(
      userInfo.access_token,
      key,
      value
    );
    requestUserInfo();
    return response;
  };

  const requestUserInfo = async () => {
    setImageProfileFile(userInfo.user_profile_avatar);
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput")?.click();
  };

  const profilePictureChangeHandler = async (file: File) => {
    setImageProfileFile(URL.createObjectURL(file as File));

    try {
      const request = await postAvatarProfile(userInfo.access_token, { file });
      switch (request.status) {
        case 201:
          setUserInfo("user_profile_avatar", request.res.avatar.url || "");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlerProfilePreview = async () => {
    try {
      const request = await getPublicDetails(
        userInfo.access_token,
        userInfo.user_id
      );
      setCreatorDetails(request.res);
      setPrincipalScreen("/interaction");
    } catch (error) {
      showToast({
        type: "error",
        title: "Occurred a problem!",
        description: "We appreciate your patience. Please, try later!",
      });
    }
  };

  useEffect(() => {
    const colorsArray = [
      "#FFFFFF",
      "#D4AFB2",
      "#FFDAA9",
      "#F5AC67",
      "#A8794C",
      "#EE4E87",
      "#8764E0",
      "#33AEEC",
      "#37B1C2",
    ];
    setCircleColors(colorsArray);
    requestUserInfo();
  }, [userInfo]);

  return (
    <div
      className="user-settings-profile-customize
		 		user-settings-profile-customize-wrapper
		  	wrapper
				standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-profile-customize-inner inner">
        <PageTitle
          title={"CUSTOMIZE"}
          subtitle={"Enhance Profile"}
          hasCloseIcon={false}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings", { btn: "button2" });
            } else {
              setPrincipalScreen("/user-settings", { btn: "button2" });
            }
          }}
        />
        <main className="user-settings-edit-profile__main">
          <div className="container-profile">
            <div className="user-settings-edit-profile__avatar">
              <ImageCircleBorder
                centerImage={imageProfileFile}
                centerImageAlt="user profile"
                size={64}
                onClick={triggerFileInput}
              />
              {!userInfo.user_profile_avatar && (
                <div
                  className="user-settings-edit-profile__add-moments-container"
                  onClick={triggerFileInput}
                >
                  <span className="icon icon-plus icon-white icon-sm"></span>
                </div>
              )}
            </div>

            <input
              id="fileInput"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files) {
                  const file = e.target.files[0];
                  profilePictureChangeHandler(file);
                }
              }}
            />
            <div className="user-settings-edit-profile__info">
              <h2 className="title-customize-profile">
                Your profile has potential
              </h2>

              <p className="subtitle-customize-profile">
                Great job so far. Keep adding info to attract more fans
              </p>
            </div>

            <div>
              <Button
                buttonStyle="tertiary"
                className="button-customize-page"
                children="Preview profile"
                onClick={handlerProfilePreview}
              />
            </div>

            <div className="user-settings-edit-profile__colors">
              {circleColors?.map((color, index) => (
                <ColorCircle
                  key={index}
                  color={color}
                  colorSelected={userInfoRequest?.feedHexColor}
                  onClick={() => changeColorBanner(color)}
                />
              ))}
            </div>
          </div>

          <div className="grid-container">
            {options.map((option, index) => {
              const {
                icon,
                status,
                title,
                infosComplete,
                infosTotal,
                onClick,
                isActive,
              } = option;

              if (!isActive) return null;

              return (
                <div
                  key={`box-info-${index}`}
                  className={`box-info ${!isActive ? "box-info--hidden" : ""}`}
                  onClick={onClick}
                >
                  <div className="box-info__top">
                    <div className="circle-icon-box-info">
                      <span className={`${icon} span-icon`}></span>
                    </div>
                    <div className="signal-circle">
                      <div
                        className={`signal ${status ? "signal-green" : "signal-gray"}`}
                      ></div>
                    </div>
                  </div>

                  <div className="box-info__texts">
                    <h3 className="title-box-info">{title}</h3>
                    <p className="subtitle-box-info">{`${infosComplete} of ${infosTotal} added`}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserSettingsProfileCustomizePage;
