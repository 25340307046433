import React, { useRef, useState } from "react";
import { NavigateFunction, Params as RouterParams } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import "./styles.scss";

import FadeCarousel from "components/Carousels/FadeCarousel";
import Header from "components/Headers/Header";
import SubText from "components/Texts/SubText";
import Button from "components/Buttons/Button";
import InputText from "components/Inputs/InputText";
import LoadingPage from "components/LoadingPage";

import LoginModal from "../Login";

import { useUserInfo } from "hooks/userInfo";
import { useToast } from "hooks/useToast";
import { useModal } from "hooks/useModal";
import { Params } from "hooks/useDeskNavigation";

import getUserInfo from "api/userInfo/getUserInfo";
import createUser from "api/auth/postCreateUser";
import getUserSelfDetail from "api/getUserSelfDetail";

import { localStorageClear } from "utils/localStorageClear";

import EyeIcon from "assets/icons/eye-icon-gray.svg";
import SignUpImage from "assets/images/login&CreateAccount/sign-up.jpg";
import CheckCircleGreen from "assets/icons/check-circle-green.svg";

interface CreateAccountModalProps {
  navigate: NavigateFunction;
  setComponent: (path: string | React.ReactNode, params?: Params) => void;
  paramsUrl: Readonly<RouterParams<string>>;
}

const CreateAccountModal: React.FC<CreateAccountModalProps> = (props) => {
  const { navigate, setComponent, paramsUrl } = props;
  const { showToast } = useToast();
  const { setModalContent, setOpenUseModal } = useModal();
  const { setUserInfo } = useUserInfo();

  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userNameWarning, setUserNameWarning] = useState(false);
  const [userEmailWarning, setUserEmailWarning] = useState(false);
  const [createdPassword, setCreatedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [isSignNewsLetter, setIsSignNewsLetter] = useState(true);

  const modalRef = useRef<HTMLDivElement | null>(null);

  const passwordsMatch =
    createdPassword === confirmPassword && createdPassword !== "";

  const getUserInfoRequest = async (token: string) => {
    try {
      const response = await getUserInfo(token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            console.error(response.res.statusCode);
          } else {
            setUserInfo(
              "user_profile_avatar",
              response.res?.avatar?.thumbnailUrl
            );
          }
          break;
        case 401:
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createAccountSubmit = async () => {
    if (userName.trim().length < 3) {
      showToast({
        description: "Your display name should be at least 3 characters long",
        title: "Short Display name",
        type: "warning",
      });

      setUserNameWarning(true);

      setTimeout(() => {
        setUserNameWarning(false);
      }, 4000);
      return;
    }

    if (
      userEmail.length <= 5 ||
      !userEmail.includes("@") ||
      !userEmail.includes(".com")
    ) {
      showToast({
        description: "Check your email and try again",
        title: "Invalid email",
        type: "warning",
      });

      setUserEmailWarning(true);

      setTimeout(() => {
        setUserEmailWarning(false);
      }, 4000);
      return;
    }

    if (confirmPassword.trim().length < 8) {
      showToast({
        description: "Your password should be at least 8 characters long",
        title: "Short Pass",
        type: "warning",
      });

      setPasswordInputWarning(true);

      setTimeout(() => {
        setPasswordInputWarning(false);
      }, 4000);
      return;
    }

    if (createdPassword.trim() !== confirmPassword.trim()) {
      showToast({
        description: "Check passwords and try again",
        title: "Passwords don't match",
        type: "warning",
      });

      setPasswordInputWarning(true);

      setTimeout(() => {
        setPasswordInputWarning(false);
      }, 4000);
      return;
    }

    setIsLoading(true);

    try {
      const createdUserData = {
        displayname: userName,
        email: userEmail,
        password: confirmPassword.trim(),
      };

      const createUserResponse = await createUser(createdUserData);

      switch (createUserResponse.status) {
        case 201:
          // The cleaners below keep the information up to data
          localStorageClear();
          sessionStorage.clear();
          const accessToken = createUserResponse.res.access_token;

          setUserInfo("access_token", accessToken);

          const activateResponse = await getUserSelfDetail(accessToken);

          switch (activateResponse?.status) {
            case 200:
              getUserInfoRequest(accessToken);
              setUserInfo("user_id", activateResponse.res.userId);
              setUserInfo("display_name", activateResponse.res.displayname);
              setUserInfo("account_type", activateResponse.res.role);
              setUserInfo("user_email", activateResponse.res.email);
              setUserInfo("user_phone", activateResponse.res.phone);

              navigate("/app");
              break;

            default:
              showToast({
                description:
                  "Something went wrong on user activate. Please try again.",
                title: "Error",
                type: "error",
              });

              break;
          }

          break;

        case 409:
          showToast({
            description: "Register with new credentials or log in",
            title: "User already registered",
            type: "warning",
          });

          setUserEmailWarning(true);

          break;

        case 422:
          if (createUserResponse.res.message[0].includes("email")) {
            showToast({
              description: "Check your email and try again",
              title: "Invalid email",
              type: "warning",
            });

            setUserEmailWarning(true);

            setTimeout(() => {
              setUserEmailWarning(false);
            }, 4000);
          } else {
            showToast({
              description:
                "Something went wrong on user creation. Please try again.",
              title: "Error",
              type: "error",
            });
          }

          break;

        default:
          showToast({
            description:
              "Something went wrong on user creation. Please try again.",
            title: "Error",
            type: "error",
          });

          break;
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const loginPageHandler = () => {
    setModalContent(
      <LoginModal
        navigate={navigate}
        paramsUrl={paramsUrl}
        setComponent={setComponent}
      />
    );
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div
      id="create-account-modal"
      ref={modalRef}
      className="create-account-modal-wrapper wrapper"
    >
      <span
        onClick={() => setOpenUseModal(false)}
        className="icon icon-close icon-gray-light
			 		icon-md close-modal-icon"
      />
      <div className="create-account-modal__content">
        <div className="content__carousel-wrapper">
          <FadeCarousel
            slides={[{ backgroundImage: SignUpImage }]}
            duration={4000}
            borderRadius={[16, 0, 0, 16]}
            showGradient={false}
            showText={false}
          />
        </div>
        <div className="content__form-wrapper">
          <Header
            marginTop={0}
            title="Create your account"
            hasGapBetweenTitleAndSub={false}
            noMarginTopTextAndIcon={true}
            titleClass="header"
            headerIcon="back-button"
            backButtonClick={loginPageHandler}
            inlineElements
          />
          <div className="form-wrapper__inputs">
            <div className="form__top">
              <InputText
                value={userName}
                placeholder="Display name"
                onChangeInput={(e) => setUserName(e)}
                isWarning={userNameWarning}
                inputType="text"
                backgroundColor="charcoal"
              />
              <SubText
                icon={EyeIcon}
                altIcon="clock icon"
                classNameIcon="create-account__icon"
                className="create-account__subtext"
              >
                This will be shown on your profile
              </SubText>
            </div>

            <div className="form__main">
              <InputText
                value={userEmail}
                placeholder="Email"
                onChangeInput={(e) => setUserEmail(e)}
                isWarning={userEmailWarning}
                inputType="email"
                backgroundColor="charcoal"
              />

              <SubText
                checkAnswer
                checkValueCallback={setIsSignNewsLetter}
                isChecked={isSignNewsLetter}
                className="create-account__subtext create-account__subtext--checkbox"
              >
                I want to receive news, updates, and offers from Roses
              </SubText>
              <div className="inputs-container__passwords-container">
                <InputText
                  backgroundColor="charcoal"
                  value={createdPassword}
                  placeholder="Password"
                  inputType="password"
                  isWarning={passwordInputWarning}
                  iconRight={passwordsMatch ? CheckCircleGreen : ""}
                  hiddenEye={passwordsMatch}
                  className="inputs-container__password-input"
                  onChangeInput={(e) => setCreatedPassword(e)}
                />

                <InputText
                  backgroundColor="charcoal"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  inputType="password"
                  isWarning={passwordInputWarning}
                  iconRight={passwordsMatch ? CheckCircleGreen : ""}
                  hiddenEye={passwordsMatch}
                  onChangeInput={(e) => setConfirmPassword(e)}
                />
                <PasswordStrengthBar
                  minLength={8}
                  barColors={[
                    "var(--charcoal)",
                    "var(--peach)",
                    "#FFC045",
                    "var(--green)",
                    "var(--green",
                  ]}
                  scoreWords={[
                    "(add more characters to strengthen) very weak",
                    "(add more characters to strengthen) weak",
                    "good",
                    "strong",
                    "very strong",
                  ]}
                  className="create-account__password"
                  scoreWordClassName="create-account__score"
                  shortScoreWord="Your password should be at least 8 characters long"
                  password={createdPassword}
                />
              </div>
            </div>
          </div>

          <div className="form-wrapper__buttons-container">
            <Button
              disabled={isLoading}
              buttonStyle="primary"
              onClick={createAccountSubmit}
            >
              Create account
            </Button>

            <p className="buttons-container__login-link">
              Already have an account?{" "}
              <span onClick={loginPageHandler} className="login-link__link">
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountModal;
