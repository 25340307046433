import { useState } from "react";
import "./styles.scss";

import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import PageTitle from "components/PageTitles";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

import ShieldPinkIcon from "assets/icons/shieldIcons/shield-icon-pink.svg";

const CreateLegalNamePage: React.FC = () => {
  const { setUserInfo } = useUserInfo();
  const { showToast } = useToast();

  const [userLegalName, setUserLegalName] = useState("");
  const [inputLegalNameWarning, setInputLegalNameWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();

  const addLegalNameSubmit = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const stringCleaner = userLegalName.replace(/[^a-zA-Z]/g, "");

    if (stringCleaner.length < 3) {
      showToast({
        type: "warning",
        title: "Invalid Name",
        description: "The name must have at least three letters",
      });

      setInputLegalNameWarning(true);

      setTimeout(() => {
        setInputLegalNameWarning(false);
      }, 4000);
    } else {
      setUserInfo("legal_name", userLegalName.trim());
      setPrincipalScreen("/upload-document");
    }

    // const request = await PatchUserSelfDetailApi(userInfo.access_token, {
    //   username: userLegalName,
    // });

    // switch (request.status) {
    // 	case 200:
    // 		setPrincipalScreen("/upload-document")
    // 		break
    //   default:
    //     showToast({
    //       type: "error",
    //       title: "Error",
    //       description:
    //         "Something went wrong on user creation. Please try again.",
    //     });

    //
    //     setInputLegalNameWarning(true);

    //     setTimeout(() => {
    //
    //       setInputLegalNameWarning(false);
    //     }, 4000);
    //     break;
    // }
    // setPrincipalScreen("/create-password");
    setIsLoading(false);
  };

  return (
    <div className="create-legal-name create-legal-name-wrapper wrapper standard-page-layout">
      <div className="create-legal-name-inner inner">
        <PageTitle
          title={"Settings"}
          subtitle={"Configuration"}
          hasCloseIcon={false}
          //
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings");
            } else {
              setPrincipalScreen("/user-settings");
            }
          }}
        />
        <main className="create-legal-name__main">
          <Header
            title="Legal name"
            subTitle={`We safeguard our community by ensuring the authenticity 
						of every member on our platform, upholding a standard of trust
						and transparency.`}
            marginTop={0}
            hasGapBetweenTitleAndSub
            titleClass="create-legal-name__title"
            className="create-legal-name__header"
          />
          <InputText
            value={userLegalName}
            placeholder="Legal Name (as shown on document)"
            onChangeInput={setUserLegalName}
            isWarning={inputLegalNameWarning}
            inputType="text"
          />
          <SubText
            icon={ShieldPinkIcon}
            altIcon="shield Roses icon"
            className="create-legal-name__icon-disclaimer  "
            classNameIcon="create-legal-name__icon"
          >
            We’ll never share your legal name
          </SubText>
        </main>

        <Button
          className="create-legal-name__btn  "
          buttonStyle="primary"
          onClick={addLegalNameSubmit}
          disabled={isLoading}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CreateLegalNamePage;
