import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";

interface ModalContextProps {
  openUseModal: boolean;
  setOpenUseModal: (open: boolean) => void;
  setModalContent: (content: ReactNode) => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [openUseModal, setOpenUseModal] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);

  useEffect(() => {
    const bodyApplication = document.getElementsByTagName("body")[0];
    if (!bodyApplication) return;

    if (openUseModal) {
      bodyApplication.classList.add("no-scroll");
    } else if (!openUseModal) {
      bodyApplication.classList.remove("no-scroll");
    }
  }, [openUseModal]);

  return (
    <ModalContext.Provider
      value={{ openUseModal, setOpenUseModal, setModalContent }}
    >
      {children}
      {openUseModal && (
        <div id="use-modal-hook" className="modal-overlay">
          <div className="modal-content">{modalContent}</div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
