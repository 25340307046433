import React from "react";
import LogInSignUpDesk from "components/Wrappers/LoginSignUpDesk";
import AccountLoginPage from "./AccountLogin";
import { useLoginSignUpNavigation } from "../hooks/useLoginSignUpNavigation";

const App: React.FC = () => {
  const { component } = useLoginSignUpNavigation();

  return <LogInSignUpDesk component={component || <AccountLoginPage />} />;
};

export default App;
