import React from "react";
import "./styles.scss";

import Toggle from "../../Toggle/SimpleToggle";

interface ListItemToggleProps {
  className?: string;
  highlightText?: string;
  simpleText?: string;
  imageLeft?: string;
  isImageCircle?: boolean;
  hasSeparator?: boolean;
  iconFill?: boolean;
  isGold?: boolean;
  toggleInitialState?: boolean;
  toggleCallBack: (event: boolean) => void;
}

const ListItemToggle: React.FC<ListItemToggleProps> = (props) => {
  const {
    highlightText,
    imageLeft,
    isImageCircle,
    simpleText,
    className,
    hasSeparator,
    iconFill,
    isGold = false,
    toggleInitialState,
    toggleCallBack,
  } = props;

  const toggleChange = (a: boolean) => {
    toggleCallBack(a);
  };

  return (
    <div
      id={`list-item-toggle`}
      className={`list-item-toggle ${className ?? ""} ${
        imageLeft ? "column-for-image" : ""
      }`}
      data-gold={isGold}
    >
      {imageLeft && (
        <div
          className={`item__image-left-container ${
            isImageCircle ? "image-circle" : ""
          }`}
        >
          <img
            src={imageLeft}
            alt="left icon"
            className={`item__left-icon ${iconFill ? "icon-fill" : ""}`}
          />
        </div>
      )}

      <div className="item__main-container">
        <p className="main-container__highlight-text">{highlightText}</p>
        <p className="main-container__simple-text">{simpleText}</p>
      </div>

      <Toggle
        isGold={isGold}
        initialState={toggleInitialState}
        onChange={toggleChange}
      />

      {hasSeparator && <div className="separator" />}
    </div>
  );
};

export default ListItemToggle;
