import { useRef, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";
import DocumentPlaceholder from "components/DocumentPlaceholder";

import RecordSelf from "pages/RecordSelf";
import ChooseDocument from "pages/ChooseDocument";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useFiles } from "../../hooks/documentContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";

const UploadDocumentPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { windowSize } = useWindowWidthSize();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setFiles } = useFiles();
  const { showToast } = useToast();

  const [documentFileFront, setDocumentFileFront] = useState<File | null>(null);
  const [documentFileBack, setDocumentFileBack] = useState<File | null>(null);

  const fileInputRefFront = useRef<HTMLInputElement>(null);
  const fileInputRefBack = useRef<HTMLInputElement>(null);

  const breakPoint = windowSize >= 768;

  const documentFromGallery = () => {
    if (!documentFileFront) {
      fileInputRefFront.current?.click();
    } else if (!documentFileBack) {
      fileInputRefBack.current?.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!documentFileFront) {
        setDocumentFileFront(file);
      } else if (!documentFileBack) {
        setDocumentFileBack(file);
      }
    }
  };

  const captureDocument = async () => {
    if (verifyDocFiles()) {
      try {
        if (!(documentFileFront instanceof File)) {
          throw new Error("documentFileFront is not a valid File");
        }
        if (!(documentFileBack instanceof File)) {
          throw new Error("documentFileBack is not a valid File");
        }
        setFiles([documentFileFront, documentFileBack]);

        handlerNextPage();
      } catch (error) {
        showToast({
          title: "Error",
          description: "Error, Please try later!",
          type: "error",
        });
      }
    }
  };

  const verifyDocFiles = () => {
    return documentFileFront && documentFileBack;
  };

  const handlerNextPage = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<RecordSelf />);
    } else {
      setPrincipalScreen("/record-self");
    }
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<ChooseDocument />);
    } else {
      setPrincipalScreen("/choose-document");
    }
  };

  return (
    <div className="upload-document upload-document-wrapper wrapper standard-page-layout">
      <div className="upload-document-inner inner">
        {!breakPoint ? (
          <PageTitle
            title="Become a Creator"
            subtitle="Start Earning"
            hasCloseIcon={false}
            onBackClick={handlerBackPage}
          />
        ) : (
          false
        )}
        <main
          className={`upload-document__main ${
            verifyDocFiles() ? "heigh-changed" : ""
          }`}
        >
          <Header
            title="Upload your document"
            subTitle={`Please upload a document for identity verification. Ensure
					 it's legible and up-to-date for a smooth verification process.`}
            marginTop={0}
            className="upload-document__header"
            backButtonClick={handlerBackPage}
            {...(breakPoint
              ? {
                  headerIcon: "back-button",
                  inlineElements: true,
                  titleClass: "header",
                }
              : {
                  titleClass: "upload-document__title",
                })}
          />
          <DocumentPlaceholder
            docBackCallback={setDocumentFileBack}
            docFrontCallback={setDocumentFileFront}
            documentFileFront={documentFileFront}
            documentFileBack={documentFileBack}
          />
          <input
            ref={fileInputRefFront}
            onChange={handleFileChange}
            type="file"
            name="document"
            style={{ display: "none" }}
            accept=".jpg,.jpeg,.png"
          />
          <input
            ref={fileInputRefBack}
            onChange={handleFileChange}
            type="file"
            name="document"
            style={{ display: "none" }}
            accept=".jpg,.jpeg,.png"
          />
        </main>
        {!verifyDocFiles() ? (
          <div className="upload-document__buttons-container">
            <Button buttonStyle="quaternary" onClick={documentFromGallery}>
              Photo Album
            </Button>
            <Button buttonStyle="secondary" onClick={captureDocument}>
              Capture Document
            </Button>
          </div>
        ) : (
          <div className="upload-document__buttons-container">
            <Button buttonStyle="primary" onClick={captureDocument}>
              Continue
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadDocumentPage;
