import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import Button from "components/Buttons/Button";
import InputMessage from "components/TextAreas/Textarea";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import getUserInfo from "../../api/userInfo/getUserInfo";
import { UserInfoRequest } from "../../types/userInfoRequest";
import { IToast } from "../../types/toast";
import Toast from "components/Toast";
import { useToast } from "hooks/useToast";

interface ListItemBioProps {
  tokenUser?: string;
  key?: string;
  valueb?: string;
}

const UserSettingsEditBioPage: React.FC<ListItemBioProps> = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { showToast } = useToast();
  const { userInfo } = useUserInfo();
  const [value, setValue] = useState("");
  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);

  const maxLen = 250;

  const saveEditHandler = async () => {
    await patchProfileDetails(userInfo.access_token, "bio", value);
    showToast({
      title: "Success",
      description: "Description changed successfully",
      type: "success",
    });

    setTimeout(() => {
      if (componentFeed) {
        setSecondaryScreen("/user-settings-profile");
      } else {
        setPrincipalScreen("/user-settings-profile");
      }
    }, 2000);
  };

  const requestUserInfo = async () => {
    const response = await getUserInfo(userInfo.access_token);
    setUserInfoRequest(response.res);
    if (userInfoRequest) {
      if (userInfoRequest.bio) {
        setValue(userInfoRequest.bio);
      }
    }
  };

  useEffect(() => {
    requestUserInfo();
  }, [userInfo, userInfoRequest?.bio]);

  return (
    <div
      className="user-settings-edit-bio
		 		user-settings-edit-bio-wrapper
		  	wrapper
				standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-edit-bio-inner inner">
        <PageTitle
          title={"Edit Profile"}
          subtitle={"Configuration"}
          hasCloseIcon={false}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-profile", { btn: "button2" });
            } else {
              setPrincipalScreen("/user-settings-profile", { btn: "button2" });
            }
          }}
        />
        <main className="user-settings-edit-bio__main">
          <div className="user-settings-edit-bio__input-container">
            <InputMessage
              value={value}
              onChangeInput={(e) => setValue(e)}
              label="Bio"
              placeholder={"About me"}
              maxLen={maxLen}
            />
            <div className="user-settings-edit-bio__counter">
              {value.length}/{maxLen} characters
            </div>
          </div>

          <Button buttonStyle="primary" onClick={saveEditHandler}>
            Done
          </Button>
        </main>
      </div>
    </div>
  );
};

export default UserSettingsEditBioPage;
