import React, { useState, useEffect } from "react";
import "./styles.scss";
import newImage from "assets/images/landingPageImages/featuresImages/auto_translate.png";
import languageTranslateIcon from "assets/icons/language-icon-gray.svg";
import italianFlag from "assets/flags/flag-italian.svg";
import koreanFlag from "assets/flags/flag-korean.svg";
import ChatBubbleImage from "./ChatBubble";

const ChatImageAnimation: React.FC = () => {
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);
  const messages = [
    {
      countryFlag: italianFlag,
      side: "left",
      message:
        "Ciao, bellissima! Volevo solo dirti che il mio cuore inizia a battere forte quando vedo il tuo sorriso.",
      time: "13:08",
      translateDisclaimerContent:
        "(The message automatically translates into Korean for Kim)",
    },
    {
      countryFlag: koreanFlag,
      side: "right",
      message:
        "안녕하세요, 예쁜 사람! 단지 말씀드리고 싶은데, 당신의 웃음을 보면 제 심장이 뛰기 시작해요.",
      time: "20:08",
      translateDisclaimerContent: "(Translated from Italian)",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveMessageIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="image-chat-container">
      <img src={newImage} className="chat-background-image" alt="Image Chat" />
      <div className="text-container">
        <p>Auto-translation by Roses™</p>
        <img
          src={languageTranslateIcon}
          className="icon"
          alt="Language Translate Icon"
        />
      </div>
      <div
        className={`chat-message-container-left ${
          activeMessageIndex === 0 ? "active" : ""
        }`}
      >
        <ChatBubbleImage {...messages[0]} />
      </div>
      <div
        className={`chat-message-container-right ${
          activeMessageIndex === 1 ? "active" : ""
        }`}
      >
        <ChatBubbleImage {...messages[1]} />
      </div>
    </div>
  );
};

export default ChatImageAnimation;
