import fetchApi from "../fetchApi";

interface IPostGoalDonate {
  token: string;
  amount: number;
  goalId: string;
}

const postCreatorSettingsGoalDonate = async (props: IPostGoalDonate) => {
  const { token, amount, goalId } = props;
  return await fetchApi({
    service: "creatorSettings",
    endPoint: "/goal/donate",
    method: "POST",
    data: { amount, goalId },
    token,
  });
};

export default postCreatorSettingsGoalDonate;
