import React, { createContext, ReactNode, useState } from "react";

interface PullToRefreshProps {
  isEnabled: boolean;
  setIsEnabled: (value: boolean) => void;
}

interface PullToRefreshProviderProps {
  children: ReactNode;
}

export const PullToRefreshContext = createContext({} as PullToRefreshProps);

export const PullToRefreshProvider: React.FC<PullToRefreshProviderProps> = ({
  children,
}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(true);

  return (
    <PullToRefreshContext.Provider value={{ isEnabled, setIsEnabled }}>
      {children}
    </PullToRefreshContext.Provider>
  );
};
