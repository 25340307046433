import React, { useEffect, useState } from "react";
import "./styles.scss";

interface ToggleProps {
  isGold?: boolean;
  initialState?: boolean;
  onChange: (isToggled: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = (props) => {
  const { isGold, initialState = false, onChange } = props;
  const [isToggled, setIsToggled] = useState(initialState);

  useEffect(() => {
    setIsToggled(initialState);
  }, [initialState]);

  const toggle = () => {
    const toggled = !isToggled;
    setIsToggled(toggled);
    onChange(toggled);
  };
  return (
    <div className="toggle-container" onClick={toggle}>
      <div
        className={`toggle-switch ${isToggled ? "on" : "off"}`}
        data-gold={isGold}
      >
        <div
          className={`toggle-handle ${isToggled ? "handle-on" : "handle-off"}`}
          data-gold={isGold}
        ></div>
      </div>
    </div>
  );
};
export default Toggle;
