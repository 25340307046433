import * as jwtDecode from "jwt-decode";
import { IInfoToken } from "types/infoToken";

const isTokenExpired = (exp: number) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp > exp;
};

const verifyToken = (token?: string) => {
  const tokenStorage = localStorage.getItem("access_token");
  const tokenToVerify = token ? token : tokenStorage;
  if (!tokenToVerify) return false;
  try {
    const userInfoToken = jwtDecode.jwtDecode(tokenToVerify) as IInfoToken;

    if (isTokenExpired(userInfoToken.exp)) return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default verifyToken;
