import React, { useRef, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";

import UploadDocumentPage from "pages/UploadDocument";
import WaitListApplicationPage from "pages/WaitListApplication";

import postIdentity from "api/validateIdentity/postIdentity";

import { useBecomeCreator } from "contexts/becomeCreatorContext";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useUserInfo } from "../../hooks/userInfo";
import { useFiles } from "hooks/documentContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";

import { base64OrBlobToFile } from "utils/base64OrBlobToFile";

const RecordSelf: React.FC = () => {
  const { setPrincipalScreen, params } = useDeskNavigation();
  const { registerBecomeCreator } = useBecomeCreator();
  const {
    firstName,
    lastName,
    dateOfBirth,
    documentType,
    country,
    street,
    city,
    zipCode,
  } = registerBecomeCreator;

  const { files } = useFiles();
  const [documentFileFront, documentFileBack] = files;
  const { windowSize } = useWindowWidthSize();
  const { userInfo } = useUserInfo();
  const { setOpenUseModal, setModalContent } = useModal();
  const { showToast } = useToast();

  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const videoPreviewRef = useRef<HTMLVideoElement>(null);

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recording, setRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [progress, setProgress] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const breakPoint = windowSize >= 768;

  const startCamera = async () => {
    try {
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      setStream(userStream);
      if (videoRef.current) {
        videoRef.current.srcObject = userStream;
      }
    } catch (error) {
      showToast({
        title: "Error",
        description: "Error, Please try later!",
        type: "error",
      });
    }
  };

  const startRecording = () => {
    if (!stream) return;

    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    const chunks: BlobPart[] = [];
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const completeBlob = new Blob(chunks, { type: "video/webm" });
      setVideoBlob(completeBlob);
    };

    mediaRecorder.start();
    setRecording(true);
    setProgress(0);
    setTimeElapsed(0);

    const startTime = Date.now();
    const id = setInterval(() => {
      const elapsed = Date.now() - startTime;
      setTimeElapsed(elapsed);
      setProgress((elapsed / 5000) * 100);
      if (elapsed >= 5000) {
        clearInterval(id);
        stopRecording();
      }
    }, 100);

    setIntervalId(id);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }

    setRecording(false);
    setProgress(0);

    if (intervalId) {
      clearInterval(intervalId);
    }

    stopCamera();
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const calculateEllipseCircumference = (rx: number, ry: number) => {
    return Math.PI * (3 * (rx + ry) - Math.sqrt((3 * rx + ry) * (rx + 3 * ry)));
  };

  const getEllipsePath = (cx: number, cy: number, rx: number, ry: number) => {
    return `
        M ${cx} ${cy - ry}
        A ${rx} ${ry} 0 1 1 ${cx} ${cy + ry}
        A ${rx} ${ry} 0 1 1 ${cx} ${cy - ry}
    `;
  };

  const togglePlayPause = () => {
    if (videoPreviewRef.current) {
      if (isPlaying) {
        videoPreviewRef.current.pause();
      } else {
        setTimeout(() => {
          videoPreviewRef.current?.play();
        }, 100);
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  const handleSubmit = async () => {
    try {
      if (!(documentFileFront instanceof File)) {
        throw new Error("documentFileFront is not a valid File");
      }
      if (!(documentFileBack instanceof File)) {
        throw new Error("documentFileBack is not a valid File");
      }

      if (!videoBlob) {
        throw new Error("No recorded video available");
      }

      const videoBlobUrl = URL.createObjectURL(videoBlob);

      const videoFile = await base64OrBlobToFile(
        videoBlobUrl,
        "video_recorded"
      );

      if (!videoFile) {
        throw new Error("Failed to convert videoBlob to File");
      }

      if (
        !firstName ||
        !lastName ||
        !documentType ||
        !dateOfBirth ||
        !country ||
        !street ||
        !city ||
        !zipCode
      ) {
        showToast({
          title: "Missing information",
          description: "Please fill in all required fields before continuing.",
          type: "error",
        });
        return;
      }

      const data = {
        firstName,
        lastName,
        dateOfBirth: new Date(dateOfBirth),
        documentType: documentType,
        faceScanVideo: videoFile,
        docFrontImage: documentFileFront,
        docBackImage: documentFileBack,
        country,
        street,
        city,
        zipCode,
      };
      await postIdentity(userInfo.access_token, data);
      handlerNextPage();
    } catch (error) {
      showToast({
        title: "Error",
        description: "Error, Please try later!",
        type: "error",
      });
    }
  };

  const handlerNextPage = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<WaitListApplicationPage />);
    } else {
      setPrincipalScreen("/wait-list-application");
    }
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<UploadDocumentPage />);
    } else {
      setPrincipalScreen("/upload-document");
    }
  };

  return (
    <div className="record-self record-self-wrapper wrapper standard-page-layout">
      <div className="record-self-inner inner">
        <PageTitle
          title="Become a Creator"
          subtitle="Start Earning"
          hasCloseIcon={false}
          onBackClick={handlerBackPage}
        />

        <div className="record-self__video-container">
          <video
            className="record-self__video"
            ref={videoRef}
            autoPlay
            muted
            playsInline
          />
        </div>

        <div className="record-self__controllers">
          <main className={`record-self__body`}>
            <p
              className={`record-self__prefix ${stream ? "record-self__hidden" : ""}`}
            >
              Turn your head <br />
              slowly to both sides
            </p>

            <div className="record-self__guid-line">
              <span
                className={`icon icon-face-id icon-white record-self__icon ${stream ? "record-self__hidden" : ""}`}
              ></span>

              <svg
                style={{ width: "100%", height: "100%" }}
                viewBox="0 0 250 250"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="record-self__svg"
                preserveAspectRatio="xMidYMid meet"
              >
                <ellipse
                  cx="50%"
                  cy="50%"
                  rx="120"
                  ry="145"
                  fill="transparent"
                  stroke="#fff"
                  strokeWidth="5"
                />
                <path
                  d={getEllipsePath(125, 125, 120, 145)}
                  fill="transparent"
                  stroke="#ee4e87"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeDasharray={calculateEllipseCircumference(120, 145)}
                  strokeDashoffset={
                    calculateEllipseCircumference(120, 145) *
                    (1 - progress / 100)
                  }
                />
              </svg>
            </div>

            <Header
              title="Record Selfie"
              subTitle={
                <>
                  Let’s make sure nobody is <br /> trying to impersonate you.
                </>
              }
              marginTop={0}
              titleClass="record-self__title"
              className={`${stream ? "record-self__hidden" : ""}`}
            />
          </main>

          <div className="record-self__bottom">
            {stream ? (
              <>
                <Button
                  onClick={recording ? stopRecording : startRecording}
                  buttonStyle={"primary"}
                >
                  {recording ? "Stop Recording" : "Start Record"}
                </Button>
                <Button onClick={stopCamera} buttonStyle={"secondary"}>
                  Turn off Camera
                </Button>
              </>
            ) : (
              <Button onClick={startCamera} buttonStyle={"secondary"}>
                Record Selfie
              </Button>
            )}
          </div>
        </div>

        {videoBlob && !recording && (
          <div className="record-self__preview">
            <div
              className={`record-self__play-button ${isPlaying ? "record-self__hidden" : ""}`}
            >
              <span
                className="icon icon-play record-self__play-button-icon"
                onClick={togglePlayPause}
              ></span>
            </div>

            <video
              ref={videoPreviewRef}
              className="record-self__video"
              src={URL.createObjectURL(videoBlob)}
              onEnded={handleVideoEnd}
              muted
            />

            <div className="record-self__preview-buttons">
              <Button
                children="Continue"
                buttonStyle={"primary"}
                onClick={handleSubmit}
              />
              <Button
                children="Record Again"
                buttonStyle={"secondary"}
                onClick={() => {
                  setVideoBlob(null);
                  setIsPlaying(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecordSelf;
