import fetchApi from "../fetchApi";

export type IDeletePosts = {
  postId: string;
};

const deletePosts = async (token: string, data: IDeletePosts) => {
  const { postId } = data;

  return await fetchApi({
    service: "posts",
    method: "DELETE",
    endPoint: `/${postId}`,
    token,
  });
};

export default deletePosts;
