import PageTitle from "components/PageTitles";
import "./styles.scss";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import Button from "components/Buttons/Button";
import ListItemLink from "components/Lists/ListItemLink";
import { thousandsFormat } from "utils/thousandsFormat";
import { formatDateShort } from "utils/formatDateShort";
import { useEffect, useState } from "react";
import getTransactionsUserById from "api/transactions/getTransactionsUserById";
import { useUserInfo } from "hooks/userInfo";

type IExtract = {
  transactionId: string;
  transactionType: string;
  createdAt: string;
  message?: string;
  entries: IExtractEntry[];
};

type IExtractEntry = {
  amount: number;
  cash: number;
  user?: IUser;
};

type IUser = {
  displayname?: string;
};

const UserSettingsExtractDetailsPage: React.FC = () => {
  const {
    componentFeed,
    setPrincipalScreen,
    setSecondaryScreen,

    params,
  } = useDeskNavigation();
  const [extract, setExtract] = useState<IExtract | null>(null);
  const { userInfo } = useUserInfo();

  useEffect(() => {
    const fetchTransaction = async () => {
      if (params.transactionId) {
        try {
          const { res } = await getTransactionsUserById(
            userInfo.access_token,
            params.transactionId
          );

          setExtract(transformExtract(res));
        } catch (error) {
          console.error("Erro ao buscar a transação:", error);
        }
      }
    };

    fetchTransaction();
  }, [params.transactionId, userInfo.access_token]);

  const mapTransactionType: Record<string, string> = {
    deposit: "Add Roses",
    transfer: "Roses Credit",
  };

  const transformExtract = (data: IExtract): IExtract => {
    return {
      ...data,
      transactionType:
        mapTransactionType[data.transactionType.toLowerCase()] ||
        data.transactionType,
    };
  };

  return (
    <div
      className="user-settings-extract-details
		 		user-settings-extract-details-wrapper
		  	wrapper
				standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-extract-details-inner inner">
        <PageTitle
          title={"Item Detail"}
          subtitle={"Transaction"}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-wallet", {
                btn: "button2",
                id: "2",
              });
            }
            setPrincipalScreen("/user-settings-wallet", {
              btn: "button2",
              id: "2",
            });
          }}
          hasCloseIcon={false}
        />

        <ListItemLink
          highlightText={extract?.transactionType ?? ""}
          simpleText={extract ? formatDateShort(extract?.createdAt) : ""}
          rightNodeContent={
            <span className="user-settings-extract-details__value">
              {extract?.entries
                ? thousandsFormat(extract.entries[0].amount)
                : ""}
            </span>
          }
          className="user-settings-extract-details__item"
        />

        <table className="user-settings-extract-details__table">
          <tbody>
            {extract?.entries[0]?.user?.displayname &&
              extract?.entries[1]?.user?.displayname && (
                <>
                  <tr>
                    <th scope="row">To</th>
                    <td>{extract?.entries[0].user.displayname}</td>
                  </tr>
                  <tr>
                    <th scope="row">From</th>
                    <td>{extract?.entries[1].user.displayname}</td>
                  </tr>
                </>
              )}
            {extract?.entries[0].cash && (
              <tr>
                <th scope="row">Cash</th>
                <td>R$ {thousandsFormat(extract?.entries[0].cash)}</td>
              </tr>
            )}
            <tr>
              <th scope="row">Amount</th>
              <td>
                {extract?.entries && thousandsFormat(extract.entries[0].amount)}
              </td>
            </tr>
            <tr>
              <th scope="row">Date</th>
              <td>
                {extract?.createdAt && formatDateShort(extract.createdAt)}
              </td>
            </tr>
            <tr>
              <th scope="row">Message</th>
              <td>
                {extract?.message ? extract.message : "no message available"}
              </td>
            </tr>
            <tr>
              <th scope="row">Confirmation#</th>
              <td>{extract?.transactionId}</td>
            </tr>
          </tbody>
        </table>

        <div className="user-settings-extract-details__buttons-container">
          {/* TODO */}
          <Button children={"Save"} buttonStyle={"tertiary"} />
          <Button children={"Print"} buttonStyle={"quaternary"} />
        </div>
      </div>
    </div>
  );
};

export default UserSettingsExtractDetailsPage;
