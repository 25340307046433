import { TableRow } from "types/interfaces";

const tableDataMock: TableRow[] = [
  {
    type: "gold",
    label: "Golden Roses",
    values: [4],
  },
  {
    type: "data",
    label: "Roses",
    values: [150, "$485.00"],
  },
  {
    type: "total",
    label: "Total",
    values: [154, "$485.00"],
  },
];

export default tableDataMock;
