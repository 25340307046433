import React, { ReactNode } from "react";
import "./styles.scss";

import TitleText from "../../Texts/TitleText";

import { shortClassNames } from "utils/shortClassNames";

import LogoRosesIconPink from "assets/icons/logos/logo-rose-icon-pink.svg";
import LeftArrow from "assets/icons/navigationIcons/arrow-left-gray.svg";
import LeftArrowWhite from "assets/icons/navigationIcons/arrow-left-white.svg";
import Bell from "assets/icons/bell-pink.svg";
import Heart from "assets/icons/heartIcons/heart-pink.svg";
import PinIcon from "assets/icons/location-pink.svg";
import GlobePink from "assets/icons/globe-pink.svg";
import AirPlanePink from "assets/icons/air-plane-pink.svg";
import StarPink from "assets/icons/star-outline.svg";
import GridPink from "assets/icons/grid-pink.svg";
import FirePink from "assets/icons/fire-pink.svg";
import RosesLogoHollow from "assets/icons/logos/logo-hollow-pink.svg";
import CryptoIconPink from "assets/icons/crypto-icon-pink.svg";
import PercentIconPink from "assets/icons/percent-icon-pink.svg";
import ImageIconPink from "assets/icons/photo.svg";
import VideoIconPink from "assets/icons/vide-pink.svg";
import LingerieIconPink from "assets/icons/lingerie-icon-pink.svg";
import DigitalPrint from "assets/icons/digital-print-icon-pink.svg";
import ShieldIconPink from "assets/icons/shieldIcons/security.svg";
import BallonIconPink from "assets/icons/ballon-icon-pink.svg";
import QRCode from "assets/icons/qr-code.svg";

interface HeaderProps {
  title?: ReactNode;
  tagTitle?: "title" | "h1" | "h2" | "h3" | "h4";
  subTitle?: ReactNode;
  headerIcon?:
    | "air-plane"
    | "back-button"
    | "back-button-white"
    | "roses-logo"
    | "bell"
    | "globe"
    | "heart"
    | "pin-icon"
    | "star"
    | "grid"
    | "fire"
    | "roses-hollow"
    | "percent"
    | "crypto"
    | "image"
    | "video"
    | "lingerie"
    | "digital-print"
    | "shield"
    | "ballon"
    | "qr-code"
    | undefined;
  marginTop?: number;
  hasGapBetweenTitleAndSub?: boolean;
  noMarginTopTextAndIcon?: boolean;
  hasIconGap?: boolean;
  inlineElements?: boolean;
  modalStyle?: boolean;
  titleClass?: "header" | string;
  className?: string;
  backButtonClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    title,
    subTitle,
    headerIcon,
    marginTop,
    hasIconGap,
    hasGapBetweenTitleAndSub = true,
    noMarginTopTextAndIcon = false,
    tagTitle,
    inlineElements,
    modalStyle,
    titleClass,
    className,
    backButtonClick,
  } = props;

  const switchIcon = () => {
    switch (headerIcon) {
      case "air-plane":
        return AirPlanePink;
      case "back-button":
        return LeftArrow;
      case "back-button-white":
        return LeftArrowWhite;
      case "bell":
        return Bell;
      case "grid":
        return GridPink;
      case "fire":
        return FirePink;
      case "globe":
        return GlobePink;
      case "heart":
        return Heart;
      case "pin-icon":
        return PinIcon;
      case "roses-logo":
        return LogoRosesIconPink;
      case "star":
        return StarPink;
      case "crypto":
        return CryptoIconPink;
      case "percent":
        return PercentIconPink;
      case "roses-hollow":
        return RosesLogoHollow;
      case "image":
        return ImageIconPink;
      case "lingerie":
        return LingerieIconPink;
      case "video":
        return VideoIconPink;
      case "digital-print":
        return DigitalPrint;
      case "shield":
        return ShieldIconPink;
      case "ballon":
        return BallonIconPink;
      case "qr-code":
        return QRCode;

      default:
        return undefined;
    }
  };

  const icon = switchIcon();

  return (
    <header
      id="header-default"
      className={shortClassNames([
        `${hasGapBetweenTitleAndSub ? "gap-between" : ""}`,
        `${className ?? ""}`,
      ])}
      style={{ marginTop: marginTop }}
    >
      <div
        className={shortClassNames([
          `header-default__icon-title`,
          `${hasIconGap ? "header-default__icon-gap" : ""}`,
          `${inlineElements ? "inline-elements" : ""}`,
          `${noMarginTopTextAndIcon ? "header__no-margin-top" : ""}`,
        ])}
      >
        {headerIcon && (
          <div
            className={shortClassNames([
              `icon-container`,
              `${noMarginTopTextAndIcon ? "header__no-margin-top" : ""}`,
            ])}
          >
            <img
              style={{
                width: modalStyle && headerIcon !== "back-button" ? 18 : 24,
                height: modalStyle && headerIcon !== "back-button" ? 18 : 24,
              }}
              src={icon}
              alt={`${
                headerIcon === "back-button" ? "left arrow icon" : "Roses Logo"
              }`}
              onClick={
                headerIcon === "back-button" ||
                headerIcon === "back-button-white"
                  ? backButtonClick
                  : undefined
              }
            />
          </div>
        )}

        <TitleText
          size={tagTitle}
          titleClass={shortClassNames([
            `${titleClass ?? ""}`,
            `${noMarginTopTextAndIcon ? "header__no-margin-top" : ""}`,
          ])}
          modalStyle={modalStyle}
        >
          {title}
        </TitleText>
      </div>
      {subTitle && <p className="description-header">{subTitle}</p>}
    </header>
  );
};

export default Header;
