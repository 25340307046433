import { RouteObject } from "react-router-dom";
import AccountLoginPage from "../pages/AccountLogin";
import CreateNumberVerifyPage from "../pages/CreateNumberVerify";
import PasswordSignInPage from "../pages/PasswordSignIn";
import ResetPasswordPage from "../pages/ResetPassword";
import AccountTypePage from "../pages/AccountType";
import UserInformation from "../pages/UserInformation";
import CreateNamePage from "../pages/CreateName";
import SendPhotoAlbumPage from "../pages/SendPhotoAlbum";
import WaitListApplicationPage from "../pages/WaitListApplication";
import UserSettingsPage from "../pages/UserSettings";
import UserSettingsPrivacyPage from "../pages/UserSettingsPrivacy";
import UserSettingsProfilePage from "../pages/UserSettingsProfile";
import AddReferralsPage from "../pages/AddReferrals";
import InviteFriendsPage from "../pages/InviteFriends";
import AllowLocationPage from "../pages/AllowLocation";
import AllowNotificationPage from "../pages/AllowNotification";
import UserSettingsAccountPage from "../pages/UserSettingsAccount";
import UserSettingsLocationPage from "../pages/UserSettingsLocation";
import UserSettingsPersonalSafetyPage from "../pages/UserSettingsPersonalSafety";
import UserSettingsMyContactsPage from "../pages/UserSettingsMyContacts";
import CreateLegalNamePage from "../pages/CreateLegalName";
import UserSettingsHoldSafePage from "../pages/UserSettingsHoldSafe";
import RecoveryEmailPage from "../pages/RecoveryEmail";
import CreateEmailVerifyPage from "../pages/CreateEmailVerify";
import CreatePasswordPage from "../pages/CreatePassword";
import GenderIdentificationPage from "../pages/GenderIdentification";
import TermsAndConditionsPage from "../pages/TermsAndConditions";
import UploadDocumentPage from "../pages/UploadDocument";
import UserSettingsWalletPage from "../pages/UserSettingsWallet";
import UserSettingsAppLanguagePage from "../pages/UserSettingsAppLanguage";
import UserSettingsDeleteAccountPage from "../pages/UserSettingsDeleteAccount";
import UserSettingsChangePasswordPage from "../pages/UserSettingsChangePassword";
import UserSettingsSubscriptionPage from "../pages/UserSettingsSubscription";
import ChatListPage from "../pages/ChatList";
import Homepage from "../pages/Homepage";
import LandingPage from "../pages/LandingPage";
import Membership from "../pages/Membership";
import WelcomeUser from "../pages/WelcomeUser";
import ChatPage from "../pages/ChatPage";
import App from "../pages/App";
import MediaLibraryPage from "../pages/MediaLibrary";
import PrivacyPolicyPage from "../pages/LandingPage/PrivacyPolicyPage";
import TermsPage from "../pages/LandingPage/TermsPage";
import USCPage from "../pages/LandingPage/USCPage";
import UserSettingsManageAccountPage from "../pages/UserSettingsManageAccount";
import CreateAccountPage from "../pages/CreateAccount";
import FeedPage from "../pages/Feed";
import UserSettingsEditProfilePage from "../pages/UserSettingsEditProfile";
import AddMediaPage from "../pages/AddMediaPage";
import UserSettingsLanguageProfilePage from "../pages/UserSettingsLanguage";
import UserSettingsEditBioPage from "../pages/UserSettingsEditBio";
import UserSettingsMyOffersPage from "../pages/UserSettingsMyOffers";
import UserSettingsNotificationsPage from "../pages/UserSettingsNotifications";
import ShowAllButtonIcons from "../pages/ShowAllButtonIcons";
import CatalogPage from "../pages/Catalog";
import UserSettingsChangeProfile from "../pages/UserSettingsChangeProfile";
import UserSettingsGoals from "../pages/UserSettingsGoals";
import UserSettingsEditGoal from "../pages/UserSettingsEditGoal";
import UserSettingsBlockedLocationPage from "pages/UserSettingsBlockedLocation";
import CreatorDashboardPage from "pages/CreatorDashboard";
import UserSettingsExtractDetailsPage from "pages/UserSettingsExtractDetails";
//import DashboardMedia from "pages/Felipe/DashboardMedia";
import VerifyEmailPage from "pages/VerifyEmail";
import FeedGlobal from "pages/FeedGlobal";
import PageNotFound from "pages/PageNotFound";
import UserSettingsConfigurationPage from "pages/UserSettingsConfiguration";
import UserSettingsMySubscriptionsPage from "pages/UserSettingsMySubscriptions";
import LostConnectionPage from "pages/LostConnection";
import MainLayout from "pages/MainLayout";
import MyRequestPage from "pages/MyRequest";
import CreateRequestPage from "pages/CreateRequest";
import UserSettingsMySubscriptionsDetailsPage from "pages/UserSettingsMySubscriptionsDetails";
import UserSettingsMySubscribersPage from "pages/UserSettingsMySubscribers";
import UserSettingsMyPlansPage from "pages/UserSettingsMyPlans";
import PersonalInformation from "pages/PersonalInformation";
import LegalResidence from "pages/LegalResidence";
import IdentityVerificationPage from "pages/IdentityVerification";
import SendMeLink from "pages/SendMeLink";
import ChooseDocument from "pages/ChooseDocument";
import BecomeCreator from "pages/BecomeCreator";
import VerifyDocument from "pages/VerifyDocument";
import RecordSelf from "pages/RecordSelf";
import UserSettingsProfileCustomizePage from "pages/UserSettingsProfileCustomize";
import UserSettingsProfilePhotoPage from "../pages/UserSettingsProfilePhoto";
import UserSettingsReferralsPage from "pages/UserSettingsReferrals";
import UserSettingsPersonalTouchPage from "pages/UserSettingsPersonalTouch";
import ProducerSummary from "pages/ProducerSummary";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/:creator_name",
        element: <Homepage />,
      },
      {
        path: "/feed/:creator_name",
        element: <FeedPage />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/terms-conditions",
        element: <TermsPage />,
      },
      {
        path: "/usc2257",
        element: <USCPage />,
      },
      {
        path: "/login",
        element: <App />,
      },
      {
        path: "/account-login",
        element: <AccountLoginPage />,
      },
      {
        path: "/create-number-verify",
        element: <CreateNumberVerifyPage />,
      },
      {
        path: "/create-account",
        element: <CreateAccountPage />,
      },
      {
        path: "/app/:creatorId?",
        element: <Homepage />,
      },
      {
        path: "/become-creator",
        element: <BecomeCreator />,
      },
      {
        path: "/create-legal-name",
        element: <CreateLegalNamePage />,
      },
      {
        path: "/upload-document",
        element: <UploadDocumentPage />,
      },
      {
        path: "/recovery-email",
        element: <RecoveryEmailPage />,
      },
      {
        path: "/create-email-verify",
        element: <CreateEmailVerifyPage />,
      },
      {
        path: "/verify-email",
        element: <VerifyEmailPage />,
      },
      {
        path: "/verify-document",
        element: <VerifyDocument />,
      },
      {
        path: "/send-photos",
        element: <SendPhotoAlbumPage />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditionsPage />,
      },
      {
        path: "/wait-list-application",
        element: <WaitListApplicationPage />,
      },
      {
        path: "/user-information",
        element: <UserInformation />,
      },
      {
        path: "/personal-information",
        element: <PersonalInformation />,
      },
      {
        path: "/legal-residence",
        element: <LegalResidence />,
      },
      {
        path: "/identity-verification",
        element: <IdentityVerificationPage />,
      },
      {
        path: "/send-me-link",
        element: <SendMeLink />,
      },
      {
        path: "/choose-document",
        element: <ChooseDocument />,
      },
      {
        path: "/record-self",
        element: <RecordSelf />,
      },
      {
        path: "/add-referrals",
        element: <AddReferralsPage />,
      },
      {
        path: "/welcome-user",
        element: <WelcomeUser />,
      },
      /////
      {
        path: "/membership",
        element: <Membership />,
      },
      {
        path: "/password-sign-in",
        element: <PasswordSignInPage />,
      },
      {
        path: "/reset-password",
        element: <ResetPasswordPage />,
      },
      {
        path: "/account-type",
        element: <AccountTypePage />,
      },
      {
        path: "/create-name",
        element: <CreateNamePage />,
      },
      {
        path: "/user-settings",
        element: <UserSettingsPage />,
      },
      {
        path: "/user-settings-privacy",
        element: <UserSettingsPrivacyPage />,
      },
      {
        path: "/user-settings-profile",
        element: <UserSettingsProfilePage />,
      },
      {
        path: "/user-settings-profile-photo",
        element: <UserSettingsProfilePhotoPage />,
      },
      {
        path: "/user-settings-profile-customize",
        element: <UserSettingsProfileCustomizePage />,
      },
      {
        path: "/invite-friends",
        element: <InviteFriendsPage />,
      },
      {
        path: "/allow-location",
        element: <AllowLocationPage />,
      },
      {
        path: "/allow-notification",
        element: <AllowNotificationPage />,
      },
      {
        path: "/user-settings-account",
        element: <UserSettingsAccountPage />,
      },
      {
        path: "/user-settings-location",
        element: <UserSettingsLocationPage />,
      },
      {
        path: "/user-blocked-location",
        element: <UserSettingsBlockedLocationPage />,
      },
      {
        path: "/user-settings-personal-safety",
        element: <UserSettingsPersonalSafetyPage />,
      },
      {
        path: "/user-settings-holdsafe",
        element: <UserSettingsHoldSafePage />,
      },
      {
        path: "/create-password",
        element: <CreatePasswordPage />,
      },
      {
        path: "/gender-identification",
        element: <GenderIdentificationPage />,
      },
      {
        path: "/user-settings-configuration",
        element: <UserSettingsConfigurationPage />,
      },
      {
        path: "/user-settings-mycontacts",
        element: <UserSettingsMyContactsPage />,
      },
      {
        path: "/user-settings-wallet",
        element: <UserSettingsWalletPage />,
      },
      {
        path: "/user-settings-language",
        element: <UserSettingsAppLanguagePage />,
      },
      {
        path: "/user-settings-language-profile",
        element: <UserSettingsLanguageProfilePage />,
      },
      {
        path: "/user-settings-delete-account",
        element: <UserSettingsDeleteAccountPage />,
      },
      {
        path: "/user-settings-change-password",
        element: <UserSettingsChangePasswordPage />,
      },
      {
        path: "/user-settings-my-plans",
        element: <UserSettingsMyPlansPage />,
      },
      {
        path: "/user-settings-subscriptions",
        element: <UserSettingsSubscriptionPage />,
      },
      {
        path: "/user-settings-my-subscriptions",
        element: <UserSettingsMySubscriptionsPage />,
      },
      {
        path: "/user-settings-my-subscribers",
        element: <UserSettingsMySubscribersPage />,
      },
      {
        path: "/user-settings-referrals",
        element: <UserSettingsReferralsPage />,
      },
      {
        path: "/user-settings-my-subscriptions-details",
        element: <UserSettingsMySubscriptionsDetailsPage />,
      },
      {
        path: "/user-settings-manage-account",
        element: <UserSettingsManageAccountPage />,
      },
      {
        path: "/chat-list",
        element: <ChatListPage />,
      },
      {
        path: "/personal-chat",
        element: <ChatPage />,
      },
      {
        path: "/media-library",
        element: <MediaLibraryPage />,
      },
      {
        path: "/producer-summary",
        element: <ProducerSummary />,
      },
      {
        path: "/feed/:creator_name",
        element: <FeedPage />,
      },
      {
        path: "/feed",
        element: <FeedPage />,
      },
      {
        path: "/user-settings-edit-profile",
        element: <UserSettingsEditProfilePage />,
      },
      {
        path: "/user-settings-edit-bio",
        element: <UserSettingsEditBioPage />,
      },
      {
        path: "/user-settings-personal-touch",
        element: <UserSettingsPersonalTouchPage />,
      },
      {
        path: "/user-settings-myoffers",
        element: <UserSettingsMyOffersPage />,
      },
      {
        path: "/user-settings-notifications",
        element: <UserSettingsNotificationsPage />,
      },
      {
        path: "/add-media-page",
        element: <AddMediaPage />,
      },
      {
        path: "/catalog",
        element: <CatalogPage />,
      },
      {
        path: "/user-settings-change-creator",
        element: <UserSettingsChangeProfile />,
      },
      {
        path: "/user-settings-goals",
        element: <UserSettingsGoals />,
      },
      {
        path: "/user-settings-edit-goal",
        element: <UserSettingsEditGoal />,
      },
      {
        path: "/user-settings-extract-details",
        element: <UserSettingsExtractDetailsPage />,
      },
      {
        path: "/creator-dashboard",
        element: <CreatorDashboardPage />,
      },
      /*   {
    path: "/dashboardMedia",
    element: <DashboardMedia />,
  }, */
      {
        path: "/feed-global",
        element: <FeedGlobal />,
      },
      {
        path: "/my-request",
        element: <MyRequestPage />,
      },
      {
        path: "/create-request",
        element: <CreateRequestPage />,
      },
      {
        path: "/all-icons",
        element: <ShowAllButtonIcons />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
      {
        path: "/offline",
        element: <LostConnectionPage />,
      },
    ],
  },
];
