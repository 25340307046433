import React, { ReactNode } from "react";
import "./styles.scss";

import Tooltip from "components/Tooltip";

interface SubTextProps {
  icon?: string;
  altIcon?: string;
  children: ReactNode;
  checkAnswer?: boolean;
  isChecked?: boolean;
  isWarning?: boolean;
  fontSize?: string;
  fontFamily?: "Roboto Regular" | "Montserrat Variable";
  lineHeight?: string;
  tooltip?: boolean;
  tooltipText?: string;
  className?: string;
  classNameIcon?: string;
  checkValueCallback?: (a: boolean) => void;
}

const SubText: React.FC<SubTextProps> = (props) => {
  const {
    icon,
    altIcon,
    children,
    checkAnswer,
    isChecked,
    isWarning,
    fontSize = "12px",
    fontFamily = "Roboto Regular",
    lineHeight = "1.4",
    tooltip,
    tooltipText,
    className,
    classNameIcon,
    checkValueCallback,
  } = props;

  return (
    <div id="icon-disclaimer" className={`icon-disclaimer ${className ?? ""}`}>
      {icon && (
        <img
          src={icon}
          alt={altIcon}
          className={`${classNameIcon ? classNameIcon : ""}`}
        />
      )}
      {checkAnswer && checkValueCallback ? (
        <label
          htmlFor={`icon-disclaimer__check-box`}
          className="container-checkbox"
        >
          <input
            id={`icon-disclaimer__check-box`}
            type="checkbox"
            onChange={(event) => {
              checkValueCallback(event.target.checked);
            }}
            checked={isChecked}
          />
          <span
            className={`container-checkbox__checkmark ${isWarning ? "isWarning" : ""}`}
          ></span>
        </label>
      ) : tooltip && tooltipText ? (
        <Tooltip text={tooltipText} />
      ) : (
        false
      )}
      <p
        className={`only-margin-bottom ${isWarning ? "isWarning" : ""}`}
        style={{ fontSize, fontFamily, lineHeight }}
      >
        {children}
      </p>
    </div>
  );
};

export default SubText;
