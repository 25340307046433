import { SetStateAction, useState } from "react";
import "./styles.scss";

import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import PageTitle from "components/PageTitles";
import InputCheckbox from "components/Inputs/InputCheckbox";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

const RecoveryEmailPage: React.FC = () => {
  const [userRecoveryEmail, setUserRecoveryEmail] = useState("");
  const [isSignNewsLetter, setIsSignNewsLetter] = useState(true);
  const [emailInputWarning, setInputWaning] = useState(false);
  const { showToast } = useToast();

  const { setPrincipalScreen } = useDeskNavigation();
  const { setUserInfo } = useUserInfo();

  const validateEmail = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const recoveryEmailSubmit = () => {
    if (validateEmail(userRecoveryEmail)) {
      setUserInfo("user_email", userRecoveryEmail);
      setUserInfo("news_letter", JSON.stringify(isSignNewsLetter));
      setPrincipalScreen("/create-email-verify");
    } else {
      showToast({
        type: "error",
        title: "invalid email",
        description: "Add a valid email",
      });

      setInputWaning(true);

      setTimeout(() => {
        setInputWaning(false);
      }, 3000);
    }
  };

  const [selectedAccept, setSelectedAccept] = useState<string[]>([]);

  return (
    <div className="recovery-email recovery-email-wrapper wrapper standard-page-layout">
      <div className="recovery-email-inner inner">
        <PageTitle
          title={"Settings"}
          subtitle={"Configuration"}
          hasCloseIcon={false}
          onBackClick={() => setPrincipalScreen("/upload-document")}
        />

        <main className="recovery-email__main">
          <Header
            title={
              <>
                Set a <br />
                recovery email
              </>
            }
            subTitle="Your e-mail is used to recover your account."
            marginTop={0}
          />
          <InputText
            value={userRecoveryEmail.toLowerCase()}
            inputType="email"
            placeholder="Email"
            textTransform="none"
            onChangeInput={(e) => setUserRecoveryEmail(e)}
            isWarning={emailInputWarning}
          />
          <InputCheckbox
            elements={[
              {
                inputLabel:
                  "I want to receive news, updates, and offers from Roses",
                inputValue: "true",
                groupName: "accept",
              },
            ]}
            setInputsValues={setSelectedAccept}
            inputValues={selectedAccept}
          />
          {/* <SubText
          checkAnswer
          checkValueCallback={setIsSignNewsLetter}
          isChecked={isSignNewsLetter}
           
        >
          I want to receive news, updates, and offers from Roses
        </SubText> */}
        </main>
        <Button
          className="recovery-email__continue-button"
          buttonStyle={"primary"}
          onClick={recoveryEmailSubmit}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default RecoveryEmailPage;
