import { useState } from "react";
import "./styles.scss";

import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";

import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

import EyeIcon from "assets/icons/eye-icon-gray.svg";

const CreateNamePage: React.FC = () => {
  const [userName, setUsername] = useState("");
  const [isUserNameInputWarning, setIsUserNameInputWarning] = useState(false);
  const { setComponent } = useLoginSignUpNavigation();
  const { setUserInfo } = useUserInfo();
  const { showToast } = useToast();

  const addNameSubmit = () => {
    if (userName.length < 3) {
      showToast({
        title: "Oops...",
        type: "warning",
        description: "The display name must be at least three letters long.",
      });

      setIsUserNameInputWarning(true);

      setTimeout(() => {
        setIsUserNameInputWarning(false);
      }, 4000);
    } else {
      setUserInfo("display_name", userName);
      setComponent("/send-photos");
    }
  };

  const handleGoBack = () => {
    setComponent("/account-type");
  };

  return (
    <div className="create-name create-name-wrapper wrapper">
      <div className="create-name-inner inner">
        <Header
          headerIcon="back-button"
          backButtonClick={() => handleGoBack()}
          title="Display name"
          subTitle={`We safeguard our community by ensuring the authenticity of 
					every member on our platform, upholding a standard of trust and 
					transparency.`}
        />
        <InputText
          value={userName}
          placeholder="Display Name"
          onChangeInput={(e) => setUsername(e)}
          isWarning={isUserNameInputWarning}
          inputType="text"
        />
        <SubText icon={EyeIcon} altIcon="clock icon">
          This will be shown on your profile
        </SubText>
      </div>

      <Button buttonStyle="primary" onClick={addNameSubmit}>
        Continue
      </Button>
    </div>
  );
};

export default CreateNamePage;
