import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Suspense,
} from "react";
import "./styles.scss";
import { ICatalogResponse } from "types/catalog";
import { textCapitalize } from "utils/textCapitalize";
import { shortClassNames } from "utils/shortClassNames";
import LazyImage from "components/LazyLoadedImage";
import { ImagePlaceholder } from "components/LazyLoadedImage/ImagePlaceholder";

interface IProfileCardProps {
  className?: string;
  data: ICatalogResponse;
  index: number;
  onClick: () => void;
}

const ProfileCard: React.FC<IProfileCardProps> = ({
  data,
  index,
  onClick,
  className,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [hovered, setHovered] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || !data?.profile?.cover) return;

    const handleCanPlay = () => setIsVideoReady(true);

    video.addEventListener("canplay", handleCanPlay);
    return () => video.removeEventListener("canplay", handleCanPlay);
  }, [data?.profile?.cover]);

  const handleMouseEnter = () => {
    setHovered(true);
    if (videoRef.current && isVideoReady) {
      videoRef.current.play().catch(console.error);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div
      className={shortClassNames([
        "profile-card",
        "element-footer-overlay-35",
        "element-header-overlay-35",
        className,
      ])}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {data?.profile?.cover ? (
        <>
          <Suspense fallback={<ImagePlaceholder />}>
            <LazyImage
              placeholderSrc={data.profile.photos[0]?.thumbnailUrl}
              src={data.profile.photos[0]?.url}
              alt="catalog gallery"
              className="catalog-media"
            />
          </Suspense>
          <video
            ref={videoRef}
            src={data.profile.cover?.url}
            loop
            muted
            playsInline
            disablePictureInPicture
            controls={false}
            preload="metadata"
            className="catalog-media catalog-video"
          />
        </>
      ) : (
        <Suspense fallback={<ImagePlaceholder />}>
          <LazyImage
            placeholderSrc={
              // hovered && data.profile.photos.length > 1
              //   ? data.profile.photos[1]?.thumbnailUrl:
              data.profile.photos[0]?.thumbnailUrl
            }
            src={
              hovered && data.profile.photos.length > 1
                ? data.profile.photos[1]?.url
                : data.profile.photos[0]?.url
            }
            alt="catalog gallery"
            className="catalog-media"
          />
        </Suspense>
      )}

      <div className="profile-title">
        <div className="user-info">
          <div className="profile_dots">
            <div className="right_dots">
              {data.isVip && (
                <span className="profile__icon icon icon-burn icon-md icon-primary-75" />
              )}
              {data.profile?.isAI && (
                <div className="ia-icon">
                  <span className="profile-ia-box">AI</span>
                </div>
              )}
            </div>

            <div className="statistics-info left_dots">
              <span className="icon icon-heart-solid icon-md icon-primary-75" />
              <p className="counter">12.3k</p>
            </div>
          </div>
          <div className="profile-names">
            <div className="user-title">
              <p className="profile-info small">{data?.displayname}</p>
              {data?.displayname === "Travis Montaque" && (
                <span className="icon icon-blue icon-verified-1 icon-sm" />
              )}
            </div>
            <div className="user-stats">
              <p className="counter">
                {data?.profile.age}{" "}
                {data?.profile.occupation &&
                  ` | ${textCapitalize(data.profile.occupation.replaceAll("_", " "))}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
