import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

import AddMediaPage from "pages/AddMediaPage";

import PageTitle from "components/PageTitles";
import ListItemLink from "components/Lists/ListItemLink";
import SlidUp from "components/Modals/SlidUp";
import RosesDashboard from "components/Wallet/RosesDashboard";
import BuyRoses from "components/BuyRoses";
import Modal from "components/Modals/Modal";
import Button from "components/Buttons/Button";
import Revenue from "components/Revenue";
import ButtonIcon from "components/Buttons/ButtonIcon";
import ImageCircleBorder from "components/ImageCircleBorder";
import DailyLogin from "components/DailyLogin";
import Paragraph from "components/Texts/Paragraph";
import TextActionCard from "components/Cards/TextActionCard";
import SendRosesModal from "components/Chat/ChatModals/SendRosesModal";

import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";

import WaitListApplicationPage from "pages/WaitListApplication";
import BecomeCreator from "pages/BecomeCreator";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useBuyRoses } from "hooks/useBuyRoses";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useToast } from "hooks/useToast";
import { useModal } from "hooks/useModal";
import { usePullToRefreshHandler } from "hooks/usePullToRefreshHandler";

import { useIconContext } from "../../contexts/iconNavContext";
import { useAvailableRoses } from "contexts/availableRosesContext";
import { ProfileContext } from "contexts/profileContext";

import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import getPackages from "api/packages/getPackages";
import postWithdrawRoses from "api/rosesTransactions/postWithdrawRoses";
import postAvatarProfile from "api/user/postAvatarProfile";
import getUserInfo from "api/userInfo/getUserInfo";
import patchProfileDetails from "api/profileDetails/patchProfileDetails";

import { ageCalculate } from "../../utils/dateFormat";
import { thousandsFormat } from "utils/thousandsFormat";
import { textCapitalize } from "utils/textCapitalize";

import logo from "assets/icons/logos/logo-fill-pink.svg";
import mobileIcon from "assets/icons/mobile.svg";
import iconGifts from "assets/icons/gifts-gray.svg";
import iconGear from "assets/icons/gear-icon-gray.svg";

import { IPackages } from "types/interfaces";
import { UserInfoRequest } from "types/userInfoRequest";
import { shortClassNames } from "utils/shortClassNames";

import verifyToken from "utils/verifyToken";

const UserSettingsPage: React.FC = () => {
  const [packages, setPackages] = useState<IPackages[]>([]);
  const [currentPackage, setCurrentPackage] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { userInfo, setUserInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();
  const navigate = useNavigate();
  const paramsUrl = useParams();

  const { showToast } = useToast();
  const { buyRoses } = useBuyRoses(currentPackage ?? "");
  const { setOpenUseModal, setModalContent } = useModal();

  const checkAuth = useAuthCheckOpenModal({ navigate, paramsUrl });

  const userAccountType = userInfo.account_type.toLowerCase() === "creator";
  const [imageProfileFile, setImageProfileFile] = useState<string>("");
  const [imageProfileName, setImageProfileName] = useState<string>("");
  const [userProfilePicture, setUserProfilePicture] = useState("");
  const { userInfoRequest, setUserInfoRequest } = useContext(ProfileContext);

  const pageRef = useRef<HTMLDivElement | null>(null);
  const pullToRefresh = usePullToRefreshHandler(pageRef);

  const breakPoint = windowSize >= 768;

  useEffect(() => {
    setImageProfileFile(userInfo.user_profile_avatar);
    setImageProfileName(userInfo.display_name);
    const creatorApplyStatus = async () => {
      const responseStatus = await getCreatorStatus(userInfo.access_token);

      if (responseStatus?.status === 200) {
        setUserInfo("referral_code", responseStatus?.res.referralCode);
        setUserInfo("creator_application_status", responseStatus?.res.status);
        setUserInfo(
          "creator_application_id",
          responseStatus?.res.creatorApplicationId
        );
        setUserProfilePicture(userInfo?.user_profile_avatar ?? "");
      } else if (responseStatus?.status === 404) {
        if (userInfo.account_type === "CREATOR") {
          setUserInfo("creator_application_status", "APPROVED");
        }
        setUserInfo("creator_application_status", "unsolicited");
      }
    };
    creatorApplyStatus();
  }, [userInfo?.user_profile_avatar, userInfo.access_token]);

  useEffect(() => {
    const requestUserInfo = async () => {
      const response = await getUserInfo(userInfo.access_token);

      if (response?.status === 200) {
        setUserInfoRequest(response.res);
      }
    };
    requestUserInfo();
  }, []);

  useEffect(() => {
    const fetchPackages = async () => {
      if (!verifyToken()) return;

      const { res } = await getPackages(userInfo.access_token);
      setPackages(res);
    };
    fetchPackages();
  }, [userInfo.access_token]);

  const goToSettings = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings-configuration");
    } else {
      setPrincipalScreen("/user-settings-configuration");
    }
  };

  const goToProfile = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings-profile-customize");
    } else {
      setPrincipalScreen("/user-settings-profile-customize");
    }
  };

  const userSettingsItems = [
    {
      labelContent: "Requests",
      iconLeftContent: iconGifts,
      clickAction: goToProfile,
      isActive: userAccountType,
      isBig: true,
    },
  ];

  const pageBack = () => {
    if (componentFeed) {
      setSecondaryScreen("");
    } else {
      setPrincipalScreen("");
    }

    handleButtonClick("grid");
  };
  const handleBuyRoses = async () => {
    const res = await buyRoses();

    if (res) {
      showToast({
        type: "success",
        title: "Buy Roses",
        description: "Congratulations",
      });
    } else {
      showToast({
        type: "error",
        title: "Buy Roses",
        description: "Congratulations",
      });
    }
    setIsOpen(false);
  };

  const goToWallet = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings-wallet");
    } else {
      setPrincipalScreen("/user-settings-wallet");
    }
  };

  const handleRedeemRoses = async () => {
    const request = await postWithdrawRoses(
      userInfo.access_token,
      availableRoses
    );
    if (request.status === 201 && availableRoses > 0) {
      updateSubtractAvailableRoses(availableRoses);

      showToast({
        type: "success",
        title: "Redeem Roses",
        description: "Congratulations",
      });
    } else if (availableRoses === 0) {
      showToast({
        type: "info",
        title: "Redeem Roses",
        description: "Not has roses to redeem",
      });
    } else {
      showToast({
        type: "error",
        title: "Redeem  Roses",
        description: "Error",
      });
    }
  };

  const handleModalRedeem = () => {
    setModalContent(
      <aside className="redeem-modal inner">
        <header className="redeem-modal__top">
          <img src={logo} alt="Logo Roses" className="redeem-modal__logo" />
          <h2 className="redeem-modal__title">
            Would you like to rescue your roses?
          </h2>
          <p className="redeem-modal__subtitle">
            Would you like to rescue your roses?
          </p>
        </header>
        <div>
          <Button
            children={"Redeem Roses"}
            buttonStyle={"primary"}
            onClick={() => {
              handleRedeemRoses();
              setOpenUseModal(false);
            }}
          />
          <Button
            children={"Cancel"}
            buttonStyle={"quaternary"}
            onClick={() => setOpenUseModal(false)}
          />
        </div>
      </aside>
    );
  };

  const addMomentHandler = () => {
    if (!checkAuth()) return;

    setModalContent(<AddMediaPage previousPage="home" />);
    setOpenUseModal(true);
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput")?.click();
  };

  const profilePictureChangeHandler = async (file: File) => {
    setImageProfileFile(URL.createObjectURL(file as File));

    try {
      const request = await postAvatarProfile(userInfo.access_token, { file });
      switch (request.status) {
        case 201:
          setUserInfo("user_profile_avatar", request.res.avatar.url || "");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const becomeACreatorHandler = () => {
    const applicationStatus = userInfo.creator_application_status;
    const isOnWaitList =
      applicationStatus === "PENDING" || applicationStatus === "APPROVED";

    const screenPath = isOnWaitList
      ? "/wait-list-application"
      : "/become-creator";
    const modalContent = isOnWaitList ? (
      <WaitListApplicationPage />
    ) : (
      <BecomeCreator />
    );

    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(modalContent);
    } else {
      setPrincipalScreen(screenPath);
    }
  };

  return (
    <div
      ref={pageRef}
      className={shortClassNames([
        "user-settings",
        "user-settings-wrapper",
        "wrapper standard-page-layout",
        "bottom-spacing",
        `${userAccountType ? "user-settings--creator" : ""}`,
      ])}
    >
      <div className="user-settings-inner inner">
        <PageTitle
          title={"DashBoard"}
          subtitle={"Configuration"}
          hasCloseIcon={windowSize >= 768}
          className="user-settings__page-title"
          onBackClick={pageBack}
          onGoToSettings={goToSettings}
        />
        <section className="user-settings__main">
          <ListItemLink
            highlightText={"Get Mobile App"}
            simpleText={"Scan QR Code"}
            imageLeft={mobileIcon}
            isImageCircle
            isBigIcon
            iconPrimaryColor
            hasArrowRight
            imageCircleMidnight
            className="user-settings-list-item inner"
            classHighlight="user-settings-list-item-highlight"
            classImageCircle="user-settings-list-item-image-circle"
          />

          <div className="user-settings__profile-container">
            <div className="user-settings__profile-content">
              {/*  <figure className="user-settings__profile-avatar">
              <img
                className="user-settings__profile-avatar-image"
                src={userInfo.user_profile_avatar || ProfileIcon}
                alt={"settings-icon"}
              />
            </figure> */}

              <ImageCircleBorder
                centerImage={imageProfileFile}
                centerImageAlt="user profile"
                size={48}
                onClick={triggerFileInput}
                // noBorder
              />
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files) {
                    const file = e.target.files[0];
                    profilePictureChangeHandler(file);
                  }
                }}
              />
              <div className="user-settings__profile-info">
                <p className="user-settings__profile-name">
                  {userInfo.display_name}
                </p>
                <p className="user-settings__profile-subtitle">
                  {ageCalculate(userInfoRequest?.birthDate)}{" "}
                  {userInfoRequest?.birthDate && userInfoRequest?.occupation
                    ? " | "
                    : ""}
                  {textCapitalize(
                    userInfoRequest?.occupation?.replaceAll("_", " ")
                  )}
                </p>
              </div>
            </div>
            <div className="user-settings__customize-button">
              <Button buttonStyle="tertiary" onClick={goToProfile}>
                CUSTOMIZE
              </Button>
            </div>
          </div>

          <div className="user-settings__dash-preview">
            <RosesDashboard
              title={thousandsFormat(availableRoses)}
              showAddButton={false}
              showGoldRoses
              customButton={[
                {
                  title: `${userAccountType ? "Redeem Roses" : "Add Roses"}`,
                  styles: `${userAccountType ? "secondary" : "primary"}`,
                  onClick: userAccountType
                    ? handleModalRedeem
                    : () => setIsOpen(!isOpen),
                },
                {
                  title: "My wallet",
                  styles: "tertiary",
                  onClick: goToWallet,
                },
              ]}
            />

            <DailyLogin />

            {userInfo.creator_application_status !== "APPROVED" ? (
              <div className="user-settings__become">
                <div className="user-settings__become-main">
                  <h2 className="user-settings__become-title">
                    Earn with Roses
                  </h2>
                  <Paragraph
                    children={
                      "Become a content creator and start earning today"
                    }
                  />
                </div>
                <div>
                  <Button
                    children={
                      userInfo.creator_application_status === "unsolicited"
                        ? "Become a Creator"
                        : "Check Application Status"
                    }
                    isWidthAuto
                    buttonStyle={"tertiary"}
                    onClick={becomeACreatorHandler}
                  />
                </div>
              </div>
            ) : (
              false
            )}

            {userAccountType ? <Revenue /> : false}

            {windowSize <= 768 ? (
              <SlidUp
                isOpen={isOpen}
                classNameContainer="split-up-container"
                classNameChildren="split-up__slide-children"
                closeArea={() => {
                  setIsOpen(false);
                }}
                header={{
                  title: "Buy Roses",
                  subtitle: "Select Roses below",
                }}
              >
                <BuyRoses
                  onClose={() => setIsOpen(false)}
                  onSelectItem={setCurrentPackage}
                  onBuyRoses={handleBuyRoses}
                  packages={packages}
                  selectedItem={currentPackage}
                />
              </SlidUp>
            ) : (
              <Modal
                className="buy-roses-modal"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                header={{
                  title: "Buy Roses",
                  subtitle: "Select Roses below",
                }}
              >
                <BuyRoses
                  onClose={() => setIsOpen(false)}
                  onSelectItem={setCurrentPackage}
                  onBuyRoses={handleBuyRoses}
                  packages={packages}
                  selectedItem={currentPackage}
                />
              </Modal>
            )}
          </div>
        </section>
        {/* <div className="user-settings-list">
          {userSettingsItems
            .filter((item) => item.isActive)
            .map((item, index, arr) => (
              <ListItemLink
                key={index}
                hasArrowRight
                imageLeft={item.iconLeftContent}
                isBig={item.isBig}
                isImageCircle
                highlightText={item.labelContent}
                // hasSeparator={arr.length > index + 1}
                className="user-settings-list-item inner"
                onClick={item.clickAction}
              />
            ))}
        </div> */}

        {userAccountType ? (
          <ButtonIcon
            icon="icon-plus"
            isDynamic
            children="Create"
            iconClass="user-settings__btn-create-icon"
            className="user-settings__btn-create"
            onClick={addMomentHandler}
          />
        ) : (
          false
        )}
      </div>
    </div>
  );
};
export default UserSettingsPage;
