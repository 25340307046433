import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

import Button from "components/Buttons/Button";
import RosesCredit from "components/RosesCredit";
import Tooltip from "components/Tooltip";
import ButtonIcon from "components/Buttons/ButtonIcon";
import DropDown from "components/Lists/DropDown";
import InputText from "components/Inputs/InputText";
import SubText from "components/Texts/SubText";

import { dollarFormatSecondary } from "utils/dollarFormat";

import { IPackages } from "types/interfaces";

import rosesLogoGold from "assets/icons/logos/logo-fill-gold.svg";

interface BuyRosesProps {
  onClose: () => void;
  onBuyRoses: () => void;
  onSelectItem: (item: string) => void;
  selectedItem: string;
  packages: IPackages[];
  className?: string;
  children?: React.ReactNode;
}

const BuyRoses: React.FC<BuyRosesProps> = ({
  onClose,
  onBuyRoses,
  onSelectItem,
  selectedItem,
  packages,
}) => {
  const maxValue = 2000;
  const [rosesToSend, setRosesToSend] = useState<string | number>(100);
  const [isSelectMore, setIsSelectMore] = useState(false);
  const [residence, setResidence] = useState("USD");
  const [value, setValue] = useState("");

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const currencyOptions = [
    {
      groupName: "currency",
      inputLabel: "USD",
      inputValue: "USD",
    },
  ];

  const startIncrement = () => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => {
          const nextValue = Number(prevCount) + 1;
          return nextValue > maxValue ? maxValue : nextValue;
        });
      }, 100);
    }
  };

  const startDecrement = () => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => {
          const nextValue = Number(prevCount) - 1;
          return nextValue < 0 ? 0 : nextValue;
        });
      }, 100);
    }
  };

  const stopAction = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleClose = () => {
    setIsSelectMore(false);
    onClose();
  };

  const handleBuyRoses = () => {
    if (isSelectMore) {
      handleClose();
    } else {
      onBuyRoses();
    }
  };

  useEffect(() => {
    if (packages.length > 1) {
      onSelectItem(packages[1].packageId);
    }
  }, [packages]);

  return (
    <div className="buy-roses__container">
      {isSelectMore ? (
        <section className="buy-roses__select-more-container">
          <div className="buy-roses__select-more-container-head">
            <ButtonIcon
              icon="icon-minus"
              buttonStyle="tertiary"
              onMouseDown={startDecrement}
              onMouseUp={stopAction}
              onMouseLeave={stopAction}
              onClick={() =>
                setRosesToSend((prev) => Math.max(Number(prev) - 1, 0))
              }
            />
            <div className="middle-content">
              <p>${rosesToSend}</p>
              <div className="info-head">
                <img
                  src={rosesLogoGold}
                  alt="logo-gold"
                  width={24}
                  height={24}
                />
                <h3 className="info-title">
                  Win <span className="send-roses-modal__gold-value">6</span>{" "}
                  Golden Roses
                </h3>
                <Tooltip
                  text={"Golden roses"}
                  classNameIcon="info-title__icon"
                />
              </div>
            </div>
            <ButtonIcon
              icon="icon-plus"
              buttonStyle="tertiary"
              onMouseDown={startIncrement}
              onMouseUp={stopAction}
              onMouseLeave={stopAction}
              onClick={() =>
                setRosesToSend((prev) => Math.min(Number(prev) + 1, maxValue))
              }
            />
          </div>
          <div className="buy-roses__select-more-inputs">
            <div>
              <InputText
                value={String(rosesToSend)}
                placeholder="100"
                onChangeInput={(e) => setRosesToSend(e)}
                inputType="text"
              />
              <SubText className="subtext">$100 USD = 250 Roses credit</SubText>
            </div>
            <DropDown
              inputValueSet={residence}
              dropType="click-select"
              label="Currency"
              setValueCallBack={(e) => {
                setResidence(e[0] as any);
              }}
              defaultValueProp={residence}
              placeholder="Select currency"
              options={currencyOptions}
            />
          </div>
        </section>
      ) : (
        <div className="buy-roses__grid">
          {packages?.map((item, index) => {
            return (
              <RosesCredit
                key={index}
                text={String(item.totalRosesAmount)}
                value={`${dollarFormatSecondary(String(item.price))}`}
                originalPrice={`${dollarFormatSecondary(
                  String(item.originalPrice)
                )}`}
                discount={item.discount}
                active={selectedItem === item.packageId}
                onClick={() => onSelectItem(item.packageId)}
                isPopular={index === 1}
              />
            );
          })}
        </div>
      )}

      {!isSelectMore && (
        <>
          {/**
                <Button
                    buttonStyle="tertiary"
                    onClick={()=>setIsSelectMore(true)}
                >
                    SELECT MORE
                </Button>
                */}
          <div className="info-head">
            <img src={rosesLogoGold} alt="logo-gold" width={24} height={24} />
            <h3 className="info-title">
              Win <span className="send-roses-modal__gold-value">6</span> Golden
              Roses
            </h3>
            <Tooltip text={"Golden roses"} classNameIcon="info-title__icon" />
          </div>
        </>
      )}

      <div className="buy-roses__buttons-container">
        <Button buttonStyle="primary" onClick={handleBuyRoses}>
          BUY ROSES
        </Button>
        <Button buttonStyle="quaternary" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default BuyRoses;
