import React, { useState } from "react";
import "./styles.scss";

import ButtonIcon from "components/Buttons/ButtonIcon";

import { useOpenChat } from "hooks/useOpenChat";

import { thousandsFormat } from "utils/thousandsFormat";
import Button from "components/Buttons/Button";
import { shortClassNames } from "utils/shortClassNames";

interface IInfosPost {
  postHasLiked: boolean;
  likedByCount?: number;
  userId: string;
  isLocked?: boolean;
  handlerUnlock?: () => void;
  handlerLike: () => void;
}

const InfosPost = (props: IInfosPost) => {
  const {
    postHasLiked,
    likedByCount,
    userId,
    isLocked,
    handlerUnlock,
    handlerLike,
  } = props;

  const openChat = useOpenChat(userId);

  const [showContent, setShowContent] = useState(true);

  return (
    <div className={`infosPost ${showContent ? "infosPost--show" : ""} inner`}>
      <div
        className={shortClassNames([
          "infosPost-content",
          `${showContent ? "infosPost-content--show" : ""}`,
        ])}
      >
        <h3 className="infosPost-content-title">
          Unlock my latest exclusive content! 🔥
        </h3>
        <div className="infosPost-content-texts">
          <p className="infosPost-content-text">
            Get a sneak peek into my world and experience the behind-the-scenes
            magic. 🌹
          </p>
          <p className="infosPost-content-text">
            Tap to unlock and join my VIP fan club for more! 💋
          </p>
        </div>

        <ul className="infosPost-content-tag-list">
          <li className="infosPost-content-tag">#ExclusiveContent</li>
          <li className="infosPost-content-tag">#VIPAccess</li>
        </ul>
      </div>

      <div
        className={shortClassNames([
          "infosPost-blur",
          `${showContent ? "infosPost-blur--show" : ""}`,
        ])}
      ></div>

      <div className="infosPost-card-btns">
        {isLocked ? (
          <Button
            children={"Unlock"}
            buttonStyle={"quintenary"}
            onClick={handlerUnlock}
          />
        ) : (
          <>
            <ButtonIcon
              buttonStyle={postHasLiked ? "transparentActive" : "transparent"}
              icon={
                postHasLiked
                  ? "icon-heart-solid"
                  : "icon-heart-outline icon-gray-light"
              }
              className={likedByCount ? "infosPost-card-btn" : ""}
              onClick={handlerLike}
            >
              {likedByCount ? thousandsFormat(likedByCount) : false}
            </ButtonIcon>

            <div className="infosPost-card-btns--side">
              <ButtonIcon
                // buttonStyle={`${
                //   showContent ? "transparentActive" : "transparent"
                // }`}
                buttonStyle="transparent"
                // icon={showContent ? "icon-info" : "icon-info icon-gray-light"}
                icon="icon-info icon-primary-50"
                className={`infosPost-card-btn infosPost-card-btn--chat`}
                onClick={() => setShowContent(!showContent)}
              />
              <ButtonIcon
                buttonStyle={"transparent"}
                icon={"icon-messages icon-gray-light"}
                className="infosPost-card-btn infosPost-card-btn--chat"
                onClick={openChat}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InfosPost;
