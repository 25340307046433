import fetchApi from "../fetchApi";

const getSubscribePlans = async (token: string, creatorId: string) => {
  const params = {
    creatorId,
  };
  return await fetchApi({
    service: "subscribe",
    endPoint: "/prices-plans",
    method: "GET",
    token,
    params,
  });
};

export default getSubscribePlans;
