import React, { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import ListItemLink from "components/Lists/ListItemLink";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";

import FilterIcon from "assets/icons/iconfilter.svg";
import ProfileIcon from "assets/icons/profile-icon-gray.svg";

const UserSettingsNotificationsPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();

  const [searchValue, setSearchValue] = useState("");

  const list = [
    { imageLeft: "", title: "Mr. Grey", desc: "Unlocked chat", value: 10 },
    {
      imageLeft: "",
      title: "Dream Man",
      desc: "Sent you roses credit",
      value: 500,
    },
    { imageLeft: "", title: "Aron", desc: "Sent you a new message message" },
  ];

  return (
    <div
      className="user-settings-notifications
		 		user-settings-notifications-wrapper
		 		wrapper
		 		standard-page-layout
		 		bottom-spacing"
    >
      <div className="user-settings-notifications-inner inner">
        <PageTitle
          title={"Notifications"}
          subtitle={"Alerts"}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
        />
        <div className="user-settings-notifications__notifications-container">
          <InputText
            value={searchValue}
            searchInput
            onClick={() => {
              // searchCreator
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // searchCreator();
              }
            }}
            onChangeInput={(e) => {
              setSearchValue(e);
            }}
            placeholder="Search Messages"
            inputStyle="input-text-search"
            iconRight={FilterIcon}
            inputType="text"
          />
          <section>
            <div className="user-settings-notifications__list-header">
              <p className="user-settings-notifications__list-text">
                Notifications
              </p>
            </div>
            {list.map((item, index, arr) => (
              <ListItemLink
                key={index}
                hasSeparator={arr.length > index + 1}
                highlightText={item.title}
                simpleText={item.desc}
                isImageCircle
                iconFill
                imageLeft={item.imageLeft}
                hasArrowRight
                showLeftImg
                rightNodeContent={
                  item?.value ? (
                    <span className="user-settings-notifications__list--highlight">
                      + {item.value}
                    </span>
                  ) : (
                    false
                  )
                }
                onClick={() => console.log("")}
              />
            ))}
            {/* {hasNextPast && (
              <Button
                buttonStyle="quaternary"
                onClick={getNextPast}
                disabled={nextIsLoading}
              >
                SHOW MORE
              </Button>
            )} */}
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsNotificationsPage;
