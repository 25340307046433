import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";

interface ButtonNavigationProps {
  buttons: ButtonNavigationInfo[];
  className?: string;
  activatedButton?: number;
  horizontalMargin?: boolean;
  rightButtonIcon?: ButtonIconNavigation;
  buttonsColorNonSelected?: "midnight" | "dawn";
}

export interface ButtonNavigationInfo {
  text: string;
  textRight?: string;
  id: number;
  iconLeft?: string;
  clickAction: () => void;
}

export interface ButtonIconNavigation {
  iconButton?: string;
  clickAction: () => void;
}

const ButtonNavigation: React.FC<ButtonNavigationProps> = ({
  buttons,
  className,
  activatedButton,
  horizontalMargin = true,
  rightButtonIcon,
  buttonsColorNonSelected = "midnight",
}) => {
  const { windowSize } = useWindowWidthSize();

  const [actionedButton, setActionedButton] = useState(activatedButton ?? 1);
  const [width, setWidth] = useState(0);

  const gliderRef = useRef<HTMLSpanElement>(null);
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const buttonContainerRefs = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (activatedButton) {
      setActionedButton(activatedButton);
    }
  }, [activatedButton]);

  const handlerGlider = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const target = e.currentTarget;

    if (gliderRef.current) {
      const gliderWidth = target.offsetWidth - 8;
      const gliderHeight = target.offsetHeight - 8;

      gliderRef.current.style.transition = "left 0.2s ease-in-out";
      gliderRef.current.style.width = `${gliderWidth}px`;
      gliderRef.current.style.height = `${gliderHeight}px`;

      gliderRef.current.style.left = `${target.offsetLeft + 4}px`;
      gliderRef.current.style.top = `${target.offsetTop + 4}px`;
    }

    target.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  useEffect(() => {
    if (buttonRefs.current[actionedButton - 1] && gliderRef.current) {
      const firstButton = buttonRefs.current[actionedButton - 1];
      if (firstButton) {
        const gliderWidth = firstButton.offsetWidth - 8;
        const gliderHeight = firstButton.offsetHeight - 8;

        gliderRef.current.style.width = `${gliderWidth}px`;
        gliderRef.current.style.height = `${gliderHeight}px`;
        gliderRef.current.style.left = `${firstButton.offsetLeft + 4}px`;
        gliderRef.current.style.top = `${firstButton.offsetTop + 4}px`;
      }
    }
  }, [actionedButton, windowSize, width]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setWidth(entry.contentRect.width);
      }
    });

    if (buttonContainerRefs.current) {
      observer.observe(buttonContainerRefs.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={buttonContainerRefs}
      className={["action-button-group", `${className ?? ""}`]
        .filter(Boolean)
        .join(" ")
        .trim()}
    >
      <div className="action-button-group-container">
        {buttons.map((btn, index) => (
          <button
            key={btn.id}
            ref={(el) => (buttonRefs.current[index] = el)}
            className={[
              "action-button",
              actionedButton === btn.id ? "active" : "",
              index === 0
                ? "first-child"
                : buttons.length === index + 1
                  ? "last-child"
                  : "",
              !horizontalMargin ? "no-hor-margin" : "",
              `background-${buttonsColorNonSelected}`,
            ]
              .filter(Boolean)
              .join(" ")
              .trim()}
            onClick={(e) => {
              btn.clickAction();
              setActionedButton(btn.id);
              handlerGlider(e);
            }}
          >
            {btn.iconLeft && (
              <span
                className={`icon icon-${btn.iconLeft} ${
                  actionedButton === btn.id ? "" : "icon-gray"
                }  icon-sm icon-left`}
              />
            )}

            <p className={`${actionedButton === btn.id ? "active" : ""}`}>
              {btn.text}
            </p>

            <p
              className={`right-text ${
                actionedButton === btn.id ? "active" : ""
              }`}
            >
              {btn.textRight}
            </p>
          </button>
        ))}
        <span ref={gliderRef} className="action-button-glider"></span>
      </div>
      {rightButtonIcon && (
        <button
          className={`action-button__button-right background-${buttonsColorNonSelected}`}
        >
          <span
            className={`icon ${rightButtonIcon.iconButton} icon-md icon-gray button-right__icon`}
          />
        </button>
      )}
    </div>
  );
};

export default ButtonNavigation;
