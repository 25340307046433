import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import InputText from "components/Inputs/InputText";
import SlidUp from "components/Modals/SlidUp";
import Button from "components/Buttons/Button";
import InputRadioCarousel from "components/Inputs/InputRadioCarrossel";
import ListItemLink from "../../components/Lists/ListItemLink";
import InputRadio from "../../components/Inputs/InputRadio";
import InputCheckbox from "../../components/Inputs/InputCheckbox";
import ProgressbarWithoutAnimation from "components/ProgressbarWithoutAnimation";
import Toggle from "components/Toggle/SimpleToggle";

import PhotosPlaceholder from "components/PhotosPlaceholder";
import SubText from "components/Texts/SubText";
import ListItemToggle from "../../components/Lists/ListItemToggle";
import ImageCircleBorder from "../../components/ImageCircleBorder";

import { ProfileContext } from "contexts/profileContext";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { usePullToRefreshHandler } from "hooks/usePullToRefreshHandler";

import getUserInfo from "../../api/userInfo/getUserInfo";
import getProfileDetails from "../../api/profileDetails/getProfileDetails";
import getUserPreferences from "../../api/userPreferences/getUserPreferences";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import postUploadProfilePhotos from "../../api/profileDetails/postUploadProfilePhotos";
import deleteProfilePhotos from "../../api/profileDetails/deleteprofilePhotos";
import patchUserInfo from "../../api/userInfo/patchUserInfo";
import patchUserLocation from "../../api/userInfo/patchUserLocation";
import getUserLocationHistory from "../../api/userInfo/getUserLocationHistory";
import patchUserConfidentiality from "../../api/userPhotos/patchUserConfidentiality";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import patchRosesRequest from "../../api/userPreferences/patchRosesRequest";
import postUploadProfileVideo from "../../api/profileDetails/postUploadProfileVideo";
import deleteProfileVideo from "../../api/profileDetails/deleteProfileVideo";
import patchProfilePhoto from "../../api/profileDetails/patchProfilePhoto";
import deleteProfileLocation from "api/userInfo/deleteProfileLocation";
import postUserInterests from "api/userInfo/postUserInterests";
import deleteUserInterests from "api/userInfo/deleteUserInterests";
import postUploadProfileBanner from "api/profileDetails/postUploadProfileBanner";
import deleteProfileBanner from "api/profileDetails/deleteProfileBanner";

import { ageCalculate } from "../../utils/dateFormat";
import { textCapitalize } from "../../utils/textCapitalize";
import { genderToApi, genderToFront } from "utils/genderHelper";

import { UserInfoRequest } from "../../types/userInfoRequest";

import verifyIcon from "../../assets/icons/verify.svg";
import verifyIconGray from "../../assets/icons/verify-icon-gray.svg";
import chevronRightIcon from "assets/icons/navigationIcons/bx-chevron-right.svg";
import disclaimerIcon from "assets/icons/union-gray.svg";

//import bannerMock from "../../assets/images/feed-mock-images/feed-2.png";

interface ListDetails {
  name: string;
  profileDetailId: number;
  type: DetailsType;
}
interface BodyDataRequest {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;
  latitude?: number;
  longitude?: number;
  locationHistoryId?: string;
}

type DetailsType =
  | "eye_color"
  | "hair_color"
  | "nationality"
  | "ethnicity"
  | "language"
  | "gender"
  | "age"
  | "height"
  | "weight"
  | "language"
  | "occupation"
  | "marital_status"
  | "net_worth"
  | "zodiac"
  | "body_style"
  | "interests";

type Details = { list: string[] | ListDetails[]; title: string };

const UserSettingsProfilePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button2");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>([]);
  const [coverVideo, setCoverVideo] = useState<string>();
  const [banner, setBanner] = useState<string>();

  const [isSlidUpVisible, setIsSlidUpVisible] = useState(false);
  const [isSlidUpWriteVisible, setIsSlidUpWriteVisible] = useState(false);

  const { userInfoRequest, setUserInfoRequest } = useContext(ProfileContext);

  const [eyeColorList, setEyeColorList] = useState<ListDetails[] | []>([]);
  const [hairColorList, setHairColorList] = useState<ListDetails[] | []>([]);
  const [nationalityList, setNationalityList] = useState<ListDetails[] | []>(
    []
  );
  const [zodiacList, setZodiacList] = useState<ListDetails[] | []>([]);
  const [bodyStyleList, setBodyStyleList] = useState<string[] | []>([]);

  const [ethnicityList, setEthnicityList] = useState<ListDetails[] | []>([]);
  const [languageList, setLanguageList] = useState<ListDetails[] | []>([]);
  const [genderList, setGenderList] = useState<string[] | []>([]);
  const [titleSlidUp, setTitleSlidUp] = useState("");
  const [profileBirthday, setProfileBirthday] = useState("");
  const [profileHeight, setProfileHeight] = useState("");
  const [profileWeight, setProfileWeight] = useState("");
  const [openWriteInput, setOpenWriteInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string[]>();
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [interests, setInterests] = useState<string>("");
  const [occupation, setOccupation] = useState<string>("");

  const [modalSlidUpContent, setModalSlidUpContent] =
    useState<ReactNode | null>(null);
  const { componentFeed, setPrincipalScreen, setSecondaryScreen, params } =
    useDeskNavigation();
  const { userInfo } = useUserInfo();

  const [locationList, setLocationList] = useState<BodyDataRequest[]>();
  const [valueSelectedState, setValueSelectedState] = useState<string>("");
  const [toogleConfidentiality, setToogleConfidentiality] = useState();
  const [toogleRosesRequest, setToogleRosesRequest] = useState();
  const [isVerify, setIsVerify] = useState(false);
  const [imageProfileFile, setImageProfileFile] = useState<File>();
  const [showAll, setShowAll] = useState(false);

  const [heightList, setHeightList] = useState<string[] | []>([]);
  const [isCm, setIsCm] = useState(false);
  const [circleColors, setCircleColors] = useState<any[]>();
  const [circleSelect, setCircleSelect] = useState<string>();
  const [currentSlidUp, setCurrentSlidUp] = useState<DetailsType | null>(null);

  const pageRef = useRef<HTMLDivElement | null>(null);
  const pullToRefresh = usePullToRefreshHandler(pageRef);
  const [searchParams] = useSearchParams();
  const btnParams = searchParams.get("btn");
  const requestUserInfo = useCallback(async () => {
    const response = await getUserInfo(userInfo.access_token);

    setCoverVideo(response.res?.cover?.url);
    setBanner(response.res?.banner?.url);

    if (response?.status === 200) {
      setUserInfoRequest(response?.res);
    }
    setCircleSelect(response?.res?.feedHexColor);

    const namesArray = response.res.languages.map(
      (language: ListDetails) => language.name
    );
    setSelectedLanguages(namesArray);
  }, [setUserInfoRequest, userInfo.access_token]);

  const patchDetails = useCallback(
    async (key: string, value: string | string[] | number) => {
      const response = await patchProfileDetails(
        userInfo.access_token,
        key,
        value
      );
      requestUserInfo();
      return response;
    },
    [requestUserInfo, userInfo.access_token]
  );

  const updateLanguage = useCallback(
    async (languages: string[]) => {
      if (languages) {
        const response = await patchUserInfo(userInfo.access_token, {
          profileLanguages: languages,
        });
      }
    },
    [userInfo.access_token]
  );

  //------- photo

  const changeColorBanner = (color: string) => {
    setCircleSelect(color);
    patchDetails("feedHexColor", color);
    requestUserInfo();
  };
  const triggerFileInput = () => {
    document.getElementById("fileInput")?.click();
  };

  const profilePictureChangeHandler = (file: File) => {
    setImageProfileFile(file);
  };

  const preserveConfidentiallyHandler = async (event: boolean) => {
    const res = await patchUserConfidentiality(userInfo.access_token, {
      confidentiality: event,
    });

    requestUserInfo();
  };

  const smartPhotoHandler = (event: boolean) => {};

  const addPhotoHandler = async (files: (File | null)[]): Promise<void> => {
    setPhotosAlbum(files);
    const imageToUpload = files.filter(
      (file, index) =>
        file !== null && !file.name.endsWith(".mp4") && index !== 0
    );

    const videoToUpload = files.find(
      (file) => file !== null && file.name.endsWith(".mp4")
    );

    const bannerToUpload = files.find(
      (file, index) => file !== null && index === 0
    );

    const photo = imageToUpload[imageToUpload.length - 1];

    if (photo) {
      await postUploadProfilePhotos(userInfo.access_token, photo);
    }

    if (videoToUpload) {
      await postUploadProfileVideo(userInfo.access_token, videoToUpload);
    }

    if (bannerToUpload) {
      await postUploadProfileBanner(userInfo.access_token, bannerToUpload);
    }

    requestUserInfo();
  };

  const removePhotoHandler = async (id: string) => {
    await deleteProfilePhotos(userInfo.access_token, id);
    requestUserInfo();
  };

  const removeVideoHandler = async (id: string) => {
    await deleteProfileVideo(userInfo.access_token, id);
    requestUserInfo();
  };

  const removeBannerHandler = async () => {
    await deleteProfileBanner(userInfo.access_token);
    requestUserInfo();
  };

  const updatePhotoHandler = async (file: {
    fileId: string;
    position: number;
  }) => {
    const res = await patchProfilePhoto(
      userInfo.access_token,
      file.fileId,
      file.position
    );

    if (res?.status === 200) {
      requestUserInfo();
    }
  };

  //------- info

  const selectProfileActionHandler = (
    title: string,
    valuesArr: string[],
    valueState: string[],
    funcState: React.Dispatch<React.SetStateAction<[] | string[]>>,
    valueDefault?: string[]
  ) => {
    setTitleSlidUp(title);
    setModalSlidUpContent(
      <>
        <div className="user-settings-profile__radio-list">
          <InputCheckbox
            elements={
              valuesArr
                ? valuesArr?.map((item: any) => {
                    return {
                      groupName: item,
                      inputLabel: `${textCapitalize(item)}`,
                      inputValue: `${item}`,
                    };
                  })
                : []
            }
            inputValues={valueState}
            setInputsValues={funcState}
            // defaultValueProp={valueDefault}
          />
        </div>

        <Button
          onClick={async () => {
            await updateLanguage(selectedLanguages);
            setIsSlidUpVisible(false);
          }}
          buttonStyle="primary"
        >
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
    setIsSlidUpVisible(true);
  };

  const applyChangeDetailRequestListed = useCallback(
    async (detail: string, valueSelected: string) => {
      let type: string | boolean = false;
      const types: Record<string, string> = {
        gender: "gender",
        ethnicity: "profileEthnicities",
        eye_color: "profileEyeColor",
        hair_color: "profileHairColor",
        nationality: "profileNationality",
      };

      if (types.hasOwnProperty(detail)) {
        type = types[detail];
      }

      let value =
        detail === "gender"
          ? valueSelected?.toUpperCase().replaceAll("-", "")
          : valueSelected?.toLowerCase().replaceAll(" ", "_");
      if (type === "gender") {
        value = genderToApi[value];
      }

      if (type) {
        if (detail === "ethnicity") {
          const value = [valueSelected.toLowerCase().replaceAll(" ", "_")];
          await patchDetails(type, value);
          return;
        }

        await patchDetails(type, value);
      }

      setValueSelectedState("");
      setIsSlidUpVisible(false);
    },
    [patchDetails]
  );

  const handleApply = useCallback(async () => {
    if (!currentSlidUp) {
      return;
    }

    if (currentSlidUp === "zodiac") {
      console.log("zodiac");
      await patchDetails("profileZodiac", valueSelectedState.toLowerCase());
    }

    if (currentSlidUp === "body_style") {
      await patchDetails("profileBodyStyle", valueSelectedState.toLowerCase());
    }

    if (currentSlidUp === "height") {
      if (
        valueSelectedState.includes("’") &&
        valueSelectedState.includes("”")
      ) {
        await patchDetails(
          "height",
          Number(convertHeightToFeet(valueSelectedState))
        );
      } else {
        await patchDetails("height", Number(valueSelectedState));
      }
    }

    if (currentSlidUp === "language") {
      await updateLanguage(selectedLanguages);
    } else {
      await applyChangeDetailRequestListed(currentSlidUp, valueSelectedState);
    }

    await requestUserInfo();

    setIsSlidUpVisible(false);
    setIsSlidUpWriteVisible(false);
  }, [
    applyChangeDetailRequestListed,
    currentSlidUp,
    patchDetails,
    requestUserInfo,
    selectedLanguages,
    updateLanguage,
    valueSelectedState,
  ]);

  const changeListItemHandler = useCallback(
    (toChange: DetailsType, selected: string) => {
      let value = selected;

      let detailsSlide: Details = { list: [], title: "" };

      const details: Record<string, Details> = {
        gender: { list: genderList, title: "Gender" },
        ethnicity: { list: ethnicityList, title: "Ethnicity" },
        eye_color: { list: eyeColorList, title: "Eye Color" },
        hair_color: { list: hairColorList, title: "Hair Color" },
        nationality: { list: nationalityList, title: "Nationality" },
        zodiac: { list: zodiacList, title: "Zodiac" },
        height: { list: heightList, title: "Height" },
        body_style: { list: bodyStyleList, title: "Body Style" },
      };

      if (details.hasOwnProperty(toChange)) {
        detailsSlide = details[toChange];
      }

      if (toChange === "gender") {
        value = genderToFront[value.toUpperCase()];
      }

      setTitleSlidUp(detailsSlide?.title);
      setCurrentSlidUp(toChange);

      setIsSlidUpVisible(true);
      const selectedValueHandler = (value: any) => {
        if (value) setValueSelectedState(value);
      };

      detailsSlide.list?.forEach((detail) => {});

      setModalSlidUpContent(
        <>
          <div className="user-settings-profile__radio-list">
            <InputRadioCarousel
              elements={
                detailsSlide
                  ? detailsSlide.list?.map((item: any) => {
                      return {
                        groupName: toChange,
                        inputLabel: `${textCapitalize(item)}`,
                        inputValue: `${textCapitalize(item)}`,
                      };
                    })
                  : []
              }
              onChangeValueCallback={(e) => {
                value = e[0];
                selectedValueHandler(e[0]);
              }}
              defaultValueProp={value}
            />
          </div>

          <div className="user-settings-profile__radio-list">
            {toChange === "height" && (
              <div className="user-settings-profile__toggle">
                Height Unit
                <Toggle onChange={() => setIsCm(!isCm)} />
              </div>
            )}
          </div>
        </>
      );
    },
    [
      bodyStyleList,
      ethnicityList,
      eyeColorList,
      genderList,
      hairColorList,
      heightList,
      isCm,
      nationalityList,
      zodiacList,
    ]
  );

  const addListLanguage = async (lang: string[]) => {
    const response = await patchDetails("profileLanguages", lang);

    switch (response.status) {
      case 200:
        setIsSlidUpVisible(false);
        break;
    }
  };

  const addSpecification = async () => {
    const key = openWriteInput === "birthday" ? "birthDate" : openWriteInput;
    const value =
      openWriteInput === "weight"
        ? Number(profileWeight)
        : openWriteInput === "height"
          ? Number(profileHeight)
          : openWriteInput === "interests"
            ? interests.toString().toLowerCase().replaceAll(" ", "_")
            : openWriteInput === "occupation"
              ? occupation.toString().toLowerCase().replaceAll(" ", "_")
              : profileBirthday;

    if (openWriteInput === "birthday") {
      zodiacCalculate(userInfoRequest?.birthDate);
    }

    if (openWriteInput === "interests") {
      const response = await postUserInterests(
        userInfo.access_token,
        value.toString()
      );
      switch (response.status) {
        case 201:
          setInterests("");
          requestUserInfo();

          break;
      }
      return;
    }
    const response = await patchDetails(key, value);
    switch (response.status) {
      case 200:
        setIsSlidUpWriteVisible(false);
        break;
    }
  };
  const formatHeight = (height: string, display: boolean) => {
    const feetConverter = Number(height) * 0.0328084;
    const meters = `${height.toString()[0]},${height.toString().slice(1)}`;
    const feet = `${feetConverter.toString()[0]}’${feetConverter
      .toString()
      .slice(1)
      .substring(1, 3)}”`;
    if (display) {
      return `${feet} / ${meters}`;
    }
    return `${feet}`;
  };

  const formatHeightToggle = () => {
    const start = 150;
    const end = 200;
    if (!isCm) {
      return setHeightList(
        Array.from({ length: end - start + 1 }, (_, i) =>
          (start + i).toString()
        )
      );
    }

    return setHeightList(
      Array.from({ length: end - start + 1 }, (_, i) =>
        formatHeight((start + i).toString(), false)
      )
    );
  };

  const convertHeightToCm = (height: string): string => {
    let heightConverted =
      Number(height.replace("’", ".").replace("”", "")) * 0.0328084;

    heightConverted = Math.floor(heightConverted * 100) / 100;

    return heightConverted.toString();
  };

  const convertHeightToFeet = (height: string): string => {
    let heightConverted =
      Number(height.replace("’", ".").replace("”", "")) / 0.0328084;
    heightConverted = Math.ceil(heightConverted);

    return heightConverted.toString();
  };

  const formatWeight = () => {
    if (userInfoRequest?.weight) {
      const poundConverter = Number(userInfoRequest?.weight) * 2.205;
      const kilo = `${userInfoRequest?.weight}kg`;
      const pounds = `${poundConverter.toFixed(2).replace(".", ",")}lb`;
      return `${kilo} / ${pounds}`;
    } else {
      return "Add";
    }
  };

  useEffect(() => {
    if (userInfoRequest?.languages && userInfoRequest?.languages.length > 0) {
      const currentLanguages = userInfoRequest?.languages.map(
        (language) => language.name
      );
      setSelectedLanguages(currentLanguages);
    }
  }, [userInfoRequest]);

  const selectLanguageHandler = useCallback(() => {
    setTitleSlidUp("Language");
    setCurrentSlidUp("language");
    setIsSlidUpVisible(true);
  }, []);

  const textLimit = (text: any) => {
    if (text) {
      const content = text;
      return content.length > 35 ? `${content.substring(0, 25)}...` : content;
    }
  };

  const changeZodiac = async (zodiac: string) => {
    const response = await patchDetails(
      "profileZodiac",
      zodiac.toLocaleLowerCase()
    );
    switch (response.status) {
      case 200:
        setIsSlidUpWriteVisible(false);
        break;
    }
  };

  const zodiacCalculate = (birth: Date | undefined) => {
    if (birth) {
      const date = new Date(birth);

      const month = date.getUTCMonth();
      const day = date.getUTCDate();

      const zodiac = getZodiac(day, month);
      changeZodiac(zodiac.toString());
    }
  };

  const getZodiac = (day: any, month: any) => {
    const dates = [
      [19, "Capricorn", "Aquarius"],
      [18, "Aquarius", "Pisces"],
      [20, "Pisces", "Aries"],
      [19, "Aries", "Taurus"],
      [20, "Taurus", "Gemini"],
      [20, "Gemini", "Cancer"],
      [22, "Cancer", "Leo"],
      [22, "Leo", "Virgo"],
      [22, "Virgo", "Libra"],
      [22, "Libra", "Scorpio"],
      [21, "Scorpio", "Sagittarius"],
      [21, "Sagittarius", "Capricorn"],
    ];

    return day <= dates[month][0] ? dates[month][1] : dates[month][2];
  };

  const personalInfoData = useMemo(
    () => [
      {
        labelContent: "Bio",
        valueContent: textCapitalize(userInfoRequest?.bio) || "Add",
        clickAction: () => {
          if (componentFeed) {
            setSecondaryScreen("/user-settings-edit-bio");
          } else {
            setPrincipalScreen("/user-settings-edit-bio");
          }
        },
        isActive: true,
      },
      {
        labelContent: "Username",
        valueContent: textCapitalize(userInfo.display_name) || "Add",
        clickAction: () => {
          if (componentFeed) {
            setSecondaryScreen("/user-settings-edit-profile");
          } else {
            setPrincipalScreen("/user-settings-edit-profile");
          }
        },
        isActive: true,
      },
      {
        labelContent: "Gender",
        valueContent: userInfoRequest?.gender
          ? genderToFront[userInfoRequest?.gender]
          : "Add",
        clickAction: () => {
          setValueSelectedState(textCapitalize(userInfoRequest?.gender));
          changeListItemHandler(
            "gender",
            textCapitalize(userInfoRequest?.gender)
          );
        },
        isActive: true,
      },
      {
        labelContent: "Age",
        valueContent: ageCalculate(userInfoRequest?.birthDate) || "Add",
        clickAction: () => {
          setIsSlidUpWriteVisible(true);
          setOpenWriteInput("birthday");
        },
        isActive: true,
      },
      {
        labelContent: "Occupation",
        valueContent:
          userInfoRequest?.occupation?.replaceAll("_", " ") || "Add",
        clickAction: () => {
          /*  selectProfileActionHandler(
          "Occupation",
          mockOccupation,
          occupationList,
          setOccupationList
        ); */

          setIsSlidUpWriteVisible(true);
          setOpenWriteInput("occupation");
        },
        isActive: true,
      },
      {
        labelContent: "Language",
        valueContent:
          userInfoRequest && userInfoRequest?.languages?.length > 0
            ? userInfoRequest.languages
                .map((lang) => textCapitalize(lang.name))
                .join(", ")
            : "Add",
        clickAction: selectLanguageHandler,
        isActive: true,
      },
      {
        labelContent: "Ethnicity",
        //TODO
        valueContent:
          textCapitalize(
            userInfoRequest?.ethnicities[0]?.name.replaceAll("_", " ")
          ) || "Add",
        clickAction: () => {
          setValueSelectedState(
            textCapitalize(userInfoRequest?.ethnicities[0]?.name)
          );
          changeListItemHandler(
            "ethnicity",
            textCapitalize(userInfoRequest?.ethnicities[0]?.name)
          );
        },
        isActive: false,
      },
      {
        labelContent: "Height",
        valueContent: userInfoRequest?.height
          ? formatHeight(userInfoRequest?.height?.toString(), true)
          : "Add",
        clickAction: () => {
          setValueSelectedState(userInfoRequest?.height?.toString() || "");

          changeListItemHandler("height", valueSelectedState);
        },
        isActive: true,
      },
      {
        labelContent: "Eye color",
        valueContent: textCapitalize(userInfoRequest?.eyeColor?.name) || "Add",
        clickAction: () => {
          setValueSelectedState(
            textCapitalize(userInfoRequest?.eyeColor?.name)
          );
          changeListItemHandler(
            "eye_color",
            textCapitalize(userInfoRequest?.eyeColor?.name)
          );
        },
        isActive: true,
      },
      {
        labelContent: "Hair color",
        valueContent: textCapitalize(userInfoRequest?.hairColor?.name) || "Add",
        clickAction: () => {
          setValueSelectedState(
            textCapitalize(userInfoRequest?.hairColor?.name)
          );
          changeListItemHandler(
            "hair_color",
            textCapitalize(userInfoRequest?.hairColor?.name)
          );
        },
        isActive: true,
      },

      {
        labelContent: "Nationality",
        valueContent:
          textCapitalize(userInfoRequest?.nationality?.name) || "Add",
        clickAction: () => {
          setValueSelectedState(
            textCapitalize(userInfoRequest?.nationality?.name)
          );
          changeListItemHandler(
            "nationality",
            textCapitalize(userInfoRequest?.nationality?.name)
          );
        },
        isActive: true,
      },

      {
        labelContent: "Interests",
        valueContent: userInfoRequest?.interests?.length
          ? userInfoRequest.interests
              .map((int) => textCapitalize(int.name))
              .join(", ")
          : "Add",
        clickAction: () => {
          /*     selectProfileActionHandler(
          "Interests",
          interestsList,
          interests,
          setInterests
        ); */

          setIsSlidUpWriteVisible(true);
          setOpenWriteInput("interests");
        },
        isActive: false,
      },
      {
        labelContent: "Zodiac",
        valueContent: textCapitalize(userInfoRequest?.zodiac?.name) || "Add",
        clickAction: () => {
          setValueSelectedState(textCapitalize(userInfoRequest?.zodiac?.name));
          changeListItemHandler(
            "zodiac",
            textCapitalize(userInfoRequest?.zodiac?.name)
          );
        },
        isActive: true,
      },
      /*     {
      labelContent: "Race",
      valueContent: textCapitalize(mockRace) || "Add",
      clickAction: () => {
        setValueSelectedState(mockRace);
        changeListItemHandler("race", textCapitalize(mockRace));
      },
      isActive: true,
    }, */
      {
        labelContent: "Body Style",
        valueContent: textCapitalize(userInfoRequest?.bodyStyle?.name) || "Add",
        clickAction: () => {
          setValueSelectedState(
            textCapitalize(userInfoRequest?.bodyStyle?.name)
          );
          changeListItemHandler(
            "body_style",
            textCapitalize(userInfoRequest?.bodyStyle?.name)
          );
        },
        isActive: true,
      },
      {
        labelContent: "Perfume",
        valueContent: textCapitalize(userInfoRequest?.occupation) || "Add",
        clickAction: () => {
          setIsSlidUpWriteVisible(true);
          setOpenWriteInput("Perfume");
        },
        isActive: true,
      },
      /*     {
      labelContent: "Genre",
      valueContent: mockGenre.join(", ") || "Add",
      clickAction: () => {
        selectProfileActionHandler("Genre", mockGenre, genreList, setGenreList);
      },
      isActive: true,
    }, */
    ],
    [
      changeListItemHandler,
      componentFeed,
      selectLanguageHandler,
      setPrincipalScreen,
      setSecondaryScreen,
      userInfo.display_name,
      userInfoRequest,
      valueSelectedState,
    ]
  );

  const preferenceListData = [
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Connect & Chat",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "Free",
    },
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Photos",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "50",
    },
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Packs",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "100",
    },
  ];

  //------- preference

  const acceptRosesFromEveryoneHandler = async (event: boolean) => {
    const res = await patchRosesRequest(userInfo.access_token, {
      blockRequest: event,
    });
  };

  const removeLocation = async (e: any) => {
    locationList?.map(async (item, index) => {
      if (index === e) {
        if (item.locationHistoryId) {
          const response = await deleteProfileLocation(
            userInfo.access_token,
            item.locationHistoryId
          );
          requestLocationHistory();
        }
      }
    });
  };

  const updateLocation = async (location: string[]) => {
    //TODO - #01 refatorar update
    /*     location[0].location?.map(async (data: any) => {
      if (array[0] === data.place) {
        const response = await patchUserLocation(userInfo.access_token, data);
      }
    }); */

    let data;
    /* update */

    locationList?.map(async (item: any) => {
      const place = [`${item.place}, ${item.country_code}`];
      if (place[0] === location[0]) {
        data = item;
        const response = await patchUserLocation(userInfo.access_token, data);
        requestLocationHistory();
      }
    });
  };

  // -------

  const getUserSelfDetailInit = async () => {
    try {
      const res = await getUserSelfDetail(userInfo.access_token);
      setToogleConfidentiality(res.res.confidentiality);
      setToogleRosesRequest(res.res.blockRequest);
      setIsVerify(res.res.isVerified);
    } catch (error) {
      console.error("Erro ao buscar detalhes do usuário:", error);
    }
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    { text: "Info", clickAction: () => setActiveSection("button2"), id: 2 },
    /*   {
      text: "Preference",
      clickAction: () => setActiveSection("button3"),
      id: 3,
    }, */
  ];

  const requestLocationHistory = async () => {
    const response = await getUserLocationHistory(userInfo.access_token);

    if (response.res.currentLocation) {
      const currentLocation = [
        `${response.res.currentLocation.place}, ${response.res.currentLocation.country}`,
      ];
      setSelectedLocation(currentLocation);
    }

    if (response.res.locationHistory) {
      const history = response.res.locationHistory.map((item: any) => ({
        ...item.location,
        locationHistoryId: item.locationHistoryId,
      }));
      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => item)
      );
    }
  };

  useEffect(() => {
    const colorsArray = [
      "#FFFFFF",
      "#D4AFB2",
      "#FFDAA9",
      "#F5AC67",
      "#A8794C",
      "#EE4E87",
      "#8764E0",
      "#33AEEC",
      "#37B1C2",
    ];
    setCircleColors(colorsArray);

    const requestProfileDetails = async () => {
      const response = await getProfileDetails(userInfo.access_token);

      setEyeColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "eye_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setHairColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "hair_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setNationalityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "nationality")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setEthnicityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "ethnicity")
          .map((item: ListDetails) =>
            textCapitalize(item.name.replaceAll("_", " "))
          )
      );

      setLanguageList(
        response?.res?.filter(
          (detail: ListDetails) => detail.type === "language"
        )
      );

      setGenderList(["Male", "Female", "Non-binary", "Trans"]);

      setZodiacList(
        response.res
          .filter((detail: ListDetails) => detail.type === "zodiac")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setBodyStyleList(
        response.res
          .filter((detail: ListDetails) => detail.type === "body_style")
          .map((item: ListDetails) => textCapitalize(item.name))
      );
      formatHeightToggle();
    };

    const requestPreferences = async () => {
      const response = await getUserPreferences(userInfo.access_token);
    };

    requestLocationHistory();
    requestPreferences();
    requestProfileDetails();
    getUserSelfDetailInit();
    //requestUserInfo();
  }, [userInfo]);

  /*  useEffect(() => {
    requestUserInfo();
  }, [isSlidUpVisible, isSlidUpWriteVisible, valueSelectedState]); */

  useEffect(() => {
    if (!params.btn) {
      console.log(params.btn);
      setActiveSection("1");
    } else if (params.btn === "button1") {
      setShowAll(false);

      setActiveSection(params.btn);
    } else if (params.btn === "2") {
      setShowAll(true);
      console.log(params.btn);
    }
  }, [btnParams]);

  useEffect(() => {
    if (isSlidUpVisible && titleSlidUp === "Height") {
      const start = 150;
      const end = 200;

      const newHeightList = isCm
        ? Array.from({ length: end - start + 1 }, (_, i) =>
            formatHeight((start + i).toString(), false)
          )
        : Array.from({ length: end - start + 1 }, (_, i) =>
            (start + i).toString()
          );

      if (JSON.stringify(newHeightList) !== JSON.stringify(heightList)) {
        setHeightList(newHeightList);
      }

      if (!isCm) {
        changeListItemHandler(
          "height",
          userInfoRequest?.height?.toString() || ""
        );
      } else {
        const convertHeight =
          convertHeightToCm(userInfoRequest?.height?.toString() || "").replace(
            `.`,
            `’`
          ) + `”`;

        changeListItemHandler("height", convertHeight || "");
      }
    }
  }, [isCm, isSlidUpVisible, heightList]);

  return (
    <>
      <div
        ref={pageRef}
        className="user-settings-profile
				 user-settings-profile-wrapper
				 wrapper
				 standard-page-layout
				 bottom-spacing"
      >
        <div className="user-settings-profile-inner  inner">
          <div className="user-settings-profile__actions">
            {showAll === false && Number(params.btn) === 1 ? (
              <PageTitle
                title={"BASIC INFO"}
                subtitle={"3 of 5 added"}
                onBackClick={() => {
                  if (componentFeed) {
                    setSecondaryScreen("/user-settings-profile-customize", {
                      btn: "",
                    });
                  } else {
                    setPrincipalScreen("/user-settings-profile-customize", {
                      btn: "",
                    });
                  }
                }}
                hasCloseIcon={false}
              />
            ) : (
              <PageTitle
                title={"MORE INFO"}
                subtitle={"3 of 7 added"}
                onBackClick={() => {
                  if (componentFeed) {
                    setSecondaryScreen("/user-settings-profile-customize", {
                      btn: "",
                    });
                  } else {
                    setPrincipalScreen("/user-settings-profile-customize", {
                      btn: "",
                    });
                  }
                }}
                hasCloseIcon={false}
              />
            )}

            <div className="user-settings-profile__main">
              <div className="user-settings-profile__bars">
                {[...Array(6)].map((_, i) => (
                  <ProgressbarWithoutAnimation
                    key={i}
                    index={i}
                    activeIndex={0}
                    isPink
                  />
                ))}
              </div>

              <div>
                {personalInfoData.map((item, index) => {
                  if ((!showAll && index >= 8) || (showAll && index < 8))
                    return null;

                  if (!item.isActive) {
                    return null;
                  }

                  return (
                    <ListItemLink
                      key={index}
                      isCompact
                      hasSeparator={
                        showAll
                          ? index < personalInfoData.length - 1
                          : index < Math.min(personalInfoData.length - 1, 7)
                      }
                      hasArrowRight
                      highlightText={item.labelContent}
                      onClick={item.clickAction}
                      rightNodeContent={
                        <p className="user-settings-profile__detail">
                          {textLimit(item.valueContent)}
                          {index === 1 && isVerify && (
                            <img src={verifyIcon} alt="Verify Icon" />
                          )}
                          {index === 1 && !isVerify && (
                            <img src={verifyIconGray} alt="Not Verify Icon" />
                          )}
                        </p>
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>

          {activeSection === "button2" && (
            <>
              {!showAll && Number(params.btn) === 1 ? (
                <Button onClick={() => setShowAll(true)} buttonStyle="tertiary">
                  SKIP
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowAll(false);
                    setPrincipalScreen("/user-settings-profile-photo");
                  }}
                  buttonStyle="tertiary"
                >
                  SKIP
                </Button>
              )}
            </>
          )}
        </div>

        <SlidUp
          classNameContainer="user-settings-profile__slid-up"
          classNameChildren="user-settings-profile__slid-up-list"
          isOpen={isSlidUpVisible}
          title={titleSlidUp}
          closeArea={() => {
            setIsSlidUpVisible(false);
          }}
          isFullScreen
          onBackClick={() => setIsSlidUpVisible(false)}
          onCheckClick={handleApply}
        >
          {currentSlidUp === "language" ? (
            <InputCheckbox
              inputValues={selectedLanguages}
              setInputsValues={setSelectedLanguages}
              elements={
                languageList
                  ? languageList.map((item: ListDetails) => {
                      return {
                        groupName: "language",
                        inputLabel: `${item.name}`,
                        inputValue: `${item.name}`,
                      };
                    })
                  : []
              }
              defaultValueProp={selectedLanguages}
            />
          ) : (
            modalSlidUpContent
          )}
        </SlidUp>

        <SlidUp
          classNameContainer="user-settings-slid-up"
          classNameChildren="list-item-slid-up"
          isOpen={isSlidUpWriteVisible}
          title={openWriteInput}
          closeArea={() => {
            setIsSlidUpWriteVisible(false);
          }}
          isFullScreen
          onBackClick={() => setIsSlidUpWriteVisible(false)}
          onCheckClick={addSpecification}
        >
          {openWriteInput === "birthday" ? (
            <InputText
              inputType="text"
              value={profileBirthday}
              onChangeInput={(e) => setProfileBirthday(e)}
              placeholder="MM/DD/YYYY"
              mask="99/99/9999"
            />
          ) : openWriteInput === "weight" ? (
            <InputText
              inputType="text"
              value={profileWeight}
              onChangeInput={(e) => setProfileWeight(e)}
              placeholder="Weight"
              mask="99.99"
            />
          ) : openWriteInput === "height" ? (
            <InputText
              value={profileHeight}
              inputType="text"
              onChangeInput={(e) => setProfileHeight(e)}
              placeholder="Height"
              mask="999.9"
            />
          ) : openWriteInput === "interests" ? (
            <>
              <div className="div__content_scroll">
                <InputRadio
                  elements={
                    userInfoRequest?.interests
                      ? userInfoRequest?.interests.map((item: any) => {
                          return {
                            groupName: "interests",
                            inputLabel: `${textCapitalize(
                              item.name.replaceAll("_", " ")
                            )}`,
                            inputValue: `${textCapitalize(
                              item.name.replaceAll("_", " ")
                            )}`,
                          };
                        })
                      : []
                  }
                  onChangeValueCallback={(e) => {}}
                  defaultValueProp={interests}
                  iconRight={true}
                  actionIconRight={async (e) => {
                    if (e !== undefined && e !== null) {
                      const value = userInfoRequest?.interests[e]?.name;
                      const response = await deleteUserInterests(
                        userInfo.access_token,
                        value
                      );
                      requestUserInfo();
                    }
                  }}
                />
              </div>
              <InputText
                value={interests}
                inputType="text"
                onChangeInput={(e) => setInterests(e)}
                placeholder="Interests"
              />
            </>
          ) : openWriteInput === "occupation" ? (
            <>
              {userInfoRequest?.occupation && (
                <>
                  <div className="div__content_scroll">
                    <InputRadio
                      elements={
                        userInfoRequest?.occupation
                          ? userInfoRequest?.occupation
                              .split(",")
                              .map((item: any) => {
                                return {
                                  groupName: "occupation",
                                  inputLabel: `${textCapitalize(
                                    item.replaceAll("_", " ")
                                  )}`,
                                  inputValue: `${textCapitalize(
                                    item.replaceAll("_", " ")
                                  )}`,
                                };
                              })
                          : []
                      }
                      onChangeValueCallback={(e) => {}}
                      defaultValueProp={occupation}
                      iconRight={true}
                      actionIconRight={async (e) => {
                        if (e !== undefined && e !== null) {
                          const response = await patchDetails("occupation", "");
                          setOccupation("");
                          requestUserInfo();
                        }
                      }}
                    />
                  </div>
                </>
              )}
              <InputText
                value={occupation}
                inputType="text"
                onChangeInput={(e) => setOccupation(e)}
                placeholder="Occupation"
              />
            </>
          ) : (
            ""
          )}
        </SlidUp>
      </div>
    </>
  );
};

export default UserSettingsProfilePage;
