import React from "react";
import "./styles.scss";

interface RadioListProps {
  elements: InputRadioElement[];
  onChangeValueCallback: (a: string) => void;
}

interface InputRadioElement {
  inputIcon: string;
  inputIconAlt: string;
  inputTitle: string;
  inputText: string;
  inputValue: string;
  groupName: string;
  checked?: boolean;
  sphereIcon?: "pink" | "blue" | string;
}

const RadioList: React.FC<RadioListProps> = (props) => {
  const { elements, onChangeValueCallback } = props;
  return (
    <div id="radio-list">
      {elements.map((element, index) => (
        <div className="list-label-input-container  " key={index}>
          {element.inputIcon && (
            <div className="image-list  ">
              <img src={element.inputIcon} alt={element.inputIconAlt} />

              {element.sphereIcon && (
                <div className={`sphere-icon ${element.sphereIcon}`} />
              )}
            </div>
          )}

          <label
            htmlFor={`${element.inputValue}-${index}`}
            className="title-radio-list"
          >
            <p className="title-radio">{element.inputTitle}</p>

            <p className="sub-radio  ">{element.inputText}</p>
          </label>
          <input
            checked={element.checked}
            className="input-radio"
            type="radio"
            id={`${element.inputValue}-${index}`}
            name={element.groupName}
            value={element.inputValue}
            onChange={(e) => onChangeValueCallback(e.target.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioList;
