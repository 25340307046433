import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as jwtDecode from "jwt-decode";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import InputText from "components/Inputs/InputText";
import ListItemLink from "components/Lists/ListItemLink";
import LoadingPage from "components/LoadingPage";

import { MomentsTriggerContext } from "contexts/momentTrigger";

import getAuthTokenById from "../../api/auth/getAuthTokenById";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import getUserInfo from "../../api/userInfo/getUserInfo";
import getMatch from "api/match/getMatch";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useModal } from "../../hooks/useModal";
import { useToast } from "hooks/useToast";

import { localStorageClear } from "utils/localStorageClear";

import { IInfoToken } from "../../types/infoToken";
import { ICatalogResponse } from "../../types/catalog";

const UserSettingsChangeProfile: React.FC = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const { showToast } = useToast();
  const { setModalContent, setOpenUseModal } = useModal();
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const navigate = useNavigate();
  const { setMomentTrigger } = useContext(MomentsTriggerContext);

  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);
  const [catalogFilteredData, setCatalogFilteredData] = useState<
    ICatalogResponse[] | []
  >([]);
  const [searchCreator, setSearchCreator] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pastProfileAvatar, setPastProfileAvatar] = useState("");
  const [pastProfileName, setPastProfileName] = useState("");
  const [userPastToken, setUserPastToken] = useState("");

  const pastTokenStorage = localStorage.getItem("user-info-past-token");
  const pastNameStorage = localStorage.getItem("user-info-past-name");
  const pastAvatarStorage = localStorage.getItem("user-info-past-avatar");

  useEffect(() => {
    getCatalogData();

    if (pastTokenStorage && pastNameStorage && pastAvatarStorage) {
      if (pastAvatarStorage === "undefined") {
        setPastProfileAvatar("");
      } else {
        setPastProfileAvatar(pastAvatarStorage);
      }

      setUserPastToken(pastTokenStorage);
      // getUserDetailsInfo(pastTokenStorage);
      setPastProfileName(pastNameStorage);
    }
  }, [pastTokenStorage, pastNameStorage, pastAvatarStorage]);

  const backPageClick = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings-configuration");
    } else {
      setPrincipalScreen("/user-settings-configuration");
    }
  };

  const getCatalogData = async () => {
    try {
      const response = await getMatch(userInfo.access_token);

      switch (response?.status) {
        case 200:
          setCatalogData(response.res);
          setCatalogFilteredData(response.res);

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const searchInputHandler = (search: string) => {
    setSearchCreator(search);
    const filterSearch = catalogData.filter((creator) =>
      creator.displayname.toLowerCase().startsWith(search.toLowerCase())
    );
    setCatalogFilteredData(filterSearch);
  };

  const tokenTreatment = async (token: string) => {
    const pastToken = userInfo.access_token;
    const pastName = userInfo.display_name;
    const pastAvatar = userInfo.user_profile_avatar;

    if (!pastTokenStorage) {
      setPastProfileName(pastName);
      setPastProfileAvatar(pastAvatar);
      setUserPastToken(pastToken);

      localStorageClear();

      localStorage.setItem("user-info-past-token", pastToken);
      localStorage.setItem("user-info-past-name", pastName);
      localStorage.setItem("user-info-past-avatar", pastAvatar);
      getUserDetailsInfo(token);
    } else {
      localStorageClear();
    }

    const userInfoToken = (await jwtDecode.jwtDecode(token)) as IInfoToken;

    setUserInfo("access_token", token);
    setUserInfo("display_name", userInfoToken?.validUser.displayname);
    setUserInfo("account_type", userInfoToken?.validUser.role);
    setUserInfo("user_phone", userInfoToken?.validUser.phone);
    setUserInfo("user_email", userInfoToken?.validUser.email);
    setUserInfo("user_id", userInfoToken?.validUser.userId);

    getUserDetailsInfo(token);
    const responseStatus = await getCreatorStatus(token);

    if (responseStatus?.status === 200) {
      setUserInfo("referral_code", responseStatus?.res.referralCode);
      setUserInfo("creator_application_status", responseStatus?.res.status);
      setUserInfo(
        "creator_application_id",
        responseStatus?.res.creatorApplicationId
      );
    }
    setMomentTrigger((prev) => !prev);
  };

  const selectedCreatorHandler = async (creator?: ICatalogResponse) => {
    setIsLoading(true);
    setModalContent(<LoadingPage />);
    setOpenUseModal(true);
    const token = pastTokenStorage ?? userInfo.access_token;

    try {
      if (creator && !pastTokenStorage) {
        const response = await getAuthTokenById(token, creator.userId);
        switch (response.status) {
          case 200:
            tokenTreatment(response.res.access_token);
            break;
          case 403:
            showToast({
              description:
                "You do not have permission to perform this operation",
              title: "Permission denied",
              type: "error",
            });
            break;
          default:
            showToast({
              description: "We had a problem executing the request",
              title: "Oops...",
              type: "error",
            });
            break;
        }
      } else if (pastTokenStorage) {
        tokenTreatment(pastTokenStorage);
      }
    } catch (error) {
      console.error(error);
      showToast({
        description: "We had a problem executing the request",
        title: "Oops...",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setOpenUseModal(false);
    }
  };

  const getUserDetailsInfo = async (token: string) => {
    const response = await getUserInfo(token);

    switch (response.status) {
      case 200:
        if (response?.res?.message?.statusCode === 401) {
          navigate("/login");
        } else {
          setUserInfo(
            "user_profile_avatar",
            response.res?.avatar?.thumbnailUrl
          );
        }
        break;
      case 401:
        localStorageClear();
        navigate("/login");
        break;
      default:
        localStorageClear();

        navigate("/login");
        break;
    }
  };

  return (
    <div
      className="user-settings-change-profile
		 		wrapper
		  	user-settings-change-profile-wrapper
				standard-page-layout"
    >
      <div className="user-settings-change-profile-inner inner">
        <PageTitle
          title={"Manage Profiles"}
          subtitle={"Configuration"}
          hasCloseIcon={false}
          onBackClick={backPageClick}
          className="user-settings-change-profile__page-title"
        />
        <main className="user-settings-change-profile__main">
          {pastTokenStorage && pastNameStorage ? (
            <ListItemLink
              highlightText={"Return to your profile"}
              simpleText={pastNameStorage}
              hasArrowRight
              onClick={selectedCreatorHandler}
              isImageCircle
              imageLeft={pastProfileAvatar}
              avatarImgStyle
              iconFill
            />
          ) : (
            <>
              <InputText
                inputType="text"
                searchInput
                placeholder="Search creator"
                onChangeInput={searchInputHandler}
                value={searchCreator}
              />
              <section className="main__creators-list-wrapper bottom-spacing">
                {catalogFilteredData.map((creator, index, arr) => {
                  return (
                    <ListItemLink
                      key={index}
                      highlightText={creator.displayname}
                      hasArrowRight
                      onClick={() => selectedCreatorHandler(creator)}
                      isImageCircle
                      hasSeparator={arr.length > index + 1}
                      imageLeft={creator?.profile?.avatar?.thumbnailUrl}
                      avatarImgStyle
                      iconFill
                    />
                  );
                })}
              </section>
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default UserSettingsChangeProfile;
