import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Button from "components/Buttons/Button";
import ListItemLink from "components/Lists/ListItemLink";
import ListItemToggle from "components/Lists/ListItemToggle";
import Paragraph from "components/Texts/Paragraph";
import SubText from "components/Texts/SubText";
import ListItemSecondary from "components/Lists/ListItemSecondary";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";
import { useLoginSignUpNavigation } from "hooks/useLoginSignUpNavigation";

import getUserSelfDetail from "../../api/getUserSelfDetail";
import PatchUserSelfDetailApi from "../../api/patchUserSelfDetail";
import getBlockedLocations from "../../api/user/getBlockedLocations";
import DeleteBlockedLocations from "../../api/user/deleteBlockedLocations";
import DeleteMembershipUserApi from "api/deleteMembershipApplicationAdmin";
import PostBlockedLocations from "../../api/user/postBlockedLocations";
import getUserLocationHistory from "../../api/userInfo/getUserLocationHistory";

import ClockIconGray from "assets/icons/clock-icon-gray.svg";
import PrivacyIconGray from "assets/icons/privacy-icon-gray.svg";
import ShieldIconGray from "assets/icons/shieldIcons/personal-icon-gray.svg";
import CloseIconPeach from "assets/icons/closeIcons/close-icon-peach.svg";

import { localStorageClear } from "utils/localStorageClear";

interface ListDetails {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;
  latitude?: number;
  longitude?: number;
  locationHistoryId: string;
}

const UserSettingsPrivacyPage: React.FC = () => {
  const {
    componentFeed,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();
  const { userInfo } = useUserInfo();
  const { showToast } = useToast();
  const { setComponent } = useLoginSignUpNavigation();
  const navigate = useNavigate();

  const [isProfileHidden, setIsProfileHidden] = useState(false);
  const [isBlockRequests, setIsBlockRequests] = useState(false);
  const [hideEveryone, setHideEveryone] = useState();
  const [hideLocation, setHideLocation] = useState<boolean>();
  const [historyLocationsBlocked, setHistoryLocationsBlocked] = useState<
    ListDetails[] | any[]
  >();
  const [locationList, setLocationList] = useState<ListDetails[]>();
  const [activeLocation, setActiveLocation] = useState<boolean>();

  const handleToggleProfileHidden = (newState: boolean) => {
    setIsProfileHidden(newState);
  };

  const handleToggleBlockRequests = (newState: boolean) => {
    setIsBlockRequests(newState);
  };

  /*   const addLocationHandler = async () => {
    const res = await PostBlockedLocations(userInfo.access_token, locationMock);
    getUserDetail();
  }; */

  const openLocationPage = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-blocked-location");
    } else {
      setPrincipalScreen("/user-blocked-location");
    }
  };

  const removeLocationHandler = async (index: number) => {
    if (locationList) {
      const locationId = locationList[index].locationHistoryId || "teste";

      const res = await DeleteBlockedLocations(
        userInfo.access_token,
        locationId
      );
      const response = await requestLocationHistory();
      if (response === 200) {
        //setModalRemoveIsOpen(false);

        showToast({
          description: `Location was successfully removed`,
          title: "Location removed",
          type: "success",
        });
      } else if (response === 500) {
        showToast({
          description: `Unable to remove location, please try again later`,
          title: "Location was not removed",
          type: "error",
        });
      }
    }
  };

  const activateLocationShieldHandler = async (e: any) => {
    setActiveLocation(e);
    if (e === false) {
      /*   hideSpecificLocationHandler(e);
      hideEveryoneHandler(e); */
    }
  };

  const hideSpecificLocationHandler = async (e: any) => {
    const res = await PatchUserSelfDetailApi(userInfo.access_token, {
      blockLocation: e,
    });
    setHideLocation(e);
  };

  const hideEveryoneHandler = async (e: any) => {
    const res = await PatchUserSelfDetailApi(userInfo.access_token, {
      isHidden: e,
    });
    setHideEveryone(e);
  };

  const requestLocationHistory = async () => {
    const response = await getBlockedLocations(userInfo.access_token);

    if (response.res) {
      const history = response.res.map((item: any) => ({
        ...item.location,
        locationHistoryId: item.blockedLocationId,
      }));
      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => item)
      );
      return 200;
    }

    return 500;
  };

  const getUserDetail = async () => {
    const res = await getUserSelfDetail(userInfo.access_token);
    requestLocationHistory();
    // setHistoryLocationsBlocked([locationMock]);

    setHideLocation(res.res.blockLocation);
    setHideEveryone(res.res.isHidden);
    if (res.res.blockLocation || res.res.isHidden) {
      setActiveLocation(true);
    } else {
      setActiveLocation(false);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  const takeABreakHandler = async (e: any) => {
    const res = await PatchUserSelfDetailApi(userInfo.access_token, {
      isActive: e,
    });
  };
  const blockRequestsHandler = async (e: any) => {
    const res = await PatchUserSelfDetailApi(userInfo.access_token, {
      blockRequest: e,
    });
  };
  const deleteAccountHandler = async () => {
    const phone = userInfo.user_phone.replace("+", "");

    if (
      phone === "5511967700452" ||
      phone === "14074464664" ||
      phone === "13053704780" ||
      phone === "5545999640276" ||
      phone === "5511914013654" ||
      phone === "5519984484218"
    ) {
      try {
        const deleteResponse = await DeleteMembershipUserApi(phone);
        if (deleteResponse.status === 204) {
          localStorageClear();
          sessionStorage.clear();
          setPrincipalScreen("");
          setSecondaryScreen("");
          setTertiaryScreen("");
          setComponent("");
          navigate("/");
        } else {
          showToast({
            type: "error",
            title: "Delete Account",
            description: "Not can be complete you request",
          });
        }
      } catch (error) {
        showToast({
          type: "error",
          title: "Delete Account",
          description: "There was an error during the delete operation",
        });
      }
    }
  };

  return (
    <div
      className="user-settings-privacy
				user-settings-privacy-wrapper
				wrapper
				standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-privacy-inner inner">
        <PageTitle
          title={"Privacy"}
          subtitle={"Configuration"}
          hasCloseIcon={false}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
        />
        <div className="user-settings-privacy__main">
          <Paragraph
            children={`Emergency Assistance lets you choose contacts for
							emergencies. Contacts are notified only if an emergency occurs,
							receiving your last location and activity details for prompt
							help.`}
          />

          <div className="user-settings-privacy__options">
            <ListItemToggle
              toggleCallBack={activateLocationShieldHandler}
              hasSeparator
              imageLeft={ShieldIconGray}
              isImageCircle
              toggleInitialState={activeLocation}
              simpleText="Profile will only show to specific users"
              highlightText="Activate Location Shield"
            />

            {activeLocation && (
              <>
                <ListItemToggle
                  highlightText="Hide from everyone"
                  simpleText="Only show profile to users with a direct link"
                  hasSeparator
                  toggleInitialState={hideEveryone}
                  toggleCallBack={hideEveryoneHandler}
                />

                <ListItemToggle
                  highlightText="Hide specific location"
                  simpleText={`Your profile will be hidden from users in these 
                  locations`}
                  hasSeparator={
                    !!locationList?.length ||
                    (activeLocation && !!locationList?.length)
                  }
                  toggleInitialState={hideLocation}
                  toggleCallBack={hideSpecificLocationHandler}
                />
                {locationList && hideLocation && (
                  <>
                    {locationList.map((location: ListDetails, index, arr) => (
                      <ListItemLink
                        key={index}
                        highlightText={location.place + ", " + location.country}
                        hasSeparator={arr.length > index + 1}
                        hasCloseIconRight
                        className="location-list-item__location"
                        closeIconClick={() => removeLocationHandler(index)}
                      />
                    ))}
                    <Button buttonStyle="tertiary" onClick={openLocationPage}>
                      Add location
                    </Button>
                  </>
                )}
              </>
            )}

            {/* <ListItemToggle
              toggleInitialState
              hasSeparator
              highlightText="Account"
              simpleText="Deactivate my account for 7 days"
              isImageCircle
              imageLeft={ClockIconGray}
              toggleCallBack={takeABreakHandler}
            />

            <ListItemToggle
              toggleInitialState
              highlightText="Block Requests"
              simpleText="Prohibit rose requests"
              isImageCircle
              imageLeft={PrivacyIconGray}
              toggleCallBack={blockRequestsHandler}
            /> */}
          </div>

          {/* <div className="list-container-settings__bts-container">
            <Button buttonStyle="quaternary" onClick={deleteAccountHandler}>
              Delete Account
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserSettingsPrivacyPage;
