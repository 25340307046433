import React, { Suspense, useRef, useState } from "react";
import "./styles.scss";

import lockIcon from "assets/icons/lock-icon-gray.svg";
import playIcon from "assets/icons/play-circle-white.svg";

import Button from "../../Buttons/Button";
import Loading from "../../Loading";
import AudioWaveform from "../../AudioWave";
import VideoMessageModal from "../../Chat/ChatModals/VideoMessageModal";
import { shortClassNames } from "utils/shortClassNames";
import LazyImage from "components/LazyLoadedImage";
import { ImagePlaceholder } from "components/LazyLoadedImage/ImagePlaceholder";

interface CardsMediaLibraryProps {
  fileType: string;
  src: string;
  placeholderSrc: string;
  duration?: number;
  altImage?: string;
  rosesPrice?: string | number;
  isBlurred?: boolean;
  className?: string;
  expandClick?: (a: any) => void;
  unlockClick?: (a: any) => void;
}

const CardsMediaLibrary: React.FC<CardsMediaLibraryProps> = (props) => {
  const {
    fileType,
    src,
    duration,
    rosesPrice,
    isBlurred,
    className,
    unlockClick,
    expandClick,
    placeholderSrc,
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const audioRef = useRef<HTMLDivElement | null>(null);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const renderComponent = () => {
    switch (fileType) {
      case "PHOTO":
        return (
          <Suspense fallback={<ImagePlaceholder />}>
            <LazyImage
              placeholderSrc={placeholderSrc}
              src={src}
              loading="eager"
              alt="profile cover"
              onClick={expandClick}
              className="cards-media-library"
            />
          </Suspense>
        );
      // case "AUDIO":
      //   return (
      //     <div className="principal-src" onClick={handleAudioClick}>
      //       <AudioWaveform
      //         ref={audioRef}
      //         className="media-audio"
      //         audioSrc={src}
      //         duration={duration}
      //       />
      //     </div>
      //   );
      case "VIDEO":
        return (
          <div className="principal-src" onClick={expandClick}>
            <img src={playIcon} alt="play icon" className="media-play-icon" />
            <video className="media-video" onLoadedData={handleLoad}>
              <source src={src}></source>
            </video>
          </div>
        );
      default:
        return "";
    }
  };

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="cards-media element-footer-overlay-35 element-header-overlay-35">
      {/* {!isLoaded && (
        <div className="cards-loading-box">
          <Loading className="cards-loading" />
        </div>
      )} */}
      <div
        id="cards-media-library"
        className={shortClassNames([
          "cards-media-library",
          isBlurred ? "blurred" : "",
          className ?? "",
        ])}
      >
        {renderComponent()}
        {isBlurred && (
          <div className="locked-section">
            <img src={lockIcon} alt="lock icon" onLoad={handleImageLoad} />
            <p className="roses-price">{rosesPrice}</p>

            <Button onClick={unlockClick} buttonStyle="quintenary">
              unlock
            </Button>
          </div>
        )}

        {!isBlurred && <p className="infinity-symbol">∞</p>}
      </div>
    </div>
  );
};

export default CardsMediaLibrary;
