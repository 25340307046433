import fetchApi from "../fetchApi";

const getCreatorSettingsGoalProgress = async (
  token: string,
  goalId: string
) => {
  return await fetchApi({
    service: "creatorSettings",
    endPoint: `/goal/progress/${goalId}`,
    method: "GET",
    token,
  });
};

export default getCreatorSettingsGoalProgress;
