import { servicesApi } from "../fetchApi/services-api";

export type AvatarProfileData = {
  file: File;
};

const postAvatarProfile = async (token: string, data: AvatarProfileData) => {
  const formData = new FormData();

  if (data.file instanceof File) {
    formData.append("file", data.file);
  }
  try {
    const response = await fetch(`${servicesApi("user")}/avatar`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    return { res: data, status: response.status };
  } catch (error) {
    console.error(error);
    return { res: null, status: null };
  }
};

export default postAvatarProfile;
