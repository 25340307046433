import { ICatalogResponse } from "types/catalog";
import fetchApi from "../fetchApi";

const getPublicDetails = async (
  token: string,
  userId: string
): Promise<{ res: ICatalogResponse | null; status: number | null }> => {
  return await fetchApi({
    service: "user",
    endPoint: `/public-detail/${userId}`,
    method: "GET",
    token,
  });
};

export default getPublicDetails;
