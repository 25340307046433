import React, { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Header from "components/Headers/Header";
import Paragraph from "components/Texts/Paragraph";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import InputText from "components/Inputs/InputText";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal";

import BecomeCreator from "pages/BecomeCreator";
import TermsAndConditionsPage from "pages/TermsAndConditions";

import { useBecomeCreator } from "contexts/becomeCreatorContext";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";

import ShieldIcon from "assets/icons/shieldIcons/personal-icon-gray.svg";
import PrivacyPolicyPage, {
  PolicyContent,
} from "pages/LandingPage/PrivacyPolicyPage";

const PersonalInformation: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { setRegisterBecomeCreator } = useBecomeCreator();
  const { windowSize } = useWindowWidthSize();
  const { setModalContent } = useModal();
  const { showToast } = useToast();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  const breakPoint = windowSize >= 768;

  const handlerGoTo = (route: string) => {
    if (breakPoint) {
      switch (route) {
        case "/terms-and-conditions":
          setModalContent(<TermsAndConditionsPage />);
          break;
        case "/privacy-policy":
          setModalContent(
            <div className="personal-information__modal standard-page-layout bottom-spacing">
              <ButtonCloseModal />
              {!breakPoint ? (
                <PageTitle
                  title="Become a Creator"
                  subtitle="Start Earning"
                  isBecome
                  hasCloseIcon={false}
                  onBackClick={handlerBackPage}
                />
              ) : (
                false
              )}
              <PolicyContent />
            </div>
          );
          break;
        default:
          break;
      }
    } else {
      setPrincipalScreen(route);
    }
  };

  const handlerNextPage = () => {
    const isFieldsFill =
      firstName === "" || lastName === "" || dateOfBirth.length !== 10;
    if (isFieldsFill) {
      showToast({
        title: "Error",
        description: "You must fill in all fields to continue!",
        type: "error",
      });
      return;
    }

    setRegisterBecomeCreator({
      firstName,
      lastName,
      dateOfBirth,
    });

    if (breakPoint) {
      setModalContent(<TermsAndConditionsPage />);
    } else {
      setPrincipalScreen("/terms-and-conditions");
    }
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setModalContent(<BecomeCreator />);
    } else {
      setPrincipalScreen("/become-creator");
    }
  };

  return (
    <div className="personal-information personal-information-wrapper wrapper standard-page-layout bottom-spacing">
      <div className="personal-information-inner inner">
        <ButtonCloseModal />

        <div className="personal-information__top">
          {!breakPoint ? (
            <PageTitle
              title="Become a Creator"
              subtitle="Start Earning"
              isBecome
              hasCloseIcon={false}
              onBackClick={handlerBackPage}
            />
          ) : (
            false
          )}

          <Header
            title="Personal information"
            subTitle="Please fill in your legal name and data of birth that matches with your government-issued ID."
            marginTop={0}
            backButtonClick={handlerBackPage}
            {...(breakPoint
              ? {
                  headerIcon: "back-button",
                  inlineElements: true,
                  titleClass: "header",
                }
              : {
                  titleClass: "personal-information__title",
                })}
          />
        </div>

        <div className="personal-information__information">
          <InputText
            placeholder="First Name"
            textTransform="none"
            inputType="text"
            value={firstName}
            onChangeInput={(e) => setFirstName(e)}
          />

          <InputText
            placeholder="Last Name"
            textTransform="none"
            inputType="text"
            value={lastName}
            onChangeInput={(e) => setLastName(e)}
          />

          <InputText
            iconRightCustom="icon-events icon-gray"
            placeholder="Date of Birth"
            textTransform="none"
            inputType="text"
            mask="99/99/9999"
            value={dateOfBirth}
            onChangeInput={(e) => setDateOfBirth(e)}
          />
        </div>

        <SubText
          icon={ShieldIcon}
          altIcon="shield icon"
          className="personal-information__icon-disclaimer"
          classNameIcon="personal-information__icon"
          children="We’ll never share your personal information"
        />

        <div className="personal-information__bottom">
          <Button
            children="Continue"
            buttonStyle={"secondary"}
            onClick={handlerNextPage}
          />
          <Paragraph>
            By tapping any of the actions above, you acknowledge that you are
            over the age of 18 and agree to our{" "}
            <span
              className="personal-information__go-to"
              onClick={() => handlerGoTo("/terms-and-conditions")}
            >
              Terms of Services
            </span>
            . Learn how we process your data in our{" "}
            <span
              className="personal-information__go-to"
              onClick={() => handlerGoTo("/privacy-policy")}
            >
              Privacy Policy
            </span>
            .
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
