import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

import postCreatorSettingsGoal from "../../api/creatorSettings/postCreatorSettingsGoal";
import getCreatorSettingsGoalHistory from "../../api/creatorSettings/getCreatorSettingsGoalHistory";

import { IGoal } from "../../types/goal";

import logo from "assets/icons/logos/logo-fill-pink.svg";

type UserSettingsGoalsProps = {
  className?: string;
};

const UserSettingsGoals: React.FC<UserSettingsGoalsProps> = (props) => {
  const { className } = props;

  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { showToast } = useToast();

  const { userInfo } = useUserInfo();
  const token = userInfo.access_token;

  const [title, setTitle] = useState("My Goal");
  const [target, setTarget] = useState<string | number>(0);
  const [listGoals, setListGoals] = useState<IGoal[]>([]);

  useEffect(() => {
    getListGoals();
  }, []);

  const getListGoals = useCallback(async () => {
    try {
      const { res } = await getCreatorSettingsGoalHistory(token);
      setListGoals(res);
    } catch (error) {
      return false;
    }
  }, [token]);

  const postCreateGoals = async () => {
    try {
      const res = await postCreatorSettingsGoal({
        token,
        title,
        target: Number(target),
      });
      await getListGoals();
    } catch (error) {
      showToast({
        description: "Oops! There was a problem. Please try again in a moment.",
        title: "Create a Goals",
        type: "error",
      });
    }
  };

  return (
    <div
      className={`user-settings-goals user-settings-goals-wrapper wrapper standard-page-layout ${
        className ? className : ""
      }`}
    >
      <div className="user-settings-goals-inner inner">
        <PageTitle
          title={"My Goals"}
          subtitle={"Configuration"}
          hasCloseIcon={false}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
        />

        <div className="goals">
          <InputText
            label="Title: "
            value={title}
            inputType="text"
            placeholder="Buy a house"
            textTransform="none"
            onChangeInput={(e) => setTitle(e)}
          />

          <InputText
            label="Target: "
            value={String(target)}
            inputType="text"
            placeholder="Buy a house"
            textTransform="none"
            onChangeInput={(e) => setTarget(e ? parseFloat(e) || 0 : 0)}
          />

          <Button buttonStyle={"primary"} onClick={postCreateGoals}>
            Create Goals
          </Button>

          <h2 className="goals__history-title">Goals History:</h2>
          {listGoals.length > 0 ? (
            listGoals.map((goal) => {
              const { goalId, isActive, target, title, currentAmount } = goal;
              return (
                <div
                  key={goalId}
                  className="goals__history  "
                  onClick={() => {
                    if (componentFeed) {
                      setSecondaryScreen("/user-settings-edit-goal", {
                        goal: JSON.stringify(goal),
                      });
                    } else {
                      setPrincipalScreen("/user-settings-edit-goal", {
                        goal: JSON.stringify(goal),
                      });
                    }
                  }}
                >
                  <div className="goals__top">
                    <h3 className="goals__history-title  ">{title}</h3>
                    <span
                      className={`
                  goals__history-title 
                  goals__history-title--${isActive ? "active" : "inactive"}
                  `}
                    >
                      {isActive ? "Active" : "Inactive"}
                    </span>
                  </div>
                  <div className="goals__bottom">
                    <p className="goals__objective">Objective: {target}</p>
                    <p className="goals__amount">Amount: {currentAmount}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="goals-fallback">
              <img className="goals-logo" src={logo} alt="Logo Roses" />
              <h2 className="goals-no-post">
                No goals found
                <span className="goals-no-post-support-text">
                  Would you like to create a goal?
                </span>
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSettingsGoals;
