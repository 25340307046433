import React, { ReactNode, useEffect, useRef, useState } from "react";

import "./styles.scss";
import "../chat-modal-global-style.scss";

import Button from "../../../Buttons/Button";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import InputMessage from "../../../TextAreas/Textarea";
import DropDown from "../../../Lists/DropDown";
import RosesCredit from "../../../RosesCredit";
import SubText from "../../../Texts/SubText";
import RangeSlider from "../../../Ranges/RangeSlider";

import ListItemToggle from "components/Lists/ListItemToggle";
import Tooltip from "components/Tooltip";
import ImageCircleBorder from "components/ImageCircleBorder";

import { useModal } from "../../../../hooks/useModal";
import { useUserInfo } from "hooks/userInfo";
import { useBuyRoses } from "hooks/useBuyRoses";

import getPackages from "api/packages/getPackages";
import getUserInfo from "api/userInfo/getUserInfo";

import { IPackages, TableRow } from "types/interfaces";

import { dollarFormat, dollarFormatSecondary } from "utils/dollarFormat";
import verifyToken from "utils/verifyToken";

import cardsMock from "../../../../mocks/cards";
import tableDataMock from "mocks/tableDataMocks";

import rosesLogoPink from "assets/icons/logos/logo-fill-pink.svg";
import rosesLogoGold from "assets/icons/logos/logo-fill-gold.svg";

interface SendRosesModalProps {
  showLogo?: boolean;
  showRosesOptions?: boolean;
  showInfoGold?: boolean;
  showSendGold?: boolean;
  showSendMessage?: boolean;
  showCards?: boolean;
  showTable?: {
    table?: boolean;
    header?: {
      gold?: boolean;
      roses?: boolean;
      total?: boolean;
    };
  };
  tableData?: TableRow[];
  title: string;
  description: string | ReactNode;
  typeModal?: "send" | "chat" | "buy";
  buttonText?: string;
  maxValue?: number;
  className?: string;
  onClose?: () => void;
  onClick?: (e?: string) => void;
  rosesMessageCallback?: (roses: number, message: string) => void;
}

const SendRosesModal: React.FC<SendRosesModalProps> = (props) => {
  const {
    showLogo = false,
    showRosesOptions = false,
    showInfoGold = false,
    showSendGold = false,
    showSendMessage = false,
    showCards = false,
    showTable,
    title = "Title text",
    description = "Description text",
    typeModal = "send",
    buttonText = "Send Roses",
    maxValue = 2000,
    className,
    onClose,
    onClick,
    rosesMessageCallback,
  } = props;
  const { setOpenUseModal } = useModal();

  const { userInfo } = useUserInfo();
  const [messageToSend, setMessageToSend] = useState("");
  const [values, setValues] = useState<{ min: number }>({ min: 1 });
  const [activeButton, setActiveButton] = useState<string | null>();
  const [rosesToSend, setRosesToSend] = useState<string | number>(
    Math.max(1, Math.floor(maxValue / 4))
  );
  const [tableModal, setTableModal] = useState<TableRow[]>();
  const [goldenRoses, setGoldenRoses] = useState(0);
  const [valueSelected, setValueSelected] = useState(0);
  const [profileAvatar, setProfileAvatar] = useState("");
  // const [packages, setPackages] = useState<IPackages[]>([]);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const { buyRoses } = useBuyRoses(activeButton ?? "");

  // useEffect(() => {
  //   const fetchPackages = async () => {
  //     const { res } = await getPackages(userInfo.access_token);
  //     setPackages(res);

  //     const secondItemId = res[1]?.packageId ?? null;

  //     const actionButton = typeModal === "send" ? null : secondItemId;
  //     setActiveButton(actionButton);

  //     setRosesToSend(Math.max(1, Math.floor(maxValue / 4)));
  //   };

  //   fetchPackages();
  // }, [typeModal, userInfo.access_token]);

  const startIncrement = () => {
    setActiveButton(null);
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => {
          const nextValue = Number(prevCount) + 1;
          return nextValue > maxValue ? maxValue : nextValue;
        });
      }, 100);
    }
  };

  const startDecrement = () => {
    setActiveButton(null);
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => {
          const nextValue = Number(prevCount) - 1;
          return nextValue < 0 ? 0 : nextValue;
        });
      }, 100);
    }
  };

  const stopAction = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    const totalRoses = Number(rosesToSend) + goldenRoses;
    const totalPrice = activeButton
      ? String(valueSelected)
      : String(valueSelected * Number(rosesToSend));

    setTableModal([
      {
        type: "gold",
        label: "Golden Roses",
        values: [goldenRoses],
      },
      {
        type: "data",
        label: "Roses",
        values: [rosesToSend, dollarFormat(totalPrice)],
      },
      {
        type: "total",
        label: "Total",
        values: [totalRoses, dollarFormat(totalPrice)],
      },
    ]);
  }, [rosesToSend, goldenRoses]);

  useEffect(() => {
    const goldenMultiply = Math.floor(Number(rosesToSend) / 500);
    const totalGolden = goldenMultiply * 6;
    setGoldenRoses(totalGolden);
  }, [rosesToSend]);

  useEffect(() => {
    getUserInfoRequest();
  }, []);

  const getUserInfoRequest = async () => {
    if (!verifyToken()) return;
    try {
      const response = await getUserInfo(userInfo.access_token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            // navigate("/login");
          } else {
            setProfileAvatar(response.res?.avatar?.thumbnailUrl);
          }
          break;
        case 401:
          // navigate("/login");
          break;
        default:
          // navigate("/login");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const suggestionRosesValues = [
    {
      id: "1",
      amount: 25,
    },
    {
      id: "2",
      amount: 50,
    },
    {
      id: "3",
      amount: 100,
    },
    {
      id: "4",
      amount: 150,
    },
    {
      id: "5",
      amount: 200,
    },
    {
      id: "6",
      amount: 250,
    },
  ];

  const suggestionGoldValues = [
    {
      id: "1",
      amount: 5,
    },
    {
      id: "2",
      amount: 10,
    },
    {
      id: "3",
      amount: 100,
    },
    {
      id: "4",
      amount: 150,
    },
    {
      id: "5",
      amount: 200,
    },
    {
      id: "6",
      amount: 250,
    },
  ];

  const [suggestionValues, setSuggestionValues] = useState(
    suggestionRosesValues
  );
  const [goldenToggle, setGoldenToggle] = useState(false);

  return (
    <div
      className={`send-roses-modal ${className ?? ""} wrapper`}
      data-type={typeModal}
      data-gold-active={goldenToggle}
    >
      <div className="send-roses-modal__wrapper" data-type={typeModal}>
        <div className="send-roses-modal__top">
          {showLogo && <img src={rosesLogoPink} alt="roses logo icon" />}
          <h3 className="send-roses-modal__title">{title}</h3>
          <p className="send-roses-modal__description">{description}</p>
        </div>

        {typeModal === "send" && (
          <section className="range-roses-to-send">
            <ButtonIcon
              icon="icon-minus"
              buttonStyle="tertiary"
              onMouseDown={startDecrement}
              onMouseUp={stopAction}
              onMouseLeave={stopAction}
              onClick={() =>
                setRosesToSend((prev) => Math.max(Number(prev) - 1, 0))
              }
            />
            <div className="range-roses-to-send__value">
              <p className="value-to-send">{rosesToSend}</p>
              <p className="available-value">
                {typeModal === "send" && `${maxValue} available`}
                {/* {typeModal === "buy" && (
                  <>
                    <span className="range-rose__value--quotation">$0.97</span>{" "}
                    per Roses
                  </>
                )} */}
              </p>
            </div>
            <ButtonIcon
              icon="icon-plus"
              buttonStyle="tertiary"
              onMouseDown={startIncrement}
              onMouseUp={stopAction}
              onMouseLeave={stopAction}
              onClick={() =>
                setRosesToSend((prev) => Math.min(Number(prev) + 1, maxValue))
              }
            />
            <RangeSlider
              min={1}
              max={maxValue}
              initialMin={1}
              minValue={Number(rosesToSend)}
              isGold={goldenToggle}
              onChange={(e) => {
                setActiveButton(null);
                setRosesToSend(e.min);
                setValues(e);
              }}
              onMouseDown={() => {
                setActiveButton(null);
                // setValueSelected(0.97);
              }}
              className="range-roses-to-send__range"
            />
          </section>
        )}

        <section className="send-roses-modal__main">
          {showSendGold && (
            <div className="send-roses-modal__toggle-ipt">
              <ImageCircleBorder
                centerImageAlt=""
                centerImage={profileAvatar}
                size={40}
              />
              <ListItemToggle
                highlightText={"Add Golden Roses"}
                simpleText={"Turn your roses to gold"}
                isGold={goldenToggle}
                toggleCallBack={(e) => {
                  setGoldenToggle(e);
                  if (e) {
                    setSuggestionValues(suggestionGoldValues);
                  } else {
                    setSuggestionValues(suggestionRosesValues);
                  }
                }}
              />
            </div>
          )}
          {showRosesOptions && suggestionValues.length ? (
            <div className="send-roses-modal__grid">
              {suggestionValues?.map((item, index) => {
                const { id, amount } = item;
                return (
                  <RosesCredit
                    key={index}
                    text={String(amount)}
                    // value={`${dollarFormatSecondary(String(item.price))}`}
                    active={activeButton === id}
                    isGold={goldenToggle}
                    onClick={() => {
                      setActiveButton(id);
                      // setValueSelected(item.price);
                      setRosesToSend(Math.min(amount, maxValue));
                    }}
                    // isPopular={index === 1}
                  />
                );
              })}
            </div>
          ) : (
            false
          )}
          {/* //! Corne Job Remover tableData */}
          {showTable?.table && (
            <table className="send-roses-modal__table">
              <tbody>
                {tableModal
                  ? tableModal.map((row: TableRow, index: number) => (
                      <tr
                        key={index}
                        className={`send-roses-modal__table--${row.type}`}
                      >
                        <th>{row.label}</th>
                        {row.values.map((value, i) => (
                          <td key={i}>{value}</td>
                        ))}
                      </tr>
                    ))
                  : tableDataMock?.map((row: TableRow, index: number) => (
                      <tr
                        key={index}
                        className={`send-roses-modal__table--${row.type}`}
                      >
                        <th>{row.label}</th>
                        {row.values.map((value, i) => (
                          <td key={i}>{value}</td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          )}
          {showInfoGold && (
            <>
              <SubText
                icon={rosesLogoGold}
                className={"send-roses-modal__info  "}
              >
                <div className="send-roses-modal__info-head">
                  <h3 className="send-roses-modal__info-title roboto-regular  ">
                    Win <span className="send-roses-modal__gold-value">6</span>{" "}
                    Golden Roses
                  </h3>
                  <Tooltip text={"Tooltip"} />
                </div>
                <p className="send-roses-modal__info-text  ">
                  When you buy 500 more Roses
                </p>
              </SubText>
            </>
          )}
          {showSendMessage && (
            <InputMessage
              value={messageToSend}
              placeholder="Add a message..."
              onChangeInput={(e) => setMessageToSend(e)}
            />
          )}
          {showCards && (
            <DropDown
              placeholder="Select card"
              textButton={"Manage Payment Methods"}
              className="send-roses-modal__dropDown"
              dropType={"radio"}
              options={
                cardsMock
                  ? cardsMock?.map((card) => {
                      return {
                        groupName: "cards",
                        inputLabel: `${card.cardType} ${card.number.slice(-4)}`,
                        inputValue: `${
                          card.cardType
                        } Card ****${card.number.slice(-4)}`,
                      };
                    })
                  : []
              }
              setValueCallBack={function (option: string[]): void {}}
            />
          )}
        </section>

        <div className="send-roses__buttons-container">
          <Button
            buttonStyle={!goldenToggle ? "primary" : "golden"}
            onClick={() => {
              onClick?.();
              if (typeModal === "buy") {
                buyRoses();
              }
              rosesMessageCallback?.(Number(rosesToSend), messageToSend);
            }}
          >
            {buttonText}
          </Button>
          <Button
            buttonStyle="tertiary"
            onClick={() => {
              onClose?.();
              setOpenUseModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SendRosesModal;
