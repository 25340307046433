import React, { createContext, useContext, useState, ReactNode } from "react";

interface IconContextProps {
  activeIcon: string;
  setActiveIcon: (icon: string) => void;
  handleButtonClick: (icon: string, action?: () => void) => void;
  getIconClass: (icon: string) => string;
}

const IconContext = createContext<IconContextProps | undefined>(undefined);

export const IconProvider = ({ children }: { children: ReactNode }) => {
  const [activeIcon, setActiveIcon] = useState("grid");

  const handleButtonClick = (icon: string, action?: () => void) => {
    setActiveIcon(icon);
    if (action) {
      action();
    }
  };

  const getIconClass = (icon: string) =>
    `header-home__btn--inactive ${
      activeIcon === icon ? "header-home__btn--active" : ""
    }`;

  return (
    <IconContext.Provider
      value={{ activeIcon, setActiveIcon, handleButtonClick, getIconClass }}
    >
      {children}
    </IconContext.Provider>
  );
};

export const useIconContext = (): IconContextProps => {
  const context = useContext(IconContext);
  if (!context) {
    throw new Error("useIconContext must be used within an IconProvider");
  }
  return context;
};
