import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "components/Buttons/Button";
import RosesCredit from "components/RosesCredit";
import { dollarFormatSecondary } from "utils/dollarFormat";
import { PlanDuration } from "types/enum";
import Loading from "components/Loading";
import roses from "assets/icons/logos/logo-fill-pink.svg";
import rosesGold from "assets/icons/logos/logo-fill-gold.svg";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import PlanCard from "components/PlanCard";

interface IPlan {
  price: number;
  priceWithoutDiscount: number;
  discount: number;
  period: string;
  type: string;
  features: string[];
}

interface PlanProps {
  onClose?: () => void;
  onSubscribe: () => void;
  onSelectItem: (item: IPlan) => void;
  plans: IPlan[];
  children?: React.ReactNode;
  disabled: boolean;
  isLoading: boolean;
  type: string;
}

const Plan: React.FC<PlanProps> = ({
  onClose,
  onSubscribe,
  onSelectItem,
  plans,
  disabled,
  isLoading,
  type,
}) => {
  const [activeItem, setActiveItem] = useState<IPlan | null>(null);
  const { windowSize } = useWindowWidthSize();

  const handleClickItem = (item: IPlan, index: number) => {
    onSelectItem(item);
    setActiveItem(item);
  };

  useEffect(() => {
    if (plans.length > 1) {
      onSelectItem(plans[1]);
      setActiveItem(plans[1]);
    }
  }, [plans]);

  return (
    <div className="plan__container">
      {isLoading ? (
        <Loading className="loading" />
      ) : (
        <div className="plan-modal__content">
          <div className="plan-list">
            {plans.map((item, index) => {
              return (
                <PlanCard
                  key={index}
                  text={dollarFormatSecondary(String(item.price))}
                  value={item.period}
                  active={activeItem?.price === item.price}
                  onClick={() => handleClickItem(item, index)}
                  discount={item.discount}
                  originalPrice={dollarFormatSecondary(
                    item.priceWithoutDiscount
                  )}
                  headerText={item.type}
                  type={type === "CREATOR" ? "creator" : "consumer"}
                />
              );
            })}
          </div>
          <ul className="plan-features-list inner">
            <li className="plan-features-list-item">
              <img
                className="plan-features-list-logo"
                src={type === "CREATOR" ? rosesGold : roses}
                alt="logo"
              />
              <div className="plan-features-list-first-item">
                <div className="plan-features-list-first-line">
                  <span
                    className={`
                    plan-features-list-first-line-number
                    plan-features-list-first-line-number-${
                      type === "CREATOR" ? "creator" : "consumer"
                    }
                  `}
                  >
                    65
                  </span>
                  <p className="plan-features-list-first-line-text">
                    credits per month
                  </p>
                  <i
                    data-icon
                    className="plan-features-list-first-line-icon icon icon-md icon-info icon-gray"
                  ></i>
                </div>
                <p className="plan-features-list-first-item-subtext">
                  $1.09 per 10 credits
                </p>
              </div>
            </li>
            {activeItem?.features.map((feature, index) => (
              <li className="plan-features-list-item" key={index}>
                <i
                  data-icon
                  className={`
                    plan-features-list-item-icon
                    icon icon-md icon-check ${type === "CREATOR" && "icon-gold"}
                  `}
                ></i>
                <p className="plan-features-list-item-text">{feature}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="plan-modal-buttons-container">
        {windowSize > 768 && (
          <Button
            buttonStyle="quaternary"
            onClick={onClose}
            className="button-cancel"
          >
            Cancel
          </Button>
        )}
        <div className="plan-modal-buttons-container-right">
          <Button buttonStyle="tertiary" onClick={onClose}>
            CONTINUE FREE
          </Button>
          <Button
            buttonStyle={type === "CREATOR" ? "octonary" : "primary"}
            onClick={() => onSubscribe()}
            disabled={disabled || isLoading}
          >
            SUBSCRIBE MONTHLY
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Plan;
