import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Button from "components/Buttons/Button";
import ListItemToggle from "components/Lists/ListItemToggle";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";

import DeleteMembershipUserApi from "../../api/deleteMembershipApplicationAdmin";
import patchUserSelfDetail from "api/patchUserSelfDetail";

import { localStorageClear } from "utils/localStorageClear";

import ClockIconGray from "assets/icons/clock-icon-gray.svg";
import PrivacyIconGray from "assets/icons/privacy-icon-gray.svg";

const UserSettingsManageAccountPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { setComponent } = useLoginSignUpNavigation();
  const navigate = useNavigate();
  const {
    componentFeed,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const takeABreakHandler = async (e: any) => {
    const res = await patchUserSelfDetail(userInfo.access_token, {
      isActive: e,
    });
  };
  const blockRequestsHandler = async (e: any) => {
    const res = await patchUserSelfDetail(userInfo.access_token, {
      blockRequest: e,
    });
  };
  const deleteAccountHandler = async () => {
    const phone = userInfo.user_phone.replace("+", "");

    if (
      phone === "5511967700452" ||
      phone === "14074464664" ||
      phone === "13053704780" ||
      phone === "5545999640276" ||
      phone === "5511914013654" ||
      phone === "5519984484218"
    ) {
      try {
        const deleteResponse = await DeleteMembershipUserApi(phone);
        if (deleteResponse.status === 204) {
          localStorageClear();
          sessionStorage.clear();
          setPrincipalScreen("");
          setSecondaryScreen("");
          setTertiaryScreen("");
          setComponent("");
          navigate("/");
        } else {
          console.error(
            "Unexpected response from delete operation",
            deleteResponse
          );
        }
      } catch (error) {
        console.error("There was an error during the delete operation:", error);
      }
    }
  };

  return (
    <div
      className="user-settings-manage-account
		 		user-settings-manage-account-wrapper
		  	wrapper
				standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-manage-account-inner inner">
        <PageTitle
          title={"Manage Account"}
          subtitle={"Account Security"}
          hasCloseIcon={false}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
        />

        <div className="user-settings-configuration__options">
          <ListItemToggle
            toggleInitialState
            hasSeparator
            highlightText="Take a Break"
            simpleText="Deactivate my account for 7 days"
            isImageCircle
            imageLeft={ClockIconGray}
            toggleCallBack={takeABreakHandler}
          />

          <ListItemToggle
            toggleInitialState
            highlightText="Block Requests"
            simpleText="Prohibit rose requests"
            isImageCircle
            imageLeft={PrivacyIconGray}
            toggleCallBack={blockRequestsHandler}
          />
        </div>

        <Button buttonStyle="tertiary" onClick={deleteAccountHandler}>
          Delete Account
        </Button>
      </div>
    </div>
  );
};

export default UserSettingsManageAccountPage;
