import React from "react";
import "./styles.scss";

import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal";

import PersonalInformation from "pages/PersonalInformation";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useModal } from "../../hooks/useModal";

const BecomeCreator: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { windowSize } = useWindowWidthSize();
  const { setModalContent } = useModal();

  const breakPoint = windowSize >= 768;

  const handlerNextPage = () => {
    if (breakPoint) {
      setModalContent(<PersonalInformation />);
    } else {
      setPrincipalScreen("/personal-information");
    }
  };

  return (
    <div className="become-creator become-creator-wrapper wrapper standard-page-layout">
      <div className="become-creator-inner inner">
        <ButtonCloseModal />

        <Header
          headerIcon="roses-logo"
          title={"Become a creator"}
          subTitle={`Your creator’s account has been accepted. Complete your profile to start monetizing your content.`}
          hasIconGap
          marginTop={0}
          titleClass="become-creator__title"
          className="become-creator__header"
        />

        <Button buttonStyle="primary" onClick={handlerNextPage}>
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default BecomeCreator;
