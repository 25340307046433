import { ReactElement } from "react";
import { routes } from "../routes";
import { RouteObject } from "react-router-dom";

interface Route {
  path: string;
  element: ReactElement | null;
}

const extractRoutesArray = (): Route[] | undefined => {
  return routes[0]?.children?.map((route: RouteObject) => ({
    path: route.path || "",
    element: (route.element as ReactElement) || null,
  }));
};

export const getElementByPath = (path: string | null) => {
  try {
    if (!path) return null;
    const routes = extractRoutesArray();

    if (routes) {
      const route = routes?.find((route) => route.path === path);
      return route ? route.element : null;
    }
  } catch (error) {
    console.error(error);
  }
};
