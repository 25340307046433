import { servicesApi } from "../fetchApi/services-api";

const getSelfReferralCode = async (token: string) => {
  const apiRoute = `${servicesApi("creator_applications")}/get-referral-code`;

  const authorizationHeader = `Bearer ${token}`;

  try {
    const response = await fetch(apiRoute, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorizationHeader,
      },
    });

    const data = await response.json();
    return { res: data, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { res: null, status: null };
  }
};

export default getSelfReferralCode;
