import React, { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import Button from "components/Buttons/Button";
import InputText from "components/Inputs/InputText";
import ListItemLink from "components/Lists/ListItemLink";
import ListItemExtract from "components/Lists/ListItemExtract";
import Paragraph from "components/Texts/Paragraph";
import RevenueCard from "components/Revenue/RevenueCard";
import Tooltip from "components/Tooltip";

import { useIconContext } from "contexts/iconNavContext";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";

import { IButtonStyles } from "types/buttonStyles";

import { formatDateShort } from "utils/formatDateShort";
import { textCapitalize } from "utils/textCapitalize";
import { thousandsFormat } from "utils/thousandsFormat";

import checkIcon from "assets/icons/check-simple-pink.svg";
import chevronRightIcon from "assets/icons/navigationIcons/bx-chevron-right.svg";

const UserSettingsReferralsPage = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen, params } =
    useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { windowSize } = useWindowWidthSize();

  const [activeSection, setActiveSection] = useState<string>(
    params.btn ? params.btn : "button1"
  );
  const [activatedButton, setActivatedButton] = useState(
    params.id ? Number(params.id) : 1
  );
  const [search, setSearch] = useState("");

  const pageBack = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings-configuration");
    } else {
      setPrincipalScreen("/user-settings-configuration");
    }

    handleButtonClick("grid");
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    { text: "Activity", clickAction: () => setActiveSection("button1"), id: 1 },
    { text: "Payout", clickAction: () => setActiveSection("button2"), id: 2 },
  ];

  interface IBoxSection {
    title?: string;
    desc?: string;
    tooltip?: string;
    className?: string;
    customButton?: {
      title: string;
      styles: IButtonStyles;
      onClick?: () => void;
    }[];
    onClick?: () => void;
  }

  const BoxSection = (props: IBoxSection) => {
    const { title, desc, tooltip, className, customButton, onClick } = props;

    return (
      <div className={`box-section ${className ?? ""}`}>
        <div className="box-section__top">
          {title && <h2 className="box-section__title">{title}</h2>}
          {desc && (
            <p className="box-section__text">
              {desc} {tooltip && <Tooltip text={tooltip} />}
            </p>
          )}
        </div>
        {onClick && (
          <Button
            children={"Copy Referral Link"}
            buttonStyle={"tertiary"}
            onClick={onClick}
          />
        )}

        {customButton &&
          customButton.map((button, index) => (
            <Button
              key={`box-section-btn-${index}`}
              children={button.title}
              buttonStyle={button.styles ?? "primary"}
              onClick={button.onClick}
            />
          ))}
      </div>
    );
  };

  const ListSection = () => {
    return (
      <div className="list-section">
        <div className="list-section__top">
          <h2 className="list-section__title">How it works?</h2>
          <p className="list-section__text">
            Invite creators to join Roses using your personal link. When they
            start earning, you’ll receive 10% of Roses’ share (which is{" "}
            <span className="list-section__text--highlight">
              2% of their earnings
            </span>
            ) — forever.
          </p>
        </div>
        <div>
          {listItemsMock.map((item, index, arr) => {
            const { icon, title, desc } = item;

            return (
              <ListItemLink
                key={`list-section__item--${index}`}
                classImageCircle="list-section__icon"
                className="list-section__item"
                imageLeft={icon}
                highlightText={title}
                simpleText={desc}
                hasSeparator={arr.length > index + 1}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const listItemsMock = [
    {
      icon: checkIcon,
      title: "Invite Creators",
      desc: "Share your referral link.",
    },
    {
      icon: checkIcon,
      title: "Creators Join",
      desc: "They sign up and create content.",
    },
    {
      icon: checkIcon,
      title: (
        <>
          Earn <span className="list-section__text--highlight">2% forever</span>
        </>
      ),
      desc: "Get a percentage of their earnings.",
    },
  ];

  const listRevenue = [
    {
      title: "This month",
      value: "345",
    },
    {
      title: "All time",
      value: "842",
    },
  ];

  const listExtractMock = [
    {
      userName: "Scarlet",
      date: "March 3rd, 2025 21:16",
      value: 500,
    },
    {
      userName: "Marie",
      date: "January 12th, 2025 21:16",
      value: 10,
    },
    {
      userName: "Viki",
      date: "January 14th, 2025 21:16",
      value: 500,
    },
    {
      userName: "Mia",
      date: "August 13th, 2025 21:16",
      value: 120,
    },
  ];

  return (
    <div
      className="user-settings-referrals
		 		user-settings-referrals-wrapper
		  	wrapper
				standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-referrals-inner inner">
        <PageTitle
          title={"Referrals"}
          subtitle={"Invite Creators & Earn More"}
          hasCloseIcon={windowSize >= 768}
          onBackClick={pageBack}
        />

        <div className="user-settings-referrals__main">
          <ButtonNavigation
            activatedButton={Number(activatedButton)}
            buttons={buttonsGroup}
            className="action-wrapper__buttons-navigation"
          />

          {activeSection === "button1" && (
            <section className="user-settings-referrals__section">
              <BoxSection
                title="24"
                desc="Referrals"
                tooltip="Tooltip"
                onClick={() => console.log("Olá")}
              />

              <ListSection />
            </section>
          )}

          {activeSection === "button2" && (
            <section className="user-settings-referrals__section">
              <div>
                <BoxSection
                  title="345"
                  desc="Total Earnings"
                  tooltip="Tooltip"
                />

                <div className="user-settings-referrals__revenues">
                  {listRevenue.map((item, index) => {
                    const { title, value } = item;

                    return (
                      <RevenueCard
                        key={`revenue-card--${index}`}
                        title={title}
                        value={value}
                      />
                    );
                  })}
                </div>
              </div>

              <Paragraph
                children={
                  "View all users who signed up through your referral link, including those who have started earning."
                }
              />

              <div>
                <InputText
                  value={search}
                  placeholder="Search"
                  onChangeInput={(text) => {
                    setSearch(text);
                  }}
                  searchInput
                  inputType="text"
                />

                <div className="user-settings-referrals__extracts">
                  {listExtractMock &&
                    listExtractMock.map((extract, index) => {
                      const { userName, date, value } = extract;

                      return (
                        <ListItemExtract
                          // ref={(el) => (listExtractsRef.current[index] = el)}
                          key={index}
                          title={userName}
                          // description={date}
                          date={{
                            long: date,
                            short: date,
                          }}
                          amount={thousandsFormat(value)}
                          isExtract
                          iconRight={chevronRightIcon}
                          showAvatar={true}
                          onClick={() => {
                            // if (componentFeed) {
                            //   setSecondaryScreen(
                            //     "/user-settings-extract-details",
                            //     {
                            //       transactionId: extract.transactionId,
                            //     }
                            //   );
                            // } else {
                            //   setPrincipalScreen(
                            //     "/user-settings-extract-details",
                            //     {
                            //       transactionId: extract.transactionId,
                            //     }
                            //   );
                            // }
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSettingsReferralsPage;
