import React, { useEffect } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";

import rosesLogo from "assets/icons/logos/roses-modal-logo.png";
import sphereIconPink from "assets/icons/sphere-item-icon-pink.svg";
import saveIconWhite from "assets/icons/save-icon-white.svg";
import { handleInstallClick, setupPWAInstall } from "../../../utils/PWA";
import { useWindowWidthSize } from "../../../hooks/useWindowWidthSize";
import { useModal } from "hooks/useModal";

interface ModalPwaProps {}

const ModalPwa: React.FC<ModalPwaProps> = (props) => {
  const { setOpenUseModal, setModalContent } = useModal();

  const { windowSize } = useWindowWidthSize();

  const handleClose = () => {
    setOpenUseModal(false);
  };

  useEffect(() => {
    setupPWAInstall();
  }, []);

  return (
    <div id="modal-pwa">
      <div className="modal-content">
        <div className="logo-wrapper">
          <img src={rosesLogo} alt="Roses Logo" />
          <h5 className="logo-wrapper__logo">
            ROSES<sup>™</sup>
          </h5>
        </div>
        <p className="modal-content__explanation-text">
          To add this web app to the home screen:
        </p>

        <section className="list-wrapper__wrapper">
          {windowSize >= 768 ? (
            <>
              <div className="list-wrapper">
                <img className="sphere-icon" src={sphereIconPink} alt="" />
                <p className="">
                  Open{" "}
                  <span className="text-highlight--white">
                    https://roses.vip
                  </span>
                  <br /> on your mobile browser
                </p>
              </div>
              <hr />
            </>
          ) : (
            false
          )}
          <div className="list-wrapper">
            <img src={sphereIconPink} alt="" />
            <p className="">Tap the button:</p>
            <img className="save-icon" src={saveIconWhite} alt="" />
          </div>
          <hr />
          <div className="list-wrapper">
            <img src={sphereIconPink} alt="" />
            <p className="">
              Then select{" "}
              <span className="text-highlight--white">
                "Add to Home Screen"
              </span>
            </p>
          </div>
        </section>
        <section className="modal-content__buttons-container">
          <Button
            children={"Add to Home Screen"}
            buttonStyle={"primary"}
            onClick={handleInstallClick}
          />
          {/* <button id="installButton" onClick={handleInstallClick}>
            Add to Home Screen***
          </button> */}
          <Button
            buttonStyle="quaternary"
            onClick={handleClose}
            children={"Close"}
          />
        </section>
      </div>
    </div>
  );
};

export default ModalPwa;
