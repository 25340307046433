import "./styles.scss";

import PageTitle from "components/PageTitles";
import ListItem from "components/Lists/ListItem";
import SubText from "components/Texts/SubText";

import blockGrayIcon from "assets/icons/privacy-icon-gray.svg";
import chevronRigntIcon from "assets/icons/navigationIcons/bx-chevron-right.svg";
import closePeachIcon from "assets/icons/closeIcons/close-icon-peach.svg";

const UserSettingsPersonalSafetyPage: React.FC = () => {
  return (
    <div className="user-settings-personal-safety user-settings-personal-safety-wrapper wrapper">
      <div className="user-settings-personal-safety-inner inner">
        <div className="user-settings-personal-safety__list-container">
          <PageTitle
            title={"Personal Safety"}
            subtitle={"Emergency Response"}
          />
          <SubText
            children={`Emergency Assistance lets you choose contacts for
						 emergencies. Contacts are notified only if an emergency occurs,
						 receiving your last location and activity details for prompt
						 help.`}
          />
          <ListItem
            showLabel={true}
            labelContent="André Toti"
            showDescription={true}
            descriptionContent="+55(11)967700452"
            showIconLeft={true}
            iconLeftContent={closePeachIcon}
            showIconRight={true}
            iconRightContent={closePeachIcon}
            showSeparator={true}
          />
          <ListItem
            showLabel={true}
            labelContent="André Toti"
            showDescription={true}
            descriptionContent="+55(11)967700452"
            showIconLeft={true}
            iconLeftContent={closePeachIcon}
            showIconRight={true}
            iconRightContent={closePeachIcon}
            showSeparator={true}
          />
          <ListItem
            showLabel={true}
            labelContent="André Toti"
            showDescription={true}
            descriptionContent="+55(11)967700452"
            showIconLeft={true}
            iconLeftContent={closePeachIcon}
            showIconRight={true}
            iconRightContent={closePeachIcon}
            showSeparator={true}
          />
          <ListItem
            showValue={true}
            valueContent={"Add New Contact"}
            showIconRight={true}
            iconRightContent={chevronRigntIcon}
          />
        </div>
        <div className="list-container">
          <ListItem
            showIconLeft={true}
            iconLeftContent={blockGrayIcon}
            showIconBorderLeft={true}
            showLabel={true}
            labelContent="Security PIN"
            showDescription={true}
            descriptionContent="Setup your security code"
            showIconRight={true}
            iconRightContent={chevronRigntIcon}
            showSeparator={true}
          />
          <ListItem
            showIconLeft={true}
            iconLeftContent={blockGrayIcon}
            showIconBorderLeft={true}
            showLabel={true}
            labelContent="My Location"
            showDescription={true}
            descriptionContent="Shares my last location"
            showToggle={true}
            showSeparator={true}
          />
          <ListItem
            showIconLeft={true}
            iconLeftContent={blockGrayIcon}
            showIconBorderLeft={true}
            showLabel={true}
            labelContent="Message Notification"
            showDescription={true}
            descriptionContent="Send WhatsApp Message"
            showToggle={true}
            showSeparator={true}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSettingsPersonalSafetyPage;
