import { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Button from "components/Buttons/Button";
import InputRadioSub from "components/Inputs/InputRadioSub";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";

import infoIcon from "assets/icons/union-circle-gray.svg";

const UserSettingsSubscriptionPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();

  const [chosenType, setChosenType] = useState("bloom");

  const handleOptionChange = (value: string) => {
    setChosenType(value);
  };

  const subscriptionOptions = [
    {
      title: "Seed Basic",
      price: "FREE",
      description:
        "Your starting point, where new connections begin to sprout.",
      value: "seed",
      benefits: [
        {
          id: 0,
          benefit: "Access to local profiles",
        },
        {
          id: 1,
          benefit: "Basic messaging functionality",
        },
        {
          id: 2,
          benefit: "Standard privacy settings",
        },
      ],
    },
    {
      title: "Bloom Global",
      price: "$259",
      description:
        "Enjoy all features from Seed Basic, plus connect with people from all over the world.",
      value: "bloom",
      benefits: [
        {
          id: 0,
          benefit: "All features of Seed Basic",
        },
        {
          id: 1,
          benefit: "Ability to change location",
        },
        {
          id: 2,
          benefit: "Access to global profiles",
        },
        {
          id: 3,
          benefit: "Enhanced messaging capabilities",
        },
        {
          id: 4,
          benefit: "Advanced privacy options",
        },
      ],
    },
    {
      title: "Roses VIP",
      price: "$495",
      description:
        "Enjoy all features from Bloom Global, plus exclusive VIP perks.",
      value: "roses",
      benefits: [
        {
          id: 0,
          benefit: "All features of Bloom Global",
        },
        {
          id: 1,
          benefit: "VIP status with profile highlighting",
        },
        {
          id: 2,
          benefit: "Exclusive access to top-tier profiles",
        },
        {
          id: 3,
          benefit: "First access to new features and updates",
        },
        {
          id: 4,
          benefit: "Personalized matchmaking assistance",
        },
      ],
    },
  ];

  return (
    <div className="user-settings-subscription user-settings-subscription-wrapper wrapper">
      <div className="user-settings-subscription-inner inner">
        <PageTitle
          title={"Subscription"}
          subtitle={"Manage Plans"}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
        />
        <div className="user-settings-subscription__roses-container ">
          {subscriptionOptions.map((option) => (
            <InputRadioSub
              title={option.title}
              price={option.price}
              description={option.description}
              value={option.value}
              icon={infoIcon}
              groupName={"options"}
              checked={option.value === chosenType}
              onChange={handleOptionChange}
              benefits={option.benefits}
            />
          ))}
          <div>
            <Button buttonStyle="primary" onClick={() => {}} className="">
              Subscribe
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsSubscriptionPage;
