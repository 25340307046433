import { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { format } from "date-fns";

import PageTitle from "components/PageTitles";
import ListItemLink from "components/Lists/ListItemLink";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";

import getSubscribers from "api/subscribe/getSubscribers";

import { useUserInfo } from "hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useToast } from "hooks/useToast";

import FilterIcon from "assets/icons/iconfilter.svg";
import ProfileIcon from "assets/icons/profile-icon-gray.svg";

type SubscriptionStatus = "ACTIVE" | "PAST_DUE" | "CANCELED";

type ConsumerSubscriptionsParams = {
  take?: string;
  cursor?: string;
  status?: SubscriptionStatus[];
  isActive?: boolean;
  nameSearch?: string;
};

interface ISubscriptions {
  creatorId: string;
  subscriptionId: string;
  displayName: string;
  photo: string;
  subscriptionExpirationDate: Date;
  subscriptionStatus: SubscriptionStatus;
}

interface IListItem {
  id: string;
  subscriptionId: string;
  labelContent: string;
  imageLeft: string;
  simpleText: string;
  dangerSimpleText: boolean;
  clickAction: () => void;
}

const UserSettingsMySubscribersPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { userInfo } = useUserInfo();
  const { showToast } = useToast();

  const [activeList, setActiveList] = useState<IListItem[]>([]);
  const [hasNextActive, setHasNextActive] = useState(false);
  const [pastList, setPastList] = useState<IListItem[]>([]);
  const [hasNextPast, setHasNextPast] = useState(false);
  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const getSubscriptions = useCallback(
    async (params?: ConsumerSubscriptionsParams) => {
      const { res, status } = await getSubscribers(
        userInfo.access_token,
        params
      );
      if (status === 200) {
        const items: IListItem[] = res.followers.map(
          (value: ISubscriptions) => ({
            id: value.subscriptionId,
            labelContent: value.displayName,
            imageLeft: value.photo,
            dangerSimpleText: value.subscriptionStatus === "PAST_DUE",
          })
        );
        return items;
      }
    },
    [userInfo.access_token]
  );

  const getActiveList = useCallback(
    async (nameSearch?: string) => {
      const itemsPerPage = 10;

      const activeItems = await getSubscriptions({
        status: ["ACTIVE"],
        nameSearch,
      });
      if (activeItems) {
        setActiveList(activeItems);
        setHasNextActive(activeItems.length === itemsPerPage);
      }
    },
    [getSubscriptions]
  );

  const getPastList = useCallback(
    async (nameSearch?: string) => {
      const itemsPerPage = 10;
      const pastItems = await getSubscriptions({
        status: ["CANCELED", "PAST_DUE"],
        nameSearch,
      });
      if (pastItems) {
        setPastList(pastItems);
        setHasNextPast(pastItems.length === itemsPerPage);
      }
    },
    [getSubscriptions]
  );

  const searchCreator = async () => {
    if (!searchValue) {
      await getActiveList();
      await getPastList();
      return;
    }

    await getActiveList(searchValue);
    await getPastList(searchValue);
  };

  const getNextActive = async () => {
    setNextIsLoading(true);
    try {
      const itemsPerPage = 10;

      const activeItems = await getSubscriptions({
        status: ["ACTIVE"],
        cursor: activeList[activeList.length - 1].id,
      });
      if (activeItems) {
        setActiveList([...activeList, ...activeItems]);
        setHasNextActive(activeList.length === itemsPerPage);
      }
    } catch (error) {
      console.error("getNextActive error: ", error);
    } finally {
      setNextIsLoading(false);
    }
  };

  const getNextPast = async () => {
    setNextIsLoading(true);
    try {
      const itemsPerPage = 10;

      const pastItems = await getSubscriptions({
        status: ["CANCELED", "PAST_DUE"],
        cursor: pastList[pastList.length - 1].id,
      });
      if (pastItems) {
        setPastList(pastItems);
        setHasNextPast(pastItems.length === itemsPerPage);
      }
    } catch (error) {
      console.error("getNextPast error: ", error);
    } finally {
      setNextIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveList();
    getPastList();
  }, []);

  return (
    <div className="user-settings-my-subscribers user-settings-my-subscribers-wrapper wrapper standard-page-layout">
      <div className="user-settings-my-subscribers-inner inner">
        <PageTitle
          title={"My Subscribers"}
          subtitle={"Subscribed profiles"}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
          hasCloseIcon={false}
        />
        <div className="user-settings-my-subscribers_list  ">
          <InputText
            value={searchValue}
            searchInput
            onClick={searchCreator}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchCreator();
              }
            }}
            onChangeInput={(e) => {
              setSearchValue(e);
            }}
            placeholder="Search"
            inputStyle="input-text-search"
            iconRight={FilterIcon}
            inputType="text"
          />
          <section>
            <div className="user-settings-my-subscribers__list-header">
              <p className="user-settings-my-subscribers__list-text">
                Active Subscribers
              </p>
            </div>
            {activeList.map((item, index, arr) => (
              <ListItemLink
                key={index}
                hasSeparator={arr.length > index + 1}
                highlightText={item.labelContent}
                simpleText={"Renew: 11/10/2025"}
                isImageCircle
                iconFill
                imageLeft={item.imageLeft || ProfileIcon}
                hasArrowRight
                onClick={() => {
                  showToast({
                    title: "Info",
                    type: "info",
                    description: "Feature coming soon!",
                  });
                }}
              />
            ))}
            {hasNextActive && (
              <Button
                buttonStyle="quaternary"
                onClick={getNextActive}
                disabled={nextIsLoading}
              >
                SHOW MORE
              </Button>
            )}
          </section>
          <section>
            <div className="user-settings-my-subscribers__list-header">
              <p className="user-settings-my-subscribers__list-text">
                Past Subscribers
              </p>
            </div>
            {pastList.map((item, index, arr) => (
              <ListItemLink
                key={index}
                hasSeparator={arr.length > index + 1}
                highlightText={item.labelContent}
                simpleText={"Cancelled"}
                isImageCircle
                iconFill
                imageLeft={item.imageLeft || ProfileIcon}
                hasArrowRight
                onClick={() => {
                  showToast({
                    title: "Info",
                    type: "info",
                    description: "Feature coming soon!",
                  });
                }}
              />
            ))}
            {hasNextPast && (
              <Button
                buttonStyle="quaternary"
                onClick={getNextPast}
                disabled={nextIsLoading}
              >
                SHOW MORE
              </Button>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsMySubscribersPage;
