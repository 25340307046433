import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import SubText from "components/Texts/SubText";
import PhotosPlaceholder from "components/PhotosPlaceholder";
import ListItemToggle from "../../components/Lists/ListItemToggle";
import Button from "components/Buttons/Button";

import { ProfileContext } from "contexts/profileContext";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";

import getUserInfo from "../../api/userInfo/getUserInfo";
import getUserPreferences from "../../api/userPreferences/getUserPreferences";
import postUploadProfilePhotos from "../../api/profileDetails/postUploadProfilePhotos";
import deleteProfilePhotos from "../../api/profileDetails/deleteprofilePhotos";
import patchUserConfidentiality from "../../api/userPhotos/patchUserConfidentiality";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import postUploadProfileVideo from "../../api/profileDetails/postUploadProfileVideo";
import deleteProfileVideo from "../../api/profileDetails/deleteProfileVideo";
import patchProfilePhoto from "../../api/profileDetails/patchProfilePhoto";
import postUploadProfileBanner from "api/profileDetails/postUploadProfileBanner";
import deleteProfileBanner from "api/profileDetails/deleteProfileBanner";

import disclaimerIcon from "assets/icons/union-gray.svg";

const UserSettingsProfilePhotoPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen, params } =
    useDeskNavigation();
  const { userInfo } = useUserInfo();
  const { userInfoRequest, setUserInfoRequest } = useContext(ProfileContext);

  const [activeSection, setActiveSection] = useState<string>("button1");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>([]);
  const [coverVideo, setCoverVideo] = useState<string>();
  const [banner, setBanner] = useState<string>();
  const [toggleConfidentiality, setToggleConfidentiality] = useState();
  const [toggleRosesRequest, setToggleRosesRequest] = useState();
  const [isVerify, setIsVerify] = useState(false);
  const [circleSelect, setCircleSelect] = useState<string>();
  const pageRef = useRef<HTMLDivElement | null>(null);

  const requestUserInfo = useCallback(async () => {
    const response = await getUserInfo(userInfo.access_token);

    setCoverVideo(response.res?.cover?.url);
    setBanner(response.res?.banner?.url);

    if (response?.status === 200) {
      setUserInfoRequest(response?.res);
    }
    setCircleSelect(response?.res?.feedHexColor);
  }, [setUserInfoRequest, userInfo.access_token]);

  const preserveConfidentiallyHandler = async (event: boolean) => {
    const res = await patchUserConfidentiality(userInfo.access_token, {
      confidentiality: event,
    });

    requestUserInfo();
  };

  const smartPhotoHandler = (event: boolean) => {};

  const addPhotoHandler = async (files: (File | null)[]): Promise<void> => {
    setPhotosAlbum(files);
    const imageToUpload = files.filter(
      (file, index) =>
        file !== null && !file.name.endsWith(".mp4") && index !== 0
    );

    const videoToUpload = files.find(
      (file) => file !== null && file.name.endsWith(".mp4")
    );

    const bannerToUpload = files.find(
      (file, index) => file !== null && index === 0
    );

    const photo = imageToUpload[imageToUpload.length - 1];

    if (photo) {
      await postUploadProfilePhotos(userInfo.access_token, photo);
    }

    if (videoToUpload) {
      await postUploadProfileVideo(userInfo.access_token, videoToUpload);
    }

    if (bannerToUpload) {
      await postUploadProfileBanner(userInfo.access_token, bannerToUpload);
    }

    requestUserInfo();
  };

  const removePhotoHandler = async (id: string) => {
    await deleteProfilePhotos(userInfo.access_token, id);
    requestUserInfo();
  };

  const removeVideoHandler = async (id: string) => {
    await deleteProfileVideo(userInfo.access_token, id);
    requestUserInfo();
  };

  const removeBannerHandler = async () => {
    await deleteProfileBanner(userInfo.access_token);
    requestUserInfo();
  };

  const updatePhotoHandler = async (file: {
    fileId: string;
    position: number;
  }) => {
    const res = await patchProfilePhoto(
      userInfo.access_token,
      file.fileId,
      file.position
    );

    if (res?.status === 200) {
      requestUserInfo();
    }
  };

  // -------

  const getUserSelfDetailInit = async () => {
    try {
      const res = await getUserSelfDetail(userInfo.access_token);
      setToggleConfidentiality(res.res.confidentiality);
      setToggleRosesRequest(res.res.blockRequest);
      setIsVerify(res.res.isVerified);
    } catch (error) {
      console.error("Erro ao buscar detalhes do usuário:", error);
    }
  };

  useEffect(() => {
    const requestPreferences = async () => {
      const response = await getUserPreferences(userInfo.access_token);
    };

    requestPreferences();
    getUserSelfDetailInit();
    //requestUserInfo();
  }, [userInfo]);

  return (
    <>
      <div
        ref={pageRef}
        className="user-settings-profile-photo
					user-settings-profile-photo-wrapper
					wrapper
					standard-page-layout"
      >
        <div className="user-settings-profile-photo-inner inner">
          <PageTitle
            title={"Photos"}
            subtitle={"0 de 9 added"}
            onBackClick={() => {
              if (componentFeed) {
                setSecondaryScreen("/user-settings-profile-customize", {
                  btn: "",
                });
              } else {
                setPrincipalScreen("/user-settings-profile-customize", {
                  btn: "",
                });
              }
            }}
            hasCloseIcon={false}
          />

          {activeSection === "button1" && (
            <section
              className="
								user-settings-profile-customize__main
								bottom-spacing"
            >
              <div>
                <div className="bar-progress">
                  <div className="progress"></div>
                  <div className="progress "></div>
                  <div className="progress "></div>
                  <div className="progress none"></div>
                  <div className="progress none"></div>
                  <div className="progress none"></div>
                </div>

                <div className="user-settings-photo-container ">
                  <PhotosPlaceholder
                    updatePhotoCallback={updatePhotoHandler}
                    quantityFiles={10}
                    photoAlbumCallback={addPhotoHandler}
                    images={userInfoRequest?.photos}
                    video={userInfoRequest?.cover?.url}
                    banner={userInfoRequest?.banner?.url}
                    removeFileCallback={removePhotoHandler}
                    removeVideo={() =>
                      removeVideoHandler(
                        userInfoRequest?.cover?.url
                          ? userInfoRequest?.cover?.url
                          : ""
                      )
                    }
                    removeBanner={removeBannerHandler}
                    bannerColor={userInfoRequest?.feedHexColor}
                  />
                  <p className="album-photos-quantity  ">
                    {(userInfoRequest?.cover ? 1 : 0) +
                      (userInfoRequest?.banner?.url ? 1 : 0) +
                      (userInfoRequest?.photos?.length || 0)}
                    / 10 photos
                  </p>
                </div>

                <ListItemToggle
                  toggleCallBack={preserveConfidentiallyHandler}
                  hasSeparator
                  highlightText="Preserve my Confidentiality"
                  imageLeft={disclaimerIcon}
                  isImageCircle
                  simpleText="Conceal my facial identity"
                  toggleInitialState={toggleConfidentiality}
                />

                <ListItemToggle
                  toggleCallBack={smartPhotoHandler}
                  hasSeparator
                  highlightText="Smart Photo"
                  imageLeft={disclaimerIcon}
                  isImageCircle
                  simpleText="Chooses best photo for you"
                />
                <SubText
                  children={`We welcome sensual expression that's artistic and 
               sophisticated. Please avoid explicit content to maintain the 
               respectful and tasteful spirit of our community.`}
                  icon={disclaimerIcon}
                />
              </div>

              <div className="user-settings-profile-customize__footer">
                <Button
                  onClick={() => {
                    setPrincipalScreen("/user-settings-profile-customize");
                  }}
                  buttonStyle="tertiary"
                >
                  SKIP
                </Button>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default UserSettingsProfilePhotoPage;
