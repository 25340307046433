import { useEffect, useState } from "react";

import "./styles.scss";

import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import ListItem from "components/Lists/ListItem";

import { useUserInfo } from "hooks/userInfo";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useToast } from "hooks/useToast";

import getSelfReferralCode from "api/creatorApplications/getSelfReferralCode";
import getReferralList from "api/creatorApplications/getReferralList";
import postAddReferralCode from "api/creatorApplications/postAddReferralCode";

import CheckIconGreen from "assets/icons/check-simple-green.svg";
import WaitListApplicationPage from "pages/WaitListApplication";
import { useModal } from "hooks/useModal";

const AddReferralsPage: React.FC = () => {
  const [referral, setReferral] = useState("");
  const [myReferralCode, setMyReferralCode] = useState("");
  const [referralList, setReferralList] = useState([] as any[]);
  const [isReferralInputWarning, setIsReferralInputWarning] = useState(false);

  const { userInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();
  const { setModalContent } = useModal();
  const { showToast } = useToast();

  const { setPrincipalScreen } = useDeskNavigation();

  const breakPoint = windowSize >= 768;

  useEffect(() => {
    const fetchReferralCode = async () => {
      const response = await getSelfReferralCode(userInfo.access_token);
      setMyReferralCode(response?.res.referralCode);
    };

    fetchReferralCode();
    fetchReferralList();
  }, []);

  const fetchReferralList = async () => {
    const response = await getReferralList(userInfo.access_token);
    setReferralList(response?.res.data);
  };

  const useCopyCode = () => {
    navigator.clipboard.writeText(myReferralCode);

    showToast({
      title: "Copy",
      type: "success",
      description: "Code copied to clipboard.",
    });
  };

  const useAddReferral = async () => {
    const response = await postAddReferralCode(userInfo.access_token, referral);
    switch (response?.status) {
      case 200:
        showToast({
          title: "Referral Added",
          type: "success",
          description: "Referral added with success!",
        });

        break;

      default:
        showToast({
          title: "Something went wrong!",
          type: "error",
          description: "Please, check th referral code and try again",
        });

        setIsReferralInputWarning(true);

        setTimeout(() => {
          setIsReferralInputWarning(false);
        }, 4000);
        break;
    }
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setModalContent(<WaitListApplicationPage />);
    } else {
      setPrincipalScreen("/wait-list-application");
    }
  };

  return (
    <div className="add-referrals add-referrals-wrapper wrapper standard-page-layout">
      <div className="add-referrals-inner inner">
        <Header
          title="Add referrals"
          subTitle={
            <>
              Enter a referral code from a shared link or invite friends to join
              Roses using your referral code:{" "}
              <span className="add-referrals__code-text">
                {myReferralCode}
              </span>{" "}
            </>
          }
          marginTop={0}
          backButtonClick={handlerBackPage}
          {...(breakPoint
            ? {
                headerIcon: "back-button",
                inlineElements: true,
                titleClass: "header",
              }
            : {
                titleClass: "add-referrals__title",
              })}
        />
        <main className="add-referrals__main">
          <InputText
            value={referral}
            placeholder="Enter referral code"
            onChangeInput={(e) => setReferral(e)}
            isWarning={isReferralInputWarning}
            inputType="text"
          />

          {referralList?.map((referral, index, arr) => (
            <ListItem
              key={index}
              showLabel={true}
              labelContent={referral.displayName}
              showValue
              valueContent={referral.referralCode}
              showIconLeft
              iconLeftContent={CheckIconGreen}
              showSeparator={arr.length > index + 1}
            />
          ))}
        </main>
        <div className="add-referrals__buttons-container">
          <Button buttonStyle="primary" onClick={useAddReferral}>
            Add Referral
          </Button>
          <Button buttonStyle="tertiary" onClick={useCopyCode}>
            Copy Code
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddReferralsPage;
