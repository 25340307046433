import { useState } from "react";
import "./styles.scss";

import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import PageTitle from "components/PageTitles";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

import ChangePassword from "../../api/user/changePassword";

import LockIcon from "assets/icons/lock-icon-gray.svg";
import CheckCircleGreen from "assets/icons/check-circle-green.svg";

const UserSettingsChangePasswordPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { userInfo } = useUserInfo();
  const { showToast } = useToast();

  const [userCurrentPassword, setUserCurrentPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userNewPasswordConfirm, setUserNewPasswordConfirm] = useState("");
  const [newPasswordWarning, setNewPasswordWarning] = useState(false);
  const [confirmNewPasswordWarning, setConfirmNewPasswordWarning] =
    useState(false);
  const [currentPasswordWarning, setCurrentPasswordWarning] = useState(false);

  const passwordsMatch =
    userNewPassword === userNewPasswordConfirm && userNewPassword !== "";

  const resetPasswordSubmit = async () => {
    if (!userCurrentPassword) {
      showToast({
        description: "Check current password, please try again",
        title: "Reset password",
        type: "password",
      });

      setCurrentPasswordWarning(true);

      setTimeout(() => {
        setCurrentPasswordWarning(false);
      }, 3000);
    }
    if (!userNewPassword) {
      showToast({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "password",
      });

      setNewPasswordWarning(true);
      setTimeout(() => {
        setNewPasswordWarning(false);
      }, 3000);
    }
    if (!userNewPasswordConfirm) {
      showToast({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "password",
      });

      setConfirmNewPasswordWarning(true);

      setTimeout(() => {
        setConfirmNewPasswordWarning(false);
      }, 3000);
    }
    if (
      userNewPassword === userNewPasswordConfirm &&
      userNewPassword.length >= 8
    ) {
      if (
        userNewPassword !== "" &&
        userNewPasswordConfirm !== "" &&
        userCurrentPassword
      ) {
        const update = await ChangePassword(userInfo.access_token, {
          oldPassword: userCurrentPassword,
          newPassword: userNewPassword,
        });

        if (update?.status === 200) {
          showToast({
            description: "Success changing passwords",
            title: "Reset password",
            type: "success",
          });
        } else {
          showToast({
            description:
              "Server error changing password or incorrect passwords, please try again later",
            title: "Fail reset password",
            type: "info",
          });
        }
      }
    } else {
      showToast({
        description:
          "Your password should be at least 8 characters long and equal to.",
        title: "Reset password",
        type: "password",
      });

      setNewPasswordWarning(true);
      setConfirmNewPasswordWarning(true);

      setTimeout(() => {
        setNewPasswordWarning(false);
        setConfirmNewPasswordWarning(false);
      }, 3000);
    }
  };

  const cancelSubmit = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings-configuration");
    } else {
      setPrincipalScreen("/user-settings-configuration");
    }
  };

  return (
    <div
      className="user-settings-change-password
		 		user-settings-change-password-wrapper
		  	wrapper
				standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-change-password-inner inner">
        <PageTitle
          title={"Password & security"}
          subtitle={"Account Security"}
          hasCloseIcon={false}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
        />
        <div className="change-password-container">
          <SubText fontSize="14px">
            Your password should be at least <br />8 characters long
          </SubText>
          <InputText
            value={userCurrentPassword}
            placeholder="Current Password"
            onChangeInput={(e) => setUserCurrentPassword(e)}
            inputType="password"
            isWarning={currentPasswordWarning}
          />
          <div className="separator" />
          <div className="password-container">
            <InputText
              value={userNewPassword}
              placeholder="New Password"
              inputType="password"
              isWarning={newPasswordWarning}
              iconRight={passwordsMatch ? CheckCircleGreen : ""}
              hiddenEye={passwordsMatch}
              onChangeInput={(e) => setUserNewPassword(e)}
            />
            <InputText
              value={userNewPasswordConfirm}
              placeholder="Confirm New Password"
              inputType="password"
              isWarning={confirmNewPasswordWarning}
              iconRight={passwordsMatch ? CheckCircleGreen : ""}
              hiddenEye={passwordsMatch}
              onChangeInput={(e) => setUserNewPasswordConfirm(e)}
            />
          </div>
          <SubText
            icon={LockIcon}
            altIcon="clock icon"
            classNameIcon="change-password__icon"
          >
            Never share your credentials with anyone.
          </SubText>{" "}
        </div>
        <div className="buttons-container  ">
          <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
            Reset Password
          </Button>
          <Button buttonStyle="quaternary" onClick={cancelSubmit}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UserSettingsChangePasswordPage;
