import React from "react";
import "./styles.scss";

import { useModal } from "hooks/useModal";

interface IButtonModalClose {
  className?: string;
}

const ButtonCloseModal: React.FC<IButtonModalClose> = (props) => {
  const { className } = props;

  const { setOpenUseModal } = useModal();

  return (
    <span
      onClick={() => setOpenUseModal(false)}
      className={`icon icon-close icon-gray-light
       icon-md button-close-modal ${className ?? ""}`}
    />
  );
};

export default ButtonCloseModal;
