import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import "./styles.scss";

import ButtonIcon from "../../Buttons/ButtonIcon";
import ChatSuggestion from "../../Chat/ChatSuggestion";

import { useChat } from "../../../contexts/openedChatContext";

interface ChatTextareaProps {
  onSendMessage?: (message: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onMessageChange?: (message: string) => void;
  hasEmptyMessage?: boolean;
  isButtonSendDisabled?: boolean;
  autoFocus?: boolean;
  children?: ReactNode;
  className?: string;
}

const useTextareaAutoResize = (
  ref: React.RefObject<HTMLTextAreaElement>,
  message: string
) => {
  useEffect(() => {
    const textarea = ref.current;
    if (textarea) {
      const minHeight = 50;
      textarea.style.height = `${minHeight}px`;
      const newHeight = Math.max(textarea.scrollHeight, minHeight);
      textarea.style.height = `${Math.min(newHeight, 100)}px`;
    }
  }, [message, ref]);
};

const ChatTextarea: React.FC<ChatTextareaProps> = (props) => {
  const {
    onSendMessage,
    onFocus,
    onBlur,
    onMessageChange,
    hasEmptyMessage = false,
    isButtonSendDisabled,
    autoFocus,
    children,
    className,
  } = props;

  const [message, setMessage] = useState<string>("");
  const { isFirstMessage } = useChat();

  const chatTextareaRef = useRef<HTMLTextAreaElement | null>(null);

  useTextareaAutoResize(chatTextareaRef, message);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
    onMessageChange?.(newMessage);
  };

  const handleSend = (overrideMessage?: string) => {
    const finalMessage = overrideMessage ?? message;

    if (!isButtonSendDisabled && (finalMessage.trim() || hasEmptyMessage)) {
      onSendMessage?.(finalMessage);
      setMessage("");
      onMessageChange?.("");
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <>
      {!message.trim() && isFirstMessage && (
        <ChatSuggestion
          onSendMessage={(suggestedMessage) => {
            handleSend(suggestedMessage);
          }}
        />
      )}
      <div className={`chat-textarea ${className ?? ""}`}>
        <div className={`chat-textarea__field `}>
          <textarea
            ref={chatTextareaRef}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder="Type a message"
            className={`chat-textarea__textarea ${message ? "chat-textarea__textarea--active" : ""}`}
            autoFocus={autoFocus}
          />
          {children}
        </div>
        <ButtonIcon
          buttonStyle="primary"
          icon="icon-arrow-right"
          disabled={isButtonSendDisabled}
          iconClass="chat-textarea__icon"
          className={`chat-textarea__btn`}
          onClick={() => handleSend()}
        />
      </div>
    </>
  );
};

export default ChatTextarea;
