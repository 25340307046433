export const spicyMessages = [
  "Hey, I feel like you’ve got something interesting to say... how about starting the conversation? 😏",
  "I think you’ve got the power to make me curious... come on, surprise me! 😉",
  "I’m here, waiting for your message... what’s it going to be? 🔥",
  "If you know how to keep a conversation interesting, show me... I’m waiting! 😘",
  "You know what they say, the best conversation starts with a good 'Hi'. So, hi! 😈",
  "I think you can make me laugh... or can you? Let’s see! 😜",
  "I love a good chat... do you think you can keep me hooked in the conversation? 😏",
  "I have a feeling this conversation is going to be unforgettable... shall we find out? 🔥",
  "I’m challenging you to start something that’s going to heat up... ready for that? 😘",
  "This conversation has all the makings of fun. What do you think? Shall we go for it? 😉",
  "I can’t stop thinking about how this conversation is going to go... wanna start? 😏",
  "I like someone who knows what they want... show me how it’s done! 😜",
  "I’m giving you the chance to impress me, go ahead! 😏",
  "Can you keep the fire burning throughout the conversation? 🔥",
  "I’m good at conversations... but let’s see if you can surprise me! 😘",
  "I’m ready to start, now it’s up to you... what’s it going to be? 😉",
  "Did you know that the best conversation starts light? Let’s go! 😏",
  "Think you can leave me speechless? Start the conversation and show me! 😈",
  "Here, waiting for the message that’s going to make me smile... can you do it? 😜",
  "Let’s see how far this conversation can go... I’m curious! 😘",
];
