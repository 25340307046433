import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Autoplay,
  Navigation,
  Pagination,
  Virtual,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/scss/effect-fade";
import "./styles.scss";
import Button from "../../Buttons/Button";
import TranslatableText from "../../Texts/TranslatableText";
import ModalPwa from "../../Modals/ModalPwa";
import { useModal } from "hooks/useModal";

interface Slide {
  title: string;
  subtitle: string;
  backgroundImage: string;
  icon: string;
}

interface CarouselProps {
  slides: Slide[];
  desktopMembershipBanner?: boolean;
}

const LandingPageCarousel: React.FC<CarouselProps> = ({
  slides,
  desktopMembershipBanner = false,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [startX, setStartX] = useState<number | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { setOpenUseModal, setModalContent } = useModal();

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const endX = e.changedTouches[0].clientX;

    if (startX !== null) {
      const distance = endX - startX;

      if (distance > 50) {
        swiperRef.current?.slidePrev();
      } else if (distance < -50) {
        swiperRef.current?.slideNext();
      }
      setStartX(null);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    const { clientX } = e;
    const slideWidth = window.innerWidth / 2;

    if (clientX > slideWidth) {
      swiperRef.current?.slideNext();
    } else {
      swiperRef.current?.slidePrev();
    }
  };

  // const handleSkipClick = () => {
  //   swiperRef.current?.slideTo(slides.length - 1);
  // };

  // const handleActionClick = () => {

  // };

  const handleDownloadApp = () => {
    setModalContent(<ModalPwa />);
    setOpenUseModal(true);
  };

  return (
    <div id="landing-page-carousel" onClick={handleClick}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={false}
        modules={[Pagination, Navigation, Virtual, Autoplay, EffectFade]}
        autoplay={{ delay: 5000 }}
        effect="fade"
        speed={500}
        onSlideChange={(swiper: SwiperCore) =>
          setCurrentSlide(swiper.activeIndex)
        }
        onSwiper={(swiper: SwiperCore) => {
          swiperRef.current = swiper;
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <div
              className="slide-background"
              style={{ backgroundImage: `url(${slide.backgroundImage})` }}
            >
              <div className="banner-wrapper-inner inner">
                <div className="banner-text-container inner">
                  <img src={slide.icon} alt="Icon" />
                  <h1 textkey={slide.title} />
                  <p className="white-p" textkey={slide.subtitle} />
                  <div className="banner-button-wrapper">
                    <Button
                      buttonStyle="primary"
                      onClick={handleDownloadApp}
                      children={
                        <TranslatableText
                          textAlt
                          fontSize="10"
                          textKey="2/bannerButton"
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LandingPageCarousel;
