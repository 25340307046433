import React from "react";
import { NavigateFunction, Params } from "react-router-dom";
import { useLoginSignUpNavigation } from "hooks/useLoginSignUpNavigation";
import { useModal } from "hooks/useModal";
import LoginModal from "components/Modals/AuthModalFlux/Login";
import { localStorageClear } from "utils/localStorageClear";
import { IInfoToken } from "types/infoToken";
import * as jwtDecode from "jwt-decode";

interface UseAuthCheckOpenModalProps {
  navigate: NavigateFunction;
  paramsUrl: Readonly<Params<string>>;
}

const useAuthCheckOpenModal = (props: UseAuthCheckOpenModalProps) => {
  const { navigate, paramsUrl } = props;
  const { setComponent } = useLoginSignUpNavigation();
  const { setOpenUseModal, setModalContent } = useModal();

  const isTokenExpired = (exp: number) => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp > exp;
  };

  const checkAuth = () => {
    try {
      const savedToken = localStorage.getItem("access_token");

      if (!savedToken) {
        setModalContent(
          <LoginModal
            navigate={navigate}
            setComponent={setComponent}
            paramsUrl={paramsUrl}
          />
        );
        setOpenUseModal(true);
        localStorageClear();
        return false;
      } else {
        const userInfoToken = jwtDecode.jwtDecode(savedToken) as IInfoToken;
        if (isTokenExpired(userInfoToken.exp)) {
          setModalContent(
            <LoginModal
              paramsUrl={paramsUrl}
              navigate={navigate}
              setComponent={setComponent}
            />
          );
          setOpenUseModal(true);
          localStorageClear();
          return false;
        }
      }
    } catch (error) {
      console.error(error);

      if (error instanceof jwtDecode.InvalidTokenError) {
        setModalContent(
          <LoginModal
            paramsUrl={paramsUrl}
            navigate={navigate}
            setComponent={setComponent}
          />
        );
        setOpenUseModal(true);
        localStorageClear();
        return false;
      }
    }

    return true;
  };

  return checkAuth;
};

export default useAuthCheckOpenModal;
