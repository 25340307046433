import { servicesApi } from "../fetchApi/services-api";

const deleteProfileBanner = async (token: string) => {
  const apiRoute = `${servicesApi("user")}/banner`;
  try {
    const response = await fetch(apiRoute, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "access-control-allow-origin": "*",
      },
    });

    const text = await response.text();
    const res = text ? JSON.parse(text) : null;

    return { res, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export default deleteProfileBanner;
