import fetchApi from "../fetchApi";

const postUnlockChatContent = async (token: string, messageId: string) => {
  return await fetchApi({
    service: "chat",
    endPoint: "/unlock-content",
    method: "POST",
    token,
    data: { messageId },
  });
};

export default postUnlockChatContent;
