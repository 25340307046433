import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from "react";
import "./styles.scss";
import VMasker from "vanilla-masker";

import Loupe from "assets/icons/loupe-gray.svg";
import OpenedEye from "assets/icons/eye-icon-gray.svg";
import ClosedEye from "assets/icons/hide-icon-gray.svg";
import Tooltip from "components/Tooltip";
import Button from "components/Buttons/Button";

interface InputTextProps {
  inputStyle?: string;
  mask?: string;
  value?: string | string[];
  iconLeft?: string;
  iconRight?: string;
  iconRightCustom?: string;
  hiddenEye?: boolean;
  label?: ReactNode;
  inputType: React.HTMLInputTypeAttribute;
  searchInput?: boolean;
  textAlign?: "start" | "end" | "left" | "right" | "center";
  isWarning?: boolean;
  tooltip?: {
    message: string;
  };
  explanation?: {
    message: string | ReactNode;
  };
  hasCopyButton?: boolean;
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "full-width"
    | "full-size-kana";
  placeholder?: string;
  disabled?: boolean | undefined;
  readOnly?: boolean;
  widthIcon?: number;
  elementNode?: ReactNode;
  cursor?: React.CSSProperties["cursor"];
  backgroundColor?: "charcoal" | "dawn" | "gray-dark" | "bg-inverse-15";
  className?: string;
  onChangeInput?: (a: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onClick?: (a: any) => void;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  const {
    inputStyle,
    value = "",
    mask,
    iconRight,
    iconLeft,
    iconRightCustom,
    hiddenEye,
    label,
    searchInput,
    textAlign,
    inputType,
    isWarning,
    tooltip,
    explanation,
    hasCopyButton,
    textTransform = "none",
    placeholder,
    disabled,
    readOnly,
    widthIcon,
    elementNode,
    cursor,
    backgroundColor = "dawn",
    className,
    onChangeInput,
    onKeyDown,
    onFocus,
    onClick,
  } = props;
  const [inputTypeComp, setInputTypeComp] =
    useState<React.HTMLInputTypeAttribute>("text");

  useEffect(() => {
    setInputTypeComp(inputType);
  }, []);

  const handlerInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (mask) {
      onChangeInput?.(VMasker.toPattern(inputValue, mask));
    } else {
      onChangeInput?.(inputValue);
    }
  };

  const inputClassName = [
    "input-text",
    className ?? "",
    isWarning ? "isWarning" : "",
    inputStyle ?? "",
    inputType === "password" ? "type-password" : "",
  ]
    .filter(Boolean)
    .join(" ")
    .trim();

  return (
    <div id="input-text-default" className="input-text-default">
      {label && (
        <label className="input input-text-default__label">
          {label} {tooltip && <Tooltip text={tooltip.message} />}
        </label>
      )}
      <div className={`input-container`}>
        {searchInput ? (
          <img
            className={`input-text-icon-left ${
              disabled ? "disabled-icon" : ""
            }`}
            src={Loupe}
            alt="loupe icon"
          />
        ) : iconLeft ? (
          <img
            className={`input-text-icon-left ${
              disabled ? "disabled-icon" : ""
            }`}
            src={iconLeft}
            alt=""
          />
        ) : (
          false
        )}
        <input
          readOnly={readOnly || disabled}
          ref={ref}
          onFocus={onFocus}
          disabled={disabled}
          className={inputClassName}
          value={disabled ? "" : value}
          onClick={onClick}
          onChange={handlerInput}
          type={inputTypeComp}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          style={{
            backgroundColor: `var(--${backgroundColor})`,
            textAlign,
            paddingRight:
              searchInput || iconRight
                ? 40
                : inputType === "password"
                  ? 48
                  : 16,
            paddingLeft: searchInput || iconLeft ? 40 : 16,
            textTransform,
            cursor: cursor
              ? cursor
              : inputType === "radio"
                ? "pointer"
                : "text",
          }}
        />
        {inputType === "password" && !hiddenEye && (
          <img
            className="input-text-icon-right"
            src={inputTypeComp === "password" ? OpenedEye : ClosedEye}
            alt={inputTypeComp === "password" ? "opened eye" : "closed eye"}
            onClick={() => {
              if (inputTypeComp === "password") {
                setInputTypeComp("text");
              } else if (inputTypeComp === "text") {
                setInputTypeComp("password");
              }
            }}
          />
        )}

        {elementNode ? (
          <div className="element-node-wrapper">{elementNode}</div>
        ) : (
          false
        )}
        {iconRight && (
          <img
            className={` ${
              widthIcon ? "input-text-icon-right-24" : "input-text-icon-right"
            }`}
            src={iconRight}
            alt="right icon"
            onClick={onClick}
          />
        )}

        {iconRightCustom && (
          <span
            className={`icon ${iconRightCustom} input-text__icon-right`}
            onClick={onClick}
          ></span>
        )}

        {hasCopyButton && (
          <Button
            className="input-text__btn"
            children={"Copy"}
            buttonStyle={"primary"}
          />
        )}
      </div>
      {explanation && (
        <>
          {typeof explanation.message === "string" ? (
            <p className="input-text__explanation">{explanation.message}</p>
          ) : (
            explanation.message
          )}{" "}
        </>
      )}
    </div>
  );
});

export default InputText;
