import React, { useEffect } from "react";
import "./styles.scss";

interface InputCheckboxProps {
  elements: InputCheckboxElement[];
  defaultValueProp?: string[];
  inputValues: string[];
  isReverse?: boolean;
  className?: string;
  setInputsValues: React.Dispatch<React.SetStateAction<string[]>>;
}
export interface InputCheckboxElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  const {
    elements,
    defaultValueProp,
    inputValues,
    isReverse,
    className,
    setInputsValues,
  } = props;

  useEffect(() => {
    setInputsValues(defaultValueProp || []);
  }, [defaultValueProp]);

  const checkValueCallback = (
    event: React.ChangeEvent<HTMLInputElement>,
    element: string
  ) => {
    setInputsValues((prevValues) => {
      let updatedValues = [...prevValues];

      if (event.target.checked) {
        if (!updatedValues.includes(element)) {
          updatedValues.push(element);
        }
      } else {
        updatedValues = updatedValues.filter((value) => value !== element);
      }
      return updatedValues;
    });
  };

  return (
    <div
      id="input-checkbox-default"
      className={`${className ?? ""} ${
        isReverse ? "label-checkbox-reverse" : ""
      }`}
    >
      {elements.map((element, index) => {
        return (
          <div className="label-input-container" key={index}>
            <label
              htmlFor={`${element.inputValue}-${index}`}
              className="container-checkbox"
            >
              {element.inputLabel}
              <input
                id={`${element.inputValue}-${index}`}
                name={element.groupName}
                value={element.inputValue}
                type="checkbox"
                onChange={(event) => {
                  checkValueCallback(event, element.inputValue);
                }}
                checked={inputValues?.includes(element.inputValue)}
              />
              <span className="container-checkbox__checkmark"></span>
            </label>

            {index + 1 < elements.length && <div className="separator" />}
          </div>
        );
      })}
    </div>
  );
};

export default InputCheckbox;
