import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./styles.scss";

import CardFallback from "components/Cards/CardFallback";
import FeedCards from "components/Cards/FeedCards";
import Loading from "components/Loading";
import Modal from "components/Modals/Modal";
import CoverImage from "components/CoverImage";
import PageTitle from "components/PageTitles";

import { useUserInfo } from "hooks/userInfo";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useUnlockContent } from "hooks/useUnlockContent";
import { useToast } from "hooks/useToast";

import { useIconContext } from "contexts/iconNavContext";
import { CreatorDetailsContext } from "contexts/creatorDetails";

import deletePosts from "api/posts/deletePosts";
import getPostsById from "api/posts/getPostsById";
import getPostsUnseen from "api/posts/getPostsUnseen";
import getPostsSeen from "api/posts/getPostsSeen";
import postPostsView from "api/posts/postPostsView";

import logo from "assets/icons/logos/logo-fill-pink.svg";

const FeedGlobal: React.FC = () => {
  const {
    componentPrincipal,
    componentSecondary,
    setMainScreen,
    setSecondaryScreen,
    setFeedScreen,
  } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { unlockContentSubmit } = useUnlockContent();
  const { creatorDetails, setCreatorDetails } = useContext(
    CreatorDetailsContext
  );

  const { userInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();
  const { showToast } = useToast();

  const modalPostsContainerRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const feedCardsContainer = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  const lastPostRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasViewUnseen, setHasViewUnseen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fallbackText, setFallbackText] = useState({
    text1: "No Content Here Yet!",
    text2: "This creator hasn’t uploaded any content yet. Check back soon!",
  });

  const [creatorPosts, setCreatorPosts] = useState<any[]>([]);
  const [lastPost, setLastPost] = useState<{ postId: string } | undefined>();
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [isOpenPostsModal, seIsOpenPostsModal] = useState(false);
  const [selectedPostIndex, setSelectedPostIndex] = useState<number | null>(
    null
  );

  const options = {
    root: divRef.current,
    rootMargin: "0px",
    threshold: 0.5,
  };

  const scrollToModalPostsItem = useCallback(() => {
    if (selectedPostIndex === null) {
      return;
    }
    const item = itemsRef.current[selectedPostIndex];
    const container = modalPostsContainerRef.current;

    if (item && container) {
      const itemTop = item.offsetTop;

      container.scrollTo({
        top: itemTop,
      });
    }
  }, [selectedPostIndex]);

  const scrollToPostsItem = useCallback(() => {
    if (selectedPostIndex === null) {
      return;
    }
    const item = itemsRef.current[selectedPostIndex];
    const container = feedCardsContainer.current;

    if (item && container) {
      const itemTop = item.offsetTop;

      container.scrollTo({
        top: itemTop,
      });
    }
  }, [selectedPostIndex]);

  const onOpenPostsModal = useCallback((selectedIndex: number) => {
    setSelectedPostIndex(selectedIndex);
    seIsOpenPostsModal(true);
  }, []);

  const onClosePostsModal = useCallback((selectedIndex: number) => {
    setSelectedPostIndex(selectedIndex);
    seIsOpenPostsModal(false);
  }, []);

  const handlerDeletePost = useCallback(
    async (postId: string) => {
      try {
        await deletePosts(userInfo.access_token, { postId });
        setCreatorPosts((prevPosts) =>
          prevPosts.filter((post) => post.postId !== postId)
        );
      } catch (error) {
        showToast({
          type: "error",
          title: "No Content Here Yet!",
          description:
            "This creator hasn’t uploaded any content yet. Check back soon!",
        });
      }
    },
    [userInfo.access_token]
  );

  const handlerUnlockPost = useCallback(
    async (postId: string, price: number) => {
      try {
        await new Promise<void>((resolve, reject) => {
          unlockContentSubmit(
            postId,
            price,
            "feed",
            () => {
              resolve();
            },
            true
          );
        });

        const { res } = await getPostsById(userInfo.access_token, { postId });

        setCreatorPosts((prevPosts) => {
          return prevPosts.map((post) => {
            if (post.postId === postId) {
              return { ...post, ...res };
            }
            return post;
          });
        });
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, try again",
          type: "error",
        });
      }
    },
    [unlockContentSubmit, userInfo.access_token]
  );

  useEffect(() => {
    setCreatorDetails(null);
  }, [setCreatorDetails]);

  useEffect(() => {
    if (selectedPostIndex === null) {
      return;
    }
    if (isOpenPostsModal) {
      scrollToModalPostsItem();
    } else {
      scrollToPostsItem();
    }
  }, [
    isOpenPostsModal,
    scrollToModalPostsItem,
    scrollToPostsItem,
    selectedPostIndex,
  ]);

  useEffect(() => {
    const userPosts = async () => {
      try {
        const { res } = await getPostsUnseen(userInfo.access_token);

        if (res.posts.length < 10) {
          const { res: seenRes } = await getPostsSeen(userInfo.access_token, {
            take: 10 - res.posts.length,
          });
          setCreatorPosts((prevMessages) => [
            ...prevMessages,
            ...res.posts,
            ...seenRes.posts,
          ]);
        } else {
          setCreatorPosts(res.posts);
        }
      } catch (error) {
        setFallbackText({
          text1: "No Content Here Yet!",
          text2:
            "This creator hasn’t uploaded any content yet. Check back soon!",
        });
      } finally {
        setIsLoaded(!isLoaded);
      }
    };
    userPosts();
  }, [userInfo.access_token, userInfo.user_id]);

  useEffect(() => {
    setLastPost(creatorPosts.at(-1));
  }, [creatorPosts]);

  useEffect(() => {
    if (itemsRef.current.length === 0) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const beholder = entry.target;

          const postId = beholder.id;

          if (postId === lastPostRef?.current?.id) {
            loadCards(postId, "bottom");
          }

          const cards = creatorPosts.find(
            (feedCard) => feedCard.postId === postId
          );
          if (cards && !cards.hasSeen && userInfo.user_id !== cards.userId) {
            markFeedCardAsRead(postId);
          }

          observer.unobserve(entry.target);
        }
      });
    }, options);

    itemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);
      }
    });

    return () => observer.disconnect();
  }, [creatorPosts, setCreatorPosts]);

  useEffect(() => {
    if (creatorPosts.length > 0) {
      setLastPost(creatorPosts[creatorPosts.length - 1]);
    }
  }, [creatorPosts]);

  const loadCards = async (
    cursor: string | undefined,
    direction: "top" | "bottom"
  ) => {
    if (isLoading || cursor === undefined || !hasMorePosts) return;

    setIsLoading(true);

    const cardsParams = {
      take: direction === "top" ? 0 : 10,
      cursor,
    };

    try {
      let newPosts: any[] = [];

      if (!hasViewUnseen) {
        const { res } = await getPostsUnseen(
          userInfo.access_token,
          cardsParams
        );

        newPosts = res.posts;
        if (res.posts.length < 10) {
          setHasViewUnseen(true);
        }
      }

      if (newPosts.length < 10) {
        const { res: seenRes } = await getPostsSeen(userInfo.access_token, {
          take: 10 - newPosts.length,
          cursor,
        });

        newPosts = [...newPosts, ...seenRes.posts];

        if (seenRes.posts.length < 10 - newPosts.length) {
          setHasMorePosts(false);
        }
      }

      setCreatorPosts((prevMessages) =>
        direction === "top"
          ? [...newPosts, ...prevMessages]
          : [...prevMessages, ...newPosts]
      );
    } catch (error) {
      setFallbackText({
        text1: "No Content Here Yet!",
        text2: "This creator hasn’t uploaded any content yet. Check back soon!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const markFeedCardAsRead = async (id: string) => {
    try {
      await postPostsView(userInfo.access_token, { postId: id });
      return true;
    } catch (error) {
      return false;
    }
  };

  const closeFeed = () => {
    setFeedScreen("");

    if (!componentPrincipal && componentSecondary) {
      setSecondaryScreen("");
    }
    setMainScreen("/catalog");
    handleButtonClick("grid");
  };

  return (
    <div ref={divRef} className="feed-global feed-global-wrapper wrapper">
      <div className="feed-global-inner inner">
        {/* <PageTitle
        onBackClick={closeFeed}
        className="feed-page__page-title  "
        hasCloseIcon={windowSize >= 768}
      /> */}
        {/* <CoverImage
        creatorInfo={{
          creatorCoverImage: CoverPhoto,
          creatorName: "",
        }}
      /> */}

        <main ref={feedCardsContainer} className="feed-global-cards">
          {!isLoaded ? (
            <Loading className="feed-global-loading" />
          ) : (
            <>
              {creatorPosts.length > 0 ? (
                creatorPosts.map((post, index) => {
                  return (
                    <FeedCards
                      key={post.postId}
                      post={post}
                      isGlobal
                      handleExpand={() => onOpenPostsModal(index)}
                      onDelete={() => handlerDeletePost(post.postId)}
                      onUnlockedPost={handlerUnlockPost}
                      ref={(el) => {
                        if (el) {
                          itemsRef.current[index] = el;
                        }
                        if (index === creatorPosts.length - 1) {
                          lastPostRef.current = el;
                        }
                      }}
                    />
                  );
                })
              ) : (
                <CardFallback
                  title={fallbackText.text1}
                  desc={fallbackText.text2}
                  onClick={closeFeed}
                />
              )}
            </>
          )}
          {isOpenPostsModal && (
            <Modal
              isOpen={isOpenPostsModal}
              isFeed
              className="feed-posts-expand-modal"
              onClose={() => onClosePostsModal(0)}
            >
              <div
                className="feed-posts-expand-modal-list"
                ref={modalPostsContainerRef}
              >
                {!isLoaded ? (
                  <Loading className="feed-global-loading" />
                ) : creatorPosts.length > 0 ? (
                  creatorPosts.map((post, index) => {
                    return (
                      // <div
                      //   className="feed-posts-expand-modal-list-item"
                      //   key={`modal-posts-${post.postId}`}
                      // >
                      <FeedCards
                        post={post}
                        isExpanded={isOpenPostsModal}
                        handleExpand={() => onClosePostsModal(index)}
                        onDelete={() => handlerDeletePost(post.postId)}
                        onUnlockedPost={handlerUnlockPost}
                        isGlobal={false}
                        ref={(el) => {
                          if (el) {
                            itemsRef.current[index] = el;
                          }
                          if (index === creatorPosts.length - 1) {
                            lastPostRef.current = el;
                          }
                        }}
                      />
                      // </div>
                    );
                  })
                ) : (
                  <CardFallback
                    title={fallbackText.text1}
                    desc={fallbackText.text2}
                    onClick={closeFeed}
                  />
                )}
              </div>
            </Modal>
          )}
        </main>
      </div>
    </div>
  );
};

export default FeedGlobal;
