import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import Header from "components/Headers/Header";
import SubText from "components/Texts/SubText";

import { useDeskNavigation } from "hooks/useDeskNavigation";

import InfoIcon from "assets/icons/union-gray.svg";

const LostConnectionPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setFeedScreen,
    setMainScreen,
  } = useDeskNavigation();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setFeedScreen("");
    setMainScreen("");

    const handleOffline = () => {
      navigate("/offline");

      interval = setInterval(() => {
        if (navigator.onLine) {
          navigate("/app");
          if (interval) clearInterval(interval);
        }
      }, 3000);
    };

    const handleOnline = () => {
      navigate("/app");
      if (interval) clearInterval(interval);
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
      if (interval) clearInterval(interval);
    };
  }, [navigator]);

  return (
    <div className="lost-connection lost-connection-wrapper wrapper">
      <div className="lost-connection-inner inner">
        <Header
          title={
            <>
              <b>Oops...!</b>
              <span> Looks like you’ve stumbled into a hidden garden.</span>
            </>
          }
          marginTop={0}
          titleClass="header"
          headerIcon="roses-logo"
          subTitle="We couldn’t find the page you were looking for. But don’t worry, there’s plenty of content blooming here to explore."
        />

        <SubText icon={InfoIcon} altIcon="info icon" fontSize="12px">
          Or if you need help, feel free to reach out. Our roses are always in
          full bloom, ready to assist!
        </SubText>
      </div>
    </div>
  );
};

export default LostConnectionPage;
