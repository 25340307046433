import React, { ReactNode, ReactElement } from "react";
import { AuthProvider } from "./hooks/auth";
import { PwaProvider } from "./hooks/pwa";
import { DeskNavigationProvider } from "./hooks/useDeskNavigation";
import { LoginSignUpProvider } from "./hooks/useLoginSignUpNavigation";
import { UserInfoProvider } from "./hooks/userInfo";
import { ToggleChatPageProvider } from "./contexts/toggleChatOpen";
import { FilesProvider } from "./hooks/documentContext";
import { ModalProvider } from "./hooks/useModal";
import { ChatProvider } from "./contexts/openedChatContext";
import { UseWindowWidthSizeProvider } from "./hooks/useWindowWidthSize";
import { PublishContentProvider } from "./hooks/usePublishContent";
import { WebSocketProvider } from "./contexts/webSocketContext";
import { ProducerDetailsProvider } from "./contexts/producerDetailsContext";
import { UserAvailableRosesProvider } from "./contexts/availableRosesContext";
import { IconProvider } from "./contexts/iconNavContext";
import { MomentsTriggerProvider } from "./contexts/momentTrigger";
import { LanguageProvider } from "./hooks/languageContext";
import { FavoritesCatalogTriggerProvider } from "./contexts/favoritesCatalogTrigger";
import { MediaProvider } from "./contexts/mediaContext";
import { FavoritesListTriggerProvider } from "./contexts/favoritesListTrigger";
import { SearchCreatorCatalogProvider } from "hooks/useSearchCatalog";
import { DialogProvider } from "hooks/useDialog";
import { MediaModalProvider } from "hooks/useMediaModal";
import { ToastProvider } from "hooks/useToast";
import { CreatorDetailsProvider } from "contexts/creatorDetails";
import { catalogCreatorsProvider } from "contexts/catalogCreatorsContext";
import { PullToRefreshProvider } from "contexts/pullToRefreshContext";
import { ProfileProvider } from "contexts/profileContext";
import { BecomeCreatorProvider } from "contexts/becomeCreatorContext";

interface AppProviderProps {
  children: ReactNode;
}

const providers = [
  PublishContentProvider,
  UseWindowWidthSizeProvider,
  UserInfoProvider,
  WebSocketProvider,
  MomentsTriggerProvider,
  ChatProvider,
  ToastProvider,
  ModalProvider,
  MediaModalProvider,
  //NEVER TOUCH OR CHANGE ORDER OF PROVIDERS ABOVE
  //IF YOU NEED TO PROMOTE A PROVIDER, ADD THEM LAST IN THE LIST ABOVE

  CreatorDetailsProvider,
  LanguageProvider,
  UserAvailableRosesProvider,
  ToastProvider,
  FilesProvider,
  BecomeCreatorProvider,
  ModalProvider,
  PwaProvider,
  AuthProvider,
  DialogProvider,
  SearchCreatorCatalogProvider,
  DeskNavigationProvider,
  LoginSignUpProvider,
  ToggleChatPageProvider,
  ProducerDetailsProvider,
  FavoritesCatalogTriggerProvider,
  FavoritesListTriggerProvider,
  IconProvider,
  MediaProvider,
  catalogCreatorsProvider,
  PullToRefreshProvider,
  ProfileProvider,
];

const AppProvider = ({ children }: AppProviderProps): ReactElement => {
  return providers.reduceRight(
    (acc, Provider) => <Provider>{acc}</Provider>,
    <>{children}</>
  );
};

export default AppProvider;
