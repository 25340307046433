import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useToast } from "hooks/useToast";

const Toast: React.FC = () => {
  const { toastConfig, toastVisible } = useToast();
  const [toastState, setToastState] = useState("");

  useEffect(() => {
    if (toastVisible) {
      setToastState("show");
    } else {
      setToastState("hide");
    }
  }, [toastVisible]);

  useEffect(() => {
    if (toastState === "show" && toastConfig) {
      const timer = setTimeout(() => {
        setToastState("hide");
      }, toastConfig.timeout || 5000);

      return () => clearTimeout(timer);
    }
  }, [toastState, toastConfig]);

  if (!toastConfig) return null;

  const { type, title, description } = toastConfig;

  let iconColor;
  let iconType;
  switch (type) {
    case "success":
      iconColor = "success";
      iconType = "check";
      break;
    case "warning":
      iconColor = "warning";
      iconType = "info";
      break;
    case "info":
      iconColor = "info";
      iconType = "info";
      break;
    case "error":
    default:
      iconColor = "error";
      iconType = "info";
      break;
  }

  return (
    <div className={`toastContainer ${toastState}`} role="alert">
      <span
        className={`icon icon-${iconType} icon-md icon-${iconColor} icon-round`}
      ></span>
      <div className="textContainer">
        <p className="textPrimary  ">{title}</p>
        <p className="textSecondary  ">{description}</p>
      </div>
    </div>
  );
};

export default Toast;
