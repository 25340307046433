import { createContext, useContext, useState, ReactNode } from "react";

interface IBecomeCreator {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  country?: string;
  state?: string;
  street?: string;
  complement?: string;
  city?: string;
  zipCode?: string;
  [key: string]: string | undefined;
}

interface IUserBecomeCreatorContext {
  registerBecomeCreator: IBecomeCreator;
  setRegisterBecomeCreator: React.Dispatch<
    React.SetStateAction<IBecomeCreator>
  >;
}

const UserBecomeCreatorContext = createContext<
  IUserBecomeCreatorContext | undefined
>(undefined);

const BecomeCreatorProvider = ({ children }: { children: ReactNode }) => {
  const [registerBecomeCreator, setRegisterBecomeCreator] =
    useState<IBecomeCreator>({
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      country: "",
      state: "",
      street: "",
      complement: "",
      city: "",
      zipCode: "",
    });

  return (
    <UserBecomeCreatorContext.Provider
      value={{
        registerBecomeCreator,
        setRegisterBecomeCreator,
      }}
    >
      {children}
    </UserBecomeCreatorContext.Provider>
  );
};

const useBecomeCreator = () => {
  const context = useContext(UserBecomeCreatorContext);
  if (!context) {
    throw new Error(
      "useBecomeCreator must be used within a UserBecomeCreatorProvider"
    );
  }
  return context;
};

export { BecomeCreatorProvider, useBecomeCreator };
