import React, { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import ListItemLink from "components/Lists/ListItemLink";
import SlidUp from "components/Modals/SlidUp";
import InputRadioCarousel from "components/Inputs/InputRadioCarrossel";
import Button from "components/Buttons/Button";

import { useDeskNavigation } from "hooks/useDeskNavigation";

import { shortClassNames } from "utils/shortClassNames";
import ProgressbarWithoutAnimation from "components/ProgressbarWithoutAnimation";

const UserSettingsPersonalTouchPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();

  const [isSlidUpWriteVisible, setIsSlidUpWriteVisible] = useState(false);
  const [selectValue, setSelectValue] = useState("");

  const listVoices = [
    "Sweet & Soothing",
    "Playful & Flirtatious",
    "Bold & Confident",
    "Warm and Nurturing",
    "Sarcastic & Humorous",
    "Calm & Collected",
  ];

  return (
    <div
      className={shortClassNames([
        `user-settings-personal-touch`,
        `user-settings-personal-touch-wrapper`,
        `wrapper`,
        `standard-page-layout`,
        `bottom-spacing`,
      ])}
    >
      <div className="user-settings-personal-touch-inner inner">
        <PageTitle
          title={"Personal Touch"}
          subtitle={"0 of 5 added"}
          hasCloseIcon={false}
          onBackClick={() =>
            setPrincipalScreen(`/user-settings-profile-customize`, {
              btn: "button2",
            })
          }
        />

        <div className="user-settings-personal-touch__main">
          <div className="user-settings-personal-touch__bars">
            {[...Array(6)].map((_, i) => (
              <ProgressbarWithoutAnimation
                key={i}
                index={i}
                activeIndex={0}
                isPink
              />
            ))}
          </div>

          <div className="user-settings-personal-touch__list">
            <ListItemLink
              highlightText={"Voice Tone"}
              isCompact
              hasArrowRight
              onClick={() => setIsSlidUpWriteVisible(true)}
              rightNodeContent={
                <p className="user-settings-personal-touch__detail">
                  {selectValue || "Add"}
                </p>
              }
            />
          </div>

          <Button children={"Skip"} buttonStyle={"tertiary"} />
        </div>
      </div>

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up"
        isOpen={isSlidUpWriteVisible}
        title={"Voice Tone"}
        closeArea={() => {
          setIsSlidUpWriteVisible(false);
        }}
        isFullScreen
        onBackClick={() => setIsSlidUpWriteVisible(false)}
        onCheckClick={() => setIsSlidUpWriteVisible(false)}
      >
        <div className="user-settings-profile__radio-list">
          <InputRadioCarousel
            elements={listVoices.map((item) => {
              return {
                groupName: "voices",
                inputLabel: `${item}`,
                inputValue: `${item}`,
              };
            })}
            onChangeValueCallback={(e) => {
              setSelectValue(e[0]);
            }}
          />
        </div>
      </SlidUp>
    </div>
  );
};

export default UserSettingsPersonalTouchPage;
