import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";

import CardsMediaLibrary from "components/Cards/CardsMediaLibrary";
import PageTitle from "components/PageTitles";
import ImageMessageModal from "components/Chat/ChatModals/ImageMessageModal";
import VideoMessageModal from "components/Chat/ChatModals/VideoMessageModal";

import { IChatMediaResponse, IMedia } from "../../types/chatInfo";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useModal } from "../../hooks/useModal";
import { useUnlockContent } from "../../hooks/useUnlockContent";

import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import getChatMediaById from "../../api/chat/getChatMediaById";

import { useWebSocket } from "../../contexts/webSocketContext";
import { useProducerDetails } from "../../contexts/producerDetailsContext";
import { useMediaContext } from "../../contexts/mediaContext";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { CreatorDetailsContext } from "contexts/creatorDetails";
import verifyToken from "utils/verifyToken";
import MediaProfileModal from "components/Modals/MediaProfileModal";
import { shortClassNames } from "utils/shortClassNames";
import MediaModal, { ICreatorMediaInfo } from "components/Modals/MediaModal";

const MediaLibraryPage: React.FC = ({ className }: { className?: string }) => {
  const { addChatListener, removeListener } = useWebSocket();
  const { producerDetails, updateProducerDetails } = useProducerDetails();
  const { unlockedMedias } = useMediaContext();
  const { creatorDetails } = useContext(CreatorDetailsContext);
  const { setTertiaryScreen, params, setFeedScreen } = useDeskNavigation();
  const { chatId, userId, feedWasOpen } = params;
  const { userInfo } = useUserInfo();
  const { access_token } = userInfo || {};
  const { setOpenUseModal, setModalContent } = useModal();
  const { unlockContentSubmit } = useUnlockContent();
  const { windowSize } = useWindowWidthSize();

  const [chatMedias, setChatMedias] = useState<IMedia[]>();
  const [unauthenticated, setUnauthenticated] = useState(
    params.chatId === "unauthenticated"
  );

  useEffect(() => {
    if (!verifyToken()) {
      setUnauthenticated(true);
    }
  }, [userInfo?.access_token, params]);

  useEffect(() => {
    const handleNewMessage = (newMessage: any) => {
      if (newMessage.eventType === "NEW_MESSAGE") {
        getChatMedias();
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener]);

  useEffect(() => {
    const producerDetail = async () => {
      if (!(access_token && userId)) return;

      const { res, status } = await getPublicDetails(access_token, userId);

      if (status === 200) updateProducerDetails(res);
    };
    producerDetail();
  }, [access_token, userId]);

  useEffect(() => {
    getChatMedias();
  }, [access_token, chatId, userId, unlockedMedias]);

  const getChatMedias = async () => {
    if (access_token && userId && chatId) {
      const { res, status }: IChatMediaResponse = await getChatMediaById(
        access_token,
        chatId,
        210
      );

      if (status === 200 && res) {
        const filteredMessages = res.filter(
          (message) =>
            !message.files.some((file) => file.fileType === "AUDIO") &&
            message.userId === userId
        );

        const extractMedias = filteredMessages.map((message) => {
          const extract = {
            files: message.files,
            messageSettings: message.messageSettings,
          };
          return extract;
        });
        setChatMedias(extractMedias);
      }
    }
  };

  const backPage = () => {
    if (feedWasOpen === "true") {
      setFeedScreen("/feed", { userId, chatId });
    } else {
      setTertiaryScreen("", params);
    }
  };

  const imageMessageModalHandler = (index: number) => {
    const prepareMedias = creatorDetails?.profile?.photos.map((media) => {
      return {
        fileDuration: 0,
        hasPaid: false,
        isPaidContent: false,
        mediaSrc: media?.url || "",
        type: "photo",
        unlockPrice: 0,
      };
    });
    setModalContent(
      <MediaModal
        creatorId={userId}
        medias={prepareMedias as ICreatorMediaInfo[]}
        initialIndex={index}
      />
    );
    setOpenUseModal(true);
  };

  return (
    <div
      id="media-library-page"
      className={shortClassNames([
        "media-library",
        `${className ?? ""} `,
        "media-library-wrapper",
        "wrapper",
        "standard-page-layout",
        "bottom-spacing",
      ])}
    >
      <div className="media-library-inner inner">
        <PageTitle
          title="Media Library"
          subtitle={
            producerDetails.displayname ?? creatorDetails?.displayname ?? ""
          }
          onBackClick={backPage}
          hasCloseIcon={windowSize >= 768}
        />
        <div className="media-library__photos-container">
          {unauthenticated ? (
            <>
              {creatorDetails?.profile.photos.map((detail, index) => (
                <CardsMediaLibrary
                  key={index}
                  // TODO
                  fileType={"PHOTO"}
                  src={detail.url}
                  placeholderSrc={detail.thumbnailUrl}
                  // rosesPrice={price}
                  expandClick={() => {
                    imageMessageModalHandler(index);
                  }}
                  // unlockClick={() => {
                  //   unlockContentSubmit(messageId, price, "chat", getChatMedias);
                  // }}
                  // isBlurred={paidContent && !isPaid}
                />
              ))}
            </>
          ) : (
            false
          )}
          {chatMedias
            ? chatMedias.map((media, index) => {
                const { files, messageSettings } = media;
                const { price, paidContent, isPaid, messageId } =
                  messageSettings;

                return files.map((file, i) => {
                  const { url, fileType, thumbnailUrl } = file;
                  return (
                    <CardsMediaLibrary
                      placeholderSrc={thumbnailUrl}
                      key={index + i}
                      fileType={fileType}
                      src={url}
                      rosesPrice={price}
                      expandClick={() => {
                        setOpenUseModal(true);
                        switch (fileType) {
                          case "PHOTO":
                            return setModalContent(
                              <ImageMessageModal url={url} />
                            );
                          case "VIDEO":
                            return setModalContent(
                              <VideoMessageModal url={url} />
                            );
                          default:
                            break;
                        }
                      }}
                      unlockClick={() => {
                        unlockContentSubmit(
                          messageId,
                          price,
                          "chat",
                          getChatMedias
                        );
                      }}
                      isBlurred={paidContent && !isPaid}
                    />
                  );
                });
              })
            : false}
        </div>
      </div>
    </div>
  );
};

export default MediaLibraryPage;
