import "./styles.scss";

interface CardsArrowsProps {
  isFirstItem?: boolean;
  isLastItem?: boolean;
  className?: string;
  changePrevious: () => void;
  changeNext: () => void;
}

const CardsArrows: React.FC<CardsArrowsProps> = (props) => {
  const { isFirstItem, isLastItem, className, changePrevious, changeNext } =
    props;

  return (
    <div className={`cards-arrows ${className ? className : ""}`}>
      <div
        className={`cards-arrow-left ${
          isFirstItem ? "cards-arrow--hidden" : ""
        }`}
        onClick={changePrevious}
      >
        <div className="cards-arrow-button-icon">
          <span className=" icon icon-chevron-left cards-arrow-icon icon-primary-75" />
        </div>
      </div>
      <div
        className={`cards-arrow-right  ${
          isLastItem ? "cards-arrow--hidden" : ""
        }`}
        onClick={changeNext}
      >
        <div className="cards-arrow-button-icon">
          <span className="icon icon-chevron-right cards-arrow-icon icon-primary-75" />
        </div>
      </div>
    </div>
  );
};

export default CardsArrows;
