import React from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Header from "components/Headers/Header";
import Paragraph from "components/Texts/Paragraph";
import TitleText from "components/Texts/TitleText";
import Button from "components/Buttons/Button";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal";

import LegalResidence from "pages/LegalResidence";
import PersonalInformation from "pages/PersonalInformation";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useModal } from "hooks/useModal";

const TermsAndConditionsPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { windowSize } = useWindowWidthSize();
  const { setModalContent } = useModal();

  const breakPoint = windowSize >= 768;

  const handlerNextPage = () => {
    if (breakPoint) {
      setModalContent(<LegalResidence />);
    } else {
      setPrincipalScreen("/legal-residence");
    }
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setModalContent(<PersonalInformation />);
    } else {
      setPrincipalScreen("/personal-information");
    }
  };

  return (
    <div className="terms-and-conditions terms-and-conditions-wrapper wrapper standard-page-layout bottom-spacing">
      <div className="terms-and-conditions-inner inner">
        <ButtonCloseModal />

        {!breakPoint ? (
          <PageTitle
            title="Become a Creator"
            subtitle="Start Earning"
            isBecome
            hasCloseIcon={false}
            onBackClick={handlerBackPage}
          />
        ) : (
          false
        )}
        <Policy />
        <Button buttonStyle="primary" onClick={handlerNextPage}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;

export const Policy = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { windowSize } = useWindowWidthSize();
  const { setOpenUseModal, setModalContent } = useModal();

  const breakPoint = windowSize >= 768;

  const handlerBackPage = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<PersonalInformation />);
    } else {
      setPrincipalScreen("/personal-information");
    }
  };

  return (
    <div className="term-of-service__texts">
      <Header
        title="Terms of Services"
        marginTop={0}
        backButtonClick={handlerBackPage}
        {...(breakPoint
          ? {
              headerIcon: "back-button",
              inlineElements: true,
              titleClass: "header",
            }
          : {
              titleClass: "term-of-service__title",
            })}
      />
      <div>
        <TitleText titleClass={`term-of-service__category`}>General</TitleText>
        <Paragraph>
          The ownership and legal establishment of RosesElite.com are under the
          purview of Young Lands LLC, a duly formed limited liability company in
          compliance with the legislative statutes of Delaware, United States.
          The company's legally registered office is situated at 600 N Broad
          Street, Suite 5 #3977, Middletown, DE, 19709.
        </Paragraph>
        <Paragraph>
          We earnestly implore you to peruse and fully comprehend the terms and
          conditions set forth herein prior to your engagement with this
          website. Your continued use of RosesElite.com signifies an unambiguous
          understanding and unconditional acceptance of these terms. Should you
          find yourself in disagreement with any part of these terms, or if
          their significance is not clear to you, we advise refraining from
          using this site. Be aware that these terms and conditions are
          uniformly applicable across all pages of RosesElite.com.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Introduction
        </TitleText>
        <Paragraph>
          RosesElite.com operates as an exclusive platform, specifically created
          to facilitate the registration and reservation of a discounted rate
          for an innovative application set to launch later in the year. It is
          crucial to understand that RosesElite.com does not function as an
          escort agency. Instead, it serves as a sophisticated advertising
          portal, catering to the needs of both escort agencies and independent
          models. Your access to or use of the Site in any form, including but
          not limited to visiting, browsing, or contributing content or
          materials, explicitly implies your agreement to comply with these
          Terms and Conditions. Terms capitalized within this Agreement are
          defined herein for clarity and specificity.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Acceptance of Term of Use Agreement
        </TitleText>
        <Paragraph>
          Upon creating an account or utilizing any service provided by
          RosesElite.com, including but not limited to access via mobile
          devices, mobile applications, or computers (herein collectively
          referred to as the “Service”), you unequivocally agree to adhere to
          the stipulations outlined in these Terms of Use and our Privacy
          Policy. These documents are incorporated by reference into this
          Agreement. This also extends to any terms presented to you in
          connection with the purchase of additional features, products, or
          services offered on the Service (collectively, this “Agreement”).
          Should you disagree with any part of this Agreement, it is advised not
          to access or use the Service.
        </Paragraph>
        <Paragraph>
          By using RosesElite.com, you affirm and warrant that you are at least
          18 years of age (or of legal majority in your jurisdiction), possess
          the legal right to access adult material in your community, and will
          prevent access to minors for any content found herein. You acknowledge
          and agree that your use of this website is compliant with the laws
          applicable to you, and that you are not restricted from using this
          website due to any legal limitations, including age restrictions.
        </Paragraph>
        <Paragraph>
          You acknowledge your awareness that RosesElite.com is an adult website
          that may feature sexually explicit material, including photographs,
          videos, audio, language, and other adult content. You confirm that
          accessing adult material is not illegal in your community or current
          location, and that you understand and will adhere to the standards and
          laws related to accessing and using such material. You accept sole
          responsibility for your actions in using this site.
        </Paragraph>
        <Paragraph>
          You acknowledge your awareness that RosesElite.com is an adult website
          that may feature sexually explicit material, including photographs,
          videos, audio, language, and other adult content. You confirm that
          accessing adult material is not illegal in your community or current
          location, and that you understand and will adhere to the standards and
          laws related to accessing and using such material. You accept sole
          responsibility for your actions in using this site.
        </Paragraph>
        <Paragraph>
          You agree to indemnify RosesElite.com, its owners, and moderators from
          any liability that may arise from your use of the site. You commit not
          to engage in unauthorized use of materials from this site, including
          downloading, copying, modifying, distributing, or creating derivative
          works without the explicit consent of the rightful owners.
        </Paragraph>
        <Paragraph>
          Any attempt to circumvent the security measures of RosesElite.com is
          strictly prohibited. Accessing the site implies implicit acceptance of
          these terms. RosesElite.com reserves the right to amend these terms
          and conditions at any time. You are advised to review these Terms &
          Conditions regularly as they are subject to change and are binding
          upon you.
        </Paragraph>
        <Paragraph>
          By continuing to use RosesElite.com, you affirm that you have read,
          understood, and agreed to all the terms and conditions stated above.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          User's Code of Conduct
        </TitleText>
        <Paragraph>
          In utilizing RosesElite.com, you hereby affirm, warrant, and commit to
          adhering to the following Code of Conduct. You acknowledge and accept
          that we, at our sole discretion, may review and, if necessary, reject
          or remove any content posted or uploaded by you that contravenes these
          guidelines. Furthermore, we reserve the right, though not the
          obligation, to terminate your access to the Websites for any breach of
          these conditions:
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Zero-Tolerance Policy:{" "}
        </TitleText>
        <Paragraph>
          RosesElite.com stringently prohibits human trafficking, prostitution,
          and any form of illegal conduct. We actively collaborate with law
          enforcement, pursuant to legal processes such as subpoenas, to
          investigate criminal activities. Any advertisements or user activities
          that defy our zero-tolerance policy may lead to immediate referral to
          law enforcement and account termination. You must ensure that your use
          of the site is in full compliance with RosesElite.com's policies as
          well as federal, state, and local laws, and report any violations to
          the appropriate authorities.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Prohibited Conduct:{" "}
        </TitleText>
        <Paragraph>
          The Websites must not be used for engaging in any illegal activities,
          harassment, or behavior deemed offensive. This includes the
          prohibition of posting or uploading any communications, graphics,
          videos, or audio content that are libelous, slanderous, abusive,
          defamatory, racist, obscene, or offensive in nature.
        </Paragraph>
        <Paragraph>Rights Infringement: </Paragraph>
        <Paragraph>
          You are prohibited from using the Websites in a manner that infringes
          upon the privacy rights, property rights, or any other civil rights of
          individuals.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Respect for Third Party Rights and Legal Compliance:
        </TitleText>
        <Paragraph>
          You agree not to post messages or utilize the Websites in any way that
          (i) violates, plagiarizes, or infringes upon the rights of third
          parties, including but not limited to copyright and trademark laws,
          privacy, or other personal or proprietary rights, or (ii) is
          fraudulent, unlawful, or in violation of any applicable law.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Credit Card Payment
        </TitleText>
        <Paragraph>
          These Terms and Conditions govern all credit card transactions
          conducted on RosesElite.com. When you make a credit card payment to
          RosesElite.com or request Electronic Communications, you are agreeing
          to these terms. RosesElite.com reserves the right to modify these
          terms at any time, with amendments published on the website at least
          30 days before they take effect.
        </Paragraph>
        <Paragraph>
          Entering your credit card details signifies your confirmation that you
          are the authorized cardholder and that the information provided
          (including the account holder's name, account number, billing address,
          etc.) is accurate. You thereby authorize RosesElite.com to charge the
          specified amount to your credit card.
        </Paragraph>
        <Paragraph>
          Upon clicking “Submit” for the payment, you consent to receive a
          one-time electronic confirmation of this transaction to the email
          address you have provided.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Report Human Trafficking
        </TitleText>
        <Paragraph>
          RosesElite.com is firmly committed to raising awareness and advocating
          against human trafficking. Our organization adheres to best practices
          in this critical area and is actively engaged in advocacy efforts. We
          maintain a policy of zero tolerance towards child pornography and the
          involvement of minors on our site. In the event of becoming aware of
          any trafficking activities, RosesElite.com promptly and
          enthusiastically cooperates with law enforcement and organizations
          dedicated to fighting human rights abuses.
        </Paragraph>
        <Paragraph>
          We urge users to report any suspected instances of sexual exploitation
          of minors and/or human trafficking to the relevant authorities.
          Contact details for various organizations in the United States,
          Germany, and the United Kingdom, including national hotlines and
          agencies specializing in these issues, are provided for this purpose.
          It is our collective responsibility to ensure the safety and rights of
          vulnerable individuals are protected.
        </Paragraph>
        <Paragraph>
          United States: National Center for Missing & Exploited Children
          (NCMEC) CyberTipline - report child exploitation 24-Hour Hotline:
          1-800-843-5678
        </Paragraph>
        <Paragraph>
          Polaris Project - Report Human Trafficking: 888-373-7888 Dept. of
          Health & Human Services: 888-373-7888 Children of the Night:
          800-551-1300 ACE National: 202-220-3019 Homeland Security
          Investigations Tip Line: 866-DHS-2-ICE Dept. of Justice: 888-428-7581
          FBI Office: http://www.fbi.gov/contact-us/field
        </Paragraph>
        <Paragraph>
          Germany: YPA Agent - Jugendschutzbeauftragter: Rechtsanwalt Dr. Daniel
          Kötz
        </Paragraph>
        <Paragraph>
          United Kingdom - Modern Slavery Helpline: Anyone who is concerned that
          someone may be a victim of trafficking or has information about
          trafficking should report it to their local police on 101 or
          confidentially to the Modern Slavery Helpline on 08000 121 700 or
          Crime stoppers. In an emergency, always call 999.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Disclaimer
        </TitleText>
        <Paragraph>
          RosesElite.com provides its services on an "as is" and "as available"
          basis. Pursuant to applicable law, RosesElite.com issues no
          warranties, whether express, implied, statutory, or otherwise,
          regarding the service and all content therein. This includes but is
          not limited to any implied warranties of satisfactory quality,
          merchantability, fitness for a particular purpose, or
          non-infringement. RosesElite.com does not guarantee that the service
          will be uninterrupted, secure, or error-free, nor does it assert that
          defects or errors in the service will be corrected, or that any
          content or information obtained through the service will be accurate
          or suitable for your purposes.
        </Paragraph>
        <Paragraph>
          RosesElite.com does not accept responsibility for content posted,
          sent, or received by you or any other member or third party through
          the service. Any material obtained through the service is done at your
          own discretion and risk.
        </Paragraph>
        <Paragraph>
          Furthermore, RosesElite.com disclaims any responsibility for the
          conduct of users, whether on or off the service.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Limitation of Liability
        </TitleText>
        <Paragraph>
          To the fullest extent permitted by applicable law, RosesElite.com,
          along with its affiliates, employees, licensors, and service
          providers, shall not be liable for any indirect, consequential,
          exemplary, incidental, special, punitive, fixed, or enhanced damages.
          This includes, but is not limited to, loss of profits, data, use,
          goodwill, or other intangible losses, resulting from: (i) your access
          to or use of or inability to access or use the service; (ii) the
          conduct or content of any members or third parties on or through our
          websites or in connection with the service; or (iii) any unauthorized
          access, use, or alteration of your content. The aggregate liability of
          RosesElite.com for any claims arising out of or relating to the
          service or this agreement shall not exceed the amount paid by you to
          RosesElite.com, if any, during the twenty-four (24) month period
          immediately preceding the date of the first claim filed against
          RosesElite.com. This limitation of liability applies irrespective of
          the basis of liability (contract, tort, statute, or otherwise) and
          regardless of the type of breach of obligations. The limitation of
          liability provisions set forth in this section shall apply even if
          your remedies under this agreement fail in their essential purpose. It
          should be noted that some jurisdictions do not allow the exclusion or
          limitation of certain damages, so these exclusions and limitations may
          not apply to you.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          18 U.S.C. 2257 Compliance Statement
        </TitleText>
        <Paragraph>
          RosesElite.com is fully compliant with 18 U.S.C. 2257 and all
          associated regulations. All models, actors, actresses, and other
          persons appearing in any visual depiction of actual sexually explicit
          conduct appearing or otherwise contained in this website were over the
          age of eighteen years at the time of the creation of such depictions.
          Records required to be maintained pursuant to 18 U.S.C. 2257 are kept
          by the custodian of records, who can be reached at 600 N BROAD STREET
          SUITE 5 #3977, MIDDLETOWN, DE, 19709.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Trademark
        </TitleText>
        <Paragraph>
          All trademarks, service marks, and trade names of RosesElite.com used
          on the site are trademarks or registered trademarks of RosesElite.com
          or its affiliates. Other marks, graphics, logos, scripts, and sounds
          are proprietary to RosesElite.com. No trademark or service mark
          license is granted in connection with the materials contained on this
          site. Accessing our website does not authorize anyone to use any name,
          logo, or mark in any manner.
        </Paragraph>
        <TitleText titleClass={`term-of-service__category`}>
          Jurisdiction
        </TitleText>
        <Paragraph>
          The terms, conditions, and use of the RosesElite.com website shall be
          governed by and construed in accordance with the laws of the State of
          Delaware, without giving effect to any principles of conflicts of law.
          By using this site, you agree that any legal action or proceeding
          between RosesElite.com and you for any purpose concerning this
          Agreement or the parties' obligations hereunder shall be brought
          exclusively in a federal or state court of competent jurisdiction
          sitting in Delaware, USA.
        </Paragraph>
      </div>
    </div>
  );
};
