import MomentsTrack from "components/Moments/MomentsTrack";
import React, { createContext, useState, ReactNode, useContext } from "react";
import MediaModal, { ICreatorMediaInfo } from "components/Modals/MediaModal";
import { NavigateFunction } from "react-router-dom";

interface MediaModalContextProps {
  openUseMediaModal: boolean;
  setOpenUseMediaMomentModal: (open: boolean) => void;
  setOpenUseMediaModal: (open: boolean) => void;
  setContentMomentMediaModal: (
    modalContent: ContentMomentMediaModalProps
  ) => void;
  setContentMediaModal: (modalContent: ContentMediaModalProps) => void;
}

type ContentMediaModalProps = {
  creatorId: string;
  medias: ICreatorMediaInfo[];
  unlockMediaCallback: (media: ICreatorMediaInfo) => void;
};

type ContentMomentMediaModalProps = {
  momentStyle: boolean;
  indexClicked: number;
  userIdToStarted: string;
  allUnseenUserIds: string[];
  allSeenUserIds: string[];
};

const MediaModalContext = createContext<MediaModalContextProps | undefined>(
  undefined
);

export const MediaModalProvider = ({ children }: { children: ReactNode }) => {
  const [openUseMediaModal, setOpenUseMediaModal] = useState(false);
  const [openUseMediaMomentModal, setOpenUseMediaMomentModal] = useState(false);
  const [contentMomentMediaModal, setContentMomentMediaModal] =
    useState<ContentMomentMediaModalProps>({} as ContentMomentMediaModalProps);
  const [contentMediaModal, setContentMediaModal] =
    useState<ContentMediaModalProps>({} as ContentMediaModalProps);

  return (
    <MediaModalContext.Provider
      value={{
        setContentMomentMediaModal,
        openUseMediaModal,
        setOpenUseMediaModal,
        setContentMediaModal,
        setOpenUseMediaMomentModal,
      }}
    >
      {children}
      {openUseMediaMomentModal ? (
        <div className="modal-overlay">
          <div className="modal-content">
            <MomentsTrack
              indexClicked={contentMomentMediaModal.indexClicked}
              userIdToStarted={contentMomentMediaModal.userIdToStarted}
              allUnseenUserIds={contentMomentMediaModal.allUnseenUserIds}
              allSeenUserIds={contentMomentMediaModal.allSeenUserIds}
            />
          </div>
        </div>
      ) : (
        false
      )}
      {openUseMediaModal ? (
        <div className="modal-overlay">
          <div className="modal-content">
            <MediaModal
              unlockMediaCallback={contentMediaModal.unlockMediaCallback}
              medias={contentMediaModal.medias}
              creatorId={contentMediaModal.creatorId}
            />
          </div>
        </div>
      ) : (
        false
      )}
    </MediaModalContext.Provider>
  );
};

export const useMediaModal = () => {
  const context = useContext(MediaModalContext);
  if (!context) {
    throw new Error("useMediaModal must be used within a MediaModalProvider");
  }
  return context;
};
