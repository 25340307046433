import { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import PhotoRequired from "components/PhotoPlaceholderRequired";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal";

import ChooseDocument from "pages/ChooseDocument";
import LegalResidence from "pages/LegalResidence";
import WaitListApplicationPage from "pages/WaitListApplication";

import applyCreator from "api/creatorApplications/postCreatorApplicationApply";

import { useBecomeCreator } from "contexts/becomeCreatorContext";

import { useUserInfo } from "../../hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useModal } from "hooks/useModal";
import { useFiles } from "../../hooks/documentContext";
import { useToast } from "hooks/useToast";

import GalleryIcon from "assets/icons/keyBoardIcons/gallery-icon-gray.svg";

const SendPhotoAlbumPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { registerBecomeCreator } = useBecomeCreator();
  const { firstName, lastName } = registerBecomeCreator;

  const { windowSize } = useWindowWidthSize();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setFiles } = useFiles();
  const { showToast } = useToast();
  const { userInfo, setUserInfo } = useUserInfo();

  const [instagramProfile, setInstagramProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>();

  const breakPoint = windowSize >= 768;

  const verifyImages = () => {
    if (photosAlbum) {
      const hasNullImage = (photosAlbum ?? []).filter(
        (photo): photo is File => photo === null
      );
      if (hasNullImage.length) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const sendPhotoSubmit = async () => {
    if (loading) return;
    setLoading(true);
    setUserInfo("instagram", instagramProfile);

    if (verifyImages()) {
      const validPhotos = (photosAlbum ?? []).filter(
        (photo): photo is File => photo !== null
      );

      setFiles(validPhotos);
      // ! Enable in future
      // setPrincipalScreen("/identity-verification");

      const prepareDataRequest = {
        legalName: `${firstName} ${lastName}`,
        instagramUser: userInfo.instagram,
        files: validPhotos,
      };

      const request = await applyCreator(
        userInfo.access_token,
        prepareDataRequest
      );

      switch (request.status) {
        case 201:
          if (
            request.res?.message?.statusCode &&
            request.res?.message?.statusCode !== 201
          ) {
            showToast({
              title: "Unexpected error",
              description:
                "We had a problem with the request. Please try again.",
              type: "error",
            });
          } else {
            if (breakPoint) {
              setModalContent(<WaitListApplicationPage />);
            } else {
              setPrincipalScreen("/wait-list-application");
            }
          }
          break;
        default:
          showToast({
            title: "Unexpected error",
            description: "We had a problem with the request. Please try again.",
            type: "error",
          });
      }

      if (breakPoint) {
        setOpenUseModal(true);
        setModalContent(<ChooseDocument />);
      } else {
        setPrincipalScreen("/choose-document");
      }
    } else {
      showToast({
        title: "Add 3 photos",
        description: "You need to add three photos to continue",
        type: "warning",
      });
    }

    setLoading(false);
  };

  const onChangeInput = (instagram: string) => {
    let userInstagram;
    if (instagram[0] !== "@") {
      userInstagram = "@" + instagram;
    } else {
      userInstagram = instagram;
    }

    setInstagramProfile(userInstagram);
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<LegalResidence />);
    } else {
      setPrincipalScreen("/legal-residence");
    }
  };

  return (
    <div className="send-photo-album send-photo-album-wrapper wrapper standard-page-layout bottom-spacing">
      <div className="send-photo-album-inner inner">
        <ButtonCloseModal />

        {!breakPoint ? (
          <PageTitle
            title={"Become a Creator"}
            subtitle={"Start Earning"}
            hasCloseIcon={false}
            onBackClick={handlerBackPage}
          />
        ) : (
          false
        )}
        <main className="send-photo-album__main">
          <Header
            title={
              <>
                Add your <br />
                photos
              </>
            }
            subTitle={"Select 3 photos that clearly shows you."}
            marginTop={0}
            className="send-photo-album__header"
            backButtonClick={handlerBackPage}
            {...(breakPoint
              ? {
                  headerIcon: "back-button",
                  inlineElements: true,
                  titleClass: "header",
                }
              : {
                  titleClass: "send-photo-album__title",
                })}
          />

          <PhotoRequired
            quantityFiles={3}
            photoAlbumCallback={setPhotosAlbum}
          />

          <SubText className="send-photo-album__sub-text">
            Boost your approval chances by adding your Instagram profile. Your
            profile must be set to public (highly recommended).
          </SubText>

          <InputText
            placeholder="@instagram (optional)"
            textTransform="none"
            inputType="text"
            value={instagramProfile}
            onChangeInput={(e) => onChangeInput(e)}
          />
        </main>
        <div className="send-photo-album__bottom">
          <SubText
            icon={GalleryIcon}
            altIcon="gallery photos icon"
            className={"send-photo-album__sub-text-alt"}
            classNameIcon={"send-photo-album__sub-text-icon"}
          >
            We welcome sensual expression that's artistic and sophisticated.
            Please avoid explicit content to maintain the respectful and
            tasteful spirit of our community.
          </SubText>

          <Button
            buttonStyle="primary"
            disabled={loading}
            onClick={sendPhotoSubmit}
          >
            Submit application
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SendPhotoAlbumPage;
