import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import { ICatalogResponse } from "../../types/catalog";
import { IMoments, IMomentsUnseen } from "../../types/moments";
import { useUserInfo } from "../../hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useModal } from "../../hooks/useModal";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import { MomentsTriggerContext } from "../../contexts/momentTrigger";
import { FavoritesCatalogContext } from "../../contexts/favoritesCatalogTrigger";
import { useIconContext } from "../../contexts/iconNavContext";
import { useChat } from "../../contexts/openedChatContext";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import MomentsCircles from "components/Moments/MomentsCircles";
import getUsersListAll, {
  GetUsersListAllQueryParams,
} from "../../api/user/getUsersListAll";
import getMomentsUnseen from "../../api/moments/getMomentsUnseen";
import getMomentSeen from "../../api/moments/getMomentsSeen";
import AddMediaPage from "../AddMediaPage";
import { FavoritesListContext } from "../../contexts/favoritesListTrigger";
import { useSearchCatalog } from "hooks/useSearchCatalog";
import getUserMoments from "api/moments/getUserMoments";
import getUserPreferences from "api/userPreferences/getUserPreferences";
import { PatchUserPreferencesBodyDataRequest } from "api/userPreferences/patchUserPreferences";
import { useMediaModal } from "hooks/useMediaModal";
import getPublicCreators from "api/publicCreators/getPublicCreators";
import { CreatorDetailsContext } from "contexts/creatorDetails";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";
import verifyToken from "utils/verifyToken";
import { catalogCreatorsContext } from "contexts/catalogCreatorsContext";
import { textCapitalize } from "utils/textCapitalize";
import { useNavigate, useParams } from "react-router-dom";
import { shortClassNames } from "utils/shortClassNames";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { PullToRefreshContext } from "contexts/pullToRefreshContext";
import { usePullToRefreshHandler } from "hooks/usePullToRefreshHandler";
import ProfileCard from "components/ProfileCard";

const CatalogPage: React.FC = () => {
  // Contexts
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();
  const paramsUrl = useParams();

  const { setContentMomentMediaModal, setOpenUseMediaMomentModal } =
    useMediaModal();
  const { handleButtonClick, getIconClass, activeIcon } = useIconContext();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setPrincipalScreen, setSecondaryScreen, setTertiaryScreen } =
    useDeskNavigation();
  const { favoritesCatalogTrigger, setFavoritesCatalogTrigger } = useContext(
    FavoritesCatalogContext
  );
  const { favoritesListTrigger } = useContext(FavoritesListContext);
  const { momentTrigger } = useContext(MomentsTriggerContext);
  const { isChatPageOpen } = useContext(ToggleChatOpenContext);
  const { activeChatId } = useChat();

  // Local States
  const [counters, setCounters] = useState<string[]>([]); // MOCK
  const [filterType, setFilterType] = useState<
    "all" | "vip" | "favorites" | "guys" | "top-creators" | "girls" | "ai"
  >("all");
  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);
  const [catalogFilteredData, setCatalogFilteredData] = useState<
    ICatalogResponse[] | []
  >([]);
  const [momentsUnseen, setMomentsUnseen] = useState<IMomentsUnseen[] | []>([]);
  const [momentsSeen, setMomentsSeen] = useState<IMomentsUnseen[] | []>([]);
  const [activatedButton, setActivatedButton] = useState(0);
  const divRef = useRef<HTMLDivElement | null>(null);
  const pullToRefresh = usePullToRefreshHandler(divRef);
  const [creatorSelfMoments, setCreatorSelfMoments] = useState<IMoments[] | []>(
    []
  );
  const { setCreatorDetails } = useContext(CreatorDetailsContext);

  const { searchValue } = useSearchCatalog();
  const checkAuth = useAuthCheckOpenModal({ navigate, paramsUrl });
  const [widthClass, setWidthClass] = useState("mobile-size");
  const { setCatalogList } = useContext(catalogCreatorsContext);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        const sizeAndSpacing = entries[0].contentRect.width + 24;
        if (sizeAndSpacing < 425) setWidthClass("mobile-size");
        else if (sizeAndSpacing < 768) setWidthClass("mobile-size-plus");
        else if (sizeAndSpacing < 1024) setWidthClass("tablet-size");
        else if (sizeAndSpacing < 1728) setWidthClass("wide-size");
        else setWidthClass("super-wide-size");
      }
    });

    if (divRef.current) observer.observe(divRef.current);

    return () => observer.disconnect();
  }, []);

  useLayoutEffect(() => {
    const filteredCatalogData = catalogData
      .filter((profileCard) => {
        switch (filterType) {
          case "ai":
            return profileCard?.profile.isAI;
          case "girls":
            return (
              profileCard?.profile?.gender?.toLowerCase() === "woman" ||
              profileCard?.profile?.gender === null
            );
          case "guys":
            return profileCard?.profile?.gender?.toLowerCase() === "man";
          default:
            return profileCard;
        }
      })
      .sort((a, b) => (b?.profile?.isAI ? 1 : 0) - (a?.profile?.isAI ? 1 : 0));

    const search = filteredCatalogData.filter((catalog) =>
      catalog.displayname.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    setCatalogFilteredData(search);
  }, [catalogData, filterType, searchValue]);

  useEffect(() => {
    getCatalogData(userInfo.access_token);
  }, [favoritesListTrigger]);

  useEffect(() => {
    if (!verifyToken()) return;

    momentsSeenRequest();
    momentsUnseenRequest();
    getSelfMoments();
  }, [momentTrigger]);

  useEffect(() => {
    if (activeIcon === "grid") {
      buttonGroupClickHandler("all");
      // the next line is necessary to activate the useEffect
      setActivatedButton(4);
      // >>
      setTimeout(() => {
        setActivatedButton(1);
      }, 100);
    }
  }, [handleButtonClick]);

  useEffect(() => {
    if (favoritesCatalogTrigger) {
      setCatalogFilteredData(
        catalogData
          .filter((profile) => profile.isFavorite)
          .sort(
            (a, b) => (b?.profile?.isAI ? 1 : 0) - (a?.profile?.isAI ? 1 : 0)
          )
      );
    } else {
      const filteredCatalogData = catalogData
        .filter((profileCard) => {
          switch (filterType) {
            case "ai":
              return profileCard?.profile.isAI;
            case "girls":
              return (
                profileCard?.profile?.gender?.toLowerCase() === "woman" ||
                profileCard?.profile?.gender === null
              );
            case "guys":
              return profileCard?.profile?.gender?.toLowerCase() === "man";
            default:
              return profileCard;
          }
        })
        .sort(
          (a, b) => (b?.profile?.isAI ? 1 : 0) - (a?.profile?.isAI ? 1 : 0)
        );
      setCatalogFilteredData(filteredCatalogData);
    }
  }, [catalogData, favoritesCatalogTrigger, filterType]);

  useEffect(() => {
    const generateRandomNumber = (index: number) => {
      const randomNum = Math.floor(Math.random() * 1799) + 200 + index * 100;
      return randomNum;
    };

    const formatCounter = (num: number) => {
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + "k";
      }
      return num.toString();
    };

    const randomCounters = catalogData.map((_, index) => {
      const randomCounter = generateRandomNumber(index);
      return formatCounter(randomCounter);
    });

    setCounters(randomCounters);
  }, [catalogData]);

  const getSelfMoments = async () => {
    try {
      const request = await getUserMoments(
        userInfo.access_token,
        userInfo.user_id
      );
      switch (request.status) {
        case 200:
          const moments: IMoments[] = request.res;

          setCreatorSelfMoments(moments);
          break;
        case 401:
          // if (!checkAuth()) return;
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const catalogHandlerCallback = async (profile: ICatalogResponse) => {
    setCreatorDetails(profile);
    if (isChatPageOpen && verifyToken()) {
      setTimeout(() => {
        setSecondaryScreen("/personal-chat", {
          chatId: activeChatId ?? "",
          userId: profile.userId,
        });
      }, 0);
    } else {
      setSecondaryScreen("");
    }

    setTertiaryScreen("");

    setPrincipalScreen("/producer-summary", { userId: profile.userId });
  };

  const momentsSeenRequest = async () => {
    if (!verifyToken()) return;

    try {
      const resMomentsSeen = await getMomentSeen(userInfo.access_token, 10);

      switch (resMomentsSeen.status) {
        case 200:
          setMomentsSeen(resMomentsSeen.res);
          break;
        case 401:
          // if (! checkAuth()) return;

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const momentsUnseenRequest = async () => {
    try {
      const resMomentsUnseen = await getMomentsUnseen(
        userInfo.access_token,
        10
      );

      switch (resMomentsUnseen.status) {
        case 200:
          setMomentsUnseen(resMomentsUnseen.res);
          break;
        case 401:
          // if (! checkAuth()) return;

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCatalogDataWithoutLogin = async () => {
    try {
      const publicCreators = await getPublicCreators({ take: 100 });

      switch (publicCreators.status) {
        case 200:
          // setCatalogList(publicCreators.res);
          setCatalogData(publicCreators.res);

          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCatalogData = async (userToken: string) => {
    if (!verifyToken()) {
      getCatalogDataWithoutLogin();
    } else {
      try {
        const response = await getUserPreferences(userToken);
        const userPreferences: PatchUserPreferencesBodyDataRequest =
          response.res;

        switch (response.status) {
          case 200:
            const prepareParams: Partial<GetUsersListAllQueryParams> = {
              ageMax: userPreferences?.preferedAgeMax,
              ageMin: userPreferences?.preferedAgeMin,
              ethnicity: userPreferences?.preferedEthnicities,
              eyeColor: userPreferences?.preferedEyeColors,
              hairColor: userPreferences?.preferedHairColors,
              heightMax: userPreferences?.preferedHeightMax,
              heightMin: userPreferences?.preferedHeightMin,
              languages: userPreferences?.preferedLanguages,
              nationality: userPreferences?.preferedNationalities,
              weightMax: userPreferences?.preferedWeightMax,
              weightMin: userPreferences?.preferedWeightMin,
              take: 100,
              // cursor:""
            };

            Object.keys(prepareParams).forEach(
              (key) =>
                prepareParams[key as keyof GetUsersListAllQueryParams] ===
                  undefined &&
                delete prepareParams[key as keyof GetUsersListAllQueryParams]
            );

            const listCatalogRequest = await getUsersListAll(
              userToken,
              prepareParams
            );

            if (listCatalogRequest?.status === 200) {
              // setCatalogList(listCatalogRequest.res);

              setCatalogData(listCatalogRequest.res);
              setCatalogList(listCatalogRequest.res);

              if (activeIcon === "heart-outline") {
                const favorites = listCatalogRequest.res
                  .filter((profile: ICatalogResponse) => profile.isFavorite)
                  .sort(
                    (a: ICatalogResponse, b: ICatalogResponse) =>
                      (b?.profile?.isAI ? 1 : 0) - (a?.profile?.isAI ? 1 : 0)
                  );

                setCatalogFilteredData(favorites);
              } else {
                setCatalogFilteredData(listCatalogRequest.res);
              }
            }

            break;

          case 401:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const buttonGroupClickHandler = (
    filter:
      | "all"
      | "vip"
      | "favorites"
      | "guys"
      | "top-creators"
      | "girls"
      | "ai"
  ) => {
    setFavoritesCatalogTrigger(false);
    setFilterType(filter);
    handleButtonClick("grid");
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "All",
      clickAction: () => buttonGroupClickHandler("all"),
      id: 1,
      textRight: catalogData.length.toString(),
    },
    {
      text: "Girls",
      clickAction: () => buttonGroupClickHandler("girls"),
      id: 2,
      textRight: catalogData
        .filter(
          (catalog) =>
            catalog.profile.gender === "WOMAN" ||
            catalog.profile.gender === null
        )
        .length.toString(),
    },
    {
      text: "Guys",
      clickAction: () => buttonGroupClickHandler("guys"),
      id: 3,
      textRight: catalogData
        .filter((catalog) => catalog.profile.gender === "MAN")
        .length.toString(),
    },
    {
      text: "AI",
      clickAction: () => buttonGroupClickHandler("ai"),
      id: 4,
      textRight: catalogData
        .filter((catalog) => catalog.profile.isAI === true)
        .length.toString(),
    },
  ];

  const addMomentHandler = () => {
    if (!checkAuth()) return;

    setModalContent(<AddMediaPage previousPage="home" />);
    setOpenUseModal(true);
  };

  const clickMoment = (userId: string, index: number) => {
    if (!checkAuth()) return;
    // setPrincipalScreen("");
    // setSecondaryScreen("");
    // setTertiaryScreen("");

    setContentMomentMediaModal({
      indexClicked: index,
      userIdToStarted: userId,
      momentStyle: true,
      allUnseenUserIds: momentsUnseen.map((moment) => moment.userId),
      allSeenUserIds: momentsSeen.map((moment) => moment.userId),
    });

    setOpenUseMediaMomentModal(true);
  };

  const classNamesCatalog = [
    "catalog-page",
    widthClass,
    momentsSeen.length ||
    momentsUnseen.length ||
    userInfo?.account_type === "CREATOR"
      ? "space-nav"
      : "",
  ]
    .filter(Boolean)
    .join(" ")
    .trim();

  return (
    <div className="catalog-page-wrapper">
      <div className="catalog-page-inner">
        <div className="actions-wrapper">
          {momentsSeen.length ||
          momentsUnseen.length ||
          (userInfo?.account_type === "CREATOR" && verifyToken()) ? (
            <MomentsCircles
              showAddMoment={userInfo.account_type === "CREATOR"}
              // showAddMoment
              clickMoment={clickMoment}
              addMomentClick={addMomentHandler}
              momentsDetails={{ momentsSeen, momentsUnseen }}
              className="add-moments-container__moments"
              creatorSelfMoments={creatorSelfMoments}
            />
          ) : (
            false
          )}
          <ButtonNavigation
            buttons={buttonsGroup}
            className={`button-navigation-catalog`}
            activatedButton={activatedButton}
          />
        </div>

        <div ref={divRef} className={classNamesCatalog}>
          {catalogFilteredData?.map((profileCard, index) => {
            return (
              <ProfileCard
                className="catalog-page__catalog-card"
                key={index}
                data={profileCard}
                index={index}
                // hoveredIndex={hoveredIndex}
                onClick={() => {
                  catalogHandlerCallback(profileCard);
                }}
                // setHoveredIndex={setHoveredIndex}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CatalogPage;
