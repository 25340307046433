import { localStorageClear } from "./localStorageClear";
import verifyToken from "./verifyToken";

export type IPagePositionRoute = {
  index: number;
  route: IPagePositionPage;
  page: string;
  params: Params;
};

export type IPagePositionPage =
  | "main"
  | "principal"
  | "secondary"
  | "tertiary"
  | "feed";

export interface Params {
  [key: string]: string;
}

export type PagePositionStorage = Record<IPagePositionPage, IPagePositionRoute>;

const setScreenWithHistory = (
  screenKey: IPagePositionPage,
  path: string,
  params: Params
) => {
  const state = { params, path, screenKey };
  window.history.pushState(
    state,
    "",
    ""
    // `?${new URLSearchParams(params).toString()}`
  );
};

export const pagesPositionFeeder = (
  route: IPagePositionPage,
  page: string,
  params: Params
) => {
  if (!verifyToken()) {
    return localStorageClear();
  }

  const storagePagePosition = localStorage.getItem("roses-pages-position");
  setScreenWithHistory(route, page, params);

  //TODO -> We have a new history mode. For now, we need to clear the old history
  //Remove the line below in the future
  localStorage.removeItem("roses-storage-history");

  let parseStoragePagePosition: Record<string, IPagePositionRoute> = {};

  try {
    if (storagePagePosition && storagePagePosition !== "undefined") {
      parseStoragePagePosition = JSON.parse(storagePagePosition);
    }
  } catch (error) {
    console.error(error);
    parseStoragePagePosition = {};
  }

  parseStoragePagePosition[route] = {
    index: 1,
    route,
    page,
    params,
  };

  localStorage.setItem(
    "roses-pages-position",
    JSON.stringify(parseStoragePagePosition)
  );
};

export const deleteFromPagePositionStorage = (route: IPagePositionPage) => {
  const storagePagePosition = localStorage.getItem("roses-pages-position");

  let parseStoragePagePosition: Record<string, IPagePositionRoute> = {};

  try {
    if (storagePagePosition && storagePagePosition !== "undefined") {
      parseStoragePagePosition = JSON.parse(storagePagePosition);
    }
  } catch (error) {
    console.error(error);
    parseStoragePagePosition = {};
  }

  if (parseStoragePagePosition[route]) {
    delete parseStoragePagePosition[route];
  }

  localStorage.setItem(
    "roses-pages-position",
    JSON.stringify(parseStoragePagePosition)
  );
};
