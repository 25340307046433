import React, { ReactNode } from "react";

import "./styles.scss";

import Button from "../../Buttons/Button";

import Tooltip from "components/Tooltip";

import { IButtonStyles } from "types/buttonStyles";

interface RosesDashboardProps {
  showAddButton?: boolean;
  showRedeemButton?: boolean;
  showGoldRoses?: boolean;
  title: ReactNode;
  className?: string;
  customButton?: {
    title: string;
    styles: IButtonStyles;
    onClick?: () => void;
  }[];
  onClick?: () => void;
}

const RosesDashboard: React.FC<RosesDashboardProps> = ({
  showAddButton = false,
  showRedeemButton = false,
  showGoldRoses = false,
  className,
  customButton,
  title = "0",
  onClick,
}) => {
  return (
    <div className={`roses-dashboard-container inner ${className ?? ""}`}>
      <div className="text-container">
        <div className="title-text">{title}</div>
        <div className="subtitle-text ">
          Available Roses
          <Tooltip text={"Tooltip"} />
        </div>
        {showGoldRoses && (
          <span className="subtitle-text gold  ">0 golden</span>
        )}
      </div>
      {(showAddButton || showRedeemButton || customButton) && (
        <div className="button-container  ">
          {showAddButton && (
            <Button
              children={"Add Roses"}
              buttonStyle={"primary"}
              onClick={onClick}
            />
          )}
          {showRedeemButton && (
            <Button
              children={"Redeem Roses"}
              buttonStyle={"secondary"}
              onClick={onClick}
            />
          )}
          {customButton &&
            customButton.map((button, index) => {
              return (
                <Button
                  key={`roses-dashboard-btn-${index}`}
                  children={button.title}
                  buttonStyle={button?.styles ?? "primary"}
                  onClick={button.onClick}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default RosesDashboard;
