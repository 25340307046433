import fetchApi from "../fetchApi";

interface IPatchGoal {
  token: string;
  title: string;
  target: number;
}

const patchCreatorSettingsGoal = async (props: IPatchGoal) => {
  const { token, title, target } = props;

  return await fetchApi({
    service: "creatorSettings",
    endPoint: "/goal",
    method: "PATCH",
    data: { title, target },
    token,
  });
};

export default patchCreatorSettingsGoal;
