import fetchApi from "../fetchApi";

const patchAutomaticRenew = async (
  token: string,
  data: {
    creatorId: string;
    automaticRenew: boolean;
  }
) => {
  return await fetchApi({
    service: "subscribe",
    endPoint: `/automatic-renew`,
    method: "PATCH",
    data,
    token,
  });
};

export default patchAutomaticRenew;
