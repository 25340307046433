import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import * as jwtDecode from "jwt-decode";

import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";

import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import postUserAuth from "../../api/auth/postLogin";

import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

import { IInfoToken } from "../../types/infoToken";

import LockIcon from "assets/icons/lock-icon-gray.svg";

const PasswordSignInPage: React.FC = () => {
  const navigate = useNavigate();
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const { showToast } = useToast();

  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  useEffect(() => {
    const creatorStatus = async () => {
      const response = await getCreatorStatus(userInfo.access_token);
      if (response?.status === 200) {
        setUserInfo("referral_code", response?.res.referralCode);

        setUserInfo("creator_application_status", response?.res.status);

        setUserInfo(
          "creator_application_id",
          response?.res.creatorApplicationId
        );
      }
    };

    creatorStatus();
  }, []);

  const loginSubmit = async () => {
    setIsLoading(true);

    // const prepareData = {
    //   phone: userInfo.user_phone.split("+")[1],
    //   password: userPassword,
    // };
    const prepareData = {
      email: userInfo.user_phone.split("+")[1],
      password: userPassword,
    };

    const responseAuth = await postUserAuth(prepareData);

    switch (responseAuth.status) {
      case 200:
        const response = await responseAuth.res;
        const access_token = response.access_token;
        const userInfoToken = (await jwtDecode.jwtDecode(
          access_token
        )) as IInfoToken;

        setUserInfo("access_token", access_token);
        setUserInfo("display_name", userInfoToken?.validUser.displayname);
        setUserInfo("account_type", userInfoToken?.validUser.role);
        setUserInfo("user_phone", userInfoToken?.validUser.phone);
        setUserInfo("user_email", userInfoToken?.validUser.email);
        setUserInfo("user_id", userInfoToken?.validUser.userId);

        const responseStatus = await getCreatorStatus(access_token);

        if (responseStatus?.status === 200) {
          setUserInfo("referral_code", responseStatus?.res.referralCode);
          setUserInfo("creator_application_status", responseStatus?.res.status);
          setUserInfo(
            "creator_application_id",
            responseStatus?.res.creatorApplicationId
          );
        }

        if (
          responseStatus?.res?.status === "APPROVED" &&
          userInfoToken?.validUser.role.toLocaleLowerCase() === "consumer"
        ) {
          setComponent("/welcome-user");
        } else {
          navigate("/app");
        }

        break;
      case 401:
        showToast({
          description: "Check your credentials and, please,  try again",
          title: "Password Mismatch",
          type: "error",
        });

        setIsWarning(true);

        setTimeout(() => {
          setIsWarning(false);
        }, 4000);
        break;
    }

    setIsLoading(false);
  };

  const cancelSubmit = () => {
    navigate("/");
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginSubmit();
    }
  };

  return (
    <div className="password-sign-in password-sign-in-wrapper wrapper">
      <div className="password-sign-in-inner inner"></div>
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => setComponent("/create-number-verify")}
          title={
            <>
              Enter your
              <br />
              password
            </>
          }
          subTitle={
            <>
              Your password should be at least <br /> 8 characters long
            </>
          }
        />
        <InputText
          inputType="password"
          value={userPassword}
          placeholder="password"
          onChangeInput={(e) => setUserPassword(e)}
          onKeyDown={onKeyDown}
          isWarning={isWarning}
        />
        <SubText icon={LockIcon} altIcon="clock icon">
          Never share your credentials with anyone. Forgot your password?
          <span
            className="password-sign-in__reset-password  "
            onClick={() => setComponent("/reset-password")}
          >
            Reset Password
          </span>
        </SubText>
      </div>

      <SubText>
        Don't have an account?{" "}
        <span
          className="anchor-link"
          onClick={() => setComponent("/create-account")}
        >
          Create an account.
        </span>
      </SubText>
      <Button
        disabled={userPassword.length < 8 || isLoading}
        buttonStyle="primary"
        onClick={loginSubmit}
      >
        Login
      </Button>
      <Button buttonStyle="quaternary" onClick={cancelSubmit}>
        Cancel
      </Button>
    </div>
  );
};

export default PasswordSignInPage;
