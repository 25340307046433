import React, { useEffect, useState } from "react";
import "./styles.scss";

interface CardInvitingMessagesProps {
  className?: string;
  textMessage: string;
}

const CardInvitingMessages: React.FC<CardInvitingMessagesProps> = (props) => {
  const { className, textMessage } = props;
  const [hourNow, setHourNow] = useState("0:00");

  useEffect(() => {
    const now = new Date();
    const horas = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const localHour = `${horas}:${minutes}`;

    setHourNow(localHour);
  }, [textMessage]);

  return (
    <section id="card-inviting-messages" className={className ?? ""}>
      <p className="card-inviting-messages__time    ">{hourNow}</p>
      <div className="card-inviting-messages__balloon_wrapper">
        <div className="card-inviting-messages__text-wrapper">
          <p className="text-wrapper__text-message  ">{textMessage}</p>
        </div>
      </div>
    </section>
  );
};

export default CardInvitingMessages;
