import { useNavigate } from "react-router-dom";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Button from "components/Buttons/Button";
import ListItemLink from "components/Lists/ListItemLink";

import identityIconGray from "assets/icons/identity-icon-gray.svg";
import languageIcon from "assets/icons/language-icon-gray.svg";
import lockIcon from "assets/icons/lock-icon-gray.svg";
import personalSafetyIconGray from "assets/icons/shieldIcons/personal-icon-gray.svg";
import gearIcon from "assets/icons/gear-icon-gray.svg";
import moneyIcon from "assets/icons/dollar-icon-gray.svg";
import verifyIcon from "assets/icons/verify.svg";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "hooks/userInfo";
import { useToast } from "hooks/useToast";

import { localStorageClear } from "utils/localStorageClear";

const UserSettingsAccountPage: React.FC = () => {
  const {
    componentFeed,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const { setComponent } = useLoginSignUpNavigation();
  const navigate = useNavigate();
  const { clearUserInfo } = useUserInfo();
  const { showToast } = useToast();

  const logoutHandler = () => {
    clearUserInfo();
    sessionStorage.clear();
    localStorageClear();
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setComponent("");
    navigate("/login");
  };

  const listItems = [
    {
      labelContent: "Identity Verification",
      iconLeftContent: identityIconGray,
      clickAction: () => {
        showToast({
          type: "info",
          title: "Coming soon!",
          description: "We appreciate your patience.",
        });
      },
    },
    {
      labelContent: "Language & Location",
      iconLeftContent: languageIcon,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-language");
        } else {
          setPrincipalScreen("/user-settings-language");
        }
      },
    },
    {
      labelContent: "Password & Security",
      iconLeftContent: personalSafetyIconGray,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-change-password");
        } else {
          setPrincipalScreen("/user-settings-change-password");
        }
      },
    },
    {
      labelContent: "Privacy",
      iconLeftContent: lockIcon,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-privacy");
        } else {
          setPrincipalScreen("/user-settings-privacy");
        }
      },
    },
    {
      labelContent: "Manage Account",
      iconLeftContent: gearIcon,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-manage-account");
        } else {
          setPrincipalScreen("/user-settings-manage-account");
        }
      },
    },
    {
      labelContent: "Goals Account",
      iconLeftContent: moneyIcon,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-goals");
        } else {
          setPrincipalScreen("/user-settings-goals");
        }
      },
    },
  ];

  return (
    <div className="user-settings-account user-settings-account-wrapper wrapper standard-page-layout">
      <div className="user-settings-account-inner inner">
        <PageTitle
          title={"Account"}
          subtitle={"Manage Account"}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings");
            } else {
              setPrincipalScreen("/user-settings");
            }
          }}
          hasCloseIcon={false}
        />
        <div className="list-container-user-settings  ">
          {listItems.map((item, index, arr) => (
            <ListItemLink
              key={index}
              onClick={item.clickAction}
              hasArrowRight
              hasSeparator={arr.length > index + 1}
              highlightText={item.labelContent}
              isImageCircle
              imageLeft={item.iconLeftContent}
              rightNodeContent={
                <p className="text-detail">
                  <>
                    {index === 0 && <img src={verifyIcon} alt="Verify Icon" />}
                  </>
                </p>
              }
            />
          ))}

          <div className="button-bottom">
            <Button buttonStyle="secondary" onClick={logoutHandler}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsAccountPage;
