import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Camera from "assets/icons/camera-icon-gray.svg";
import CloseIconPeach from "assets/icons/closeIcons/close-icon-peach.svg";

interface DocumentPlaceholderProps {
  docFrontCallback: (files: File | null) => void;
  docBackCallback: (files: File | null) => void;
  documentFileFront: File | null;
  documentFileBack: File | null;
}

const DocumentPlaceholder: React.FC<DocumentPlaceholderProps> = (props) => {
  const {
    docBackCallback,
    docFrontCallback,
    documentFileBack,
    documentFileFront,
  } = props;
  const [documentFront, setDocumentFront] = useState<File | null>(null);
  const [documentBack, setDocumentBack] = useState<File | null>(null);

  const fileInputRefFront = useRef<HTMLInputElement>(null);
  const fileInputRefBack = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setDocumentFront(documentFileFront);
    setDocumentBack(documentFileBack);
  }, [documentFileBack, documentFileFront]);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    side: string
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (side === "back") {
        setDocumentBack(file);
        docBackCallback(file);
      } else {
        setDocumentFront(file);
        docFrontCallback(file);
      }
    }
  };

  const handleRemoveFile = (side: string) => {
    if (side === "back") {
      setDocumentBack(null);
      docBackCallback(null);
    } else {
      setDocumentFront(null);
      docFrontCallback(null);
    }
  };

  const handleFileInputClick = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.click();
  };

  return (
    <div id="document-placeholder">
      <div
        className="photo-preview document-front"
        onClick={() => handleFileInputClick(fileInputRefFront)}
      >
        {!documentFront ? (
          <>
            <span>
              <img src={Camera} alt="camera icon" />
            </span>
            <input
              type="file"
              name="upload-photo-front"
              key="upload-photo-front"
              ref={fileInputRefFront}
              accept=".jpg,.jpeg,.png"
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, "front")}
            />
            <p className="bottom-text">FRONT</p>
          </>
        ) : (
          <div className="preview-images">
            <img
              src={URL.createObjectURL(documentFront)}
              alt="front document"
            />
            <button onClick={() => handleRemoveFile("front")}>
              <img src={CloseIconPeach} alt="remove icon" />
            </button>
          </div>
        )}
      </div>
      <div
        className="photo-preview document-back"
        onClick={() => handleFileInputClick(fileInputRefBack)}
      >
        {!documentBack ? (
          <>
            <span>
              <img src={Camera} alt="camera icon" />
            </span>
            <input
              type="file"
              name="upload-photo-back"
              key="upload-photo-back"
              ref={fileInputRefBack}
              accept=".jpg,.jpeg,.png"
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, "back")}
            />
            <p className="bottom-text">BACK</p>
          </>
        ) : (
          <div className="preview-images">
            <img src={URL.createObjectURL(documentBack)} alt="back document" />
            <button onClick={() => handleRemoveFile("back")}>
              <img src={CloseIconPeach} alt="remove icon" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentPlaceholder;
