import fetchApi from "../fetchApi";

interface SubscribeDetails {
  creatorId: string;
  billingCycle: string;
}

const postSubscribe = async (token: string, body: SubscribeDetails) => {
  return await fetchApi({
    service: "subscribe",
    endPoint: "/create",
    method: "POST",
    token,
    data: body,
  });
};

export default postSubscribe;
