import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import SendRosesModal from "components/Chat/ChatModals/SendRosesModal";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";

import { useChat } from "contexts/openedChatContext";
import { useDeskNavigation, Params } from "hooks/useDeskNavigation";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useUserInfo } from "hooks/userInfo";
import { useOpenChat } from "hooks/useOpenChat";
import { useWebSocket } from "contexts/webSocketContext";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useAvailableRoses } from "contexts/availableRosesContext";

import { CreatorDetailsContext } from "contexts/creatorDetails";
import { FavoritesListContext } from "contexts/favoritesListTrigger";
import { MomentsTriggerContext } from "contexts/momentTrigger";
import { ToggleChatOpenContext } from "contexts/toggleChatOpen";
import { catalogCreatorsContext } from "contexts/catalogCreatorsContext";

import deleteRemoveFromFavorites from "api/favorite/deleteRemoveFromFavorites";
import getCreatorSettingsGoalProgress from "api/creatorSettings/getCreatorSettingsGoalProgress";
import getCreatorSettingsGoalUser from "api/creatorSettings/getCreatorSettingsGoalUser";

import postAddToFavorite from "api/favorite/postAddToFavorite";
import postCreatorSettingsGoalDonate from "api/creatorSettings/postCreatorSettingsGoalDonate";
import getPublicDetails from "api/publicDetails/getProfileDetails";
import { Chat } from "types/chatInfo";
import { IGoal } from "types/goal";
import { ICatalogResponse, PhotosProfile } from "types/catalog";

import verifyToken from "utils/verifyToken";
import ProducerInfo from "pages/ProducerSummary/ProducerInfo";

const ProducerSummary: React.FC = () => {
  const {
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    componentFeed,
    params,
  } = useDeskNavigation();
  const { userInfo } = useUserInfo();
  const { activeChatId } = useChat();
  const { getChats } = useWebSocket();
  const { windowSize } = useWindowWidthSize();
  const { isChatPageOpen, setIsChatPageOpen } = useContext(
    ToggleChatOpenContext
  );
  const { setFavoritesListTrigger } = useContext(FavoritesListContext);
  const { setMomentTrigger } = useContext(MomentsTriggerContext);
  const { creatorDetails } = useContext(CreatorDetailsContext);
  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();

  const producerRef = useRef<HTMLDivElement | null>(null);

  const [isProfileFavorite, setIsProfileFavorite] = useState(false);
  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>(
    {} as ICatalogResponse
  );
  const [userIdSelected, setUserIdSelected] = useState<string>("");
  const [photoProducer, setPhotoProducer] = useState<PhotosProfile[] | []>([]);
  const [videoPublic, setVideoPublic] = useState<string | null>();

  const [activeChatIdState, setActiveChatIdState] = useState("");
  const [pageParams, setPageParams] = useState<Params>({} as Params);
  const [goal, setGoal] = useState<IGoal | undefined>(undefined);
  const [goalProgress, setGoalProgress] = useState<string | undefined>("0%");
  const [avatarProfile, setAvatarProfile] = useState("");
  const { setOpenUseModal, setModalContent } = useModal();
  const { showToast } = useToast();

  const navigate = useNavigate();
  const paramsUrl = useParams();

  const checkAuth = useAuthCheckOpenModal({ navigate, paramsUrl });
  const pastTokenStorage = localStorage.getItem("user-info-past-token");

  const openChat = useOpenChat(userIdSelected);

  // Set unlocked chats
  const getListChats = useCallback(async () => {
    getChats({}, (res: Chat[]) => {
      const openedChatsIds: string[] | [] = res?.map(
        (chat: Chat) => chat?.chatParticipants[0]?.user?.userId
      );
      setOpenedChatUserIds(openedChatsIds);
    });
  }, [getChats, userIdSelected]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (Object.keys(params).length > 0) {
      setPageParams(params);
    }
  }, [creatorDetails]);

  useEffect(() => {
    if (creatorDetails) {
      setUserIdSelected(creatorDetails.userId);
    }
  }, [creatorDetails]);

  useEffect(() => {
    getListChats();
  }, [getChats, getListChats]);

  useEffect(() => {
    if (isChatPageOpen) {
      handlerOpenChat();
    }
  }, [userIdSelected]);

  useEffect(() => {
    const producerDetail = async () => {
      if (creatorDetails) {
        setIsProfileFavorite(creatorDetails?.isFavorite);
        setProducerDetails(creatorDetails);
        setPhotoProducer(creatorDetails.profile.photos);
        setAvatarProfile(
          creatorDetails.profile?.avatar?.thumbnailUrl ||
            creatorDetails.profile?.avatar?.mediumUrl ||
            creatorDetails.profile?.avatar?.url
        );

        if (creatorDetails.profile.cover) {
          setVideoPublic(creatorDetails.profile?.cover?.url);
        } else {
          setVideoPublic(null);
        }
      } else if (params.userId) {
        try {
          const request = await getPublicDetails(
            userInfo.access_token,
            params.userId
          );
          switch (request.status) {
            case 200:
              setIsProfileFavorite(request.res?.isFavorite ?? false);
              setProducerDetails(request?.res as {} as ICatalogResponse);

              setPhotoProducer(request.res?.profile.photos ?? []);
              setAvatarProfile(
                request.res?.profile.photos[0].thumbnailUrl ||
                  request.res?.profile.photos[0].mediumUrl ||
                  request.res?.profile.photos[0].url ||
                  ""
              );

              if (request.res?.profile.cover) {
                setVideoPublic(request.res.profile.cover?.url);
              } else {
                setVideoPublic(null);
              }
              break;
            default:
              break;
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    producerDetail();
    if (verifyToken()) goalUser();
  }, [userIdSelected, pastTokenStorage]);

  const backPageHandler = () => {
    if (componentFeed) {
      setPrincipalScreen("");
    } else {
      setPrincipalScreen("");
      setSecondaryScreen("");
      setTertiaryScreen("");
      setIsChatPageOpen(false);
    }
  };

  const handlerFeedButtonClick = () => {
    if (!checkAuth()) return;
    const creatorId = userIdSelected ? userIdSelected : pageParams.userId;
    const chatId = activeChatIdState
      ? activeChatIdState
      : (activeChatId as string);

    setFeedScreen("/feed", {
      userId: creatorId,
      chatId,
      isLocked: JSON.stringify(openedChatUserIds?.includes(creatorId)),
    });
  };

  const clickLikeHandler = async () => {
    if (!verifyToken()) {
      setFavoritesListTrigger((prev) => !prev);
      setIsProfileFavorite((prev) => !prev);
      return;
    }

    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(
        userInfo.access_token,
        producerDetails.userId
      );
      switch (response.status) {
        case 201:
        case 204:
          setFavoritesListTrigger((prev) => !prev);
          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, try again",
            type: "error",
          });

          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        producerDetails.userId
      );

      switch (response.status) {
        case 204:
        case null:
          setFavoritesListTrigger((prev) => !prev);

          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, try again 0000",
            type: "error",
          });

          break;
      }
    }

    setMomentTrigger((prev) => !prev);
  };

  const handlerOpenChat = async () => {
    try {
      const response = await openChat();

      if (response && response.status === 201 && response.res?.chatId) {
        setActiveChatIdState(response.res.chatId);
      }
    } catch (error) {
      console.error(
        "Error opening chat:",
        error instanceof Error ? error.message : error
      );
    }
  };

  const fetchGoalProgress = async (goalId: string) => {
    try {
      const goalProgressResponse = await getCreatorSettingsGoalProgress(
        userInfo.access_token,
        goalId
      );

      if (goalProgressResponse.status !== 200) throw new Error();

      const percentageProgress =
        goalProgressResponse?.res?.progressPercentage ?? "0%";

      setGoalProgress(() =>
        percentageProgress > 100 ? "100%" : `${percentageProgress}%`
      );

      return true;
    } catch (error) {
      setGoalProgress(undefined);
      return false;
    }
  };

  const goalUser = async () => {
    try {
      if (!userIdSelected) throw new Error();

      const goalUserResponse = await getCreatorSettingsGoalUser(
        userInfo.access_token,
        userIdSelected
      );

      if (goalUserResponse.status !== 200) throw new Error();

      setGoal(goalUserResponse.res);
      await fetchGoalProgress(goalUserResponse.res.goalId);
    } catch (error: unknown) {
      setGoal(undefined);
      setGoalProgress(undefined);

      if (typeof error === "object" && error !== null && "response" in error) {
        const err = error as { response?: { status?: number } };
        return {
          res: null,
          status: err.response?.status || 500,
        };
      }
      return { res: null, status: 500 };
    }
  };

  const donateToCreator = async (amount: number) => {
    try {
      if (!checkAuth() || availableRoses <= 0 || !goal) throw new Error();

      const donate = await postCreatorSettingsGoalDonate({
        token: userInfo.access_token,
        amount,
        goalId: goal?.goalId,
      });

      await fetchGoalProgress(donate.res.goalId);

      return donate;
    } catch (error) {
      showToast({
        type: "error",
        title: "Donation failed",
        description:
          "An error occurred while processing your donation. Please try again.",
      });
      return false;
    }
  };

  const openModal = () => {
    setOpenUseModal(true);
    setModalContent(
      <SendRosesModal
        title="Send Roses"
        description={
          <>
            Capture Your Love's Heart: <br />
            Send <b>Roses</b> Now!
          </>
        }
        showLogo
        showSendMessage
        typeModal="send"
        maxValue={availableRoses}
        onClose={() => setOpenUseModal(false)}
        rosesMessageCallback={async (roses) => {
          const res = await donateToCreator(roses);
          if (res) {
            updateSubtractAvailableRoses(roses);
          }
          setOpenUseModal(false);
        }}
      />
    );
  };
  return (
    <div
      ref={producerRef}
      className="producer-summary
			 standard-page-layout bottom-spacing"
    >
      <PageTitle
        onBackClick={backPageHandler}
        hasCloseIcon={windowSize >= 768}
        isProducer
        buttonTransparent
      />

      <ProducerInfo
        navigate={navigate}
        openFeed={handlerFeedButtonClick}
        goal={goal}
        goalProgress={goalProgress}
        openModal={openModal}
        heartButtonClick={clickLikeHandler}
        sendMessageClick={handlerOpenChat}
        buttonStyle={isProfileFavorite ? "transparentActive" : "transparent"}
        icon={
          isProfileFavorite
            ? "icon-heart-solid"
            : "icon-heart-outline icon-primary-50"
        }
        iconSecondary={"icon-messages icon-primary-50"}
      />
    </div>
  );
};

export default ProducerSummary;
