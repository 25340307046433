import React, { useRef } from "react";
import { NavigateFunction, Params as RouterParams } from "react-router-dom";
import "./styles.scss";

import FadeCarousel from "components/Carousels/FadeCarousel";
import Header from "components/Headers/Header";

import { PolicyContent } from "pages/LandingPage/PrivacyPolicyPage";
import { TermsMain } from "pages/LandingPage/TermsPage";
import LoginModal from "../Login";

import { useModal } from "hooks/useModal";
import { Params } from "hooks/useDeskNavigation";

import SignUpImage from "assets/images/login&CreateAccount/sign-up.jpg";

interface TermsAndPoliciesModalProps {
  navigate: NavigateFunction;
  setComponent: (path: string | React.ReactNode, params?: Params) => void;
  paramsUrl: Readonly<RouterParams<string>>;
  pageToShow: "terms" | "policies";
}

const TermsAndPoliciesModal: React.FC<TermsAndPoliciesModalProps> = (props) => {
  const { navigate, setComponent, paramsUrl, pageToShow } = props;
  const { setModalContent, setOpenUseModal } = useModal();

  const modalRef = useRef<HTMLDivElement | null>(null);

  const loginModalHandler = () => {
    setModalContent(
      <LoginModal
        navigate={navigate}
        paramsUrl={paramsUrl}
        setComponent={setComponent}
      />
    );
  };

  return (
    <div
      id="terms-and-policies-modal"
      ref={modalRef}
      className="terms-and-policies-modal-wrapper wrapper"
    >
      <span
        onClick={() => setOpenUseModal(false)}
        className="icon icon-close icon-gray-light
			 		icon-md close-modal-icon"
      />
      <div className="terms-and-policies-modal__content">
        <div className="content__carousel-wrapper">
          <FadeCarousel
            slides={[{ backgroundImage: SignUpImage }]}
            duration={4000}
            borderRadius={[16, 0, 0, 16]}
            showGradient={false}
            showText={false}
          />
        </div>
        <div className="content__form-wrapper">
          {pageToShow === "terms" ? (
            <>
              <Header
                marginTop={0}
                title="Terms of services"
                hasGapBetweenTitleAndSub={false}
                noMarginTopTextAndIcon={true}
                titleClass="header"
                headerIcon="back-button"
                inlineElements
                modalStyle
                backButtonClick={loginModalHandler}
              />
              <div className="form-wrapper__inputs">
                <TermsMain hideTitle />
              </div>
            </>
          ) : pageToShow === "policies" ? (
            <>
              <Header
                marginTop={0}
                title="Terms of services"
                hasGapBetweenTitleAndSub={false}
                noMarginTopTextAndIcon={true}
                titleClass="header"
                headerIcon="back-button"
                inlineElements
                modalStyle
                backButtonClick={loginModalHandler}
              />
              <div className="form-wrapper__inputs">
                <PolicyContent hideTitle />
              </div>
            </>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsAndPoliciesModal;
