import React from "react";
import "./styles.scss";
import { useLanguage } from "../../../hooks/languageContext";

interface TranslatableTextProps {
  textKey: string;
  textAlt?: boolean;
  fontSize?: string;
  className?: string;
}

const TranslatableText: React.FC<TranslatableTextProps> = (props) => {
  const { textKey, textAlt = false, fontSize, className } = props;
  const { translations } = useLanguage();
  const [page, key] = textKey.split("/");
  const pageId = parseInt(page);
  const pageContent = translations.pages.find((p) => p.id === pageId)?.content;

  if (pageContent && key in pageContent) {
    return (
      <p
        className={`
          ${textAlt ? "translatableText--alt" : "translatableText"}
          ${className || ""}
          `}
        style={{ fontSize: fontSize || "" }}
        dangerouslySetInnerHTML={{ __html: pageContent[key] ?? "" }}
      />
    );
  } else {
    console.error(`Invalid textKey: ${textKey}`);
    return null;
  }
};

export default TranslatableText;
