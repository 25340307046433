import fetchApi from "../fetchApi";

export type IPostPostsUnseen = {
  take?: number;
  cursor?: string;
};

const getPostsUnseen = async (token: string, data?: IPostPostsUnseen) => {
  const queryParams = new URLSearchParams();

  if (data?.take) queryParams.append("take", String(data.take));
  if (data?.cursor) queryParams.append("cursor", String(data.cursor));

  const queryString = queryParams.toString();
  const endpoint = queryString ? `?${queryString}` : "";

  return await fetchApi({
    service: "posts",
    method: "GET",
    endPoint: `/unseen${endpoint}`,
    token,
  });
};

export default getPostsUnseen;
