import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import ListItemLink from "components/Lists/ListItemLink";
import Button from "components/Buttons/Button";
import Modal from "components/Modals/Modal";
import Plan from "components/Plan";

import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import getPlans from "api/plans/getPlans";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useLoginSignUpNavigation } from "hooks/useLoginSignUpNavigation";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";

import { useIconContext } from "../../contexts/iconNavContext";

import { localStorageClear } from "utils/localStorageClear";

import accountIconGray from "assets/icons/gear-icon-gray.svg";
import linkIcon from "assets/icons/link-icon-gray.svg";
import myOffersIconGray from "assets/icons/offers-icon-gray.svg";
import notificationsIconGray from "assets/icons/notification-icon-gray.svg";
import profileIconGray from "assets/icons/profile-icon-gray.svg";
import wallet from "assets/icons/wallet-icon-gray.svg";
import faceGray from "assets/icons/face-pink-gray.svg";
import identityIconGray from "assets/icons/identity-icon-gray.svg";
import languageIcon from "assets/icons/language-icon-gray.svg";
import lockIcon from "assets/icons/lock-icon-gray.svg";
import personalSafetyIconGray from "assets/icons/shieldIcons/personal-icon-gray.svg";
import gearIcon from "assets/icons/gear-icon-gray.svg";
import moneyIcon from "assets/icons/dollar-icon-gray.svg";
import verifyIcon from "assets/icons/verify.svg";
import faceIdIconGray from "assets/icons/face-id-confirmed.svg";
import starIconGray from "assets/icons/star-icon-gray.svg";

const UserSettingsConfigurationPage: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [plans, setPlans] = useState([
    {
      price: 3256,
      priceWithoutDiscount: 1059,
      discount: 6,
      period: "per month",
      type: "Seed",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
    {
      price: 6499,
      priceWithoutDiscount: 5598,
      discount: 12,
      period: "per month",
      type: "Vip",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
    {
      price: 8299,
      priceWithoutDiscount: 3779,
      discount: 8,
      period: "per month",
      type: "Bloom",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
  ]);

  const {
    componentFeed,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { clearUserInfo } = useUserInfo();
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();
  const { showToast } = useToast();
  const navigate = useNavigate();

  const pastTokenStorage = localStorage.getItem("user-info-past-token");

  useEffect(() => {
    const fetchPlans = async () => {
      /*
      const { res, status } = await getPlans(userInfo.access_token);
      if(status === 200){
        setPlans(res);
      }
      */
    };
    fetchPlans();
  }, []);

  useEffect(() => {
    const creatorApplyStatus = async () => {
      const responseStatus = await getCreatorStatus(userInfo.access_token);

      if (responseStatus?.status === 200) {
        setUserInfo("referral_code", responseStatus?.res.referralCode);
        setUserInfo("creator_application_status", responseStatus?.res.status);
        setUserInfo(
          "creator_application_id",
          responseStatus?.res.creatorApplicationId
        );
      } else if (responseStatus?.status === 404) {
        if (userInfo.account_type === "CREATOR") {
          setUserInfo("creator_application_status", "APPROVED");
        }
        setUserInfo("creator_application_status", "unsolicited");
      }
    };
    creatorApplyStatus();
  }, []);

  const onOpenModal = () => {
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const onMyPlans = () => {
    if (windowSize >= 768) {
      onOpenModal();
    } else {
      if (componentFeed) {
        setSecondaryScreen("/user-settings");
      } else {
        setPrincipalScreen("/user-settings-my-plans");
      }
    }
  };

  const userSettingsItems = [
    {
      labelContent:
        userInfo.account_type === "CREATOR" ? "Subscribers" : "Subscriptions",
      iconLeftContent: starIconGray,
      clickAction: () => {
        if (componentFeed) {
          userInfo.account_type === "CREATOR"
            ? setSecondaryScreen("/user-settings-my-subscribers")
            : setSecondaryScreen("/user-settings-my-subscriptions");
        } else {
          userInfo.account_type === "CREATOR"
            ? setPrincipalScreen("/user-settings-my-subscribers")
            : setPrincipalScreen("/user-settings-my-subscriptions");
        }
      },
      isActive: true,
    },
    {
      labelContent: "Referrals",
      iconLeftContent: linkIcon,
      clickAction: () => {
        setPrincipalScreen("/user-settings-referrals", { id: "1" });
      },
      // isActive: userInfo.creator_application_status !== "unsolicited",
      isActive: true,
    },
    {
      labelContent: "Notifications",
      iconLeftContent: notificationsIconGray,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-notifications");
        } else {
          setPrincipalScreen("/user-settings-notifications");
        }
      },
      isActive: true,
    },
    {
      labelContent: "Profile",
      iconLeftContent: profileIconGray,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-profile");
        } else {
          setPrincipalScreen("/user-settings-profile");
        }
      },
      isActive: false,
    },
    {
      labelContent: "My Wallet",
      iconLeftContent: wallet,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-wallet");
        } else {
          setPrincipalScreen("/user-settings-wallet");
        }
      },
      isActive: false,
    },
    {
      labelContent: "My Offers",
      iconLeftContent: myOffersIconGray,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-myoffers");
        } else {
          setPrincipalScreen("/user-settings-myoffers");
        }
      },
      isActive: false,
    },
    {
      labelContent: "Manage Profiles",
      iconLeftContent: faceGray,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-change-creator");
        } else {
          setPrincipalScreen("/user-settings-change-creator");
        }
      },

      isActive: userInfo.account_type === "ADMIN" || pastTokenStorage,
    },
    {
      labelContent: "Account Settings",
      iconLeftContent: accountIconGray,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-account");
        } else {
          setPrincipalScreen("/user-settings-account");
        }
      },

      isActive: false,
    },
    {
      labelContent: "Identity Verification",
      iconLeftContent: identityIconGray,
      clickAction: () => {
        showToast({
          type: "info",
          title: "Coming soon!",
          description: "We appreciate your patience.",
        });
      },
      isActive: false,
    },
    {
      labelContent: "Language & Location",
      iconLeftContent: languageIcon,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-language");
        } else {
          setPrincipalScreen("/user-settings-language");
        }
      },

      isActive: true,
    },
    {
      labelContent: "Privacy",
      iconLeftContent: lockIcon,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-privacy");
        } else {
          setPrincipalScreen("/user-settings-privacy");
        }
      },

      isActive: true,
    },
    {
      labelContent: "Password & Security",
      iconLeftContent: personalSafetyIconGray,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-change-password");
        } else {
          setPrincipalScreen("/user-settings-change-password");
        }
      },

      isActive: true,
    },
    {
      labelContent: "Manage Account",
      iconLeftContent: gearIcon,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-manage-account");
        } else {
          setPrincipalScreen("/user-settings-manage-account");
        }
      },

      isActive: true,
    },
    {
      labelContent: "My Plans",
      iconLeftContent: faceIdIconGray,
      clickAction: onMyPlans,
      isActive: true,
    },
    {
      labelContent: "Goals Account",
      iconLeftContent: moneyIcon,
      clickAction: () => {
        if (componentFeed) {
          setSecondaryScreen("/user-settings-goals");
        } else {
          setPrincipalScreen("/user-settings-goals");
        }
      },

      isActive: userInfo.account_type === "CREATOR",
    },
  ];

  const pageBack = () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings");
    } else {
      setPrincipalScreen("/user-settings");
    }

    handleButtonClick("grid");
  };

  const logoutHandler = () => {
    clearUserInfo();
    sessionStorage.clear();
    localStorageClear();
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setComponent("");
    navigate("/app");
  };

  const onSubscribe = () => {
    showToast({
      type: "info",
      title: "Coming soon!",
      description: "We appreciate your patience.",
    });
    onCloseModal();
  };

  return (
    <div
      className="user-settings-configuration
		 		user-settings-configuration-wrapper
		 		wrapper standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-configuration-inner inner">
        <PageTitle
          title={"Settings"}
          subtitle={"Manage Account"}
          hasCloseIcon={windowSize >= 768}
          onBackClick={pageBack}
        />

        <div className="user-settings-configuration-list">
          {userSettingsItems
            .filter((item) => item.isActive)
            .map((item, index, arr) => (
              <ListItemLink
                key={index}
                onClick={item.clickAction}
                hasArrowRight
                imageLeft={item.iconLeftContent}
                isImageCircle
                isBackTransparent
                highlightText={item.labelContent}
                hasSeparator={arr.length > index + 1}
                className="user-settings-configuration-item"
                rightNodeContent={
                  <p className="text-detail">
                    {item.labelContent === "Identity Verification" && (
                      <img src={verifyIcon} alt="Verify Icon" />
                    )}
                  </p>
                }
              />
            ))}
        </div>

        <div className="user-settings-configuration-btns">
          <Button
            children={"Upgrade Plan"}
            buttonStyle={"tertiary"}
            onClick={() => {
              showToast({
                type: "info",
                title: "Coming soon!",
                description: "We appreciate your patience.",
              });
            }}
          />
          <Button
            children={"Logout"}
            buttonStyle={"quintenary"}
            onClick={logoutHandler}
          />
        </div>

        {/* <TextActionCard
          buttonClick={becomeACreatorHandler}
          principalText="Start Earning (Monetize Content)"
          secondaryText={`
            Apply for a creator’s account and monetize your
            exclusive content today.
          `}
          textButton={
            userInfo.creator_application_status === "unsolicited"
              ? "Become a Creator"
              : "Check Your Application"
          }
        /> */}

        {windowSize >= 768 && (
          <Modal
            className="my-plan-modal"
            isOpen={isOpenModal}
            onClose={onCloseModal}
            header={{
              title:
                userInfo.account_type === "CREATOR"
                  ? "BECOME A CREATOR"
                  : "SELECT PLAN",
              subtitle: "Select subscription below",
            }}
          >
            <Plan
              onClose={onCloseModal}
              onSelectItem={(item) => {}}
              onSubscribe={onSubscribe}
              plans={plans}
              isLoading={false}
              disabled={false}
              type={userInfo.account_type}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};
export default UserSettingsConfigurationPage;
