const location = [
  {
    location: [
      {
        place: "Toledo",
        region: "Paraná",
        country: "Brazil",
        country_code: "BR",
        country_code_alpha_3: "BRA",
      },
      {
        place: "Tokyo",
        region: "Kanto",
        country: "Japan",
        country_code: "JP",
        country_code_alpha_3: "JPN",
      },
      {
        place: "New York",
        region: "New York",
        country: "United States",
        country_code: "US",
        country_code_alpha_3: "USA",
      },
      {
        place: "Paris",
        region: "Île-de-France",
        country: "France",
        country_code: "FR",
        country_code_alpha_3: "FRA",
      },
      {
        place: "Sydney",
        region: "New South Wales",
        country: "Australia",
        country_code: "AU",
        country_code_alpha_3: "AUS",
      },
      {
        place: "Cape Town",
        region: "Western Cape",
        country: "South Africa",
        country_code: "ZA",
        country_code_alpha_3: "ZAF",
      },
      {
        place: "Berlin",
        region: "Berlin",
        country: "Germany",
        country_code: "DE",
        country_code_alpha_3: "DEU",
      },
      {
        place: "Toronto",
        region: "Ontario",
        country: "Canada",
        country_code: "CA",
        country_code_alpha_3: "CAN",
      },
      {
        place: "Mexico City",
        region: "Mexico City",
        country: "Mexico",
        country_code: "MX",
        country_code_alpha_3: "MEX",
      },
      {
        place: "Moscow",
        region: "Moscow",
        country: "Russia",
        country_code: "RU",
        country_code_alpha_3: "RUS",
      },
    ],
  },
  {
    selected: "",
  },
];

export default location;
