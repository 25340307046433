import React, { useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import Paragraph from "components/Texts/Paragraph";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

import getUserSelfDetail from "../../api/getUserSelfDetail";
import patchUserSelfDetailApi from "../../api/patchUserSelfDetail";

import verifyIconGray from "assets/icons/verify-icon-gray.svg";

const UserSettingsEditProfilePage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [usernameWarning, setUsernameWarning] = useState(false);
  const [nameWarning, setNameWarning] = useState(false);

  const { showToast } = useToast();

  const openToast = (object: {
    type: any;
    title: string;
    description: string;
  }) => {
    showToast({
      title: object.title,
      type: object.type,
      description: object.description,
    });
  };

  const verifyAccountHandler = () => {};

  const sendApiUsername = async (token: any, data: any) => {
    //send to path the name and the username
    const res = await patchUserSelfDetailApi(token, data);

    // wait the answer, and show the toast or warning
    if (res.status === 200 || data.username === userInfo.legal_name) {
      setUserInfo("legal_name", data.username);
      setUsernameWarning(false);
      return 200;
    } else {
      setUsernameWarning(true);
      return 500;
    }
  };

  const sendApiDisplayName = async (token: any, data: any) => {
    //send to path the name and the username
    const res = await patchUserSelfDetailApi(token, data);

    // wait the answer, and show the toast or warning
    if (res.status === 200) {
      setUserInfo("display_name", name);
      return 200;
    }
    return 500;
  };

  const saveEditHandler = async () => {
    const token = userInfo.access_token;

    const apiResUsername = await sendApiUsername(token, {
      username: username,
    });
    const apiResName = await sendApiDisplayName(token, { displayname: name });

    if (apiResName === 200 && apiResUsername === 200) {
      return openToast({
        title: "Changes successful",
        type: "success",
        description: "changes made successfully.",
      });
      setUsernameWarning(false);
    }
  };

  const getUserSelfDetailInit = async () => {
    try {
      const res = await getUserSelfDetail(userInfo.access_token);
      setName(res.res.displayname);
      setUsername(res.res.username);
    } catch (error) {
      console.error("Erro ao buscar detalhes do usuário:", error);
    }
  };

  useEffect(() => {
    getUserSelfDetailInit();
  }, []);

  return (
    <div
      className="user-settings-edit-profile
		 		user-settings-edit-profile-wrapper
		  	wrapper
				standard-page-layout 
				bottom-spacing"
    >
      <div className="user-settings-edit-profile-inner inner">
        <PageTitle
          title={"Username"}
          subtitle={"Verify profile"}
          hasCloseIcon={false}
          onBackClick={() =>
            setPrincipalScreen(`/user-settings-profile`, { btn: "button2" })
          }
        />

        <main className="user-settings-edit-profile__main">
          <div className="user-settings-edit-profile__card-action">
            <span className="icon icon-certified-1 user-settings-edit-profile__card-action-icon"></span>

            <div className="user-settings-edit-profile__card-action-main">
              <h2 className="user-settings-edit-profile__card-action-title">
                Verify profile
              </h2>
              <Paragraph
                children={"Become a content creator and start earning today"}
              />
            </div>

            <div>
              <Button
                children={"Verify Now"}
                isWidthAuto
                buttonStyle={"tertiary"}
                onClick={verifyAccountHandler}
              />
            </div>
          </div>

          <div className="user-settings-edit-profile__fields">
            <InputText
              iconRight={verifyIconGray}
              widthIcon={24}
              label="Display Name"
              placeholder={
                userInfo.display_name ? userInfo.display_name : "Put your name"
              }
              inputType="text"
              tooltip={{ message: "Display Name" }}
              value={name}
              isWarning={nameWarning}
              onChangeInput={(e) => {
                setName(e);
              }}
            />

            <div>
              <InputText
                placeholder={
                  userInfo.legal_name ? username : "Put your username"
                }
                inputType="text"
                label="Username"
                tooltip={{ message: "Username" }}
                explanation={{
                  message: (
                    <div className="user-settings-edit-profile__explanation">
                      In most cases, you’ll be able to change your name back to
                      <span className="user-settings-edit-profile__link-highlight">
                        {" "}
                        {username}.roses
                      </span>{" "}
                      roses for another 14 days.
                    </div>
                  ),
                }}
                value={username}
                isWarning={usernameWarning}
                onChangeInput={(e) => {
                  setUsername(e.toLowerCase());
                  sendApiUsername(userInfo.access_token, { username: e });
                }}
              />
            </div>

            <section className="user-settings-edit-profile__link-container  ">
              <InputText
                readOnly
                placeholder={
                  userInfo.legal_name ? username : "Put your username"
                }
                inputType="text"
                value={`https://roses.vip/${username}`}
                isWarning={usernameWarning}
                hasCopyButton
                onChangeInput={(e) => {
                  setUsername(e.toLowerCase());
                  sendApiUsername(userInfo.access_token, { username: e });
                }}
              />
            </section>

            <div className="user-settings-edit-profile__buttons-containers">
              <Button
                buttonStyle="primary"
                onClick={async () => {
                  saveEditHandler();
                }}
              >
                Save
              </Button>
              <Button
                buttonStyle="quaternary"
                onClick={async () => {
                  if (componentFeed) {
                    setSecondaryScreen("/user-settings-profile");
                  } else {
                    setPrincipalScreen("/user-settings-profile");
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserSettingsEditProfilePage;
