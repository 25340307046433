import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.scss";

import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";

import ResetPassword from "../../api/auth/postPasswordReset";
import UpdatePassword from "../../api/auth/postPasswordUpdate";

import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useToast } from "hooks/useToast";

import LockIcon from "assets/icons/lock-icon-gray.svg";

const ResetPasswordPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setComponent } = useLoginSignUpNavigation();
  const { showToast } = useToast();

  const [currentEmail, setCurrentEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState("");
  const [newUserPasswordWarning, setNewUserPasswordWarning] = useState(false);
  const [newUserConfirmPasswordWarning, setNewUserConfirmPasswordWarniing] =
    useState(false);
  const [emailInputWarning, setEmailInputWarning] = useState(false);

  // Use URLSearchParams to get the query parameter
  const queryParams = new URLSearchParams(location.search);
  const tokenParams = queryParams.get("token");

  const resetPasswordSubmit = async () => {
    const requestReset = await ResetPassword({ email: currentEmail });

    if (requestReset.res.message) {
      showToast({
        description: requestReset.res.message,
        title: "Reset password",
        type: "success",
      });
    }

    if (!currentEmail) {
      showToast({
        description: "Check your credential and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setEmailInputWarning(true);

      setTimeout(() => {
        setEmailInputWarning(false);
      }, 3000);
    }
  };

  const cancelSubmit = () => {
    setComponent("/account-login");
    if (tokenParams) {
      navigate("/");
    }
  };

  const resetPasswordWithToken = async () => {
    if (
      (!newUserPassword && !newUserConfirmPassword) ||
      newUserPassword != newUserConfirmPassword
    ) {
      showToast({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setNewUserConfirmPasswordWarniing(true);
      setNewUserPasswordWarning(true);

      setTimeout(() => {
        setNewUserConfirmPasswordWarniing(false);
        setNewUserPasswordWarning(false);
      }, 3000);
    }
    if (!newUserConfirmPassword || newUserConfirmPassword.length < 8) {
      showToast({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setNewUserConfirmPasswordWarniing(true);

      setTimeout(() => {
        setNewUserConfirmPasswordWarniing(false);
      }, 3000);
    }
    if (!newUserPassword || newUserPassword.length < 8) {
      showToast({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setNewUserPasswordWarning(true);

      setTimeout(() => {
        setNewUserPasswordWarning(false);
      }, 3000);
    }

    if (newUserPassword === newUserConfirmPassword) {
      if (tokenParams) {
        const update = await UpdatePassword({
          token: tokenParams,
          newPassword: newUserPassword,
        });

        if (update.res.message === "Password has been updated successfully.") {
          showToast({
            description: update.res.message,
            title: "Reset password",
            type: "success",
          });

          setTimeout(() => {
            navigate("/account-login");
          }, 3000);
        } else {
          showToast({
            description: update.res.message,
            title: "Reset password",
            type: "error",
          });
        }
      }
    }
  };

  return (
    <div className="reset-password reset-password-wrapper wrapper">
      <div className="reset-password-inner inner">
        {tokenParams ? (
          <>
            <div className="reset-password__app-link">
              <Header
                headerIcon="roses-logo"
                title={"Reset Password"}
                subTitle={
                  <>
                    Your password should be at least <br /> 8 characters long
                  </>
                }
                backButtonClick={() => setComponent("/")}
              />

              <div className="reset-password__separator" />
              <InputText
                value={newUserPassword}
                placeholder="New Password"
                inputType="password"
                isWarning={newUserPasswordWarning}
                onChangeInput={(e) => setNewUserPassword(e)}
              />
              <div className="reset-password__separator" />

              <InputText
                value={newUserConfirmPassword}
                placeholder="Confirm New Password"
                inputType="password"
                isWarning={newUserConfirmPasswordWarning}
                onChangeInput={(e) => setNewUserConfirmPassword(e)}
              />
              <SubText icon={LockIcon} altIcon="clock icon">
                Never share your credentials with anyone.
              </SubText>
              <div className="reset-password__buttons-container">
                <Button buttonStyle="primary" onClick={resetPasswordWithToken}>
                  Reset password
                </Button>
                <Button buttonStyle="quaternary" onClick={cancelSubmit}>
                  Cancel
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <Header
              className="reset-password-header"
              headerIcon="back-button"
              backButtonClick={() => setComponent("/account-login")}
              title={"Reset Password"}
              subTitle={
                <>
                  Enter your email, we will send you a link to reset your
                  password
                </>
              }
            />
            <InputText
              value={currentEmail}
              placeholder="Your email"
              onChangeInput={(e) => setCurrentEmail(e)}
              inputType="text"
              isWarning={emailInputWarning}
            />

            <div className="reset-password__separator" />

            <SubText icon={LockIcon} altIcon="clock icon">
              Never share your credentials with anyone.
            </SubText>

            <div className="reset-password__buttons-container">
              <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
                Reset password
              </Button>
              <div className="reset-password__separator" />

              <Button buttonStyle="quaternary" onClick={cancelSubmit}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
