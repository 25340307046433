import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "components/Buttons/Button";
import RosesCredit from "components/RosesCredit";
import { dollarFormatSecondary } from "utils/dollarFormat";
import { PlanDuration } from "types/enum";
import Loading from "components/Loading";

interface IPlan {
  months: number;
  billingCycle: PlanDuration;
  price: number;
  discount: number;
  priceWithoutDiscount: number;
}

interface SubscriptionProps {
  onClose: () => void;
  onSubscribeMonth: () => void;
  onSubscribe: () => void;
  onSelectItem: (item: IPlan) => void;
  oneMonthPrice: number;
  plans: IPlan[];
  className?: string;
  children?: React.ReactNode;
  disabled: boolean;
  isLoading: boolean;
}

const SubscriptionPlan: React.FC<SubscriptionProps> = ({
  onClose,
  onSubscribeMonth,
  onSubscribe,
  onSelectItem,
  oneMonthPrice,
  plans,
  disabled,
  isLoading,
}) => {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const months = {
    ONE_MONTH: "1 month",
    THREE_MONTHS: "3 months",
    SIX_MONTHS: "6 months",
    TWELVE_MONTHS: "12 months",
  };

  const handleClickItem = (item: IPlan, index: number) => {
    onSelectItem(item);
    setActiveItem(index);
  };

  useEffect(() => {
    if (plans.length > 1) {
      onSelectItem(plans[1]);
      setActiveItem(1);
    }
  }, [plans]);

  return (
    <div className="subscription__container">
      {isLoading ? (
        <Loading className="loading" />
      ) : (
        <div className="subscription-modal__grid">
          {plans.map((item, index) => {
            return (
              <RosesCredit
                key={index}
                text={String(item.price)}
                value={months[item.billingCycle]}
                active={activeItem === index}
                onClick={() => handleClickItem(item, index)}
                isPopular={index === 1}
                discount={item.discount}
              />
            );
          })}
        </div>
      )}
      <div className="subscription-modal-buttons-container">
        <Button
          buttonStyle="primary"
          onClick={() => onSubscribe()}
          disabled={disabled || isLoading}
        >
          SUBSCRIBE
        </Button>
        <Button
          buttonStyle="tertiary"
          onClick={() => onSubscribeMonth()}
          disabled={disabled || isLoading}
        >
          SUBSCRIBE FOR 30 DAYS -{" "}
          <span className="button-span">{oneMonthPrice} ROSES</span>
        </Button>
        <Button buttonStyle="quaternary" onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
