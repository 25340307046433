import fetchApi from "../fetchApi";

interface IPostGoal {
  token: string;
  title: string;
  target: number;
}

const postCreatorSettingsGoal = async (props: IPostGoal) => {
  const { token, title, target } = props;
  return await fetchApi({
    service: "creatorSettings",
    endPoint: "/goal",
    method: "POST",
    data: { title, target },
    token,
  });
};

export default postCreatorSettingsGoal;
