import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./styles.scss";

import InputText from "../../Inputs/InputText";
import ButtonIcon from "../../Buttons/ButtonIcon";
import ImageCircleBorder from "../../ImageCircleBorder";

import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";
import Modal from "components/Modals/Modal";
import Plan from "components/Plan";
import BuyRoses from "components/BuyRoses";

import { useIconContext } from "../../../contexts/iconNavContext";
import { useAvailableRoses } from "../../../contexts/availableRosesContext";

import getPackages from "api/packages/getPackages";

import { useSearchCatalog } from "hooks/useSearchCatalog";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "hooks/useLoginSignUpNavigation";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useUserInfo } from "hooks/userInfo";
import { useBuyRoses } from "hooks/useBuyRoses";

import verifyToken from "utils/verifyToken";
import { thousandsFormat } from "../../../utils/thousandsFormat";

import logoFont from "assets/icons/logos/roses_logo-font.svg";
import logo from "assets/icons/logos/roses_logo.svg";

import token from "assets/icons/navigationIcons/token-rose.svg";
import tokenPremium from "assets/icons/navigationIcons/token-gold.svg";

import { IPackages } from "types/interfaces";

interface HeaderHomePageProps {
  hasANewMessage: boolean;
  hasANotification: boolean;
  profileAvatar: string | undefined | null;
  className?: string;
  backButtonClick?: React.MouseEventHandler<HTMLImageElement>;
  logoClick?: (a?: any) => void;
  clickProfileAvatar?: (a: any) => void;
  clickFavorites?: (a?: any) => void;
  clickChatList?: (a?: any) => void;
  clickOnGrid?: () => void;
}

const HeaderHomePage: React.FC<HeaderHomePageProps> = ({
  hasANewMessage,
  hasANotification,
  profileAvatar,
  className,
  backButtonClick,
  logoClick,
  clickProfileAvatar,
  clickFavorites,
  clickChatList,
  clickOnGrid,
}) => {
  const { handleButtonClick, getIconClass } = useIconContext();
  const { userInfo, setUserInfo } = useUserInfo();
  const { availableRoses } = useAvailableRoses();
  const { setOpenUseModal, setModalContent, openUseModal } = useModal();
  const { setComponent, component } = useLoginSignUpNavigation();
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const checkAuth = useAuthCheckOpenModal({ navigate, paramsUrl });
  const { showToast } = useToast();

  const availableRosesFormatted = thousandsFormat(availableRoses);

  const [isOpen, setIsOpen] = useState(false);
  const [showButtonsAuth, setButtonsAuth] = useState(false);

  const [packages, setPackages] = useState<IPackages[]>([]);
  const [currentPackage, setCurrentPackage] = useState<string>("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { buyRoses } = useBuyRoses(currentPackage ?? "");

  const [plans, setPlans] = useState([
    {
      price: 3256,
      priceWithoutDiscount: 1059,
      discount: 6,
      period: "per month",
      type: "Seed",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
    {
      price: 6499,
      priceWithoutDiscount: 5598,
      discount: 12,
      period: "per month",
      type: "Vip",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
    {
      price: 8299,
      priceWithoutDiscount: 3779,
      discount: 8,
      period: "per month",
      type: "Bloom",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
  ]);

  const { windowSize } = useWindowWidthSize();
  const {
    setPrincipalScreen,
    setFeedScreen,
    setTertiaryScreen,
    setMainScreen,
    componentFeed,
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setSecondaryScreen,
  } = useDeskNavigation();

  const { searchValue, setSearchValue } = useSearchCatalog();

  useEffect(() => {
    if (!verifyToken()) {
      setButtonsAuth(true);
    } else {
      setButtonsAuth(false);
    }
  }, [openUseModal]);

  const clickOnSearch = () => {
    setSearchValue("");
  };

  const searchCreator = () => {
    setMainScreen("/catalog");
    setFeedScreen("");
    setTertiaryScreen("");
  };

  const createAccountHandler = () => {
    navigate("/login");
    setComponent("/create-account");
  };

  const loginHandler = () => {
    if (!checkAuth()) return;
  };

  const upgradePlanHandler = () => {
    if (windowSize >= 768) {
      setIsOpenModal(true);
    } else {
      if (componentFeed) {
        setSecondaryScreen("/user-settings-my-plans");
      } else {
        setPrincipalScreen("/user-settings-my-plans");
      }
    }
  };

  const onOpenModal = () => {
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const onSubscribe = () => {
    showToast({
      type: "info",
      title: "Coming soon!",
      description: "We appreciate your patience.",
    });
    onCloseModal();
  };

  const handleBuyRoses = async () => {
    const res = await buyRoses();

    if (res) {
      showToast({
        type: "success",
        title: "Buy Roses",
        description: "Congratulations",
      });
    } else {
      showToast({
        type: "error",
        title: "Buy Roses",
        description: "Congratulations",
      });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchPackages = async () => {
      if (!verifyToken()) return;

      const { res } = await getPackages(userInfo.access_token);
      setPackages(res);
    };
    fetchPackages();
  }, [userInfo.access_token]);

  const alertClickHandler = () => {
    handleButtonClick("notification");
    if (!checkAuth()) return;
    if (componentFeed) {
      setSecondaryScreen("/my-request");
    } else {
      setPrincipalScreen("/my-request");
    }
  };

  return (
    <header id="header-home-page" className={`header-home ${className ?? ""}`}>
      <div className={`header-home__logo`} onClick={logoClick}>
        <img src={logo} alt="Logo" className="header-home__icon" />
        <img src={logoFont} alt="Roses Logo" />
        <span className="header-home__version">beta</span>
      </div>

      {(componentPrincipal && windowSize <= 768) ||
      openUseModal ||
      componentSecondary ||
      componentTertiary ||
      (componentFeed && windowSize <= 768) ? (
        false
      ) : (
        <div className="header-home__menu">
          <div className="header-home__menu-start">
            <ButtonIcon
              icon="icon-grid"
              buttonStyle="tertiary"
              iconClass={getIconClass("grid")}
              className="header-home__btn"
              onClick={() => {
                handleButtonClick("grid");
                clickOnGrid?.();
              }}
            />
            <ButtonIcon
              icon="icon-heart-outline"
              buttonStyle="tertiary"
              iconClass={getIconClass("heart-outline")}
              className="header-home__btn"
              onClick={() => {
                handleButtonClick("heart-outline");
                clickFavorites?.();
              }}
            />
          </div>

          <InputText
            value={searchValue}
            searchInput
            placeholder="Search"
            inputType="text"
            iconRightCustom="icon-filter header-home__search-icon"
            className="header-home__search"
            onClick={clickOnSearch}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchCreator();
              }
            }}
            onChangeInput={(e) => {
              setSearchValue(e);
            }}
          />

          <div className="header-home__menu-end">
            <div className="header-home__notify">
              <ButtonIcon
                icon="icon-notification"
                buttonStyle="tertiary"
                iconClass={getIconClass("notification")}
                className="header-home__btn"
                onClick={alertClickHandler}
              />
              {hasANotification && (
                <span className="header-home__notify__sphere-icon"></span>
              )}
            </div>
            <div className="header-home__notify">
              <ButtonIcon
                icon="icon-messages"
                buttonStyle="tertiary"
                iconClass={getIconClass("messages")}
                className="header-home__btn"
                onClick={() => {
                  handleButtonClick("messages");
                  clickChatList?.();
                }}
              />
              {hasANewMessage && (
                <span className="header-home__notify__sphere-icon"></span>
              )}
            </div>
          </div>
        </div>
      )}

      {showButtonsAuth ? (
        <div className="header-home__login" onClick={loginHandler}>
          <p className="header-home__plan">
            Login
            <span className="header-home__plan-icon icon icon-chevron-right"></span>
            <span className="header-home__separator"> | </span>
          </p>

          <ImageCircleBorder
            centerImageAlt=""
            centerImage={profileAvatar}
            onClick={clickProfileAvatar}
            size={36}
            noBorder
          />
        </div>
      ) : (
        <div className="header-home__actions">
          <div className="header-home__coins">
            <p className="header-home__coin">
              <img src={token} alt="Roses Toke" />
              {availableRosesFormatted}
            </p>
            <span className="header-home__separator"> | </span>

            <p className="header-home__coin header-home__coin--premium">
              <img src={tokenPremium} alt="Roses Toke" />5
            </p>
          </div>
          <ImageCircleBorder
            centerImageAlt=""
            centerImage={profileAvatar}
            onClick={clickProfileAvatar}
            size={36}
            noBorder
          />
        </div>
      )}

      {windowSize >= 768 && (
        <Modal
          className="header-home__my-plan"
          isOpen={isOpenModal}
          onClose={onCloseModal}
          header={{
            title:
              userInfo.account_type === "CREATOR"
                ? "BECOME A CREATOR"
                : "SELECT PLAN",
            subtitle: "Select subscription below",
          }}
        >
          <Plan
            onClose={onCloseModal}
            onSelectItem={(item) => {}}
            onSubscribe={onSubscribe}
            plans={plans}
            isLoading={false}
            disabled={false}
            type={userInfo.account_type}
          />
        </Modal>
      )}
    </header>
  );
};

export default HeaderHomePage;
