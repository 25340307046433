import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./styles.scss";

import Desk from "components/Wrappers/Desk";
import ModalInitialPreferences from "components/Modals/ModalInitialPreferences";
import VerifyEmailModal from "components/Modals/VerifyEmailModal";
import ExplicitContentAlertModal from "components/Modals/ExplicitContentAlertModal";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useUserInfo } from "hooks/userInfo";
import { useModal } from "hooks/useModal";

import { useWebSocket } from "contexts/webSocketContext";
import { useChat } from "contexts/openedChatContext";
import { ToggleChatOpenContext } from "contexts/toggleChatOpen";
import { FavoritesCatalogContext } from "contexts/favoritesCatalogTrigger";

import getUserPreferences from "api/userPreferences/getUserPreferences";
import getUserInfo from "api/userInfo/getUserInfo";
import getListUserChats from "api/chat/getListUserChats";

import verifyToken from "utils/verifyToken";
import { localStorageClear } from "utils/localStorageClear";
import { CreatorDetailsContext } from "contexts/creatorDetails";
import getUserInfoByUsername from "api/userInfo/getUserInfoByUsername";

import { Chat, INewMessage } from "types/chatInfo";

import newMessageNotification from "assets/sounds/notification-01.MP3";
import getUserSelfDetail from "api/getUserSelfDetail";
import {
  deleteFromPagePositionStorage,
  PagePositionStorage,
} from "utils/pagesPositionFeeder";

const Homepage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();
  const { favoritesCatalogTrigger, setFavoritesCatalogTrigger } = useContext(
    FavoritesCatalogContext
  );
  const { creatorDetails, setCreatorDetails } = useContext(
    CreatorDetailsContext
  );

  const paramsUrl = useParams();
  const navigate = useNavigate();
  const { creator_name } = paramsUrl;

  const { addChatListener, removeListener } = useWebSocket();
  const { activeChatId } = useChat();
  const { setIsChatPageOpen } = useContext(ToggleChatOpenContext);

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    componentFeed,
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setMainScreen,
  } = useDeskNavigation();
  const [profileAvatar, setProfileAvatar] = useState<string | undefined | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasANewMessage, setHasANewMessage] = useState(false);
  const [hasANotification, setHasANotification] = useState(false);
  const pastAvatar = localStorage.getItem("user-info-past-avatar");

  const checkAuth = useAuthCheckOpenModal({ navigate, paramsUrl });

  useEffect(() => {
    verifyEmailModal();

    // userInfoDB.saveInfoDBIfNotExists(userInfo.access_token);

    // userInfoDB
    //   .getUserInfoDB(userInfo.access_token)
    //   .then((userInfoDB) => {
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
    setProfileAvatar(userInfo?.user_profile_avatar);

    if (creator_name) {
      navigate(`/${creator_name}`);
      getUserInfoByLink();

      return;
    }

    hasLegalAge();
    // setFeedScreen("");
    // setPrincipalScreen("");
    // setSecondaryScreen("");
    // setTertiaryScreen("");
  }, [userInfo.access_token, userInfo?.user_profile_avatar, pastAvatar]);

  useEffect(() => {
    if (!verifyToken()) {
      localStorageClear();
    }

    if (!creator_name) {
      setMainScreen("/catalog");
    } else {
      getUserInfoByLink();
    }
  }, []);

  useEffect(() => {
    const handleNewMessage = (newMessage: INewMessage) => {
      if (newMessage.eventType === "NEW_MESSAGE") {
        if (
          newMessage?.payload?.user?.userId !== userInfo.user_id &&
          newMessage.payload.chatId !== activeChatId
        ) {
          playNotificationSound();
          setHasANewMessage(true);
        }
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener, activeChatId, userInfo]);

  useEffect(() => {
    const refreshPageFeederDesk = () => {
      if (!verifyToken()) {
        setPrincipalScreen("");
        setSecondaryScreen("");
        setTertiaryScreen("");
        setFeedScreen("");
      }
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
          sessionStorage.setItem("wasHidden", "true");
        }
      });

      window.addEventListener("load", () => {
        if (sessionStorage.getItem("wasHidden") === "true") {
          sessionStorage.removeItem("wasHidden");

          const history =
            localStorage.getItem("roses-pages-position") ?? undefined;

          if (history) {
            const historyStorageParse: PagePositionStorage =
              JSON.parse(history);
            const main = historyStorageParse?.main;
            const principal = historyStorageParse?.principal;
            const secondary = historyStorageParse?.secondary;
            const tertiary = historyStorageParse?.tertiary;
            const feed = historyStorageParse.feed;

            if (main) {
              setMainScreen(main?.page, main?.params);
            }

            if (feed) {
              setFeedScreen(feed.page, feed?.params);
            }

            if (principal) {
              setPrincipalScreen(principal?.page, principal?.params);
            }

            if (secondary) {
              setSecondaryScreen(secondary?.page, secondary?.params);
            }

            if (tertiary) {
              setTertiaryScreen(tertiary?.page, tertiary?.params);
            }
          }
        }
      });
    };

    refreshPageFeederDesk();
  }, [userInfo.access_token]);

  const requestUserPreferences = async () => {
    if (!verifyToken()) return;

    try {
      const response = await getUserPreferences(userInfo.access_token);
      switch (response.status) {
        case 200:
          if (response.res.preferedGenders?.length === 0) {
            setModalContent(<ModalInitialPreferences />);
            setOpenUseModal(true);
          }
          break;
        case 401:
          // navigate("/login");
          break;
        default:
          // navigate("/login");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   // getUserInfoRequest();
  //   // messageInChatRequest();
  // }, [
  //   userInfo,
  //   componentPrincipal,
  //   componentSecondary,
  //   componentTertiary,
  //   addChatListener,
  //   navigate,
  //   hasANewMessage,
  // ]);

  const hasLegalAge = () => {
    const isLegalToBeHere = localStorage.getItem("has_legal_age");

    if (!isLegalToBeHere || isLegalToBeHere === "false") {
      setModalContent(
        <ExplicitContentAlertModal
          navigateToApp={() => {
            if (!creator_name) {
              navigate("/app");
            } else {
              getUserInfoByLink();
            }
          }}
          navigateToLanding={() => navigate("/")}
        />
      );
      setOpenUseModal(true);
    }
  };

  const getUserInfoByLink = async () => {
    if (!creator_name) return;
    if (!checkAuth()) return;

    try {
      deleteFromPagePositionStorage("principal");
      deleteFromPagePositionStorage("feed");
      const request = await getUserInfoByUsername(
        userInfo.access_token,
        creator_name
      );
      switch (request.status) {
        case 200:
          setCreatorDetails(request.res);

          if (!request.res?.userId) return;

          setFeedScreen("/feed", { userId: request.res?.userId });
          setPrincipalScreen("/producer-summary", {
            userId: request.res?.userId,
          });
          hasLegalAge();
          break;

        case 404:
          navigate("*");
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const verifyEmailModal = () => {
    if (userInfo.account_type === "UNVERIFIED") {
      const showModalToday = () => {
        const savedDate = localStorage.getItem("verify_modal_last_shown");
        if (!savedDate) return false;

        const saved = new Date(savedDate);
        const today = new Date();

        return (
          saved.getFullYear() === today.getFullYear() &&
          saved.getMonth() === today.getMonth() &&
          saved.getDate() === today.getDate()
        );
      };

      if (!showModalToday()) {
        const closeModal = () => {
          setOpenUseModal(false);
          onCloseModal();
        };

        const onCloseModal = () => {
          localStorage.setItem(
            "verify_modal_last_shown",
            new Date().toISOString()
          );

          requestUserPreferences();
        };

        setModalContent(
          <VerifyEmailModal
            navigateTo={() => closeModal()}
            screenToShow="resend-email-link"
            onClose={() => onCloseModal()}
          />
        );

        setOpenUseModal(true);
      }
    }
  };

  const playNotificationSound = () => {
    const audio = new Audio(newMessageNotification);
    audio.play().catch((error) => {
      console.error("Error trying to play the audio:", error);
    });
  };

  // const getUserInfoRequest = async () => {
  //   if (!verifyToken()) return;
  //   try {
  //     const response = await getUserInfo(userInfo.access_token);
  //     switch (response.status) {
  //       case 200:
  //         if (response?.res?.message?.statusCode === 401) {
  //           // navigate("/login");
  //         } else {
  //           setProfileAvatar(response.res?.avatar?.thumbnailUrl);
  //         }
  //         break;
  //       case 401:
  //         localStorageClear();
  //         window.location.reload();
  //         break;
  //       default:
  //         // navigate("/login");
  //         break;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const messageInChatRequest = async () => {
  //   if (!verifyToken()) return;

  //   try {
  //     const response = await getListUserChats(userInfo.access_token);
  //     switch (response.status) {
  //       case 200:
  //         if (response?.res?.message?.statusCode === 401) {
  //           // navigate("/login");
  //         } else {
  //           if (
  //             response?.res?.filter((chat: Chat) => chat?._count.messages !== 0)
  //               .length > 0
  //           ) {
  //             setHasANewMessage(true);
  //           } else {
  //             setHasANewMessage(false);
  //           }
  //         }
  //         break;
  //       case 401:
  //         // navigate("/login");
  //         break;
  //       default:
  //         // navigate("/login");
  //         break;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const clickProfileAvatar = () => {
    if (!checkAuth()) return;

    if (componentFeed) {
      navigate("/app");
      setSecondaryScreen("/user-settings");
      // setFeedScreen("");
      setTertiaryScreen("");
      // setSecondaryScreen("");
    } else {
      setPrincipalScreen("/user-settings");
      setTertiaryScreen("");
      setSecondaryScreen("");
      setIsChatPageOpen(false);
    }
  };

  const favoritesClickHandler = () => {
    if (!checkAuth()) return;

    if (componentFeed) {
      navigate("/app");
    }
    setFavoritesCatalogTrigger(true);
    setTertiaryScreen("");
    setSecondaryScreen("");
    setPrincipalScreen("");
    setMainScreen("");
    setFeedScreen("/feed-global");
  };

  const openChatListHandler = () => {
    if (!checkAuth()) return;

    if (componentFeed) {
      navigate("/app");
      setMainScreen("/catalog");
    }
    setFeedScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/chat-list");
  };

  const clickOnGrid = () => {
    navigate("/app");
    setFavoritesCatalogTrigger(false);
    setFeedScreen("");
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");

    setMainScreen("/catalog");
  };

  return (
    <Desk
      profileAvatar={profileAvatar}
      principalMain={componentPrincipal}
      secondaryMain={componentSecondary}
      tertiaryMain={componentTertiary}
      feedMain={componentFeed}
      hasANewMessage={hasANewMessage}
      hasANotification={hasANotification}
      catalogMain={mainScreen}
      clickProfileAvatar={clickProfileAvatar}
      clickFavorites={favoritesClickHandler}
      clickChatList={openChatListHandler}
      clickOnGrid={clickOnGrid}
    />
  );
};

export default Homepage;
