import React, { ReactNode } from "react";
import "./styles.scss";

import { shortClassNames } from "utils/shortClassNames";

interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "inverse"
    | "transparent"
    | "transparent-pink"
    | "transparent-green"
    | "transparent-dawn"
    | "transparentActive";
  icon?: string;
  iconClass?: string;
  isDynamic?: boolean;
  className?: string;
  children?: ReactNode;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseLeave?: () => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
  const {
    buttonStyle = "primary",
    icon,
    iconClass,
    isDynamic = false,
    className,
    children,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    onClick,
  } = props;

  return (
    <button
      id="button-icon"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={shortClassNames([
        `button-icon button-icon--${buttonStyle}`,
        `${isDynamic ? "button-icon--dynamic" : ""}`,
        `${className ?? ""}`,
      ])}
    >
      {icon && (
        <span className={`icon ${icon} icon-md ${iconClass ?? ""}`}></span>
      )}
      {children ?? ""}
    </button>
  );
};

export default ButtonIcon;
