import React from "react";
import "./styles.scss";

import Loading from "components/Loading";

import { shortClassNames } from "utils/shortClassNames";

import { IVerifyMessage } from "types/interfaces";

interface IVerifyDocumentMessage {
  message: IVerifyMessage;
  className?: string;
}

const VerifyDocumentMessage: React.FC<IVerifyDocumentMessage> = (props) => {
  const {
    message: { icon, title, text },
    className,
  } = props;

  return (
    <div className={`verify-document-message ${className ?? ""}`}>
      {icon === "Loading" ? (
        <Loading />
      ) : (
        <span
          className={shortClassNames([
            `icon`,
            `${icon}`,
            `verify-document-message__icon`,
          ])}
        ></span>
      )}
      <div>
        <h2 className="verify-document-message__title">{title}</h2>
        <p className="verify-document-message__text">{text}</p>
      </div>
    </div>
  );
};

export default VerifyDocumentMessage;
