import React, { useState } from "react";
import "./styles.scss";
import PageTitle from "components/PageTitles";
import DropDown, { DropDownRadioElement } from "components/Lists/DropDown";
import InputText from "components/Inputs/InputText";
import InputMessage from "components/TextAreas/Textarea";
import ListItemLink from "components/Lists/ListItemLink";
import Button from "components/Buttons/Button";
import { useWindowWidthSize } from "../../../hooks/useWindowWidthSize";
import UnionGray from "assets/icons/union-gray.svg";
import RosesLogoIconPink from "assets/icons/logos/logo-rose-icon-pink.svg";
import PlayIconWhite from "assets/icons/play-circle-white.svg";
import PhotoIconGray from "assets/icons/photo-gray.svg";
import ProfileIconGray from "assets/icons/profile-simple-icon-gray.svg";
import LockIconGray from "assets/icons/lock-icon-gray.svg";
import ProfileSimpleIconGray from "assets/icons/profile-simple-icon-gray.svg";
import BalloonIconGray from "assets/icons/ballon-icon-gray.svg";
import { useModal } from "../../../hooks/useModal";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import InputCheckbox from "components/Inputs/InputCheckbox";
import ListItemCheckBox from "components/Lists/ListItemCheckBox";

interface CreatorContentProps {
  setSecondaryScreenShowing: React.Dispatch<
    React.SetStateAction<"" | "config" | "exclusive-list" | "collaborator-list">
  >;
  shareContentBackButtonHandler: () => void;
  uploadLocation: string;
  uploadFilesMode: (location: string[]) => void;
  currencySelectedHandler: (currency: string[]) => void;
  rosesAmount: string;
  addRosesInputHandler: (e: string) => void;
  setCreatedTags: (value: React.SetStateAction<string[] | []>) => void;
  createdTags: string[];
  setDescriptionContent: React.Dispatch<React.SetStateAction<string>>;
  descriptionContent: string;
  createdTag: string;
  setCreatedTag: React.Dispatch<React.SetStateAction<string>>;
  shareContentHandler: () => Promise<void>;
  setUploadType: React.Dispatch<React.SetStateAction<"moment" | "feed">>;
  saveForLaterHandler: (a: any) => void;
  previousPage: "none" | "chat" | "home";
  currencySelected: string;
}

const CreatorContent: React.FC<CreatorContentProps> = (props) => {
  const {
    shareContentBackButtonHandler,
    currencySelected,
    uploadLocation,
    uploadFilesMode,
    currencySelectedHandler,
    rosesAmount,
    addRosesInputHandler,
    setCreatedTags,
    createdTags,
    descriptionContent,
    setDescriptionContent,
    createdTag,
    setCreatedTag,
    previousPage,
    shareContentHandler,
    setUploadType,
    setSecondaryScreenShowing,
    saveForLaterHandler,
  } = props;

  const { windowSize } = useWindowWidthSize();
  const { setOpenUseModal } = useModal();
  const [uploadTypeState, setUploadTypeState] = useState<"feed" | "moment">(
    "feed"
  );
  const [listButtonsSecondaries, setListButtonsSecondaries] = useState(false);
  const [shareInMoments, setShareInMoments] = useState(false);

  const uploadFilesOptions: DropDownRadioElement[] = [
    {
      groupName: "upload-files",
      subText: "Everyone on the internet",
      inputLabel: "Teaser",
      inputValue: "Teaser",
      icon: "icon-photo",
      node: <span className="sfw-pill">SFW</span>,
    },
    {
      groupName: "upload-files",
      subText: "Only members will see your photo",
      inputLabel: "Members Only",
      inputValue: "Members Only",
      icon: "icon-lock",
    },
  ];

  const currencyOptions: DropDownRadioElement[] = [
    {
      groupName: "currency",
      // subText: "Official currency of the United States",
      inputLabel: "US Dollar",
      inputValue: "USD",
      // icon: "icon-dollar",
      node: <span className="currency-symbol">$</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of the European Union",
      inputLabel: "Euro",
      inputValue: "EUR",
      // icon: "icon-euro",
      node: <span className="currency-symbol">€</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of the United Kingdom",
      inputLabel: "British Pound",
      inputValue: "GBP",
      // icon: "icon-pound",
      node: <span className="currency-symbol">£</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of Japan",
      inputLabel: "Japanese Yen",
      inputValue: "JPY",
      // icon: "icon-yen",
      node: <span className="currency-symbol">¥</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of Canada",
      inputLabel: "Canadian Dollar",
      inputValue: "CAD",
      // icon: "icon-dollar",
      node: <span className="currency-symbol">C$</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of Australia",
      inputLabel: "Australian Dollar",
      inputValue: "AUD",
      icon: "icon-dollar",
      node: <span className="currency-symbol">A$</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of Switzerland",
      inputLabel: "Swiss Franc",
      inputValue: "CHF",
      // icon: "icon-franc",
      node: <span className="currency-symbol">CHF</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of China",
      inputLabel: "Chinese Yuan",
      inputValue: "CNY",
      // icon: "icon-yuan",
      node: <span className="currency-symbol">¥</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of India",
      inputLabel: "Indian Rupee",
      inputValue: "INR",
      // icon: "icon-rupee",
      node: <span className="currency-symbol">₹</span>,
    },
    {
      groupName: "currency",
      // subText: "Official currency of Brazil",
      inputLabel: "Brazilian Real",
      inputValue: "BRL",
      // icon: "icon-real",
      node: <span className="currency-symbol">R$</span>,
    },
  ];

  const cancelShareHandler = () => {
    setOpenUseModal(false);
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "Post",
      clickAction: () => {
        setUploadType("feed");
        setUploadTypeState("feed");
      },
      id: 1,
    },
    {
      text: "Moment",
      clickAction: () => {
        setUploadType("moment");
        setUploadTypeState("moment");
      },
      id: 2,
    },
  ];

  const continueHandler = () => {
    setListButtonsSecondaries(true);
  };

  const backButton = () => {
    if (listButtonsSecondaries) {
      setListButtonsSecondaries(false);
    } else {
      shareContentBackButtonHandler();
    }
  };

  return (
    <section className="creator-content-settings">
      <PageTitle
        title={"Create Content"}
        subtitle={"Increase visibility"}
        onBackClick={backButton}
        hasButtonNavigation={listButtonsSecondaries || windowSize < 700}
        hasCloseIcon={false}
        className="creator-content-settings__title add-media-page__page-title"
      />
      <div className="creator-content-settings__inner add-media-page__inner">
        {previousPage === "chat" ? (
          <InputText
            inputType="text"
            value={`Roses: ${rosesAmount}`}
            iconLeft={RosesLogoIconPink}
            onChangeInput={addRosesInputHandler}
          />
        ) : listButtonsSecondaries ? (
          <div className="creator-content-settings__list-items part-two">
            <ListItemCheckBox
              textPrincipal="Share to Moments"
              textSecondary="Temporary Post"
              groupName="share-moments"
              iconLeft="icon-clock"
              onChange={setShareInMoments}
              hasSeparator
            />

            <ListItemLink
              highlightText="Add Collaborator"
              simpleText="Increase visibility"
              hasSeparator
              onClick={() => setSecondaryScreenShowing("collaborator-list")}
              hasArrowRight
              imageLeft={ProfileSimpleIconGray}
              isImageCircle
            />
            <ListItemLink
              highlightText="Message"
              simpleText="Share privately to people"
              onClick={() => {
                setSecondaryScreenShowing("exclusive-list");
              }}
              hasArrowRight
              imageLeft={BalloonIconGray}
              isImageCircle
              // hasSeparator
            />
          </div>
        ) : (
          <>
            <ButtonNavigation
              horizontalMargin={false}
              buttons={buttonsGroup}
              className="creator-content-button-navigation"
              buttonsColorNonSelected="dawn"
            />
            <div className="creator-content-settings__list-items">
              <DropDown
                dropType="click-select"
                defaultValueProp={"Members Only"}
                iconLeft={
                  uploadLocation === "Teaser"
                    ? PhotoIconGray
                    : uploadLocation === "Members Only"
                      ? LockIconGray
                      : undefined
                }
                inputValueSet={uploadLocation}
                options={uploadFilesOptions}
                setValueCallBack={uploadFilesMode}
                elementNode={
                  uploadLocation === "Teaser" ? (
                    <span className="sfw-pill">SFW</span>
                  ) : uploadLocation === "secret" ? (
                    <span className="nsfw-pill">NSFW</span>
                  ) : (
                    false
                  )
                }
              />
              {uploadLocation === "secret" ||
              uploadLocation === "Members Only" ? (
                <div className="list-items__price-content">
                  <InputText
                    inputType="text"
                    value={rosesAmount}
                    // iconLeft={RosesLogoIconPink}
                    onChangeInput={addRosesInputHandler}
                  />
                  {/* TO DO */}
                  <DropDown
                    dropType="click-select"
                    defaultValueProp={currencyOptions[0].inputValue}
                    inputValueSet={currencySelected}
                    options={currencyOptions}
                    setValueCallBack={currencySelectedHandler}
                  />
                  {/* TO DO */}
                  <p className="price-content__explanation-text">{`${rosesAmount} ${currencySelected} = 250 Roses credit`}</p>
                </div>
              ) : (
                false
              )}
              <InputMessage
                value={descriptionContent}
                onChangeInput={setDescriptionContent}
                placeholder={"Description"}
                maxLen={250}
              />

              <InputText
                onKeyDown={(e) => {
                  if (e.key === "Enter" && createdTag) {
                    setCreatedTags((prev) => [...prev, createdTag]);
                    setCreatedTag("");
                  }
                }}
                inputType="text"
                iconRight={UnionGray}
                placeholder="Add tag"
                value={createdTag.trim()}
                onChangeInput={setCreatedTag}
              />

              {createdTags.length > 0 ? (
                <div className="tags-container  ">
                  {createdTags.map((tag, index) => (
                    <span className="created-tag" key={index}>
                      {tag}
                      <span
                        className="icon icon-close icon-gray-light icon-sm"
                        onClick={() => {
                          setCreatedTags(createdTags.filter((t) => t !== tag));
                        }}
                      />
                    </span>
                  ))}
                </div>
              ) : (
                false
              )}
              <>
                {uploadTypeState === "feed" ? (
                  <ListItemLink
                    highlightText="Add Collaborator"
                    simpleText="Increase visibility"
                    // hasSeparator

                    onClick={() =>
                      setSecondaryScreenShowing("collaborator-list")
                    }
                    hasArrowRight
                    imageLeft={ProfileSimpleIconGray}
                    isImageCircle
                  />
                ) : (
                  false
                )}

                {previousPage !== "home" ? (
                  <>
                    <ListItemLink
                      highlightText="Message"
                      simpleText="Share privately to people"
                      onClick={() => {
                        setSecondaryScreenShowing("exclusive-list");
                      }}
                      hasArrowRight
                      imageLeft={BalloonIconGray}
                      isImageCircle
                      // hasSeparator
                    />
                  </>
                ) : (
                  false
                )}
              </>
            </div>
          </>
        )}

        <div className="creator-content-settings__buttons-container">
          {listButtonsSecondaries || previousPage === "chat" ? (
            <>
              <Button buttonStyle="quaternary" onClick={saveForLaterHandler}>
                Save For later
              </Button>
              <Button buttonStyle="primary" onClick={shareContentHandler}>
                Share
              </Button>
            </>
          ) : uploadTypeState === "feed" ? (
            <>
              <Button buttonStyle="quaternary" onClick={cancelShareHandler}>
                Cancel
              </Button>
              <Button buttonStyle="secondary" onClick={continueHandler}>
                Continue
              </Button>
            </>
          ) : uploadTypeState === "moment" ? (
            <>
              <Button buttonStyle="quaternary" onClick={cancelShareHandler}>
                Cancel
              </Button>
              <Button buttonStyle="primary" onClick={shareContentHandler}>
                Share
              </Button>
            </>
          ) : (
            false
          )}
        </div>
      </div>
    </section>
  );
};

export default CreatorContent;
