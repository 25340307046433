import { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import ListItemToggle from "components/Lists/ListItemToggle";
import Button from "components/Buttons/Button";
import InputText from "components/Inputs/InputText";
import InputMessage from "components/TextAreas/Textarea";
import DropDown from "components/Lists/DropDown";

import { useIconContext } from "contexts/iconNavContext";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useToast } from "hooks/useToast";

import PrivacyIconGray from "assets/icons/privacy-icon-gray.svg";

const CreateRequestPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { windowSize } = useWindowWidthSize();

  const { showToast } = useToast();

  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [coin, setCoin] = useState("USD");
  const [description, setDescription] = useState("");
  const [typeRequest, setTypeRequest] = useState("Send");
  const [typeFile, setTypeFile] = useState("Video");

  const pageBack = () => {
    setPrincipalScreen("/my-request");
    handleButtonClick("grid");
  };

  return (
    <div className="create-request create-request-wrapper wrapper standard-page-layout bottom-spacing">
      <div className="create-request-inner inner">
        <PageTitle
          title={"Create Request"}
          subtitle={"Add Item"}
          hasCloseIcon={windowSize >= 768}
          onBackClick={pageBack}
        />

        <div className="create-request__main">
          <InputText
            placeholder="Title"
            inputType="text"
            value={title}
            onChangeInput={(e) => {
              setTitle(e);
            }}
          />

          <div className="create-request__split">
            <InputText
              value={String(value)}
              placeholder="$100"
              inputType="number"
              className="create-request__value"
              onChangeInput={(e) => {
                setValue(Number(e) > 0 ? e : "");
              }}
            />

            <DropDown
              inputValueSet={coin}
              defaultValueProp={coin}
              dropType={"click-select"}
              options={[
                {
                  inputLabel: "USD",
                  inputValue: "USD",
                  groupName: "USD",
                },
                {
                  inputLabel: "BRL",
                  inputValue: "BRL",
                  groupName: "BRL",
                },
              ]}
              setValueCallBack={(e) => {
                setCoin(e[0] as any);
              }}
            />
            <span className="create-request__tip">
              $100 USD = 250 Roses credit
            </span>
          </div>

          <InputMessage
            value={description}
            placeholder={"About me"}
            maxLen={250}
            onChangeInput={(e) => {
              setDescription(e);
            }}
          />

          <div className="create-request__split">
            <DropDown
              inputValueSet={typeRequest}
              defaultValueProp={typeRequest}
              dropType={"click-select"}
              options={[
                {
                  inputLabel: "Send",
                  inputValue: "Send",
                  groupName: "Send",
                },
                {
                  inputLabel: "Request",
                  inputValue: "Request",
                  groupName: "Request",
                },
              ]}
              setValueCallBack={(e) => {
                setTypeRequest(e[0] as any);
              }}
            />

            <DropDown
              inputValueSet={typeFile}
              defaultValueProp={typeFile}
              dropType={"click-select"}
              options={[
                {
                  inputLabel: "Video",
                  inputValue: "Video",
                  groupName: "Video",
                },
                {
                  inputLabel: "Photo",
                  inputValue: "Photo",
                  groupName: "Photo",
                },
              ]}
              setValueCallBack={(e) => {
                setTypeFile(e[0] as any);
              }}
            />
          </div>

          <ListItemToggle
            highlightText="Deactivate all requests"
            simpleText="Don’t allow any requests"
            isImageCircle
            imageLeft={PrivacyIconGray}
            toggleCallBack={() =>
              showToast({
                type: "info",
                title: "Coming soon!",
                description: "We appreciate your patience.",
              })
            }
          />
        </div>

        <div className="create-request__btns">
          <Button
            children={"Save"}
            buttonStyle={"primary"}
            onClick={() =>
              showToast({
                type: "info",
                title: "Coming soon!",
                description: "We appreciate your patience.",
              })
            }
          />
          <Button
            children={"Delete"}
            buttonStyle={"quaternary"}
            onClick={pageBack}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateRequestPage;
