import React, { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Header from "components/Headers/Header";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal";

import SendPhotoAlbumPage from "pages/SendPhotoAlbum";
import UploadDocumentPage from "pages/UploadDocument";

import { useBecomeCreator } from "contexts/becomeCreatorContext";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useModal } from "hooks/useModal";

import { shortClassNames } from "utils/shortClassNames";

const ChooseDocument: React.FC = () => {
  const { setPrincipalScreen, params } = useDeskNavigation();
  const { setRegisterBecomeCreator } = useBecomeCreator();

  const { windowSize } = useWindowWidthSize();
  const { setOpenUseModal, setModalContent } = useModal();

  const [activeButton, setActiveButton] = useState<number | null>(null);

  const breakPoint = windowSize >= 768;

  const documentsTypes = [
    {
      icon: "passport",
      title: "Passport",
      text: "Document",
      type: "PASSPORT",
      isShow: true,
    },
    {
      icon: "smile",
      title: "Identity Card",
      text: "Document",
      type: "IDENTITY_CARD",
      isShow: true,
    },
    {
      icon: "id",
      title: "Drivers License",
      text: "Document",
      type: "DRIVER_LICENSE",
      isShow: true,
    },
  ];

  const handleSelectDocument = (documentType: string, active: number) => {
    if (breakPoint) {
      setOpenUseModal(true);
      setRegisterBecomeCreator((prev) => ({
        ...prev,
        documentType,
      }));
      setModalContent(<UploadDocumentPage />);
    } else {
      setActiveButton(active);
      setPrincipalScreen("/upload-document", {
        ...params,
        documentType,
      });
    }
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<SendPhotoAlbumPage />);
    } else {
      setPrincipalScreen("/send-photos");
    }
  };

  return (
    <div className="choose-document choose-document-wrapper wrapper standard-page-layout bottom-spacing">
      <div className="choose-document-inner inner">
        <ButtonCloseModal />

        <div className="choose-document__top">
          {!breakPoint ? (
            <PageTitle
              title={"Become a Creator"}
              subtitle={"Start Earning"}
              hasCloseIcon={false}
              onBackClick={handlerBackPage}
            />
          ) : (
            false
          )}
          <Header
            title="Choose document"
            subTitle="Please choose the type of document you would like use to start the verification process."
            marginTop={0}
            backButtonClick={() => {
              // ! Enable in future
              // setPrincipalScreen("/send-me-link");
              handlerBackPage();
            }}
            {...(breakPoint
              ? {
                  headerIcon: "back-button",
                  inlineElements: true,
                  titleClass: "header",
                }
              : {
                  titleClass: "choose-document__title",
                })}
          />
        </div>

        <div className="choose-document__body">
          {documentsTypes.map((item, index) => {
            const { icon, title, text, type, isShow } = item;

            if (!isShow) return null;

            return (
              <button
                key={index}
                className={`choose-document__btn ${activeButton === index ? "choose-document__btn--active" : ""}`}
                onClick={() => handleSelectDocument(type, index)}
              >
                <span
                  className={shortClassNames([
                    "icon",
                    `icon-${icon}`,
                    "icon-gray",
                    "choose-document__icon",
                  ])}
                ></span>
                <h2 className="choose-document__btn-title">{title}</h2>
                <p className="choose-document__btn-text">{text}</p>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChooseDocument;
