import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import "../commonStyles.scss";
import getCreatorMedia from "../../../api/user/getCreatorMedia";
import { useUserInfo } from "../../../hooks/userInfo";
import { ICreatorMedia } from "../../../types/creatorMedia";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import PageTitle from "components/PageTitles";
import { useWindowWidthSize } from "../../../hooks/useWindowWidthSize";
import InputText from "components/Inputs/InputText";
import getAllMoments from "api/moments/getAllMoments";
import { IMomentsSavedDetails } from "types/moments";
import { useModal } from "hooks/useModal";
import { shortClassNames } from "utils/shortClassNames";

interface MediaStorageGalleryProps {
  className?: string;
  selectedMedias: (mediaArray: MediaObj) => void;
  capturedMedia: ICapturedMedia[];
}

type MediaObj = {
  type: string;
  media: string;
  id: string;
  thumbnail?: string;
};

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

const MediaStorageGallery: React.FC<MediaStorageGalleryProps> = React.memo(
  (props) => {
    const { className, selectedMedias, capturedMedia } = props;
    const [allMediaToShow, setAllMediaToShow] = useState<MediaObj[]>([]);
    const [searchFiles, setSearchFiles] = useState("");
    const [typeMediaToShow, setTypeMediaToShow] = useState<
      "all" | "videos" | "photos" | "saved" | "search"
    >("all");
    const [selectedMediasState, setSelectedMediasState] = useState<MediaObj[]>(
      []
    );
    const { setOpenUseModal } = useModal();
    const [cursorMoment, setCursorMoment] = useState("");
    const galleryRef = useRef<HTMLElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [clickTime, setClickTime] = useState(0);

    const { userInfo } = useUserInfo();
    const { windowSize } = useWindowWidthSize();
    const [isHolding, setIsHolding] = useState(false);
    const timerRef = useRef<number | null>(null);
    const isHoldingRef = useRef(false);
    const videoRefs = useRef<HTMLVideoElement[]>([]);
    const holdDuration = 1000;

    useEffect(() => {
      mediaCreatorRequest();
      getAllMomentsSended();
    }, [userInfo.access_token]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const target = entries[0];
          if (target.isIntersecting && !isLoading) {
            // loadMoreItems();
          }
        },
        { threshold: 1.0 }
      );

      if (galleryRef.current) {
        observer.observe(galleryRef.current);
      }

      return () => {
        if (galleryRef.current) {
          observer.unobserve(galleryRef.current);
        }
      };
    }, [isLoading]);

    // useEffect(()=>{
    // 	setSelectedMediasState(capturedMedia)
    // }, [capturedMedia])

    const getAllMomentsSended = useCallback(async () => {
      try {
        const request = await getAllMoments(
          userInfo.access_token,
          20,
          cursorMoment
        );

        const moments: IMomentsSavedDetails = request.res;

        const media = moments.moments?.map((media) => {
          return {
            type: media?.file?.fileType,
            media:
              media?.file?.url ||
              media.file?.mediumUrl ||
              media.file?.thumbnailUrl,
            id: media?.fileId,
          };
        });

        setAllMediaToShow((prev) => [...prev, ...media]);
      } catch (error) {
        console.error(error);
      }
    }, [userInfo.access_token]);

    const mediaCreatorRequest = useCallback(async () => {
      try {
        const request = await getCreatorMedia(userInfo.access_token);
        const res: ICreatorMedia[] = request.res;

        const allMedias = res
          ?.filter((media) => media.fileType !== "AUDIO")
          ?.map((allMedia) => {
            return {
              type: allMedia.fileType,
              media: allMedia?.thumbnailUrl || allMedia.url,
              id: allMedia.fileId,
            };
          });

        setAllMediaToShow((prev) => [...prev, ...allMedias]);

        switch (request.status) {
          case 200:
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    }, [userInfo.access_token]);

    const buttonsGroup: ButtonNavigationInfo[] = useMemo(() => {
      return [
        {
          text: "All",
          clickAction: () => setTypeMediaToShow("all"),
          id: 1,
          textRight: allMediaToShow.length.toString(),
        },
        {
          text: "Photos",
          clickAction: () => setTypeMediaToShow("photos"),
          id: 2,
          textRight: allMediaToShow
            .filter((media) => media.type === "PHOTO")
            .length.toString(),
        },
        {
          text: "Videos",
          clickAction: () => setTypeMediaToShow("videos"),
          id: 3,
          textRight: allMediaToShow
            .filter((media) => media.type === "VIDEO")
            .length.toString(),
        },
        // {
        //   text: "Saved",
        //   clickAction: () => setTypeMediaToShow("saved"),
        //   id: 4,
        //   textRight: "0",
        // },
      ];
    }, [allMediaToShow]);

    const clickTouchDownMedia = (media: MediaObj, index: number) => {
      isHoldingRef.current = false;
      timerRef.current = window.setTimeout(() => {
        isHoldingRef.current = true;
        if (media.type === "VIDEO") {
          videoRefs.current[index].play();
        }
      }, holdDuration);
    };

    const clickTouchLeave = (media: MediaObj, index: number) => {
      if (media.type === "VIDEO") {
        videoRefs.current[index].pause();
        videoRefs.current[index].currentTime = 0;
      }
      setIsHolding(false);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };

    const clickTouchUpMedia = (media: MediaObj, index: number) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      if (isHoldingRef.current) {
        if (media.type === "VIDEO") {
          videoRefs.current[index].pause();
          videoRefs.current[index].currentTime = 0;
        }
        setIsHolding(false);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      } else {
        const mediaClicked = selectedMediasState.some(
          (item) => item.id === media.id
        )
          ? selectedMediasState.filter((item) => item.id !== media.id)
          : [...selectedMediasState, media];

        setSelectedMediasState(mediaClicked);
        selectedMedias(media);
      }
    };

    return (
      <section
        ref={galleryRef}
        id="media-storage-gallery"
        className={`${className ?? ""}`}
      >
        <PageTitle
          onBackClick={() => setOpenUseModal(false)}
          title="Create Content"
          subtitle="Share it with the world"
          className="add-media-page__page-title media-storage-gallery__page-title"
          hasCloseIcon
          hasButtonNavigation
        />
        <div className="media-storage-gallery__inner add-media-page__inner">
          <section className="media-storage-gallery__buttons-wrapper">
            <ButtonNavigation
              horizontalMargin={windowSize > 700}
              buttons={buttonsGroup}
              rightButtonIcon={
                windowSize > 700
                  ? {
                      iconButton: "icon-search",
                      clickAction: () => console.log("search"),
                    }
                  : undefined
              }
              buttonsColorNonSelected="midnight"
              // activatedButton={}
            />

            {windowSize < 700 ? (
              <div className="media-storage-gallery__search-input">
                <InputText
                  searchInput
                  inputType="text"
                  iconRight=""
                  onChangeInput={setSearchFiles}
                  value={searchFiles}
                />
              </div>
            ) : (
              false
            )}
          </section>
          <div className="media-storage-gallery__cards-wrapper">
            {allMediaToShow
              .filter((media) => {
                if (typeMediaToShow === "all") return true;
                if (typeMediaToShow === "photos" && media.type === "PHOTO")
                  return true;
                if (typeMediaToShow === "videos" && media.type === "VIDEO")
                  return true;
                return false;
              })
              .map((mediaFile, index) => {
                const isMediaIncluded = capturedMedia.find(
                  (m) => m.media === mediaFile.media
                );

                return (
                  <div
                    className={shortClassNames([
                      "cards-wrapper__file-card",
                      "feed-creator-image-post",
                      "element-header-overlay-35",
                      `${isMediaIncluded ? "file-card__selected" : ""}`,
                    ])}
                    key={index}
                    onMouseDown={() => clickTouchDownMedia(mediaFile, index)}
                    onMouseUp={() => clickTouchUpMedia(mediaFile, index)}
                    onMouseLeave={() => clickTouchLeave(mediaFile, index)}
                    // onTouchStart={() => clickTouchDownMedia(mediaFile, index)}
                    // onTouchEnd={() => clickTouchUpMedia(mediaFile, index)}
                    // onTouchMove={() => clickTouchLeave(mediaFile, index)}
                  >
                    {mediaFile.type === "PHOTO" ? (
                      <img
                        src={mediaFile.media}
                        alt={`${index} file`}
                        className="file-card__card"
                        loading="lazy"
                      />
                    ) : (
                      <video
                        ref={(el) => {
                          if (el) {
                            videoRefs.current[index] = el;
                          }
                        }}
                        src={mediaFile.media}
                        className="file-card__card"
                      >
                        <source src={mediaFile.media}></source>
                      </video>
                    )}
                    <span className="card__tag-file-type">
                      {mediaFile.type}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    );
  }
);

export default MediaStorageGallery;
