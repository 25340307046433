import React, { useEffect, useRef } from "react";
import "./styles.scss";

import { shortClassNames } from "utils/shortClassNames";

interface DialogProps {
  isOpen: boolean;
  isFeed?: boolean;
  isFullWidth?: boolean;
  header?: Header;
  children?: React.ReactNode;
  className?: string;
  onClose: () => void;
}

type Header = {
  title: string;
  subtitle: string;
};

const Modal: React.FC<DialogProps> = ({
  isOpen,
  isFeed,
  isFullWidth,
  header,
  children,
  className,
  onClose,
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (dialog) {
      if (isOpen && !dialog.open) {
        dialog.showModal();
      } else if (!isOpen && dialog.open) {
        dialog.close();
      }
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    const dialog = dialogRef.current;
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideContent = event.target === dialogRef.current;

      if (dialogRef.current && isClickOutsideContent) {
        onClose();
        dialog?.close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <dialog id="modal-default" className={`${className ?? ""}`} ref={dialogRef}>
      <div
        className={shortClassNames([
          "modal-content",
          `${isFeed ? "modal-content--feed" : ""}`,
          `${isFullWidth ? "" : "inner"}`,
        ])}
      >
        {header && (
          <div className="header">
            <h3 className="header__title">{header.title}</h3>
            <p className="header__text">{header.subtitle}</p>
          </div>
        )}
        {children}
      </div>
    </dialog>
  );
};

export default Modal;
