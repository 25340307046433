import React, { useRef, useState } from "react";
import "./styles.scss";
import { useWindowWidthSize } from "../../../hooks/useWindowWidthSize";
import InputMessage from "components/TextAreas/Textarea";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";

interface PreviewCapturedMediaProps {
  className?: string;
  mediaToShow: ICapturedMedia | null;
  setDescriptionContent: React.Dispatch<React.SetStateAction<string>>;
  descriptionContent: string;
  setSecondaryScreenShowing: React.Dispatch<
    React.SetStateAction<"" | "config" | "exclusive-list" | "collaborator-list">
  >;
  setScreenShowing: React.Dispatch<
    React.SetStateAction<"" | "library" | "preview-captured" | "camera">
  >;
  previousPage: "none" | "chat" | "home";
}

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

const PreviewCapturedMedia: React.FC<PreviewCapturedMediaProps> = (props) => {
  const {
    className,
    mediaToShow,
    descriptionContent,
    setScreenShowing,
    setDescriptionContent,
    setSecondaryScreenShowing,
    previousPage,
  } = props;
  const { windowSize } = useWindowWidthSize();
  const [isPlayerIconShow, setIsPlayerIconShow] = useState(true);

  const videoCapturedRef = useRef<HTMLVideoElement>(null);

  const playPauseVideo = () => {
    if (videoCapturedRef.current) {
      if (videoCapturedRef.current.paused) {
        videoCapturedRef.current.play();
        setIsPlayerIconShow(false);
      } else {
        videoCapturedRef.current.pause();
        setIsPlayerIconShow(true);
      }
    }
  };

  const openConfigHandler = () => {
    setScreenShowing("");
    if (previousPage === "home") {
      setSecondaryScreenShowing("config");
    } else {
      setSecondaryScreenShowing("exclusive-list");
    }
  };

  const previewCapturedMediaClassesNames = [className].join(" ").trim();

  return (
    <div
      id="preview-captured-media"
      className={previewCapturedMediaClassesNames}
    >
      {mediaToShow?.type === "photo" ? (
        <img src={mediaToShow.media} alt="media captured" />
      ) : (
        <div className="media-container__video-captured">
          <video
            onClick={playPauseVideo}
            key={mediaToShow?.media}
            ref={videoCapturedRef}
            className="video-captured__video"
          >
            <source src={mediaToShow?.media} type="video/webm" />
            Your browser does not support the video tag.
          </video>
          {isPlayerIconShow ? (
            <span
              onClick={playPauseVideo}
              className="icon icon-play icon-white icon-xlg play_icon"
            />
          ) : (
            false
          )}
        </div>
      )}
      {windowSize < 768 ? (
        <div className="media-container__footer">
          <InputText
            inputType="text"
            value={descriptionContent}
            onChangeInput={setDescriptionContent}
            placeholder={"Description"}
          />
          <button className="footer__arrow-button" onClick={openConfigHandler}>
            <span className="icon icon-md icon-white icon-arrow-right " />
          </button>
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default PreviewCapturedMedia;
