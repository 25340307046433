import React, { ChangeEvent, useEffect, useState } from "react";
import "./styles.scss";

import { UserInfoPhoto } from "types/userInfoRequest";

import CloseIconPeach from "assets/icons/closeIcons/close-icon-peach.svg";
import GalleryIcon from "assets/icons/keyBoardIcons/gallery-icon-gray.svg";

interface IPhotoRequiredProps {
  quantityFiles: number;
  images?: UserInfoPhoto[];
  className?: string;
  photoAlbumCallback: (files: (File | null)[]) => void;
  removeFileCallback?: (fileId: string) => void;
  updatePhotoCallback?: (file: { fileId: string; position: number }) => void;
}

const PhotoRequired: React.FC<IPhotoRequiredProps> = (props) => {
  const {
    quantityFiles,
    images,
    className,
    photoAlbumCallback,
    removeFileCallback,
    updatePhotoCallback,
  } = props;

  const [files, setFiles] = useState<(File | null)[]>(
    Array(quantityFiles).fill(null)
  );

  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [draggingIndexPush, setDraggingIndexPush] = useState<number | null>(
    null
  );

  const handleDrop =
    (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      //validacao para nao alterar banner e video
      if (draggingIndex === null || draggingIndex === undefined) return;
      if (draggingIndexPush === null || draggingIndexPush === index) return;

      // upload fotos
      if (!files || !images) return;

      const matchedImages = images.filter((img) =>
        files.some((file) => file?.name && img.url.includes(file.name))
      );

      if (matchedImages.length === 0) return;

      const selectedImage = matchedImages[draggingIndexPush - 2];

      if (updatePhotoCallback) {
        updatePhotoCallback({
          fileId: selectedImage.fileId,
          position: draggingIndex - 1,
        });
      }

      orderFilesPlaceholder();
    };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];

    if (images && files[index]) {
      const imageIndex = index - 2;
      removeFileCallback?.(images[imageIndex]?.fileId);
    }

    newFiles[index] = null;
    setFiles(newFiles);
    photoAlbumCallback(newFiles);
  };

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newFiles = [...files];
    if (e.target.files && e.target.files[0]) {
      newFiles[index] = e.target.files[0];
      setFiles(newFiles);
      photoAlbumCallback(newFiles);
    }
  };

  useEffect(() => {
    orderFilesPlaceholder();
  }, [images]);

  const orderFilesPlaceholder = () => {
    if (images) {
      const newFiles = Array(quantityFiles).fill(null);

      images.forEach((image, idx) => {
        const imageIndex = idx;
        if (imageIndex < quantityFiles)
          newFiles[imageIndex] = { name: image.url };
      });

      setFiles(newFiles);
    }
  };

  return (
    <div className={`photo-required ${className ?? ""}`}>
      {Array.from({ length: quantityFiles }).map((_, index) => {
        return (
          <div
            key={index}
            className={`
              photo-preview 
              preview-images 
              ${!files[index] ? "border-empty" : "border-none"}
            `}
          >
            {files && files[index] && files[index]?.name.startsWith("http") ? (
              <div className="preview-images">
                <img src={files[index]?.name} alt={`preview ${index}`} />
                <button onClick={() => handleRemoveFile(index)}>
                  <img src={CloseIconPeach} alt="remove icon" />
                </button>
              </div>
            ) : (
              <>
                {!files[index] ? (
                  <>
                    <span className="empty-photo">
                      <span className="icon icon-camera icon-md icon-gray"></span>
                    </span>
                    <input
                      type="file"
                      name="upload-photo"
                      onChange={(e) => handleFileChange(e, index)}
                    />
                  </>
                ) : null}
              </>
            )}

            {files && files[index] && files[index] instanceof File ? (
              <div className="preview-images">
                <img
                  src={URL.createObjectURL(files[index] as File)}
                  alt={`preview ${files[index]?.name}`}
                />
                <button
                  onClick={() => {
                    if (!files[index]?.name.toLowerCase().endsWith(".mp4")) {
                      handleRemoveFile(index);
                    }
                  }}
                >
                  <img src={CloseIconPeach} alt="remove icon" />
                </button>
              </div>
            ) : (
              <>
                {!files[index] ? (
                  <>
                    <span className="empty-photo">
                      <span className="icon icon-photo icon-md icon-gray"></span>
                    </span>
                    <input
                      type="file"
                      name="upload-photo"
                      onChange={(e) => handleFileChange(e, index)}
                    />
                  </>
                ) : null}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PhotoRequired;
