import fetchApi from "../fetchApi";

const getTransactionsDashboardRevenue = async (token: string) => {
  return await fetchApi({
    service: "transactions",
    endPoint: `/dashboard/revenue`,
    method: "GET",
    token,
  });
};

export default getTransactionsDashboardRevenue;
