import fetchApi from "../fetchApi";

type SubscriptionStatus = "ACTIVE" | "PAST_DUE" | "CANCELED";

type SubscribersParams = {
  take?: string;
  cursor?: string;
  status?: SubscriptionStatus[];
  isActive?: boolean;
  nameSearch?: string;
};

const getSubscribers = async (token: string, params?: SubscribersParams) => {
  const queryParams = new URLSearchParams();

  queryParams.append("take", String(params?.take || "10"));

  if (params?.cursor) queryParams.append("cursor", String(params.cursor));
  if (params?.status) {
    params.status.map((value) => {
      queryParams.append("status", String(value));
      return value;
    });
  }
  if (params?.isActive) queryParams.append("isActive", String(params.isActive));
  if (params?.nameSearch)
    queryParams.append("nameSearch", String(params.nameSearch));

  const queryString = queryParams.toString();
  const searchParams = queryString ? `?${queryString}` : "";

  return await fetchApi({
    service: "subscribe",
    endPoint: `/creator/followers${searchParams}`,
    method: "GET",
    token,
  });
};

export default getSubscribers;
