import React, { forwardRef, ReactNode } from "react";
import "./styles.scss";

import ImageCircleBorder from "components/ImageCircleBorder";

import { shortClassNames } from "../../../utils/shortClassNames";

interface ListItemLinkProps {
  className?: string;
  classHighlight?: string;
  classSimple?: string;
  classImageCircle?: string;
  highlightText: ReactNode | string;
  simpleText?: ReactNode | string;
  imageLeft?: string;
  isImageCircle?: boolean;
  hasArrowRight?: boolean;
  hasSeparator?: boolean;
  rightNodeContent?: ReactNode;
  iconFill?: boolean;
  iconPrimaryColor?: boolean;
  isBackTransparent?: boolean;
  isBigIcon?: boolean;
  isBig?: boolean;
  isCompact?: boolean;
  hasCloseIconRight?: boolean;
  imageCircleMidnight?: boolean;
  dangerSimpleText?: boolean;
  showLeftImg?: boolean;
  closeIconClick?: () => void;
  onClick?: (a: any) => void;
  avatarImgStyle?: boolean;
}

const ListItemLink = forwardRef<HTMLDivElement, ListItemLinkProps>(
  (props, ref) => {
    const {
      className,
      classHighlight,
      classSimple,
      classImageCircle,
      highlightText,
      simpleText,
      imageLeft,
      isImageCircle,
      hasArrowRight,
      hasSeparator,
      rightNodeContent,
      iconFill,
      iconPrimaryColor,
      isBackTransparent,
      isBigIcon,
      isBig,
      isCompact,
      hasCloseIconRight,
      dangerSimpleText,
      showLeftImg,
      closeIconClick,
      onClick,
      avatarImgStyle,
    } = props;

    const listItemClass = () => {
      if ((imageLeft || avatarImgStyle) && !rightNodeContent) {
        return "column-for-image";
      } else if (
        (imageLeft || avatarImgStyle) &&
        rightNodeContent &&
        hasArrowRight
      ) {
        return "column-for-image-and-node";
      } else if (
        (imageLeft || avatarImgStyle) &&
        rightNodeContent &&
        !hasArrowRight
      ) {
        return "column-for-image-and-node-without-arrow";
      } else if (!(imageLeft || avatarImgStyle) && showLeftImg) {
        return "column-for-node-and-show-left";
      } else if (!(imageLeft || avatarImgStyle) && rightNodeContent) {
        return "column-for-node";
      } else {
        return "";
      }
    };

    const listItemClasses = [
      "list-item-link",
      className ?? "",
      isBig ? "list-item-link--big" : "",
      isCompact ? "list-item-link--compact" : "",
      onClick ? "cursor-pointer" : "",
      listItemClass(),
    ];

    return (
      <div
        id="list-item-link"
        className={shortClassNames(listItemClasses)}
        onClick={onClick}
        ref={ref}
      >
        {imageLeft || avatarImgStyle || showLeftImg ? (
          <>
            {imageLeft ? (
              <div
                className={shortClassNames([
                  `item__image-left-container`,
                  `${isImageCircle ? "image-circle" : ""}`,
                  `${isBackTransparent ? "icon-transparent" : ""}`,
                  `${classImageCircle ?? ""}`,
                ])}
              >
                <img
                  src={imageLeft}
                  alt="left icon"
                  className={shortClassNames([
                    `item__left-icon`,
                    `${iconFill ? "icon-fill" : ""}`,
                    `${isBigIcon ? "icon-big" : ""}`,
                  ])}
                />
              </div>
            ) : (
              <ImageCircleBorder centerImage="" centerImageAlt="" size={40} />
            )}
          </>
        ) : (
          false
        )}

        <div className="item__main-container">
          <p
            className={`main-container__highlight-text ${classHighlight ?? ""}`}
            data-highlight
          >
            {highlightText}
          </p>
          <p
            className={shortClassNames([
              `main-container__simple-text`,
              `${dangerSimpleText ? "danger-text" : ""}`,
              `${classSimple ?? ""}`,
            ])}
            data-simple
          >
            {simpleText}
          </p>
        </div>
        {rightNodeContent && (
          <section className="list-item-link__right-node">
            {rightNodeContent}
          </section>
        )}

        {hasArrowRight ? (
          <span
            className={`icon icon-chevron-right ${iconPrimaryColor ? "icon-secondary" : "icon-gray"} icon-md arrow-right`}
          />
        ) : hasCloseIconRight && closeIconClick ? (
          <span
            className="icon icon-peach icon-md close-right icon-close"
            onClick={closeIconClick}
          />
        ) : (
          false
        )}

        {hasSeparator && <div className="separator" />}
      </div>
    );
  }
);

export default ListItemLink;
