import React, { ReactNode } from "react";
import "./styles.scss";

import Logo from "assets/icons/logos/logo-fill-pink.svg";

interface IChatMessageSystem {
  title?: string;
  text?: string;
  messageText?: string;
  specialText?: {
    text?: string;
    link?: string;
  };
  amount?: number;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const ChatMessageSendRoses: React.FC<IChatMessageSystem> = (props) => {
  const {
    title = "Order confirmation",
    text = "You receive a present",
    messageText = "",
    specialText,
    children,
    className,
    onClick,
  } = props;
  return (
    <div className={`chat-send-roses ${className || ""} inner`}>
      <img src={Logo} alt="" className="chat-send-roses__logo" />

      <div className="chat-send-roses__content">
        {title && <h2 className="chat-send-roses__title">{title}</h2>}

        {text && (
          <p className="chat-send-roses__text  ">
            {text}{" "}
            {specialText && (
              <span className="chat-send-roses__text--special  ">
                {specialText.text}
              </span>
            )}
          </p>
        )}

        {messageText && (
          <p className="chat-send-roses__text  ">{messageText}</p>
        )}

        {children && children}
      </div>

      {onClick && (
        <span
          className="chat-send-roses__icon icon-chevron-right"
          onClick={onClick}
        ></span>
      )}
    </div>
  );
};
export default ChatMessageSendRoses;
