import fetchApi from "../fetchApi";

export type IPostPostsView = {
  postId: string;
};

const postPostsView = async (token: string, data: IPostPostsView) => {
  const { postId } = data;

  return await fetchApi({
    service: "posts",
    method: "POST",
    endPoint: `/view/${postId}`,
    token,
  });
};

export default postPostsView;
