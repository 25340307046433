import React from "react";
import "./styles.scss";
import rosesLogoIconPink from "assets/icons/logos/logo-rose-icon-pink.svg";
import GirlOpenedMonth from "assets/images/girl-opened-month.png";
import Button from "../../../Buttons/Button";
import TranslatableText from "../../../Texts/TranslatableText";
import { useNavigate } from "react-router-dom";
import { useLoginSignUpNavigation } from "../../../../hooks/useLoginSignUpNavigation";
import AccountLoginPage from "../../../../pages/AccountLogin";

const ContentWhyUsWrapper = () => {
  const navigate = useNavigate();

  const { setComponent } = useLoginSignUpNavigation();

  const handleOpenApp = () => {
    setComponent(<AccountLoginPage />);
    navigate("/login");
  };

  return (
    <div id="content-why-us-wrapper">
      <div className="purple-div">
        <img src={GirlOpenedMonth} alt="principal banner" />
      </div>
      <div className="yellow-div inner">
        <div className="text-container">
          <span className="icon icon-roses-default icon-logo icon-lg"></span>
          <h1 textkey="3/introMainTitle">
            Why Choose <b>Roses</b>™?
          </h1>
          <div className="feature-main-description">
            <p textkey="3/introMainDescription">
              Welcome to Roses, a revolutionary platform designed for creators
              to showcase their passion and connect with a global audience.
              Discover why Roses is the perfect place for you to thrive:
            </p>
          </div>
          <h4 textkey="3/introTitle-01">Empower Your Passion</h4>
          <div className="feature-secondary-description">
            <p textkey="3/introDescription-01">
              Join Roses and experience the joy of sharing and receiving Roses,
              transforming transactions into meaningful connections. With Roses,
              creators keep 100% of their earnings, ensuring every contribution
              supports their creative journey.
            </p>
          </div>
          <Button
            buttonStyle="primary"
            children={
              <TranslatableText textAlt fontSize="10" textKey="3/introButton" />
            }
            onClick={handleOpenApp}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentWhyUsWrapper;
