import "./styles.scss";
import PageTitle from "components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsMyOffersPage: React.FC = () => {
  const {
    componentFeed,

    setPrincipalScreen,
    setSecondaryScreen,
  } = useDeskNavigation();

  return (
    <div className="user-settings-my-offers user-settings-my-offers-wrapper wrapper standard-page-layout">
      <div className="user-settings-my-offers-inner inner">
        <PageTitle
          title={"My Offers"}
          subtitle={"Settings"}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings");
            } else {
              setPrincipalScreen("/user-settings");
            }
          }}
        />
      </div>

      <div className="notifications-container">{"code here"}</div>
    </div>
  );
};

export default UserSettingsMyOffersPage;
