import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

interface ProgressbarProps {
  className?: string;
  time: number;
  trigger: boolean;
  pause: boolean;
  triggerCallback: (trigger: boolean) => void;
  sizeBar: number;
  index: number;
  currentIndex: number;
}

const Progressbar: React.FC<ProgressbarProps> = ({
  className,
  time,
  trigger,
  pause,
  triggerCallback,
  sizeBar,
  currentIndex,
  index,
}) => {
  const [barSize, setBarSize] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const currentBarSizeRef = useRef(0);

  useEffect(() => {
    setBarSize(sizeBar);
    currentBarSizeRef.current = 0;

    if (index > currentIndex) {
      setBarSize(0);
      currentBarSizeRef.current = 0;
    }
  }, [sizeBar, index, currentIndex]);

  useEffect(() => {
    if (trigger && !pause) {
      const intervalTime = 10;
      const increment = 100 / (time / intervalTime);

      intervalRef.current = setInterval(() => {
        currentBarSizeRef.current += increment;
        setBarSize(currentBarSizeRef.current);

        if (currentBarSizeRef.current >= 100) {
          clearInterval(intervalRef.current!);
          triggerCallback(true);
        }
      }, intervalTime);

      return () => clearInterval(intervalRef.current!);
    } else if (pause && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, [trigger, pause, time, triggerCallback]);

  return (
    <div
      id="progressbar-component"
      className={`progressbar-component ${className ?? ""}`}
    >
      <div
        className="progressbar-component__bar"
        style={{ width: `${barSize}%` }}
      />
      <div
        className="progressbar-component__bar-negative"
        style={{ width: `${100 - barSize}%` }}
      />
    </div>
  );
};

export default Progressbar;
