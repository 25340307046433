import { PullToRefreshContext } from "contexts/pullToRefreshContext";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import React, { useCallback, useContext, useRef, useState } from "react";

interface IPullToRefresh {
  children: React.ReactNode;
}

const PullToRefresh: React.FC<IPullToRefresh> = (props) => {
  const { children } = props;
  const [pullStartY, setPullStartY] = useState(0);
  const [pullMoveY, setPullMoveY] = useState(0);
  const { isEnabled } = useContext(PullToRefreshContext);

  const contentRef = useRef<HTMLDivElement>(null);
  const { windowSize } = useWindowWidthSize();

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (!isEnabled) {
        return;
      }
      setPullStartY(e.touches[0].clientY);
    },
    [isEnabled]
  );

  const distanceCalc = useCallback((distance: number, maxDistance: number) => {
    const k = 0.4;
    return maxDistance * (1 - Math.exp((-k * distance) / maxDistance));
  }, []);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (!contentRef.current) {
        return;
      }
      if (!isEnabled) {
        setPullStartY(0);
        contentRef.current.style.transform = `translateY(0px)`;
        return;
      }
      if (pullStartY === 0) {
        return;
      }

      const touchY = e.touches[0].clientY;
      const maxDistance = contentRef.current.offsetHeight * 0.33;
      const pullDistance = touchY - pullStartY;
      const formattedDistance = Math.abs(pullDistance);

      if (pullDistance > 20) {
        setPullMoveY(pullDistance);
        const isNegative = Math.sign(pullDistance) === -1;
        const translateDistance = isNegative
          ? distanceCalc(formattedDistance, maxDistance) * -1
          : distanceCalc(formattedDistance, maxDistance);

        contentRef.current.style.transform = `translateY(${translateDistance}px)`;
      }
    },
    [distanceCalc, isEnabled, pullStartY]
  );

  const handleTouchEnd = useCallback(() => {
    if (!contentRef.current) {
      return;
    }

    const pullMinHeight = contentRef.current.offsetHeight * 0.33;

    if (isEnabled) {
      if (pullMoveY > pullMinHeight) {
        window.location.reload();
      }
    }

    setPullMoveY(0);
    contentRef.current.style.transform = "translateY(0)";
  }, [isEnabled, pullMoveY]);

  if (windowSize > 768) {
    return <>{children}</>;
  }

  return (
    <div
      className="pull-to-refresh"
      ref={contentRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
};

export default PullToRefresh;
