import "./styles.scss";

import allIcons from "../../mocks/allIcons";
import ButtonIcon from "components/Buttons/ButtonIcon";
import Button from "components/Buttons/Button";
import { useToast } from "hooks/useToast";

const ShowAllButtonIcons: React.FC = () => {
  const buttonStyles = [
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "quintenary",
    "iconButton",
    "sexternary",
    "septenary",
  ] as const;

  const { showToast } = useToast();

  const copyText = (text: string) => {
    const textField = document.createElement("input");
    textField.value = text;
    document.body.appendChild(textField);

    textField.select();
    document.execCommand("copy");

    showToast({
      title: "Icon Copied",
      description: `${text} copied`,
      type: "success",
    });

    document.body.removeChild(textField);
  };

  return (
    <>
      <div className="all-button-styles">
        {buttonStyles.map((item) => {
          return <Button buttonStyle={item} children={item} />;
        })}
      </div>
      <div className="show-all-button-icons">
        {allIcons.map((icon, index) => (
          <div key={index} className="show-all-button-icons__card">
            <ButtonIcon icon={icon} />
            <span className="show-all-button-icons__texts">
              <button onClick={() => copyText(icon)}>Copy</button>
              <p>{icon}</p>
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default ShowAllButtonIcons;
