import React, { useEffect, useState } from "react";
import "./styles.scss";

import rosesLogoPink from "assets/icons/logos/logo-fill-pink.svg";

import PageTitle from "components/PageTitles";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useModal } from "../../hooks/useModal";

import deleteCreatorSettingsGoalDeactivate from "../../api/creatorSettings/deleteCreatorSettingsGoalDeactivate";

import getCreatorSettingsGoalDonors from "../../api/creatorSettings/getCreatorSettingsGoalDonors";
import patchCreatorSettingsGoal from "../../api/creatorSettings/patchCreatorSettingsGoal";

import { dateFormat } from "../../utils/dateFormat";

import { IDonor } from "../../types/goal";

const UserSettingsEditGoal: React.FC = ({
  className,
}: {
  className?: string;
}) => {
  const {
    componentFeed,
    setPrincipalScreen,
    setSecondaryScreen,

    params,
  } = useDeskNavigation();
  const { setOpenUseModal, setModalContent } = useModal();
  const { userInfo } = useUserInfo();
  const token = userInfo.access_token;
  const { goalId, isActive, target, title } = JSON.parse(params.goal);

  const [titleGoal, setTitleGoal] = useState(title);
  const [targetGoal, setTargetGoal] = useState<string | number>(target);
  const [listDonors, setListDonors] = useState<IDonor[]>([]);

  useEffect(() => {
    getListDonors(goalId);
  }, []);

  const patchGoal = async () => {
    try {
      if (targetGoal < target)
        throw new Error("Your goal cannot be lower than the previous one");

      const res = await patchCreatorSettingsGoal({
        token,
        title: titleGoal,
        target: Number(targetGoal),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListDonors = async (goalId: string) => {
    try {
      const { res } = await getCreatorSettingsGoalDonors(token, goalId);
      setListDonors(res);
    } catch (error) {
      console.error("Erro ao criar meta:", error);
    }
  };

  const deleteGoal = async (goalId: string) => {
    try {
      const res = await deleteCreatorSettingsGoalDeactivate(token, goalId);
    } catch (error) {
      console.error("Erro ao criar meta:", error);
    }
  };

  const disableGoal = async (goalId: string) => {
    setModalContent(
      <div className="goals__modal  ">
        <div className="goals__modal-top">
          <img
            className="goals__modal-logo"
            src={rosesLogoPink}
            alt="roses logo icon"
          />
          <h2 className="goals__modal-title">
            Do would like disable this Goal
          </h2>
        </div>
        <Button
          buttonStyle={"primary"}
          onClick={() => {
            try {
              const res = deleteGoal(goalId);
              setOpenUseModal(false);
            } catch (error) {
              console.error("Erroe", error);
            }
          }}
        >
          Yes
        </Button>
        <Button
          buttonStyle={"secondary"}
          onClick={() => setOpenUseModal(false)}
        >
          No
        </Button>
      </div>
    );
  };

  return (
    <div
      className={`user-settings-edit-goals  user-settings-edit-goals-wrapper wrapper standard-page-layout${
        className ?? ""
      }`}
    >
      <div className="user-settings-edit-goals-inner inner">
        <PageTitle
          title={"Edit Goals"}
          subtitle={"Configuration"}
          hasCloseIcon={false}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-goals");
            } else {
              setPrincipalScreen("/user-settings-goals");
            }
          }}
        />

        <div className={`goals   ${!isActive ? "goals--inactive" : ""}`}>
          <InputText
            label="Title: "
            value={titleGoal}
            inputType="text"
            placeholder="Buy a house"
            textTransform="none"
            onChangeInput={(e) => setTitleGoal(e)}
          />

          <InputText
            label="Target: "
            value={String(targetGoal)}
            inputType="text"
            placeholder="Buy a house"
            textTransform="none"
            onChangeInput={(e) => setTargetGoal(parseFloat(e))}
          />

          <Button
            buttonStyle={"primary"}
            onClick={isActive && patchGoal}
            disabled={!isActive}
          >
            Edit Goal
          </Button>

          <Button
            buttonStyle={"secondary"}
            onClick={() => {
              if (!isActive) return;

              setOpenUseModal(true);
              disableGoal(goalId);
            }}
            disabled={!isActive}
          >
            Disable Goal
          </Button>
        </div>

        <div className="   ">
          <h2 className="goals__history-title    ">Donor History:</h2>
          {listDonors.length !== 0 ? (
            listDonors.map((donor) => {
              const {
                donationId,
                amount,
                createdAt,
                userSender: { displayname },
              } = donor;
              return (
                <div key={donationId} className="goals__history  ">
                  <div className="goals__top">
                    <h3 className="goals__history-title  ">{displayname}</h3>
                  </div>
                  <div className="goals__bottom">
                    <p className="goals__amount">Amount: {amount}</p>
                    <p className="goals__objective">{dateFormat(createdAt)}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="goals__donation">You don't have donations yet!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSettingsEditGoal;
