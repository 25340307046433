import React from "react";
import "./styles.scss";

interface IRevenueCard {
  title: string;
  value: string;
  text?: string;
  linkText?: string;
  className?: string;
  onClick?: () => void;
}

const RevenueCard: React.FC<IRevenueCard> = (props) => {
  const { title, value, text, linkText, className, onClick } = props;

  return (
    <div className={`revenue revenue-inner inner ${className ?? ""}`}>
      <h2 className="revenue__title">{title}</h2>
      <div>
        <p className="revenue__value">{value}</p>
        {text && <p className="revenue__txt">{text}</p>}
      </div>

      {onClick && (
        <span className="revenue__link" onClick={onClick}>
          {linkText}
          <span className="icon icon-chevron-right revenue__link-icon"></span>
        </span>
      )}
    </div>
  );
};

export default RevenueCard;
