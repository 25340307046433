import React, { forwardRef, useCallback, useMemo } from "react";
import "./styles.scss";

import ButtonIcon from "../../../Buttons/ButtonIcon";
import ChatMessageAudio from "../ChatMessageAudio";
import ChatMessagePhoto from "../ChatMessagePhoto";
import ChatMessageVideo from "../ChatMessageVideo";
import ChatMessageGrid from "../ChatMessageGrid";
import ChatMessageSendRoses from "../ChatMessageSendRoses";
import LockedFilesChat from "../../../LockedFilesChat";
import ChatMessageSystem from "../ChatMessageSystem";
import Loading from "../../../Loading";
import ReadAudioChat from "../../../ReadAudioChat";
import CardRosesSendedAndReceived from "../../../Cards/CardRosesSendedAndReceived";

import { useUserInfo } from "hooks/userInfo";

import { shortClassNames } from "utils/shortClassNames";

import { File, MessageSettings } from "../../../../types/chatInfo";

import ImageBlur from "assets/images/image-blur.jpg";
import Logo from "assets/icons/logos/logo-fill-pink.svg";

interface IChatMessage {
  id: string;
  side: string;
  message?: string;
  time?: string;
  translateDisclaimerContent?: string;
  fileSended?: File[];
  isMessageRead: boolean;
  isSystemMessage?: boolean;
  messageSettings: MessageSettings;
  messageType: string;
  transfer?: {
    amount: number;
  };
  user: string;
  chatRef?: React.LegacyRef<HTMLDivElement> | undefined;
  clickOnImage?: (a: any) => void;
  clickOnVideo?: (a: any) => void;
  clickOnPack?: (file: File[]) => void;
  unlockFileCallback: (a: string) => void;
}

const ChatMessageNew = forwardRef<HTMLDivElement, IChatMessage>(
  (
    {
      id,
      side,
      message,
      time,
      translateDisclaimerContent,
      fileSended,
      isMessageRead,
      isSystemMessage,
      messageSettings,
      messageType,
      transfer,
      user,
      chatRef,
      clickOnImage,
      clickOnVideo,
      clickOnPack,
      unlockFileCallback,
    },
    ref
  ) => {
    const { userInfo } = useUserInfo();

    const unlockFile = useCallback(
      () => unlockFileCallback(messageSettings.messageId),
      [unlockFileCallback, messageSettings]
    );

    const isPaid = !!messageSettings?.isPaid;
    const paidContent = messageSettings?.paidContent || false;
    const files = useMemo(
      () => (fileSended?.length ? fileSended : []),
      [fileSended]
    );
    const hasMessage = useMemo(
      () => (message && message?.length > 0) || false,
      [message]
    );
    const chatMessageType = useMemo(() => {
      if (isSystemMessage) return "SYSTEM";
      if (!files.length) return "TEXT";
      if (files[0]?.fileType === "AUDIO") return "AUDIO";
      if (files.length < 4) return "SINGLE";
      if (files.length >= 4) return "GRID";
      if (messageType === "ROSES") return "ROSES";
      return undefined;
    }, [files, isSystemMessage]);

    const renderContentSingle = (file: File, isLastItem?: boolean) => {
      if (file?.fileType === "PHOTO") {
        return (
          <ChatMessagePhoto
            file={file}
            side={side}
            messageSettings={messageSettings}
            hasText={hasMessage}
            clickOnImage={clickOnImage}
            className={`${!isLastItem ? "chat-message__single" : ""}`}
          />
        );
      }

      if (file?.fileType === "VIDEO") {
        return (
          <ChatMessageVideo
            side={side}
            file={file}
            messageSettings={messageSettings}
            hasText={hasMessage}
            clickOnVideo={clickOnVideo}
          />
        );
      }
    };

    const renderLockedContent = useCallback(
      (unlockCallback: () => void) => (
        <LockedFilesChat
          message="Unlock this content"
          caption={message}
          rosesPrice={messageSettings.price}
          unlockClick={unlockCallback}
          qntPhotos={
            files?.filter((file) => file?.fileType === "PHOTO")?.length || "0"
          }
          qntVideos={
            files?.filter((file) => file?.fileType === "VIDEO")?.length || "0"
          }
          lockedImageBlurred={
            fileSended ? fileSended[0]?.filePreview?.previewUrl : ImageBlur
          }
        />
      ),
      [files, message, messageSettings, fileSended]
    );

    const FileContainer = ({
      children,
      isLastItem = true,
      isLocked = false,
    }: {
      children?: React.ReactNode;
      isLastItem?: boolean;
      isLocked?: boolean;
    }) => {
      return (
        <div id={id} className={`chat-message chat-message--${side}`} ref={ref}>
          {side === "left" && <p className="chat-message__time  ">{time}</p>}

          <div
            className={shortClassNames([
              `chat-message__wrapper`,
              `chat-message__wrapper--${side}`,
              `${
                chatMessageType === "SYSTEM"
                  ? `chat-message__wrapper--system`
                  : ""
              }`,
              `${files.length ? `chat-message__wrapper--file` : ""}`,
            ])}
            onClick={() => {
              if (files.length >= 4 && clickOnPack) {
                const shouldClick = isPaid || !paidContent || side === "right";

                if (shouldClick) {
                  clickOnPack(files);
                }
              }
            }}
          >
            {children && children}
            {message &&
              isLastItem &&
              !isLocked &&
              chatMessageType !== "SYSTEM" && (
                <p
                  className={shortClassNames([
                    `chat-message__text`,
                    `${files.length ? "chat-message__text--file" : ""}`,
                  ])}
                >
                  {message}
                </p>
              )}

            {message && chatMessageType === "SYSTEM" && (
              <div
                className={shortClassNames([
                  `chat-message__text`,
                  `chat-message__text--system`,
                ])}
              >
                {chatMessageType === "SYSTEM" && (
                  <ButtonIcon buttonStyle={"transparent"}>
                    <img src={Logo} alt="" className="chat-message__logo" />
                  </ButtonIcon>
                )}
                <div>
                  <p className="chat-message__text--system">{message}</p>
                </div>
              </div>
            )}
          </div>

          {side === "right" && (
            <p className="chat-message__time  ">
              {time}
              <span
                className={`chat-message__read-status ${
                  isMessageRead ? "message-read" : "unread-message"
                }`}
              />
            </p>
          )}

          {translateDisclaimerContent && (
            <span className={`disclaimer-container ${side}`}>
              {translateDisclaimerContent}
            </span>
          )}
        </div>
      );
    };

    const renderContentGrid = useCallback(
      (files: File[]) => (
        <ChatMessageGrid
          side={side}
          messageSettings={messageSettings}
          files={files}
          message={message}
        />
      ),
      [side, messageSettings]
    );

    const renderListFiles = useCallback(
      (file: File, isLastItem?: boolean) => {
        return (
          <>
            {chatMessageType === "AUDIO" && (
              <ChatMessageAudio src={file.url} duration={file.duration} />
            )}
            {chatMessageType === "SINGLE" &&
              renderContentSingle(file, isLastItem)}
          </>
        );
      },
      [chatMessageType]
    );

    return (
      <>
        {chatMessageType !== "GRID" ? (
          paidContent && !isPaid && side === "left" ? (
            <FileContainer isLastItem={true} isLocked>
              {renderLockedContent(unlockFile)}
            </FileContainer>
          ) : (
            files.map((file, index) => {
              const isLastItem = index === files.length - 1;
              return (
                <FileContainer isLastItem={isLastItem} key={index}>
                  {renderListFiles(file, isLastItem)}
                </FileContainer>
              );
            })
          )
        ) : chatMessageType === "GRID" &&
          paidContent &&
          !isPaid &&
          side === "left" ? (
          <FileContainer isLastItem={false} isLocked>
            {renderLockedContent(unlockFile)}
          </FileContainer>
        ) : (
          <FileContainer isLastItem={false}>
            {renderContentGrid(files)}
          </FileContainer>
        )}

        {chatMessageType === "TEXT" && <FileContainer />}

        {messageType === "ROSES" && transfer?.amount !== undefined && (
          <ChatMessageSendRoses
            text={
              transfer?.amount
                ? side === "left"
                  ? `You received ${String(transfer.amount)} from`
                  : `You sent ${String(transfer.amount)} to`
                : ""
            }
            amount={transfer?.amount ?? 0}
            messageText={message}
            specialText={{
              text: `@${side === "left" ? user : userInfo.display_name}`,
            }}
          />
        )}

        {/* {chatMessageType === "SYSTEM" && <FileContainer />} */}

        {/* NOT DELETE FELIPE WILL NEED IT IN THE FUTURE */}
        {/* <ChatMessageSystem
          title="Order confirmation"
          text="250 Roses was sent to "
          specialText={{ text: "@scarlet.roses" }}
         
        /> */}
      </>
    );
  }
);

export default ChatMessageNew;
