import React, { ReactNode, useContext, useEffect, useRef } from "react";
import "./styles.scss";
import { PullToRefreshContext } from "contexts/pullToRefreshContext";

interface SlidUpProps {
  isFullScreen?: boolean;
  isOpen: boolean;
  children: ReactNode;
  classNameChildren?: string;
  title?: string;
  header?: Header;
  classNameContainer?: string;
  closeArea?: () => void;
  onBackClick?: () => void;
  onCheckClick?: () => void;
}

type Header = {
  title: string;
  subtitle: string;
};

const SlidUp: React.FC<SlidUpProps> = ({
  children,
  isFullScreen,
  onBackClick,
  onCheckClick,
  isOpen,
  classNameChildren,
  title,
  header,
  classNameContainer,
  closeArea,
}) => {
  const { setIsEnabled } = useContext(PullToRefreshContext);

  const slidUpRef = useRef<HTMLDivElement>(null);
  const slidUpContentRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const startYRef = useRef<number | null>(null);
  const translateYRef = useRef(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    if (
      e.touches.length !== 1 ||
      (childrenRef.current && childrenRef.current.contains(e.target as Node))
    )
      return;
    startYRef.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (
      e.touches.length !== 1 ||
      startYRef.current === null ||
      childrenRef.current?.contains(e.target as Node)
    )
      return;
    const currentY = e.touches[0].clientY;
    const translateY = Math.max(0, currentY - startYRef.current);
    translateYRef.current = translateY;
    const target = e.currentTarget as HTMLElement;
    target.style.transform = `translateY(${translateY}px)`;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (childrenRef.current?.contains(e.target as Node)) return;
    const target = e.currentTarget as HTMLElement;
    if (translateYRef.current > 100) {
      if (closeArea) closeArea();
    } else {
      target.style.transform = "translateY(0)";
    }
    startYRef.current = null;
    translateYRef.current = 0;
  };

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  const handleCheckClick = () => {
    if (onCheckClick) {
      onCheckClick();
    }
  };

  useEffect(() => {
    const slidUp = slidUpRef.current;

    if (slidUp) {
      if (isOpen) {
        slidUp.style.display = "flex";
        requestAnimationFrame(() => {
          slidUp.classList.add("open");
          slidUp.classList.remove("close");
        });
      } else {
        slidUp.classList.add("close");
        slidUp.classList.remove("open");

        setTimeout(() => {
          if (slidUp.classList.contains("close")) {
            slidUp.style.display = "none";
            if (slidUpContentRef.current) {
              slidUpContentRef.current.style.transform = "translateY(0)";
            }
          }
        }, 350);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsEnabled(false);
    }

    return () => {
      setIsEnabled(true);
    };
  }, [setIsEnabled, isOpen]);

  if (isFullScreen) {
    return (
      <div
        ref={slidUpRef}
        className={classNameContainer ?? ""}
        onClick={() => {
          if (closeArea) closeArea();
        }}
        id="slid-up-container"
      >
        <div
          ref={slidUpContentRef}
          className="slid-up-full-screen"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="slid-up-header">
            <div
              className="slid-up-full-header-button"
              onClick={handleBackClick}
            >
              <span className="icon icon-arrow-left icon-md icon-gray-light"></span>
            </div>
            {title && <h4 className="slid-up-title">{title}</h4>}
            {header && (
              <div className="header">
                <h3 className="header__title">{header.title}</h3>
                <p className="header__text">{header.subtitle}</p>
              </div>
            )}
            <div
              className="slid-up-full-header-button"
              onClick={handleCheckClick}
            >
              <span className="icon icon-check icon-md icon-green"></span>
            </div>
          </div>
          <div className="slid-up-full-content inner">
            <p className="slid-up-full-content-info-text">
              You can add a long descriptions about this drawer here in this
              section
            </p>
            <div
              ref={childrenRef}
              onTouchStart={handleTouchStart}
              className={classNameChildren}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={slidUpRef}
      className={classNameContainer ?? ""}
      onClick={() => {
        if (closeArea) closeArea();
      }}
      id="slid-up-container"
    >
      <div
        ref={slidUpContentRef}
        className="slid-up-content"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="handler-bar" />
        {title && <h4 className="slid-up-title">{title}</h4>}
        {header && (
          <div className="header">
            <h3 className="header__title">{header.title}</h3>
            <p className="header__text">{header.subtitle}</p>
          </div>
        )}
        <div
          ref={childrenRef}
          onTouchStart={handleTouchStart}
          className={classNameChildren}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SlidUp;
