import React, { forwardRef, useEffect, useState } from "react";
import "./styles.scss";

import Button from "components/Buttons/Button";
import ButtonPill from "components/Buttons/ButtonPill";
import CardsArrows from "../CardsArrows";
import ImageWithSize from "components/ImageWithSize";
import FeedProfileLabel from "components/FeedProfileLabel";
import ProgressbarWithoutAnimation from "components/ProgressbarWithoutAnimation";
import InfosPost from "components/InfosPost";

import { useToast } from "hooks/useToast";
import { useUserInfo } from "hooks/userInfo";
import { useOpenChat } from "hooks/useOpenChat";

import postPostsLike from "api/posts/postPostsLike";
import deletePostsLike from "api/posts/deletePostsLike";

import { Post } from "types/post";

import logo from "assets/icons/logos/logo-fill-pink.svg";
import expand from "assets/icons/feed-icons/expand.svg";
import verify from "assets/icons/verify.svg";
import { shortClassNames } from "utils/shortClassNames";

interface IFeedCards {
  post: Post;
  isGlobal?: boolean;
  isExpanded?: boolean;
  className?: string;
  onDelete?: (e?: any) => void;
  onUnlockedPost?: (id: string, price: number) => void;
  handleExpand?: () => void;
}

const FeedCards = forwardRef<HTMLDivElement, IFeedCards>(
  (
    {
      post,
      isGlobal = false,
      isExpanded,
      className,
      onDelete,
      onUnlockedPost,
      handleExpand,
    },
    ref
  ) => {
    const {
      postId,
      autorId,
      content,
      paidContent,
      price,
      createdAt,
      autor: {
        userId,
        displayname,
        username,
        profile: { photos } = { photos: [] },
      },
      files = [],
      _count: { likedBy, seenBy } = { likedBy: 0, seenBy: 0 },
      hasLiked = false,
      hasSeen = false,
      hasPaid = false,
      hasActiveSubscription = false,
    } = post;
    const { userInfo } = useUserInfo();

    const { showToast } = useToast();
    const openChat = useOpenChat(userId);

    const [isOptionsActive, setIsOptionsActive] = useState(false);
    const [postHasLiked, setPostHasLiked] = useState(hasLiked);

    const [photosCounter, setPhotosCounter] = useState(0);
    const [videosCounter, setVideosCounter] = useState(0);

    const [photoSelect, setPhotoSelect] = useState("");
    const [photoSelectType, setPhotoSelectType] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [mediaAbleToShow, setMediaAbleToShow] = useState(false);
    const [mediaIndexCurrent, setMediaIndexCurrent] = useState(0);
    const [isPausedMedia, setIsPausedMedia] = useState(false);
    const [isLoadingMedias, setIsLoadingMedias] = useState(true);
    const [likedByCount, setLikedByCount] = useState(likedBy);

    const isLocked = paidContent && !hasPaid && !hasActiveSubscription;
    const isOwn = userInfo.user_id === autorId;

    useEffect(() => {
      counterMedias();
    }, []);

    const handlerLike = () => {
      if (postHasLiked) {
        return clickUnlikeHandler();
      } else {
        return clickLikeHandler();
      }
    };

    const clickLikeHandler = async () => {
      try {
        const res = await postPostsLike(userInfo.access_token, { postId });

        if (res.status === 201) {
          setPostHasLiked(true);
          setLikedByCount((prev) => prev + 1);
        }
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, try again",
          type: "error",
        });
      }
    };

    const clickUnlikeHandler = async () => {
      try {
        const res = await deletePostsLike(userInfo.access_token, { postId });

        if (res.status === 204) {
          setPostHasLiked(false);
          setLikedByCount((prev) => prev - 1);
        }
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, try again",
          type: "error",
        });
      }
    };

    const formatTimeDifference = (postDate: string) => {
      const postTime = new Date(postDate).getTime();
      const now = new Date().getTime();

      const diffMs = now - postTime;
      const diffMinutes = Math.floor(diffMs / (1000 * 60));
      const diffHours = Math.floor(diffMinutes / 60);
      const diffDays = Math.floor(diffHours / 24);

      if (diffMinutes < 1) return "Just now";
      if (diffMinutes < 60)
        return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
      if (diffHours < 24) return `${diffHours}h`;
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    };

    const counterMedias = () => {
      const photosTotal = files.reduce((total, file) => {
        return file.fileType === "PHOTO" ? total + 1 : total;
      }, 0);

      const videosTotal = files.reduce((total, file) => {
        return file.fileType === "VIDEO" ? total + 1 : total;
      }, 0);

      setPhotosCounter(photosTotal);
      setVideosCounter(videosTotal);
    };

    const changePrevious = () => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === 0) return 0;

        const newIndex = prevIndex - 1;
        setPhotoSelectType(
          files[newIndex]?.fileType || files[prevIndex]?.fileType || ""
        );
        setPhotoSelect(files[newIndex]?.url || files[prevIndex]?.url || "");
        return newIndex;
      });
    };

    const changeNext = () => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex >= files.length - 1) return prevIndex;

        const newIndex = prevIndex + 1;
        setPhotoSelectType(
          files[newIndex]?.fileType || files[prevIndex]?.fileType || ""
        );
        setPhotoSelect(files[newIndex]?.url || files[prevIndex]?.url || "");
        return newIndex;
      });
    };

    useEffect(() => {
      setPhotoSelectType(files[0].fileType);
      setPhotoSelect(files[0].url);
    }, [files]);

    useEffect(() => {
      if (paidContent) {
        if (hasPaid) {
          setMediaAbleToShow(true);
        } else {
          setMediaAbleToShow(false);
        }
      } else {
        setMediaAbleToShow(true);
      }
      setIsLoadingMedias(false);
    }, [mediaIndexCurrent]);

    const renderLocked = () => {
      return (
        <div className="feed-cards-locked">
          <img
            className="feed-cards-locked-icon  "
            src={logo}
            alt="Logo Roses"
          />
          <span className="feed-cards-locked-price">{price}</span>
          {/* <p className="feed-cards-locked-text  ">Memo Message</p> */}
        </div>
      );
    };

    const [showModal, setShowModal] = useState(false);

    return (
      <div
        ref={ref}
        id={postId}
        className={shortClassNames([
          "feed-cards",
          `${!isGlobal ? "feed-cards--private" : ""}`,
          `${isExpanded ? "feed-cards--expanded" : ""}`,
          `${className ?? ""}`,
        ])}
      >
        <div className="feed-creator-image-post element-header-overlay-35">
          {isLocked && <div className="feed-cards-container-locked"></div>}
          <div
            className={`feed-cards__top ${
              !isGlobal ? "feed-cards__top--private" : ""
            }`}
          >
            {isGlobal && (
              <FeedProfileLabel
                showTime
                showUsername
                userId={userId}
                profilePhoto={post.autor.profile?.avatar?.thumbnailUrl}
                userName={username}
                time={formatTimeDifference(createdAt)}
                displayName={displayname}
              />
            )}

            <div className="feed-card-options">
              <button className="feed-card-top-btn" onClick={handleExpand}>
                {isExpanded ? (
                  <span className="feed-card-options-icon icon icon-close"></span>
                ) : (
                  //TODO change for icon span
                  <img src={expand} alt="icon-expand" />
                )}

                {/* <span className="feed-card-options-icon icon icon-dots-v"></span> */}
              </button>
            </div>

            {/* <div className="feed-card-options">
              <button
                className="feed-card-top-btn"
                onClick={() => setIsOptionsActive(!isOptionsActive)}
              >
                <span className="feed-card-options-icon icon icon-dots-v"></span>
              </button>
              <div
                className={`feed-card-box ${
                  isOptionsActive ? "feed-card-box--active" : ""
                }`}
              >
                {isOwn ? (
                  <>
                    <ButtonPill
                      clickPill={function (a: any): void {
                        showToast({
                          description: "Please try again",
                          title: "Feature is coming soon!",
                          type: "error",
                        });
                      }}
                      textPill={"Edit"}
                      iconName={"icon-edit"}
                      isPillSelected={false}
                    />
                    <ButtonPill
                      clickPill={() => onDelete?.()}
                      textPill={"Delete"}
                      iconName={"icon-trash"}
                      isPillSelected={false}
                    />
                  </>
                ) : (
                  <>
                    <ButtonPill
                      clickPill={handlerLike}
                      textPill={"Like"}
                      iconName={"icon-heart-solid"}
                      isPillSelected={false}
                    />
                    <ButtonPill
                      clickPill={openChat}
                      textPill={"Chat"}
                      iconName={"icon-messages"}
                      isPillSelected={false}
                    />
                  </>
                )}
              </div>
            </div> */}
          </div>

          <CardsArrows
            isFirstItem={files.length > 0 && photoSelect === files[0].url}
            isLastItem={files.length > 0 && photoSelect === files.at(-1)?.url}
            className={`feed-cards-arrows ${
              files.length > 1 ? "feed-cards-arrows--show" : ""
            }`}
            changePrevious={changePrevious}
            changeNext={changeNext}
          />

          {!isLocked && files.length > 1 ? (
            <div className="feed-cards__progressbar-wrapper">
              {Array.from({ length: files.length }, (_, index) => {
                return (
                  <ProgressbarWithoutAnimation
                    key={index}
                    index={index}
                    activeIndex={files.findIndex(
                      (file) => photoSelect === file.url
                    )}
                  />
                );
              })}
            </div>
          ) : (
            false
          )}

          {photoSelectType === "VIDEO" && photoSelect.slice(-3) === "mp4" ? (
            <video
              src={photoSelect}
              autoPlay
              loop
              muted
              playsInline
              disablePictureInPicture
              controls={false}
              preload="auto"
              className="photo-video"
            />
          ) : (
            <ImageWithSize
              src={photoSelect}
              height="100%"
              alt={"creator feed post"}
            />
          )}
        </div>
        {isLocked && renderLocked()}

        {/* {isVerify && <img src={verify} alt="verify icon" />} */}

        {/* <div className="creator-pack-media-info">
          <span className="creator-pack clock">
            <span className="icon icon-clock icon-md icon-gray" />
            <p className="qnt-media-text  ">
              {formatTimeDifference(createdAt)}
            </p>
          </span>
          <span className="creator-pack video">
            <span className="icon icon-video icon-md icon-gray" />
            <p className="  qnt-media-text">{videosCounter}</p>
          </span>
          <span className="creator-pack photo">
            <span className="icon icon-photo icon-md icon-gray" />
            <p className="  qnt-media-text">{photosCounter}</p>
          </span>
          <span className="creator-pack price">
            <span className="icon icon-money icon-md icon-gray" />
            <p className="  qnt-media-text">{price}</p>
          </span>
          <span className="creator-pack views">
            <span className="icon icon-public icon-md icon-gray" />
            <p className="  qnt-media-text">{seenBy}</p>
          </span>
        </div> */}

        <InfosPost
          postHasLiked={postHasLiked}
          likedByCount={likedByCount}
          handlerLike={handlerLike}
          userId={autorId}
          isLocked={isLocked}
          handlerUnlock={() => onUnlockedPost?.(post.postId, price)}
        />
      </div>
    );
  }
);

export default FeedCards;
