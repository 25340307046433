import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

import { useAvailableRoses } from "../../contexts/availableRosesContext";

import Button from "components/Buttons/Button";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import InputText from "components/Inputs/InputText";
import PageTitle from "components/PageTitles";
import RosesDashboard from "components/Wallet/RosesDashboard";
import ListItemExtract from "components/Lists/ListItemExtract";
import ListItemRadio from "components/Lists/ListItemRadio";
import SlidUp from "components/Modals/SlidUp";
import BuyRoses from "components/BuyRoses";
import Modal from "components/Modals/Modal";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useModal } from "hooks/useModal";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useBuyRoses } from "hooks/useBuyRoses";
import { useToast } from "hooks/useToast";

import postWithdrawRoses from "../../api/rosesTransactions/postWithdrawRoses";
import getAutoRecharge from "../../api/rosesTransactions/autoRecharge/getAutoRecharge";
import getPackages from "api/packages/getPackages";
import getTransactionsExtract from "api/transactions/getTransactionsExtract";

import { IToast } from "../../types/toast";
import { IPackages } from "types/interfaces";

import verifyToken from "utils/verifyToken";
import { formatDateShort } from "../../utils/formatDateShort";
import { thousandsFormat } from "../../utils/thousandsFormat";
import { textCapitalize } from "../../utils/textCapitalize";

import cardsMock from "../../mocks/cards";

import bankIcon from "assets/icons/bank.icon-gray.svg";
import chevronRightIcon from "assets/icons/navigationIcons/bx-chevron-right.svg";
import logo from "assets/icons/logos/logo-fill-pink.svg";

type IExtract = {
  transactionId: string;
  entryType: string;
  amount: number;
  createdAt: string;
  transaction: {
    transactionType: string;
    description: string;
  };
};

const UserSettingsWalletPage: React.FC = () => {
  // Contexts
  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();
  const { componentFeed, setPrincipalScreen, setSecondaryScreen, params } =
    useDeskNavigation();
  const { showToast } = useToast();

  // Local States
  const [activeSection, setActiveSection] = useState<string>(
    params.btn ? params.btn : "button1"
  );
  const [activatedButton, setActivatedButton] = useState(
    params.id ? Number(params.id) : 1
  );
  const [cardDefault, setCardDefault] = useState("");
  const [extracts, setExtracts] = useState<IExtract[]>();
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  //Buy Roses
  const { windowSize } = useWindowWidthSize();
  const [packages, setPackages] = useState<IPackages[]>([]);
  const [currentPackage, setCurrentPackage] = useState<string>("");
  const { buyRoses } = useBuyRoses(currentPackage ?? "");

  // Refs
  const listExtractsRef = useRef<(HTMLDivElement | null)[]>([]);

  // Others hooks
  const { userInfo } = useUserInfo();
  const { setOpenUseModal, setModalContent } = useModal();

  // Dummy data/Mock
  const cards = cardsMock;

  useEffect(() => {
    handleGetExtract();
  }, [availableRoses]);

  useEffect(() => {
    const fetchPackages = async () => {
      if (!verifyToken()) return;

      const { res } = await getPackages(userInfo.access_token);
      setPackages(res);
    };

    fetchPackages();
  }, [userInfo.access_token]);

  const handleBuyRoses = async () => {
    const res = await buyRoses();

    if (res) {
      handleToast({
        type: "success",
        title: "Buy Roses",
        description: "Congratulations",
      });
    } else {
      handleToast({
        type: "error",
        title: "Buy Roses",
        description: "Congratulations",
      });
    }
    setIsOpen(false);
  };

  const applyRechargeRequest = async () => {
    const request = await getAutoRecharge(userInfo.access_token);
    return request;
  };

  const handleRedeemRoses = async () => {
    const request = await postWithdrawRoses(
      userInfo.access_token,
      availableRoses
    );
    if (request.status === 201 && availableRoses > 0) {
      updateSubtractAvailableRoses(availableRoses);

      handleToast({
        type: "success",
        title: "Redeem Roses",
        description: "Congratulations",
      });
    } else if (availableRoses === 0) {
      handleToast({
        type: "info",
        title: "Redeem Roses",
        description: "Not has roses to redeem",
      });
    } else {
      handleToast({
        type: "error",
        title: "Redeem  Roses",
        description: "Error",
      });
    }
  };

  const mapTransactionType: Record<string, string> = {
    deposit: "Add Roses",
  };

  const transformExtract = (data: IExtract): IExtract => {
    return {
      ...data,
      transaction: {
        ...data.transaction,
        transactionType:
          mapTransactionType[data.transaction.transactionType.toLowerCase()] ||
          data.transaction.transactionType,
      },
    };
  };

  const handleGetExtract = async () => {
    // const dateEnd = new Date().toISOString();
    // const dateStart = new Date("2020-01-01").toISOString();
    const { res, status } = await getTransactionsExtract({
      token: userInfo.access_token,
    });

    const transformedResponse = res.transactions.map(transformExtract);

    if (status === 200) {
      setExtracts(transformedResponse);
    }
  };

  const handleToast = ({ type, title, description }: IToast) => {
    showToast({
      type,
      title,
      description,
    });
  };

  const handleModalRedeem = () => {
    setModalContent(
      <aside className="redeem-modal">
        <header className="redeem-modal__top">
          <img src={logo} alt="Logo Roses" className="redeem-modal__logo" />
          <h2 className="redeem-modal__title">
            Would you like to rescue your roses?
          </h2>
          <p className="redeem-modal__subtitle">
            Would you like to rescue your roses?
          </p>
        </header>
        <div className="redeem-modal__buttons-container">
          <Button
            children={"Redeem Roses"}
            buttonStyle={"primary"}
            onClick={() => {
              handleRedeemRoses();
              setOpenUseModal(false);
            }}
          />
          <Button
            children={"Cancel"}
            buttonStyle={"quaternary"}
            onClick={() => setOpenUseModal(false)}
          />
        </div>
      </aside>
    );
  };

  const mapMonths = {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  };

  const getTextContent = (parent: HTMLDivElement, selector: string) =>
    parent.querySelector(selector)?.textContent?.toLocaleLowerCase() || "";

  const handlerSearchFilter = (text: string) => {
    const formattedSearch = text
      .toString()
      .toLocaleLowerCase()
      .trim()
      .split(/\s+/);

    listExtractsRef.current.forEach((item) => {
      if (!item) return;

      const typeTransaction = getTextContent(item, "[data-title]");
      const dataTransaction = getTextContent(item, "[data-date]");
      const amountTransaction = getTextContent(item, "[data-amount]");
      const valueTransaction = getTextContent(item, "[data-value]");

      const dataShort = dataTransaction ? dataTransaction.slice(0, 3) : "";
      const dataLong = mapMonths[dataShort as keyof typeof mapMonths] || "";

      const completeTransaction = `${typeTransaction} ${amountTransaction} ${dataTransaction}${dataLong} ${valueTransaction}`;

      const match = formattedSearch.every((term) =>
        completeTransaction.includes(term)
      );

      item.classList.toggle("show", match);
      item.classList.toggle("hidden", !match);
    });
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    { text: "Overview", clickAction: () => setActiveSection("button1"), id: 1 },
    { text: "Activity", clickAction: () => setActiveSection("button2"), id: 2 },
    { text: "Payout", clickAction: () => setActiveSection("button3"), id: 3 },
  ];

  return (
    <div
      className="user-settings-wallet
		 	user-settings-wallet-wrapper
		  wrapper
		  standard-page-layout
		  bottom-spacing"
    >
      <div className="user-settings-wallet-inner inner">
        <div className="actions-wrapper">
          <PageTitle
            title={"My Wallet"}
            subtitle={"Manage Payment"}
            onBackClick={() => {
              if (componentFeed) {
                setSecondaryScreen("/user-settings", {});
              } else {
                setPrincipalScreen("/user-settings", {});
              }
            }}
            hasCloseIcon={false}
          />
          <ButtonNavigation
            activatedButton={Number(activatedButton)}
            buttons={buttonsGroup}
            className="action-wrapper__buttons-navigation"
          />
        </div>

        {activeSection === "button1" && (
          <section className="available-roses-recharge">
            <RosesDashboard
              title={thousandsFormat(availableRoses)}
              showAddButton
              showGoldRoses
              onClick={() => setIsOpen(!isOpen)}
            />

            {/* <AutoRecharge
            cards={cards}
            applyRechargeCallback={applyRechargeRequest}
          /> */}
            {windowSize <= 768 ? (
              <SlidUp
                isOpen={isOpen}
                classNameContainer="split-up-container"
                classNameChildren="split-up__slide-children"
                closeArea={() => {
                  setIsOpen(false);
                }}
                header={{
                  title: "Buy Roses",
                  subtitle: "Select Roses below",
                }}
              >
                <BuyRoses
                  onClose={() => setIsOpen(false)}
                  onSelectItem={setCurrentPackage}
                  onBuyRoses={handleBuyRoses}
                  packages={packages}
                  selectedItem={currentPackage}
                />
              </SlidUp>
            ) : (
              <Modal
                className="buy-roses-modal"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                header={{
                  title: "Buy Roses",
                  subtitle: "Select Roses below",
                }}
              >
                <BuyRoses
                  onClose={() => setIsOpen(false)}
                  onSelectItem={setCurrentPackage}
                  onBuyRoses={handleBuyRoses}
                  packages={packages}
                  selectedItem={currentPackage}
                />
              </Modal>
            )}
          </section>
        )}

        {activeSection === "button2" && (
          <section className="transactions-activity">
            <InputText
              value={search}
              placeholder="Search"
              onChangeInput={(text) => {
                setSearch(text);
                handlerSearchFilter(text);
              }}
              searchInput
              inputType="text"
            />
            <div className="list-container">
              {extracts &&
                extracts.map((extract, index) => {
                  return (
                    <ListItemExtract
                      ref={(el) => (listExtractsRef.current[index] = el)}
                      key={index}
                      title={textCapitalize(
                        extract.transaction.transactionType
                      )}
                      description={formatDateShort(extract.createdAt)}
                      date={{
                        long: extract.createdAt,
                        short: formatDateShort(extract.createdAt),
                      }}
                      amount={thousandsFormat(extract.amount)}
                      isExtract
                      iconRight={chevronRightIcon}
                      onClick={() => {
                        if (componentFeed) {
                          setSecondaryScreen("/user-settings-extract-details", {
                            transactionId: extract.transactionId,
                          });
                        } else {
                          setPrincipalScreen("/user-settings-extract-details", {
                            transactionId: extract.transactionId,
                          });
                        }
                      }}
                    />
                  );
                })}
            </div>
          </section>
        )}

        {activeSection === "button3" && (
          <section className="redeem-credits  ">
            <RosesDashboard
              title={thousandsFormat(availableRoses)}
              showRedeemButton={true}
              onClick={() => {
                setOpenUseModal(true);
                handleModalRedeem();
              }}
            />

            <p className="redeem-credits__header    ">Bank account</p>
            <div className="list-container">
              {cards.map((card, index, arr) => (
                <ListItemRadio
                  key={index}
                  groupName="card-default"
                  radioId={index + ""}
                  imageLeft={bankIcon}
                  hasSeparator={arr.length > index + 1}
                  highlightText={card.banner}
                  simpleText={card.number}
                  checkValueCallback={setCardDefault}
                  isImageCircle
                  inputValue={card.number}
                  isChecked={card.default}
                  rightNodeContent={
                    card.default && <p className="text-detail    ">Default</p>
                  }
                />
              ))}
            </div>
            <Button
              buttonStyle="quaternary"
              onClick={() => {}}
              children={"Connect Account"}
            />
          </section>
        )}
      </div>
    </div>
  );
};

export default UserSettingsWalletPage;
