import React, { useState } from "react";
import "./styles.scss";

import Button from "components/Buttons/Button";
import FadeCarousel from "components/Carousels/FadeCarousel";
import Header from "components/Headers/Header";

import { useModal } from "hooks/useModal";

import RosesIcon from "assets/icons/logos/logo-fill-pink.svg";

interface ExplicitContentAlertModalProps {
  className?: string;
  navigateToApp: () => void;
  navigateToLanding: () => void;
}

const ExplicitContentAlertModal: React.FC<ExplicitContentAlertModalProps> = (
  props
) => {
  const { className, navigateToApp, navigateToLanding } = props;
  const { setOpenUseModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const setStorageLegalAge = () => {
    localStorage.setItem("has_legal_age", "true");

    navigateToApp();
    setOpenUseModal(false);
  };

  const setStorageNoLegalAge = () => {
    localStorage.setItem("has_legal_age", "false");

    navigateToLanding();
    setOpenUseModal(false);
  };

  return (
    <div id="explicit-content_modal" className={`${className ?? ""} wrapper`}>
      <div className="explicit-content_modal__content explicit-content_modal-inner inner">
        <div className="content__texts">
          <div className="texts__header">
            <img src={RosesIcon} alt="roses logo" className="header__icon" />
            <h3 className="explicit-content__title">
              Possible age
              <br /> restricted content
            </h3>
          </div>
          <div className="texts__descriptions-wrapper">
            <p className="descriptions-wrapper__description">
              ROSES contains content intended for certain audiences. It may
              contain sexual themes and may not be appropriate for you to watch.
            </p>
            <p className="descriptions-wrapper__description">
              To use ROSES, you must be at least 18 years old and the age of
              majority and legal consent under the laws of the applicable
              jurisdiction from which you are accessing this website.
            </p>
            <p className="descriptions-wrapper__description">
              By clicking the “CONTINUE” button, and by entering ROSES, you
              hereby (1) agree to these Terms of Use; and (2) under penalty of
              perjury, certify that you are above the age of 18 or the age of
              majority in your location, whichever is greater.
            </p>
          </div>
        </div>

        <div className="content__buttons-container">
          <Button buttonStyle="primary" onClick={setStorageLegalAge}>
            Continue{" "}
            <span className="icon icon-chevron-right icon-white icon-md" />
          </Button>
          <Button
            disabled={isLoading}
            buttonStyle="quaternary"
            onClick={setStorageNoLegalAge}
          >
            I’m not 18+ years old, leave now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExplicitContentAlertModal;
