import React, { useEffect, useState } from "react";
import "./styles.scss";

import { useToast } from "hooks/useToast";
import { useUserInfo } from "hooks/userInfo";
import { useDeskNavigation } from "hooks/useDeskNavigation";

import getTransactionsDashboardRevenue from "api/transactions/getTransactionsDashboardRevenue";

import { shortClassNames } from "utils/shortClassNames";

const Revenue: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { showToast } = useToast();
  const { userInfo } = useUserInfo();

  const [monthlyEarnings, setMonthlyEarnings] = useState<number | null>(null);
  const [monthlyPercentage, setMonthlyPercentage] = useState<number | null>(
    null
  );

  const [weeklyEarnings, setWeeklyEarnings] = useState<number | null>(null);
  const [weeklyPercentage, setWeeklyPercentage] = useState<number | null>(null);

  useEffect(() => {
    const getRevenue = async () => {
      try {
        const {
          res: {
            monthlyEarnings,
            monthlyPercentage,
            rosesBalance,
            totalEarnings,
            weeklyEarnings,
            weeklyPercentage,
          },
        } = await getTransactionsDashboardRevenue(userInfo.access_token);
        setMonthlyEarnings(monthlyEarnings);
        setMonthlyPercentage(monthlyPercentage);
        setWeeklyEarnings(weeklyEarnings);
        setWeeklyPercentage(weeklyPercentage);
      } catch (error) {
        showToast({
          type: "info",
          title: "Coming soon!",
          description: "We appreciate your patience.",
        });
      }
    };
    getRevenue();
  }, [userInfo.access_token]);

  return (
    <div className="revenue__wrapper">
      <div className="revenue revenue-inner inner">
        <h2 className="revenue__title">Messages</h2>
        <div>
          <p className="revenue__value">15</p>
          <p className="revenue__txt">Unanswered</p>
        </div>
        <span
          className="revenue__link"
          onClick={() => setPrincipalScreen("/chat-list")}
        >
          View messages{" "}
          <span className="icon icon-chevron-right revenue__link-icon"></span>
        </span>
      </div>

      <div className="revenue revenue-inner inner">
        <h2 className="revenue__title">My Subscribers</h2>
        <div>
          <p className="revenue__value">63</p>
          <p className="revenue__txt">users</p>
        </div>

        <span
          className="revenue__link"
          onClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-my-subscribers");
            } else {
              setPrincipalScreen("/user-settings-my-subscribers");
            }
          }}
        >
          View users{" "}
          <span className="icon icon-chevron-right revenue__link-icon"></span>
        </span>
      </div>

      <div className="revenue revenue-inner inner">
        <h2 className="revenue__title">This week</h2>
        <div>
          <p className="revenue__value">{weeklyEarnings}</p>
          {weeklyPercentage ||
            (weeklyPercentage === 0 && (
              <>
                <p
                  className={shortClassNames([
                    `revenue__percent`,
                    `${weeklyPercentage < 0 && "revenue__percent--down"}`,
                  ])}
                >
                  <span
                    className={shortClassNames([
                      `icon revenue__percent-icon`,
                      `${weeklyPercentage > 0 && "icon-arrow-up"}`,
                      `${weeklyPercentage < 0 && "icon-arrow-down revenue__percent-icon--down"}`,
                    ])}
                  ></span>
                  {weeklyPercentage > 0 ? "+" : ""}
                  {weeklyPercentage.toFixed(2)}%
                </p>
                <span className="revenue__progress-bar"></span>
              </>
            ))}
        </div>
      </div>

      <div className="revenue revenue-inner inner">
        <h2 className="revenue__title">This month</h2>
        <div>
          <p className="revenue__value">{monthlyEarnings}</p>
          {monthlyPercentage && (
            <>
              <p
                className={shortClassNames([
                  `revenue__percent`,
                  `${monthlyPercentage < 0 && "revenue__percent--down"}`,
                ])}
              >
                <span
                  className={shortClassNames([
                    `icon`,
                    "revenue__percent-icon",
                    `${monthlyPercentage > 0 && "icon-arrow-up"}`,
                    `${monthlyPercentage < 0 && "icon-arrow-down revenue__percent-icon--down"}`,
                  ])}
                ></span>{" "}
                {monthlyPercentage > 0 ? "+" : ""}
                {monthlyPercentage.toFixed(2)}%
              </p>
              <span className="revenue__progress-bar"></span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Revenue;
