export interface Option {
  icon: string;
  status: boolean;
  title: string;
  infosComplete: string;
  infosTotal: string;
  onClick: () => void;
  isActive: boolean;
}

export const getOptions = (
  setPrincipalScreen: (path: string, params?: any) => void,
  toggleDefault: () => void,
  userInfo: { account_type: string }
): Option[] => {

  return [
  {
    icon: "icon-photo",
    status: true,
    title: "Photos",
    infosComplete: "9",
    infosTotal: "9",
    onClick: () => setPrincipalScreen("/user-settings-profile-photo"),
    isActive: true,
  },
  {
    icon: "icon-selfie",
    status: false,
    title: "Basic Info",
    infosComplete: "3",
    infosTotal: "5",
    onClick: () => setPrincipalScreen("/user-settings-profile", { btn: "1" }),
    isActive: true,
  },
  {
    icon: "icon-gender",
    status: false,
    title: "More About You",
    infosComplete: "3",
    infosTotal: "8",
    onClick: () => setPrincipalScreen("/user-settings-profile", { btn: "2" }),
    isActive: true,
  },
  {
    icon: "icon-gear",
    status: false,
    title: "Personal Touch",
    infosComplete: "0",
    infosTotal: "5",
    onClick: () => setPrincipalScreen("/user-settings-personal-touch"),
    isActive: true,
  },

  {
    icon: "icon-games",
    status: true,
    title: "Interests",
    infosComplete: "5",
    infosTotal: "5",
    onClick: () => toggleDefault(),
    isActive: true,
  },
  {
    icon: "icon-gifts",
    status: false,
    title: "Requests",
    infosComplete: "9",
    infosTotal: "9",
    onClick: () => setPrincipalScreen("/my-request"),
    isActive:
      userInfo.account_type === "CREATOR" ||
      userInfo.account_type === "ADMIN" ||
      userInfo.account_type === "DEV",
  },
]};