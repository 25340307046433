import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";

interface RangeSliderProps {
  min: number;
  max: number;
  step?: number;
  initialMin: number;
  initialMax?: number;
  minValue?: number;
  maxValue?: number;
  isGold?: boolean;
  singleThumb?: boolean;
  className?: string;
  onChange?: (values: { min: number; max?: number }) => void;
  onMouseDown?: () => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  step = 1,
  initialMin,
  initialMax = max,
  minValue: externalMinValue,
  maxValue: externalMaxValue,
  isGold = false,
  singleThumb = true,
  className,
  onChange,
  onMouseDown,
}) => {
  const [minValue, setMinValue] = useState<number>(
    externalMinValue ?? initialMin
  );
  const [maxValue, setMaxValue] = useState<number>(
    externalMaxValue ?? initialMax
  );
  const sliderRef = useRef<HTMLDivElement>(null);
  const draggingRef = useRef<"min" | "max" | null>(null);

  useEffect(() => {
    updatePositions();
  }, [minValue, maxValue]);

  useEffect(() => {
    if (externalMinValue !== undefined) {
      setMinValue(externalMinValue);
    }
  }, [externalMinValue]);

  useEffect(() => {
    if (externalMaxValue !== undefined) {
      setMaxValue(externalMaxValue);
    }
  }, [externalMaxValue]);

  const updatePositions = () => {
    if (!sliderRef.current) return;

    const minPercent = ((minValue - min) / (max - min)) * 100;
    const maxPercent = ((maxValue - min) / (max - min)) * 100;

    const thumbs = sliderRef.current.querySelectorAll(".thumb");
    const range = sliderRef.current.querySelector(".range") as HTMLElement;

    if (thumbs[0])
      (thumbs[0] as HTMLElement).style.left = `${Math.min(minPercent, 100)}%`;
    if (!singleThumb && thumbs[1]) {
      (thumbs[1] as HTMLElement).style.left = `${maxPercent}%`;
    }

    if (range) {
      if (singleThumb) {
        range.style.left = "0%";
        range.style.width = `${minPercent}%`;
      } else {
        range.style.left = `${minPercent}%`;
        range.style.width = `${maxPercent - minPercent}%`;
      }
    }
  };

  const determineClosestThumb = (clickPosition: number) => {
    const minDistance = Math.abs(clickPosition - minValue);
    const maxDistance = Math.abs(clickPosition - maxValue);

    return singleThumb || minDistance <= maxDistance ? "min" : "max";
  };

  const handleMouseMove = (e: MouseEvent) => handleMove(e.clientX);
  const handleTouchMove = (e: TouchEvent) => handleMove(e.touches[0].clientX);

  const handleMove = (position: number) => {
    if (!sliderRef.current || !draggingRef.current) return;

    const rect = sliderRef.current.getBoundingClientRect();
    const offsetX = position - rect.left;
    const trackWidth = sliderRef.current.offsetWidth;

    let newValue = Math.round((offsetX / trackWidth) * (max - min) + min);
    newValue = Math.round(newValue / step) * step;

    newValue = Math.max(min, Math.min(newValue, max));

    if (draggingRef.current === "min" && newValue !== minValue) {
      setMinValue((prev) => {
        const updated = Math.min(newValue, maxValue);
        if (onChange) onChange({ min: updated, max: maxValue });
        return updated;
      });
    } else if (draggingRef.current === "max" && newValue !== maxValue) {
      setMaxValue((prev) => {
        const updated = Math.max(newValue, minValue);
        if (onChange) onChange({ min: minValue, max: updated });
        return updated;
      });
    }
  };

  const handleTrackMouseDown = (e: React.MouseEvent) => {
    handleStart(e.clientX);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleTrackTouchStart = (e: React.TouchEvent) => {
    handleStart(e.touches[0].clientX);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleMouseUp);
  };

  const handleStart = (clientX: number) => {
    if (!sliderRef.current) return;

    const rect = sliderRef.current.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const trackWidth = sliderRef.current.offsetWidth;

    let clickValue = Math.round((offsetX / trackWidth) * (max - min) + min);
    clickValue = Math.round(clickValue / step) * step;

    clickValue = Math.max(min, Math.min(clickValue, max));

    const closestThumb = determineClosestThumb(clickValue);
    draggingRef.current = closestThumb;

    if (closestThumb === "min") {
      setMinValue((prev) => {
        const updated = Math.min(clickValue, maxValue);
        if (onChange) onChange({ min: updated, max: maxValue });
        return updated;
      });
    } else if (closestThumb === "max") {
      setMaxValue((prev) => {
        const updated = Math.max(clickValue, minValue);
        if (onChange) onChange({ min: minValue, max: updated });
        return updated;
      });
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchend", handleMouseUp);
    draggingRef.current = null;
  };

  return (
    <div
      ref={sliderRef}
      className={`range-slider ${className ?? ""}`}
      data-gold={isGold}
      onMouseDown={(e) => {
        onMouseDown?.();
        handleTrackMouseDown(e);
      }}
      onTouchStart={handleTrackTouchStart}
    >
      <div className="track"></div>
      <div className="range"></div>
      <div
        className="thumb"
        onMouseDown={() => (draggingRef.current = "min")}
        onTouchStart={() => (draggingRef.current = "min")}
      ></div>
      {!singleThumb && (
        <div
          className="thumb"
          onMouseDown={() => (draggingRef.current = "max")}
          onTouchStart={() => (draggingRef.current = "max")}
        ></div>
      )}
    </div>
  );
};

export default RangeSlider;
