import { shortClassNames } from "utils/shortClassNames";
import "./styles.scss";

interface IPlanCard {
  text: string;
  value: string | number;
  originalPrice: string | number;
  discount: number;
  active: boolean;
  headerText: string;
  type: "consumer" | "creator";
  onClick: () => void;
  disabled?: boolean;
}

const PlanCard: React.FC<IPlanCard> = (props) => {
  const {
    text,
    value,
    originalPrice,
    discount,
    active,
    type = "consumer",
    disabled,
    headerText,
    onClick,
  } = props;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={shortClassNames([
        "plan-card",
        `${active ? `plan-card-color-${type}` : "plan-card-color-default"}`,
      ])}
    >
      <div
        className={shortClassNames([
          `plan-card-header`,
          `${active ? `plan-card-color-${type}` : "plan-card-header-color-default"}
      `,
        ])}
      >
        <p
          className={shortClassNames([
            `plan-card-header-text`,
            `${active && `plan-card-header-text-${type}`}`,
          ])}
        >
          {headerText}
        </p>
      </div>
      <div className={"plan-card-content inner"}>
        <span className={"plan-card-content-text"}>
          {text.slice(0, text.length - 3)}
          <span
            style={{
              fontSize: 14,
            }}
          >
            {text.slice(-3)}
          </span>
        </span>

        {value && (
          <div className="">
            <span className={"plan-card-content-info"}>{value}</span>
            {originalPrice && (
              <>
                <br />
                <span className="plan-card-content-original-price">
                  {originalPrice}
                </span>
              </>
            )}
          </div>
        )}

        <span
          className={shortClassNames([
            `plan-card-content-discount`,
            `${
              active
                ? "plan-card-content-discount-active"
                : `plan-card-content-discount-color-${type}`
            }`,
          ])}
        >
          Save {discount}%
        </span>
      </div>
    </button>
  );
};
export default PlanCard;
