import React, { useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import InputText from "components/Inputs/InputText";
import DropDown from "components/Lists/DropDown";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal";

import SendPhotoAlbumPage from "pages/SendPhotoAlbum";
import TermsAndConditionsPage from "pages/TermsAndConditions";

import { useBecomeCreator } from "contexts/becomeCreatorContext";

import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";

import location from "../../mocks/location";

import ShieldIcon from "assets/icons/shieldIcons/personal-icon-gray.svg";
import UnionIcon from "assets/icons/union-gray.svg";

const LegalResidence: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { setRegisterBecomeCreator } = useBecomeCreator();
  const { windowSize } = useWindowWidthSize();
  const { setModalContent } = useModal();
  const { showToast } = useToast();

  const [country, setCountry] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const breakPoint = windowSize >= 768;

  const handlerSubmit = () => {
    const isFieldsFill =
      country === "" ||
      streetAddress === "" ||
      city === "" ||
      postalCode === "";
    if (isFieldsFill) {
      showToast({
        title: "Error",
        description: "You must fill in all fields to continue!",
        type: "error",
      });
      return;
    }

    setRegisterBecomeCreator((prev) => ({
      ...prev,
      country,
      state: stateProvince,
      street: streetAddress,
      complement,
      city,
      zipCode: postalCode,
    }));

    if (breakPoint) {
      setModalContent(<SendPhotoAlbumPage />);
    } else {
      setPrincipalScreen("/send-photos");
    }
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setModalContent(<TermsAndConditionsPage />);
    } else {
      setPrincipalScreen("/terms-and-conditions");
    }
  };

  return (
    <div className="legal-residence legal-residence-wrapper wrapper standard-page-layout bottom-spacing">
      <div className="legal-residence-inner inner">
        <ButtonCloseModal />

        <div className="legal-residence__top">
          {!breakPoint ? (
            <PageTitle
              title="Become a Creator"
              subtitle="Start Earning"
              isBecome
              hasCloseIcon={false}
              onBackClick={handlerBackPage}
            />
          ) : (
            false
          )}
          <Header
            title="Legal residence"
            subTitle="Please fill in your streetAddress then click continue to start the upload process of your government-issued ID."
            marginTop={0}
            backButtonClick={handlerBackPage}
            {...(breakPoint
              ? {
                  headerIcon: "back-button",
                  inlineElements: true,
                  titleClass: "header",
                }
              : {
                  titleClass: "legal-residence__title",
                })}
          />
        </div>

        <div className="legal-residence__information">
          <div className="legal-residence__country-container">
            <DropDown
              inputValueSet={country}
              placeholder="Select Country"
              options={(location[0].location ?? []).map((item) => {
                const { country } = item;
                return {
                  inputLabel: country,
                  inputValue: country,
                  groupName: "country",
                };
              })}
              dropType="click-select"
              setValueCallBack={(option: string[]) => {
                setCountry(option[0]);
                setStateProvince("");
              }}
            />

            <SubText
              icon={UnionIcon}
              altIcon="Union icon"
              className="legal-residence__icon-disclaimer legal-residence__icon-disclaimer--alt"
              classNameIcon="legal-residence__icon"
              children={
                <>
                  If you want to change your country, please contact{" "}
                  <span className="legal-residence__highlight">
                    customer support
                  </span>
                </>
              }
            />
          </div>

          <DropDown
            inputValueSet={stateProvince}
            placeholder="State / Province"
            options={(location[0].location ?? [])
              .filter((item) => item.country === country)
              .map((item) => {
                const { region } = item;

                return {
                  inputLabel: region,
                  inputValue: region,
                  groupName: "stateProvince",
                };
              })}
            dropType="click-select"
            className="legal-residence__dropdown"
            setValueCallBack={(option: string[]) => {
              setStateProvince(option[0]);
            }}
          />

          <InputText
            placeholder="Address"
            textTransform="none"
            inputType="text"
            value={streetAddress}
            onChangeInput={(e) => setStreetAddress(e)}
          />

          <InputText
            placeholder="Address 2"
            textTransform="none"
            inputType="text"
            value={complement}
            onChangeInput={(e) => setComplement(e)}
          />

          <div className="legal-residence__row">
            <InputText
              placeholder="City"
              textTransform="none"
              inputType="text"
              value={city}
              onChangeInput={(e) => setCity(e)}
            />

            <InputText
              placeholder="Postal Code"
              textTransform="none"
              inputType="text"
              value={postalCode}
              onChangeInput={(e) => setPostalCode(e)}
            />
          </div>
        </div>

        <SubText
          icon={ShieldIcon}
          altIcon="shield icon"
          className="legal-residence__icon-disclaimer"
          classNameIcon="legal-residence__icon"
          children="This information will not be displayed on your public profile"
        />

        <div className="legal-residence__bottom">
          <Button
            children="Continue"
            buttonStyle={"secondary"}
            onClick={handlerSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default LegalResidence;
