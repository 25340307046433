import { useCallback, useEffect, useState } from "react";
import "./styles.scss";

import { format } from "date-fns";

import PageTitle from "components/PageTitles";
import Button from "components/Buttons/Button";
import Toggle from "components/Toggle/SimpleToggle";

import getSubscriptionById from "api/subscribe/getSubscriptionById";
import patchAutomaticRenew from "api/subscribe/patchAutomaticRenew";
import patchUnsubscribe from "api/subscribe/patchUnsubscribe";

import { useUserInfo } from "hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useDialog } from "hooks/useDialog";
import { useToast } from "hooks/useToast";

import { PlanDuration } from "types/enum";
import ListItemToggle from "components/Lists/ListItemToggle";

type SubscriptionStatus = "ACTIVE" | "PAST_DUE" | "CANCELED";

interface ISubscription {
  billingCycle: PlanDuration;
  subscriptionId: string;
  createdAt: Date;
  expirationDate: Date;
  status: SubscriptionStatus;
  automaticRenew: boolean;
  canceledAt: Date | null;
}

const UserSettingsMySubscriptionsDetailsPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen, params } =
    useDeskNavigation();
  const { userInfo } = useUserInfo();
  const { showToast } = useToast();
  const { setDialogContent, setOpenDialog } = useDialog();

  const [subscription, setSubscription] = useState<ISubscription | null>(null);
  const [creatorId, setCreatorId] = useState("");
  const [isActiveRenewal, setIsActiveRenewal] = useState(false);
  const [isDisableAutoRenew, setIsDisableAutoRenew] = useState(false);

  const subscriptionStatus = {
    ACTIVE: "Active",
    CANCELED: "Cancelled",
    PAST_DUE: "Expired",
  };

  const months = {
    ONE_MONTH: "1 month",
    THREE_MONTHS: "3 months",
    SIX_MONTHS: "6 months",
    TWELVE_MONTHS: "12 months",
  };

  const fetch = useCallback(async () => {
    try {
      const responseSubscription = await getSubscriptionById(
        userInfo.access_token,
        params.subscriptionId
      );
      if (responseSubscription.status === 200) {
        setSubscription(responseSubscription.res);
        setCreatorId(responseSubscription.res.creatorId);
        setIsActiveRenewal(responseSubscription.res.automaticRenew);
      } else {
        throw new Error("Get subscription error.");
      }
    } catch (error) {
      showToast({
        title: "Error",
        description: `We were unable to process the request. Please, try again. \n ${error}`,
        type: "error",
      });
    }
  }, [params.subscriptionId, showToast, userInfo.access_token]);

  const cancelSubscription = useCallback(async () => {
    try {
      const response = await patchUnsubscribe(userInfo.access_token, {
        creatorId: creatorId,
      });

      if (response.status === 200) {
        showToast({
          title: "Success",
          description: "Subscription plan cancelled successfully.",
          type: "success",
        });
        await fetch();
      } else {
        throw new Error("An error occurred when cancel subscription.");
      }
    } catch (error) {
      showToast({
        title: "Error",
        description: `${error}`,
        type: "error",
      });
    } finally {
      setOpenDialog(false);
    }
  }, [creatorId, fetch, setOpenDialog, showToast, userInfo.access_token]);

  const changeAutoRenewal = useCallback(async () => {
    if (isDisableAutoRenew) {
      return;
    }
    setIsDisableAutoRenew(true);
    try {
      const response = await patchAutomaticRenew(userInfo.access_token, {
        creatorId: creatorId,
        automaticRenew: !isActiveRenewal,
      });

      if (response.status === 200) {
        showToast({
          title: "Success",
          description: isActiveRenewal
            ? "Automatic renewal successfully cancelled."
            : "Automatic renewal successfully activated.",
          type: "success",
        });
        setIsActiveRenewal(!isActiveRenewal);
        await fetch();
      } else {
        throw new Error("An error occurred when cancel automatic renewal.");
      }
    } catch (error) {
      showToast({
        title: "Error",
        description: `${error}`,
        type: "error",
      });
    } finally {
      setIsDisableAutoRenew(false);
    }
  }, [
    userInfo.access_token,
    creatorId,
    isActiveRenewal,
    isDisableAutoRenew,
    showToast,
    fetch,
  ]);

  const onGoBack = useCallback(() => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings-my-subscriptions");
    } else {
      setPrincipalScreen("/user-settings-my-subscriptions");
    }
  }, [componentFeed, setPrincipalScreen, setSecondaryScreen]);

  const cancelSubscriptionDialog = () => {
    setDialogContent({
      iconHeader: "icon-close",
      iconColor: "red",
      title: "Cancel Subscription",
      text: `Are you sure you want to cancel your subscription?`,
      buttons: [
        {
          text: "Yes, Cancel subscription",
          buttonStyle: "primary",
          click: () => cancelSubscription(),
        },
        {
          text: "No",
          buttonStyle: "quaternary",
          click: () => setOpenDialog(false),
        },
      ],
    });

    setOpenDialog(true);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div
      className="user-settings-my-subscriptions-details
		 		user-settings-my-subscriptions-details-wrapper
		  	wrapper
			 	standard-page-layout
			 	bottom-spacing"
    >
      <div className="user-settings-my-subscriptions-details-inner inner">
        <PageTitle
          title={"Subscription"}
          subtitle={"Details"}
          onBackClick={onGoBack}
          hasCloseIcon={false}
        />
        <div className="user-settings-my-subscriptions-details_content">
          {subscription && (
            <>
              <table className="user-settings-my-subscriptions-details_content-table">
                <tbody>
                  <tr>
                    <th scope="row">Billing Cycle</th>
                    <td>{months[subscription.billingCycle]}</td>
                  </tr>
                  <tr>
                    <th scope="row">Created Date</th>
                    <td>{format(subscription.createdAt, "dd/MM/yyyy")}</td>
                  </tr>
                  <tr>
                    <th scope="row">Expiration Date</th>
                    <td>{format(subscription.expirationDate, "dd/MM/yyyy")}</td>
                  </tr>
                  <tr>
                    <th scope="row">Status</th>
                    <td>{subscriptionStatus[subscription.status]}</td>
                  </tr>
                  {subscription.canceledAt && (
                    <tr>
                      <th scope="row">Canceled At</th>
                      <td>{format(subscription.canceledAt, "dd/MM/yyyy")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {subscription.status === "ACTIVE" && (
                <>
                  <ListItemToggle
                    toggleCallBack={changeAutoRenewal}
                    highlightText="Automatic renewal"
                    toggleInitialState={isActiveRenewal}
                  />
                  {/* <div className="subscription-auto-renewal">
                    <p>Automatic renewal</p>
                    <Toggle
                      onChange={changeAutoRenewal}
                      initialState={isActiveRenewal}
                    />
                  </div> */}
                  <Button
                    buttonStyle="quaternary"
                    onClick={cancelSubscriptionDialog}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSettingsMySubscriptionsDetailsPage;
