import "./styles.scss";
import { useState } from "react";
import { useUserInfo } from "hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import PageTitle from "components/PageTitles";
import Plan from "components/Plan";
import { useToast } from "hooks/useToast";

const UserSettingsMyPlansPage: React.FC = () => {
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    componentFeed,
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setMainScreen,
    params,
  } = useDeskNavigation();
  const { userInfo } = useUserInfo();
  const { showToast } = useToast();

  const [plans, setPlans] = useState([
    {
      price: 3256,
      priceWithoutDiscount: 1059,
      discount: 6,
      period: "per month",
      type: "Seed",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
    {
      price: 6499,
      priceWithoutDiscount: 5598,
      discount: 12,
      period: "per month",
      type: "Vip",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
    {
      price: 8299,
      priceWithoutDiscount: 3779,
      discount: 8,
      period: "per month",
      type: "Bloom",
      features: [
        "Daily log-in golden credits",
        "Unlimited access to all models",
        "NSFW messaging",
        "Unlimited content access",
        "Purchase extra credits",
      ],
    },
  ]);

  const onSubscribe = () => {
    showToast({
      type: "info",
      title: "Coming soon!",
      description: "We appreciate your patience.",
    });
  };

  return (
    <div className="user-settings-my-plans user-settings-my-plans-wrapper wrapper standard-page-layout">
      <div className="user-settings-my-plans-inner inner">
        <PageTitle
          title={"MONTHLY PLAN"}
          subtitle={"Manage Plans"}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
          hasCloseIcon={false}
        />
        <div className="user-settings-my-plans-container">
          <Plan
            onSelectItem={(item) => {}}
            onSubscribe={onSubscribe}
            plans={plans}
            isLoading={false}
            disabled={false}
            type={userInfo.account_type}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSettingsMyPlansPage;
