import React, { useEffect, useRef } from "react";
import "./styles.scss";

import Button from "components/Buttons/Button";

import { IButtonStyles } from "types/buttonStyles";

interface DialogProps {
  isOpen: boolean;
  className?: string;
  iconHeader: string;
  iconColor: string;
  title: string;
  text: string;
  buttons: Buttons[];
}

type Buttons = {
  text: string;
  buttonStyle: IButtonStyles;
  click: (a: any) => void;
};

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  className,
  buttons,
  iconHeader,
  iconColor,
  text,
  title,
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (dialog) {
      if (isOpen && !dialog.open) {
        dialog.showModal();
      } else if (!isOpen && dialog.open) {
        dialog.close();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    const dialog = dialogRef.current;
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        dialog?.close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <dialog
      id="dialog-default"
      className={`${className ?? ""} wrapper`}
      ref={dialogRef}
    >
      <div className="dialog-content inner">
        <section className="dialog-default__header">
          <div className="header__icon-wrapper">
            <span
              className={`icon icon-md
							 ${iconHeader} icon-${iconColor}
							 icon-wrapper__icon`}
            />
          </div>
          <p className="header__title">{title}</p>
        </section>
        <section className="dialog-default__body">
          <p className="body__text  ">{text}</p>
        </section>
        <section className="dialog-default__buttons-container">
          {buttons.map((btn, index) => (
            <Button
              key={index}
              buttonStyle={btn.buttonStyle}
              onClick={btn.click}
            >
              {btn.text}
            </Button>
          ))}
        </section>
      </div>
    </dialog>
  );
};

export default Dialog;
