import React from "react";
import ReactDOM from "react-dom/client";
import "./scss";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { routes } from "./routes";
import { initializeApp } from "firebase/app";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import AppProvider from "./provider";
import Toast from "components/Toast";
import { ToastProvider } from "hooks/useToast";
import PullToRefresh from "components/PullToRefresh";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
  apiKey: "AIzaSyBfpzG7NcAdYUFiWDIKrln_bSbZT3FZIyI",
  authDomain: "roses--main.firebaseapp.com",
  projectId: "roses--main",
  storageBucket: "roses--main.appspot.com",
  messagingSenderId: "961345023378",
  appId: "1:961345023378:web:20fd72bf30e3b1d48ea54b",
  measurementId: "G-WXX5KQBN5X",
};

const app = initializeApp(firebaseConfig);

const AppRoutes = () => {
  return useRoutes(routes);
};

export default AppRoutes;

root.render(
  <BrowserRouter>
    <AppProvider>
      <PullToRefresh>
        <AppRoutes />
      </PullToRefresh>
      <Toast />
    </AppProvider>
  </BrowserRouter>
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service Worker registered successfully:", registration);
      })
      .catch((error) => {
        console.log("Failed to register Service Worker:", error);
      });
  });
}
