import { servicesApi } from "api/fetchApi/services-api";

interface IPostIdentity {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  documentType: string;
  faceScanVideo: File;
  docFrontImage: File;
  docBackImage: File;
  country: string;
  state?: string;
  street: string;
  complement?: string;
  city: string;
  zipCode: string;
}

const postIdentity = async (token: string, data: IPostIdentity) => {
  const apiRoute = `${servicesApi("validateIdentity")}`;

  const formData = new FormData();
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("dateOfBirth", data.dateOfBirth.toISOString());
  formData.append("documentType", data.documentType);
  formData.append("country", data.country);
  formData.append("street", data.street);
  formData.append("city", data.city);
  formData.append("zipCode", data.zipCode);

  formData.append("faceScanVideo", data.faceScanVideo, data.faceScanVideo.name);
  formData.append("docFrontImage", data.docFrontImage, data.docFrontImage.name);
  formData.append("docBackImage", data.docBackImage, data.docBackImage.name);

  try {
    const response = await fetch(apiRoute, {
      method: "POST",
      body: formData,
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    return { res: data, status: response.status };
  } catch (error) {
    console.error(error);
    return { res: null, status: null };
  }
};

export default postIdentity;
