import { useEffect, useState } from "react";
import "./styles.scss";

import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";
import PhotoGallery from "components/PhotoGallery";
import ListItemLink from "components/Lists/ListItemLink";
import InputText from "components/Inputs/InputText";

import WaitListApplicationPage from "pages/WaitListApplication";

import patchCreatorApplicationDetails from "../../api/creatorApplications/patchCreatorApplicationDetails";

import { useUserInfo } from "../../hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";

import { textCapitalize } from "../../utils/textCapitalize";

const UserInformation: React.FC = () => {
  const [editInfo, setEditInfo] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [instagramProfile, setInstagramProfile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [firstInfo, setFirstInfo] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState([] as any[]);
  const { showToast } = useToast();

  const { setUserInfo, userInfo } = useUserInfo();
  const { setPrincipalScreen } = useDeskNavigation();
  const { windowSize } = useWindowWidthSize();
  const { setOpenUseModal, setModalContent } = useModal();

  const breakPoint = windowSize >= 768;

  useEffect(() => {
    setDisplayName(userInfo.display_name);
    setPhoneNumber(
      userInfo.user_phone || userInfo.user_phone === "null"
        ? ""
        : userInfo.user_phone
    );
    setAccountType(textCapitalize(userInfo.account_type) || "");
    setInstagramProfile(userInfo.instagram);
  }, [
    userInfo.account_type,
    userInfo.display_name,
    userInfo.instagram,
    userInfo.user_phone,
  ]);

  const confirmEditionSubmit = async () => {
    setIsLoading(true);

    const data = {
      displayName,
      instagramUser: instagramProfile,
    };

    const patchInfoResponse = await patchCreatorApplicationDetails(
      userInfo.access_token,
      data
    );

    switch (patchInfoResponse?.status) {
      case 200:
        setUserInfo("instagram", instagramProfile);
        setUserInfo("display_name", displayName);

        showToast({
          title: "Save successfully!",
          description: "Your information has been updated successfully!",
          type: "success",
        });

        break;

      default:
        showToast({
          title: "We have a problem",
          description:
            "There was a problem with updating your information. Please try again",
          type: "success",
        });

        break;
    }

    setEditInfo(false);
    setIsLoading(false);
  };

  const handleGoBack = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<WaitListApplicationPage />);
    } else {
      setPrincipalScreen("/wait-list-application");
    }
  };

  const handleCancel = () => {
    setEditInfo(false);
    setDisplayName(firstInfo.displayName);
    setInstagramProfile(firstInfo.instagramProfile);
  };

  const handleEdit = () => {
    setEditInfo(true);
    setFirstInfo({
      displayName,
      instagramProfile,
      phoneNumber,
    });
  };

  return (
    <div
      className={`user-information user-information-wrapper wrapper standard-page-layout`}
    >
      <div className="user-information-inner inner">
        <div>
          <Header
            title="My information"
            subTitle={`We continually review our waiting list and will send you
            a push notification if there are any changes to your application
            status.`}
            marginTop={0}
            backButtonClick={handleGoBack}
            {...(breakPoint
              ? {
                  headerIcon: "back-button",
                  inlineElements: true,
                  titleClass: "header",
                }
              : {
                  titleClass: "user-information__title",
                })}
          />
        </div>

        <main className="user-information__main">
          <ListItemLink
            className="user-information__item"
            highlightText="Display name"
            hasSeparator
            rightNodeContent={
              editInfo ? (
                <InputText
                  inputType="text"
                  value={displayName}
                  onChangeInput={setDisplayName}
                />
              ) : (
                <p className="node-item-right-text">{displayName}</p>
              )
            }
          />
          <ListItemLink
            className="user-information__item"
            highlightText="Phone number"
            hasSeparator
            rightNodeContent={
              editInfo ? (
                <InputText
                  inputType="text"
                  value={phoneNumber}
                  onChangeInput={setPhoneNumber}
                />
              ) : (
                <p className="node-item-right-text">{phoneNumber ?? ""}</p>
              )
            }
          />
          <ListItemLink
            className="user-information__item"
            highlightText="Account type"
            hasSeparator
            rightNodeContent={
              <p className="node-item-right-text">{accountType}</p>
            }
          />
          <ListItemLink
            className="user-information__item"
            highlightText="Instagram"
            rightNodeContent={
              editInfo ? (
                <InputText
                  inputType="text"
                  value={instagramProfile}
                  onChangeInput={setInstagramProfile}
                />
              ) : (
                <p className="node-item-right-text">{instagramProfile}</p>
              )
            }
          />

          <div className="photo-container">
            <PhotoGallery files={photos} />
          </div>
        </main>

        {editInfo ? (
          <div className="user-information__buttons-container">
            <Button
              buttonStyle="primary"
              onClick={confirmEditionSubmit}
              disabled={isLoading}
            >
              Save
            </Button>
            <Button buttonStyle="tertiary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        ) : (
          <Button buttonStyle="tertiary" onClick={handleEdit}>
            Edit
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserInformation;
