import React, { ReactNode } from "react";
import "./styles.scss";

interface LayoutWebSiteProps {
  children: ReactNode;
  className?: string;
}

const LayoutWebSite: React.FC<LayoutWebSiteProps> = (props) => {
  const { children, className } = props;
  return (
    <div id="layout-web-site" className={`${className ?? ""}`}>
      {children}
    </div>
  );
};

export default LayoutWebSite;
