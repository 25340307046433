import { useIntersectionObserver } from "hooks/useIntersectionObserver";
import React, { memo, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { shortClassNames } from "utils/shortClassNames";

interface LazyImageProps {
  placeholderSrc: string;
  src: string;
  alt: string;
  loading?: "eager" | "lazy" | undefined;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
}

const LazyImage = memo(
  ({
    placeholderSrc,
    src,
    alt,
    loading,
    className,
    onClick,
    ...props
  }: LazyImageProps) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const [currentSrc, setCurrentSrc] = useState(placeholderSrc);
    const isVisible = useIntersectionObserver(imgRef);
    const [isHighResLoaded, setIsHighResLoaded] = useState(false);
    const [isLowResLoaded, setIsLowResLoaded] = useState(false);

    useEffect(() => {
      if (isVisible) {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          setCurrentSrc(src);
          setIsHighResLoaded(true);
        };
        img.onerror = () => {
          console.error(`Error loading ${src}`);
        };
      }
    }, [isVisible, src]);

    useEffect(() => {
      const placeHolder = new Image();
      placeHolder.src = placeholderSrc;
      placeHolder.onload = () => {
        setIsLowResLoaded(true);
      };
    }, [placeholderSrc]);

    return (
      <div className={`lazy-image-container ${className ?? ""}`}>
        {!isLowResLoaded && !isHighResLoaded ? (
          <div className="loading-block"></div>
        ) : (
          false
        )}
        <img
          onClick={onClick}
          ref={imgRef}
          src={currentSrc}
          alt={alt}
          className={shortClassNames([
            "profile-card__card",
            `${className ?? ""}`,
            `${isLowResLoaded && isHighResLoaded ? "" : "blurred-picture"}`,
          ])}
          loading={loading}
          {...props}
        />
      </div>
    );
  }
);

export default LazyImage;
