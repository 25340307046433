import React from "react";
import "./styles.scss";

interface IProgressbarWithoutAnimation {
  index: number;
  activeIndex: number;
  isPink?: boolean;
  className?: string;
}

const ProgressbarWithoutAnimation: React.FC<IProgressbarWithoutAnimation> = (
  props: IProgressbarWithoutAnimation
) => {
  const { index, activeIndex, isPink, className } = props;

  return (
    <div
      id="progressbar-without"
      className={`progressbar-without ${className ?? ""}`}
    >
      {index <= activeIndex ? (
        <div
          className={`progressbar-without__bar ${isPink ? "progressbar-without__bar--pink" : ""}`}
        />
      ) : (
        <div className="progressbar-without__bar-negative" />
      )}
    </div>
  );
};

export default ProgressbarWithoutAnimation;
