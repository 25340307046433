import "./styles.scss";
import { useCallback, useEffect, useState } from "react";
import { useUserInfo } from "hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import PageTitle from "components/PageTitles";
import getConsumerSubscriptions from "api/subscribe/getConsumerSubscriptions";
import ListItemLink from "components/Lists/ListItemLink";
import InputText from "components/Inputs/InputText";
import FilterIcon from "assets/icons/iconfilter.svg";
import { format } from "date-fns";
import Button from "components/Buttons/Button";

type SubscriptionStatus = "ACTIVE" | "PAST_DUE" | "CANCELED";

type ConsumerSubscriptionsParams = {
  take?: string;
  cursor?: string;
  status?: SubscriptionStatus[];
  isActive?: boolean;
  nameSearch?: string;
};

interface ISubscriptions {
  creatorId: string;
  subscriptionId: string;
  displayName: string;
  photo: string;
  subscriptionExpirationDate: Date;
  subscriptionStatus: SubscriptionStatus;
}

interface IListItem {
  id: string;
  subscriptionId: string;
  labelContent: string;
  imageLeft: string;
  simpleText: string;
  dangerSimpleText: boolean;
  clickAction: () => void;
}

const UserSettingsMySubscriptionsPage: React.FC = () => {
  const [activeList, setActiveList] = useState<IListItem[]>([]);
  const [hasNextActive, setHasNextActive] = useState(false);
  const [pastList, setPastList] = useState<IListItem[]>([]);
  const [hasNextPast, setHasNextPast] = useState(false);
  const [nextIsLoading, setNextIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    componentFeed,
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setMainScreen,
    params,
  } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  const getSubscriptions = useCallback(
    async (params?: ConsumerSubscriptionsParams) => {
      const subscriptionStatus = {
        ACTIVE: "Active",
        CANCELED: "Cancelled",
        PAST_DUE: "Expired",
      };
      const { res, status } = await getConsumerSubscriptions(
        userInfo.access_token,
        params
      );
      if (status === 200) {
        const items: IListItem[] = res.creatorsFollowing.map(
          (value: ISubscriptions) => ({
            id: value.subscriptionId,
            creatorId: value.creatorId,
            labelContent: value.displayName,
            imageLeft: value.photo,
            simpleText:
              value.subscriptionStatus === "ACTIVE"
                ? `Renew: ${format(
                    value.subscriptionExpirationDate,
                    "dd/MM/yyyy"
                  )}`
                : subscriptionStatus[value.subscriptionStatus],
            dangerSimpleText: value.subscriptionStatus === "PAST_DUE",
            clickAction: () => {
              if (componentFeed) {
                setSecondaryScreen("/user-settings-my-subscriptions-details", {
                  subscriptionId: value.subscriptionId,
                });
              } else {
                setPrincipalScreen("/user-settings-my-subscriptions-details", {
                  subscriptionId: value.subscriptionId,
                });
              }
            },
          })
        );
        return items;
      }
    },
    [setPrincipalScreen, userInfo.access_token]
  );

  const getActiveList = useCallback(
    async (nameSearch?: string) => {
      const itemsPerPage = 10;

      const activeItems = await getSubscriptions({
        status: ["ACTIVE"],
        nameSearch,
      });
      if (activeItems) {
        setActiveList(activeItems);
        setHasNextActive(activeItems.length === itemsPerPage);
      }
    },
    [getSubscriptions]
  );

  const getPastList = useCallback(
    async (nameSearch?: string) => {
      const itemsPerPage = 10;
      const pastItems = await getSubscriptions({
        status: ["CANCELED", "PAST_DUE"],
        nameSearch,
      });
      if (pastItems) {
        setPastList(pastItems);
        setHasNextPast(pastItems.length === itemsPerPage);
      }
    },
    [getSubscriptions]
  );

  const searchCreator = async () => {
    if (!searchValue) {
      await getActiveList();
      await getPastList();
      return;
    }

    await getActiveList(searchValue);
    await getPastList(searchValue);
  };

  const getNextActive = async () => {
    setNextIsLoading(true);
    try {
      const itemsPerPage = 10;

      const activeItems = await getSubscriptions({
        status: ["ACTIVE"],
        cursor: activeList[activeList.length - 1].id,
      });
      if (activeItems) {
        setActiveList([...activeList, ...activeItems]);
        setHasNextActive(activeList.length === itemsPerPage);
      }
    } catch (error) {
      console.error("getNextActive error: ", error);
    } finally {
      setNextIsLoading(false);
    }
  };

  const getNextPast = async () => {
    setNextIsLoading(true);
    try {
      const itemsPerPage = 10;

      const pastItems = await getSubscriptions({
        status: ["CANCELED", "PAST_DUE"],
        cursor: pastList[pastList.length - 1].id,
      });
      if (pastItems) {
        setPastList(pastItems);
        setHasNextPast(pastItems.length === itemsPerPage);
      }
    } catch (error) {
      console.error("getNextPast error: ", error);
    } finally {
      setNextIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveList();
    getPastList();
  }, []);

  return (
    <div
      className="user-settings-my-subscriptions
		 		user-settings-my-subscriptions-wrapper
		  	wrapper
				standard-page-layout
				bottom-spacing"
    >
      <div className="user-settings-my-subscriptions-inner inner">
        <PageTitle
          title={"My Subscriptions"}
          subtitle={"Subscribed profiles"}
          onBackClick={() => {
            if (componentFeed) {
              setSecondaryScreen("/user-settings-configuration");
            } else {
              setPrincipalScreen("/user-settings-configuration");
            }
          }}
          hasCloseIcon={false}
        />
        <div className="user-settings-my-subscriptions_list">
          <InputText
            value={searchValue}
            searchInput
            onClick={searchCreator}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchCreator();
              }
            }}
            onChangeInput={(e) => {
              setSearchValue(e);
            }}
            placeholder="Search"
            inputStyle="input-text-search"
            iconRight={FilterIcon}
            inputType="text"
          />
          <section>
            <div className="user-settings-my-subscriptions_list-header">
              <p>Active Subscriptions</p>
            </div>
            {activeList.map((item, index, arr) => (
              <ListItemLink
                key={index}
                onClick={item.clickAction}
                hasArrowRight
                hasSeparator={arr.length > index + 1}
                highlightText={item.labelContent}
                isImageCircle
                iconFill
                simpleText={item.simpleText}
                imageLeft={item.imageLeft}
              />
            ))}
            {hasNextActive && (
              <Button
                buttonStyle="quaternary"
                onClick={getNextActive}
                disabled={nextIsLoading}
              >
                SHOW MORE
              </Button>
            )}
          </section>
          <section>
            <div className="user-settings-my-subscriptions_list-header">
              <p>Past Subscriptions</p>
            </div>
            {pastList.map((item, index, arr) => (
              <ListItemLink
                key={item.id}
                onClick={item.clickAction}
                hasArrowRight
                hasSeparator={arr.length > index + 1}
                highlightText={item.labelContent}
                isImageCircle
                iconFill
                simpleText={item.simpleText}
                imageLeft={item.imageLeft}
                dangerSimpleText={item.dangerSimpleText}
              />
            ))}
            {hasNextPast && (
              <Button
                buttonStyle="quaternary"
                onClick={getNextPast}
                disabled={nextIsLoading}
              >
                SHOW MORE
              </Button>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsMySubscriptionsPage;
