import { PhotosAlbumType } from "../../types/files";
import { servicesApi } from "../fetchApi/services-api";

type ApplyCreatorData = {
  legalName: string;
  // email: string;
  instagramUser?: string;
  newsLetter?: boolean;
  files: PhotosAlbumType;
};

const applyCreator = async (token: string, data: ApplyCreatorData) => {
  const apiRoute = `${servicesApi("creator_applications")}/apply`;

  const formData = new FormData();
  formData.append("legalName", data.legalName);
  // formData.append("email", data.email);

  if (data.instagramUser) {
    formData.append("instagramUser", data.instagramUser);
  }

  if (data.newsLetter !== undefined) {
    formData.append("newsLetter", data.newsLetter.toString());
  }

  if (data.files instanceof File) {
    formData.append("files", data.files);
  } else if (Array.isArray(data.files)) {
    data.files.forEach((file) => {
      if (file) {
        formData.append(`files`, file);
      }
    });
  }

  try {
    const response = await fetch(apiRoute, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    return { res: data, status: response.status };
  } catch (error) {
    console.error(error);
    return { res: null, status: null };
  }
};

export default applyCreator;
