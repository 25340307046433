import React, { ReactNode } from "react";
import "./styles.scss";

import { IButtonStyles } from "types/buttonStyles";

import { shortClassNames } from "utils/shortClassNames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  isWidthAuto?: boolean;
  buttonStyle: IButtonStyles;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { children, isWidthAuto, buttonStyle, className, onClick, ...rest } =
    props;
  return (
    <button
      id="button"
      className={shortClassNames([
        "button",
        buttonStyle,
        buttonStyle === "quintenary" ? "background-blur-gray" : "",
        isWidthAuto ? "btn-auto" : "",
        className ?? "",
      ])}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
