import React, { useRef, useState, ReactNode } from "react";
import "./styles.scss";

interface HorizontalScrollProps {
  children: ReactNode;
  itemWidth?: number;
  gap?: number;
  itemsPerScroll?: number;
}

const HorizontalScroll: React.FC<HorizontalScrollProps> = ({
  children,
  itemWidth = 150,
  gap = 10,
  itemsPerScroll = 2,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const scrollSpeed = (itemWidth + gap) * itemsPerScroll;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollRef.current) return;
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * (scrollSpeed / itemWidth);
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => setIsDragging(false);

  return (
    <div
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      id="horizontal-scroll-component"
      style={{ cursor: isDragging ? "grabbing" : "grab", gap: `${gap}px` }}
    >
      <div
        className="horizontal-scroll-component__content"
        style={{ width: itemWidth }}
      >
        {children}
      </div>
      <div className="proxy"></div>
    </div>
  );
};

export default HorizontalScroll;
