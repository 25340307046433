import React from "react";
import "./styles.scss";

import Button from "components/Buttons/Button";

interface ICardFallback {
  title: string;
  desc?: string;
  txtButton?: string;
  className?: string;
  onClick?: () => void;
}

function CardFallback(props: ICardFallback) {
  const {
    title,
    desc,
    txtButton = "Back to Explore",
    className,
    onClick,
  } = props;

  return (
    <div className={`card-fallback ${className ?? ""}`}>
      <h2 className="card-fallback-title">{title}</h2>
      {desc && <p className="card-fallback-desc">{desc}</p>}

      {onClick && (
        <Button
          onClick={onClick}
          className="card-fallback-btn"
          buttonStyle={"tertiary"}
        >
          {txtButton}
        </Button>
      )}
    </div>
  );
}

export default CardFallback;
