import React, { ChangeEvent, useEffect, useState } from "react";
import "./styles.scss";
import CloseIconPeach from "assets/icons/closeIcons/close-icon-peach.svg";
import { UserInfoPhoto } from "types/userInfoRequest";
interface PhotosPlaceholderProps {
  quantityFiles: number;
  photoAlbumCallback: (files: (File | null)[]) => void;
  removeFileCallback?: (fileId: string) => void;
  images?: UserInfoPhoto[];
  video?: string;
  removeVideo?: () => void;
  updatePhotoCallback?: (file: { fileId: string; position: number }) => void;
  bannerColor?: string;
  banner?: string;
  removeBanner?: () => void;
}

const PhotosPlaceholder: React.FC<PhotosPlaceholderProps> = (props) => {
  const {
    quantityFiles,
    photoAlbumCallback,
    removeFileCallback,
    images,
    video,
    removeVideo,
    updatePhotoCallback,
    bannerColor,
    banner,
    removeBanner,
  } = props;

  const [files, setFiles] = useState<(File | null)[]>(
    Array(quantityFiles).fill(null)
  );
  const [isZooming, setIsZooming] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [draggingIndexPush, setDraggingIndexPush] = useState<number | null>(
    null
  );

  const hexToRgba = (hex: string, alpha = 1) => {
    hex = hex.replace(/^#/, "");

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const handleDrop =
    (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      //validacao para nao alterar banner e video
      if (draggingIndex === null || draggingIndex === undefined) return;
      if (draggingIndexPush === null || draggingIndexPush === index) return;
      if (index === 1 && files[index]?.name.endsWith("mp4")) return;
      if (index === 0) return;
      if (draggingIndex === 0) return;
      if (draggingIndex === 1 && files[draggingIndex]?.name.endsWith("mp4"))
        return;

      // upload fotos
      if (!files || !images) return;

      const matchedImages = images.filter((img) =>
        files.some((file) => file?.name && img.url.includes(file.name))
      );

      if (matchedImages.length === 0) return;

      const selectedImage = matchedImages[draggingIndexPush - 2];

      if (updatePhotoCallback) {
        updatePhotoCallback({
          fileId: selectedImage.fileId,
          position: draggingIndex - 1,
        });
      }

      orderFilesPlaceholder();
    };

  const handleDragOver =
    (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      //validacao para nao alterar banner e video
      if (draggingIndex === null || draggingIndex === index) return;
      if (index === 1 && files[index]?.name.endsWith("mp4")) return;
      if (index === 0) return;
      if (draggingIndex === 0) return;
      if (draggingIndex === 1 && files[draggingIndex]?.name.endsWith("mp4"))
        return;

      //nova referencia de files
      const updatedFiles = [...files];
      //imagem selecionada
      const draggedItem = updatedFiles[draggingIndex];

      //deleta imagem
      updatedFiles.splice(draggingIndex, 1);
      //adiciona no novo local
      updatedFiles.splice(index, 0, draggedItem);

      //atualiza files
      setFiles(updatedFiles);
      setDraggingIndex(index);
    };

  const handleDragStart =
    (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
      if (files[index]) {
        const target = event.target as HTMLElement;
        const rect = target.getBoundingClientRect();

        const img = new Image();

        const imageUrl = files[index]?.name;
        if (imageUrl) {
          img.src = imageUrl;
        } else {
          return;
        }

        img.style.borderRadius = "12px";
        img.style.width = `${rect.width}px`;
        img.style.height = `${rect.height}px`;

        document.body.appendChild(img);
        event.dataTransfer.setDragImage(img, rect.width / 2, rect.height / 2);

        setTimeout(() => document.body.removeChild(img), 0);
      }

      setDraggingIndex(index);
      setDraggingIndexPush(index);
      if (!files[index]?.name.endsWith("mp4")) {
        event.dataTransfer.setData("index", index.toString());
      }
    };

  const handleMouseDown = () => {
    setIsZooming(true);
  };

  const handleMouseUp = () => {
    setIsZooming(false);
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];

    if (images && files[index]) {
      const imageIndex = index - 2;
      removeFileCallback?.(images[imageIndex]?.fileId);
    }

    newFiles[index] = null;
    setFiles(newFiles);
    photoAlbumCallback(newFiles);
  };

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newFiles = [...files];
    if (e.target.files && e.target.files[0]) {
      newFiles[index] = e.target.files[0];
      setFiles(newFiles);
      photoAlbumCallback(newFiles);
    }
  };

  useEffect(() => {
    orderFilesPlaceholder();
    // }, [images, quantityFiles, banner, video]);
  }, [images]);

  const orderFilesPlaceholder = () => {
    if (images) {
      const newFiles = Array(quantityFiles).fill(null);

      if (banner) newFiles[0] = { name: banner };
      if (video) newFiles[1] = { name: video };

      images.forEach((image, idx) => {
        const imageIndex = idx + 2;
        if (imageIndex < quantityFiles)
          newFiles[imageIndex] = { name: image.url };
      });

      setFiles(newFiles);
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--banner-color",
      bannerColor ? hexToRgba(bannerColor ? bannerColor : "", 0.6) : ""
    );
  }, [bannerColor]);
  const Tag = "span";

  return (
    <div id="photos-placeholder">
      {Array.from({ length: quantityFiles }).map((_, index) => {
        return (
          <div
            draggable={
              files[index] != null && !files[index]?.name?.endsWith("mp4")
            }
            onDragStart={handleDragStart(index)}
            onDrop={handleDrop(index)}
            className={`photo-preview preview-images ${
              index === 1 ? "large-image" : ""
            } ${index === 0 ? "banner " : ""} ${
              isZooming && !files[index]?.name?.endsWith("mp4") ? "zooming" : ""
            } ${!files[index] ? "border-empty" : "border-none"}`}
            key={index}
            onDragOver={handleDragOver(index)}
            onMouseDown={() => {
              const file = files[index] ?? null;
              if (
                file &&
                typeof file.name === "string" &&
                !file.name.endsWith("mp4")
              ) {
                handleMouseDown();
              }
            }}
            onMouseUp={() => {
              const file = files[index] ?? null;
              if (
                file &&
                typeof file.name === "string" &&
                !file.name.endsWith("mp4")
              ) {
                handleMouseUp();
              }
            }}
          >
            {index == 0 && <Tag className="cover-text">Banner</Tag>}

            {index == 1 && <Tag className="cover-text">Cover</Tag>}

            {index === 0 && banner ? (
              <>
                <div className="preview-images">
                  <img src={banner} alt={`preview ${index}`} />
                  <button onClick={removeBanner}>
                    <img src={CloseIconPeach} alt="remove icon" />
                  </button>
                </div>
              </>
            ) : null}
            {index === 1 &&
            files[1]?.name.endsWith("mp4") &&
            files[index]?.name.startsWith("http") ? (
              <>
                <Tag className="cover-text">Cover</Tag>

                <div className="preview-images">
                  <video
                    key={video}
                    src={video}
                    autoPlay
                    loop
                    muted
                    playsInline
                    disablePictureInPicture
                    controls={false}
                    preload="auto"
                  />
                  <button onClick={removeVideo}>
                    <img src={CloseIconPeach} alt="remove icon" />
                  </button>
                </div>
              </>
            ) : null}

            {files &&
            files[index] &&
            !files[index]?.name.endsWith("mp4") &&
            index >= 1 &&
            files[index]?.name.startsWith("http") ? (
              <div className="preview-images">
                <img src={files[index]?.name} alt={`preview ${index}`} />
                <button onClick={() => handleRemoveFile(index)}>
                  <img src={CloseIconPeach} alt="remove icon" />
                </button>
              </div>
            ) : (
              <>
                {!files[index] ? (
                  <span className="empty-photo">
                    <span className="icon icon-photo icon-md icon-gray"></span>
                  </span>
                ) : (
                  ""
                )}
              </>
            )}

            {files && files[index] && files[index] instanceof File ? (
              <div className="preview-images">
                {files[index]?.name.toLowerCase().endsWith(".mp4") ? (
                  <video
                    key={video}
                    src={URL.createObjectURL(files[index] as File)}
                    autoPlay
                    loop
                    muted
                    playsInline
                    disablePictureInPicture
                    controls={false}
                    preload="auto"
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(files[index] as File)}
                    alt={`preview ${files[index]?.name}`}
                  />
                )}
                <button
                  onClick={() => {
                    if (!files[index]?.name.toLowerCase().endsWith(".mp4")) {
                      handleRemoveFile(index);
                    } else if (index === 0 && removeBanner) {
                      removeBanner();
                    } else {
                      removeVideo?.();
                    }
                  }}
                >
                  <img src={CloseIconPeach} alt="remove icon" />
                </button>
              </div>
            ) : (
              <>
                {!files[index] ? (
                  <>
                    <span className="empty-photo">
                      <span className="icon icon-photo icon-md icon-gray"></span>
                    </span>
                    <input
                      type="file"
                      name="upload-photo"
                      onChange={(e) => handleFileChange(e, index)}
                    />
                  </>
                ) : null}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PhotosPlaceholder;
