import "./styles.scss";
import { defaultSuggestions } from "../../../mocks/chatSuggestion";

interface IChatSuggestion {
  className?: string;
  options?: string[];
  onSendMessage?: (message: string) => void;
}

const ChatSuggestion: React.FC<IChatSuggestion> = (props) => {
  const { className, options = defaultSuggestions, onSendMessage } = props;

  const renderOption = (item: string) => (
    <li
      key={item}
      className="chat-suggestion-item"
      role="option"
      aria-selected
      onClick={() => onSendMessage?.(item.slice(1, -1))}
    >
      {item}
    </li>
  );

  return (
    <aside
      className={`chat-suggestion ${className ? className : ""}`}
      aria-label="Chat message suggestions"
    >
      <h3 className="chat-suggestion-title roboto-regular">Suggestions:</h3>

      <ul className="chat-suggestion-list" role="listbox">
        {options.map(renderOption)}
      </ul>
    </aside>
  );
};

export default ChatSuggestion;
