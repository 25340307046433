import { useContext, useEffect } from "react";
import "./styles.scss";
import { usePwa } from "../../hooks/pwa";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../hooks/languageContext";
import { translateTextNodes } from "../../utils/translation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";

//COMPONENTS
import CardsFeaturesLP from "components/Cards/CardsFeaturesLP";
import TranslatableText from "components/Texts/TranslatableText";
import LandingPageCarousel from "components/Carousels/LandingPageMainCarousel";
import LayoutWebSite from "components/Wrappers/LayoutWebSite";
import NavBar from "components/Navbars/NavBar";
import ContentWrapper from "components/Wrappers/LandingPageContents";
import LandingPageSecondaryCarousel from "components/Carousels/LandingPageSecondaryCarousel";
import ContentWhyUsWrapper from "components/Wrappers/LandingPageContents/WhyChooseContent";
import FooterLP from "components/Footers/FooterLP";
import CenterText from "components/Texts/CenterText";
import AccountLoginPage from "../AccountLogin";

//BANNER IMAGES
import banner01 from "assets/images/landingPageImages/banner/roses_banner_01.jpg";
import banner02 from "assets/images/landingPageImages/banner/roses_banner_02.jpg";
import banner03 from "assets/images/landingPageImages/banner/roses_banner_03.jpg";
import banner04 from "assets/images/landingPageImages/banner/roses_banner_04.jpg";
import banner05 from "assets/images/landingPageImages/banner/roses_banner_05.jpg";
import banner06 from "assets/images/landingPageImages/banner/roses_banner_06.jpg";
import banner07 from "assets/images/landingPageImages/banner/roses_banner_07.jpg";
import banner08 from "assets/images/landingPageImages/banner/roses_banner_08.jpg";
import banner09 from "assets/images/landingPageImages/banner/roses_banner_09.jpg";
import banner10 from "assets/images/landingPageImages/banner/roses_banner_10.jpg";
import banner11 from "assets/images/landingPageImages/banner/roses_banner_11.jpg";
import banner12 from "assets/images/landingPageImages/banner/roses_banner_12.jpg";
import banner13 from "assets/images/landingPageImages/banner/roses_banner_13.jpg";
import banner14 from "assets/images/landingPageImages/banner/roses_banner_14.jpg";
import banner15 from "assets/images/landingPageImages/banner/roses_banner_15.jpg";
import banner16 from "assets/images/landingPageImages/banner/roses_banner_16.jpg";
import banner17 from "assets/images/landingPageImages/banner/roses_banner_17.jpg";
import banner18 from "assets/images/landingPageImages/banner/roses_banner_18.jpg";

//IMAGES
import FirstGirlFeature from "assets/images/landingPageImages/girl-features-first.png";
import SecondGirlFeature from "assets/images/landingPageImages/girl-features-second.png";
import ThirdGirlFeature from "assets/images/landingPageImages/girl-features-third.png";

//ICONS
import sparkIconPink from "assets/icons/bannerIcons/spark-icon-pink.svg";
import chatIconPink from "assets/icons/bannerIcons/chat-icon-pink.svg";
import cryptoIconPink from "assets/icons/bannerIcons/crypto-icon-pink.svg";
import globeIconPink from "assets/icons/bannerIcons/globe-icon-pink.svg";
import rosesTokenIconPink from "assets/icons/bannerIcons/roses-token-icon-pink.svg";
import securityIconPink from "assets/icons/bannerIcons/security-icon-pink.svg";

import { setupPWAInstall } from "../../utils/PWA";
import getUserInfoByUsername from "api/userInfo/getUserInfoByUsername";
import { useUserInfo } from "hooks/userInfo";
import { CreatorDetailsContext } from "contexts/creatorDetails";
import { useDeskNavigation } from "hooks/useDeskNavigation";

const LandingPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { translations } = useLanguage();
  const { setFeedScreen } = useDeskNavigation();
  const navigate = useNavigate();
  const params = useParams();
  const { isPwa } = usePwa();
  const { setCreatorDetails } = useContext(CreatorDetailsContext);

  useEffect(() => {
    setupPWAInstall();
  }, []);

  useEffect(() => {
    if (isPwa) {
      navigate("/app");
    }
  }, [isPwa]);

  useEffect(() => {
    translateTextNodes(translations);
  }, [translations]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const slides = [
    {
      title: "2/bannerSlideShowMain-01",
      subtitle: "2/bannerSlideShowMainDescription-01",
      backgroundImage: banner01,
      icon: sparkIconPink,
    },
    {
      title: "2/bannerSlideShowMain-02",
      subtitle: "2/bannerSlideShowMainDescription-02",
      backgroundImage: banner02,
      icon: chatIconPink,
    },
    {
      title: "2/bannerSlideShowMain-03",
      subtitle: "2/bannerSlideShowMainDescription-03",
      backgroundImage: banner03,
      icon: securityIconPink,
    },
    {
      title: "2/bannerSlideShowMain-04",
      subtitle: "2/bannerSlideShowMainDescription-04",
      backgroundImage: banner04,
      icon: globeIconPink,
    },
    {
      title: "2/bannerSlideShowMain-05",
      subtitle: "2/bannerSlideShowMainDescription-05",
      backgroundImage: banner07,
      icon: rosesTokenIconPink,
    },
    {
      title: "2/bannerSlideShowMain-06",
      subtitle: "2/bannerSlideShowMainDescription-06",
      backgroundImage: banner08,
      icon: cryptoIconPink,
    },
  ];
  const slidesSecondary = [
    {
      backgroundImage: banner05,
    },
    {
      backgroundImage: banner06,
    },
    {
      backgroundImage: banner09,
    },
    {
      backgroundImage: banner10,
    },
    {
      backgroundImage: banner11,
    },
    {
      backgroundImage: banner12,
    },
    {
      backgroundImage: banner13,
    },
    {
      backgroundImage: banner14,
    },
    {
      backgroundImage: banner15,
    },
    {
      backgroundImage: banner17,
    },
    {
      backgroundImage: banner18,
    },
  ];

  const handleJoin = () => {
    // setComponent(<AccountLoginPage />);
    navigate("/app");
  };

  return (
    <div className="landing-page-wrapper">
      <div className="landing-page-inner">
        <NavBar />
        <div className="root-landing-page ">
          <LandingPageCarousel slides={slides} />

          <LayoutWebSite className="landing-page wrapper">
            <ContentWhyUsWrapper />
            <div className="separator-lp"></div>

            <div className="info-text-centered inner">
              <div className="features-girls-images">
                {/* <img src={FirstGirlFeature} alt="feature girl" />
            <img src={SecondGirlFeature} alt="feature girl" />
            <img src={ThirdGirlFeature} alt="feature girl" /> */}

                <div className="logos-container">
                  {/* <img src={LogoHallowPink} alt="roses logo" />
              <img src={LogoFillPink} alt="roses logo" />
              <img src={LogoCleanPink} alt="roses logo" /> */}
                  <span className="icon icon-roses-outline icon-lg"></span>
                  <span className="icon icon-roses-default icon-lg"></span>
                  <span className="icon icon-roses-solid icon-lg"></span>
                  {/* <span className="icon icon-chevron-bottom icon-md icon-gray"></span> */}
                  {/* <img src={ArrowDown} alt="arrow down" /> */}
                </div>
              </div>
              {/* <CenterText
              title={"4/featureSecondTitle"}
              subtitle={
                <TranslatableText textKey="4/featureSecondDescription" />
              }
						/> */}
              <CenterText
                title={"4/featureMainTitle"}
                // titleTag="h1"
                subtitle={
                  <TranslatableText textKey="4/featureSecondDescription" />
                }
              />
              <div className="gradient-background"></div>
            </div>
            <div className="features-girls-images">
              <img src={FirstGirlFeature} alt="feature girl" />
              <img src={SecondGirlFeature} alt="feature girl" />
              <img src={ThirdGirlFeature} alt="feature girl" />
            </div>
            <CardsFeaturesLP theme="engagement" />

            <div className="info-text-centered inner">
              {/* <CenterText
              title={"4/featureMainTitle"}
              titleTag="h1"
              subtitle={<TranslatableText textKey="4/featureMainDescription" />}
            /> */}
              <CenterText
                title={"4/featureSixthTitle"}
                subtitle={
                  <TranslatableText textKey="4/featureSixthDescription" />
                }
              />
            </div>
            <CardsFeaturesLP theme="aiContent" />
            <ContentWrapper
              contentSide="left"
              listItemCount={1}
              featureIcon={"language"}
              featureTitle={"5/communicationMainTitle"}
              featureSubTitle={"5/communicationMainDescription"}
              featureDescription={"5/communicationMainDescription"}
              listItem1LabelContent={"5/communicationTitle-01"}
              listItem1DescriptionContent={"5/communicationDescription-01"}
              // listItem2LabelContent={"5/communicationTitle-02"}
              // listItem2DescriptionContent={"5/communicationDescription-02"}
              componentType="chat"
            />

            <div className="info-text-centered inner">
              <CenterText
                title={"4/featureThirdTitle"}
                subtitle={
                  <TranslatableText textKey="4/featureThirdDescription" />
                }
              />
            </div>
            <CardsFeaturesLP theme="userContent" />
            <LandingPageSecondaryCarousel slides={slidesSecondary} />

            {/* <div className="info-text-centered ">
            <CenterText
              title={"4/featureSixthTitle"}
              subtitle={
                <TranslatableText textKey="4/featureSixthDescription" />
              }
            />
          </div>
          <CardsFeaturesLP theme="aiContent" />
          <ContentWrapper
            contentSide="left"
            listItemCount={2}
            featureIcon={"language"}
            featureTitle={"5/communicationMainTitle"}
            featureSubTitle={"5/communicationMainDescription"}
            featureDescription={"5/communicationMainDescription"}
            listItem1LabelContent={"5/communicationTitle-01"}
            listItem1DescriptionContent={"5/communicationDescription-01"}
            listItem2LabelContent={"5/communicationTitle-02"}
            listItem2DescriptionContent={"5/communicationDescription-02"}
            componentType="chat"
          /> */}
            <div className="info-text-centered inner">
              <CenterText
                title={"4/featureFourthTitle"}
                subtitle={
                  <TranslatableText textKey="4/featureFourthDescription" />
                }
              />
            </div>
            <ContentWrapper
              contentSide="right"
              listItemCount={1}
              featureIcon={"digitalprint"}
              featureTitle={"5/identityMainTitle"}
              featureSubTitle={"5/identityMainDisclaimer"}
              featureDescription={"5/identityMainDescription"}
              listItem1LabelContent={"5/identityTitle-01"}
              listItem1DescriptionContent={"5/identityDescription-01"}
              componentType="pixel"
            />
            <CardsFeaturesLP theme="security" />
            <ContentWrapper
              contentSide="left"
              listItemCount={2}
              featureIcon={"security"}
              featureTitle={"5/locationMainTitle"}
              featureSubTitle={"5/locationMainDisclaimer"}
              featureDescription={"5/locationMainDescription"}
              listItem1LabelContent={"5/locationTitle-01"}
              listItem1DescriptionContent={"5/locationDescription-01"}
              listItem2LabelContent={"5/locationTitle-02"}
              listItem2DescriptionContent={"5/locationDescription-02"}
              componentType="map"
            />

            <div className="info-text-centered ">
              <CenterText
                title={"4/featureFifthTitle"}
                subtitle={
                  <TranslatableText textKey="4/featureFifthDescription" />
                }
              />
            </div>

            <div className="features-cards__wrapper">
              <CardsFeaturesLP
                theme="comprehensiveDashboard"
                buttonText={
                  <TranslatableText
                    textAlt
                    fontSize="10"
                    textKey="4/featureButton"
                  />
                }
                buttonClick={handleJoin}
              />
            </div>
            <FooterLP />
          </LayoutWebSite>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
