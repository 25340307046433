import SendRosesModal from "components/Chat/ChatModals/SendRosesModal";
import UnlockContentModal from "components/Chat/ChatModals/UnlockContentModal";
import Loading from "components/Loading";

import { useMediaContext } from "../contexts/mediaContext";
import { useCanPurchasedMedia } from "./useCanPurchasedMedia";
import { useModal } from "./useModal";
import { useBuyRoses } from "./useBuyRoses";

const useUnlockContent = () => {
  const { canPurchase, purchaseMediaChat, purchaseMediaFeed } =
    useCanPurchasedMedia();
  const { setOpenUseModal, setModalContent } = useModal();
  const { unlockMedia } = useMediaContext();
  const { buyRoses } = useBuyRoses();

  const unlockContentSubmit = async (
    mediaId: string,
    price: number,
    typeMedia: "chat" | "feed",
    callback?: () => void,
    isDirectPurchase = false
  ) => {
    const canUnlock = await canPurchase(price);

    if (!canUnlock) {
      setModalContent(
        <SendRosesModal
          title="Buy Roses"
          description="Select Roses below"
          showRosesOptions
          showInfoGold
          showTable={{
            table: false,
            header: {
              gold: true,
              roses: true,
              total: true,
            },
          }}
          typeModal="buy"
          buttonText="Buy Roses"
          onClick={() => {
            buyRoses();
            setOpenUseModal(false);
          }}
        />
      );
      setOpenUseModal(true);
      return;
    }

    if (isDirectPurchase) {
      await unlockContent(
        mediaId,
        price,
        typeMedia,
        callback,
        isDirectPurchase
      );
    } else {
      setModalContent(
        <UnlockContentModal
          mediaId={mediaId}
          unlockContent={() =>
            unlockContent(mediaId, price, typeMedia, callback, true)
          }
        />
      );
      setOpenUseModal(true);
    }
  };

  const unlockContent = async (
    mediaId: string,
    price: number,
    typeMedia: "chat" | "feed",
    callback?: () => void,
    isDirectPurchase = false
  ) => {
    setModalContent(
      <div className="loading-send-file">
        <Loading />
      </div>
    );

    switch (typeMedia) {
      case "chat":
        await purchaseMediaChat(mediaId, price);
        break;
      case "feed":
        await purchaseMediaFeed(mediaId, price);
        break;
      default:
        break;
    }

    setOpenUseModal(false);
    setModalContent(null);

    callback?.();
    unlockMedia(mediaId);
  };

  return { unlockContentSubmit };
};

export { useUnlockContent };
