import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./styles.scss";

import Button from "../../Buttons/Button";
import InputText from "../../Inputs/InputText";
import InputRadio from "../../Inputs/InputRadio";
import InputCheckbox from "../../Inputs/InputCheckbox";
import InputListSelect from "../../Inputs/InputListSelect";

import ChevronDown from "assets/icons/bx-chevron-down.svg";
import ChevronUp from "assets/icons/bx-chevron-up.svg";

interface DropDownProps {
  options: DropDownRadioElement[];
  placeholder?: string;
  iconLeft?: string;
  iconRight?: boolean;
  label?: string;
  inputValueSet?: string | string[];
  textButton?: string;
  defaultValueProp?: string | string[] | undefined;
  elementNode?: ReactNode;
  dropType: "radio" | "checkbox" | "click-select";
  className?: string;
  setValueCallBack: (option: string[]) => void;
  buttonCallBack?: () => void;
  actionIconRight?: (index?: number) => void;
}

export interface DropDownRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
  icon?: string;
  node?: ReactNode;
  subText?: string;
}

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    options,
    placeholder,
    iconLeft,
    iconRight,
    label,
    textButton,
    inputValueSet,
    defaultValueProp,
    elementNode,
    dropType,
    className,
    setValueCallBack,
    buttonCallBack,
    actionIconRight,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [inputValue, setInputValue] = useState<string[]>([]);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setIsOpenDropDown(false);
    }
  };

  const toggleOpenDropDown = () => {
    setIsOpenDropDown((prev) => !prev);
  };

  const selectOption = (option: any) => {
    setInputValue(option);
    setValueCallBack(option);

    if (dropType === "click-select") {
      setIsOpenDropDown(false);
    }
  };

  const getDisplayValue = () => {
    if (dropType === "checkbox" && Array.isArray(inputValue)) {
      return inputValue.join(", ");
    }

    const selectedValue =
      typeof inputValue === "string" ? inputValue : inputValue[0];

    const label = options.find(
      (opt) => opt.inputValue === selectedValue
    )?.inputLabel;

    return label;
  };

  const [isTransitionComplete, setIsTransitionComplete] = useState(false);

  useEffect(() => {
    const element = inputRadioRef.current;
    let transitionsCompleted = 0;

    if (inputValueSet) {
      setInputValue(inputValueSet as string[]);
    }

    if (!element) return;

    const handleTransitionEnd = () => {
      transitionsCompleted += 1;

      const transitionProperties =
        getComputedStyle(element).transitionProperty.split(", ").length;
      if (transitionsCompleted >= transitionProperties) {
        setIsTransitionComplete(true);
        transitionsCompleted = 0;
      } else {
        setIsTransitionComplete(false);
      }
    };

    element.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      element.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, []);

  const inputRadioRef = useRef<HTMLDivElement>(null);
  const inputCheckboxRef = useRef<HTMLDivElement>(null);

  const buttonContainer = () => {
    if (textButton && buttonCallBack) {
      return (
        <div className={`drop-down__container-button`}>
          <Button
            buttonStyle="tertiary"
            onClick={buttonCallBack}
            className={` `}
          >
            {textButton}
          </Button>
        </div>
      );
    }
  };

  return (
    <div
      id="drop-down-component"
      className={className ?? ""}
      data-active={isOpenDropDown}
      ref={dropDownRef}
    >
      <InputText
        label={label}
        value={inputValueSet}
        readOnly
        backgroundColor="bg-inverse-15"
        inputType="text"
        placeholder={placeholder}
        iconRight={isOpenDropDown ? ChevronUp : ChevronDown}
        iconLeft={iconLeft}
        textTransform={"capitalize"}
        elementNode={elementNode}
        cursor="pointer"
        className={`drop-down-component__input`}
        onClick={toggleOpenDropDown}
      />
      <div
        ref={inputRadioRef}
        className={`drop-down-component__input-container ${
          isOpenDropDown
            ? "drop-down-component__input-container-open"
            : "drop-down-component__input-container-close"
        }`}
      >
        {dropType === "radio" ? (
          <div className="input-container__radio-wrapper">
            <InputRadio
              elements={options}
              onChangeValueCallback={selectOption}
              defaultValueProp={defaultValueProp}
              className={`${
                isTransitionComplete ? "active" : ""
              } radio-wrapper__content`}
              iconRight={iconRight}
              actionIconRight={actionIconRight}
            />
            {buttonContainer()}
          </div>
        ) : dropType === "checkbox" ? (
          <div className="input-container__checkbox-wrapper">
            <InputCheckbox
              inputValues={inputValue}
              setInputsValues={(e) => {
                setInputValue(e);
              }}
              elements={options}
              defaultValueProp={defaultValueProp as string[]}
              className="checkbox-wrapper__content"
            />
            {buttonContainer()}
          </div>
        ) : dropType === "click-select" ? (
          <div className="input-container__list-select-wrapper">
            <InputListSelect
              elements={options}
              onChangeValueCallback={selectOption}
              defaultValueProp={defaultValueProp as string[]}
              className={`list-select-wrapper__content ${
                isTransitionComplete ? "active" : ""
              }`}
            />
            {buttonContainer()}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DropDown;
