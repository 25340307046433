export const genderToFront: Record<string, string> = {
  MAN: "Male",
  WOMAN: "Female",
  NONBINARY: "Non-binary",
  TRANS: "Trans",
};

export const genderToApi: Record<string, string> = {
  MALE: "MAN",
  FEMALE: "WOMAN",
  NONBINARY: "NONBINARY",
  TRANS: "TRANS",
};
