import { Outlet, useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useDeskNavigation } from "hooks/useDeskNavigation";

const useOfflineRedirect = () => {
  const navigate = useNavigate();
  const {
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setFeedScreen,
    setMainScreen,
  } = useDeskNavigation();

  useEffect(() => {
    const handleOffline = () => {
      setPrincipalScreen("");
      setSecondaryScreen("");
      setTertiaryScreen("");
      setFeedScreen("");
      setMainScreen("");

      navigate("/offline");
    };

    const handleOnline = () => {
      navigate("/app");
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [navigate]);
};

const MainLayout = () => {
  useOfflineRedirect();

  return (
    <>
      <Outlet />
    </>
  );
};

export default MainLayout;
