import fetchApi from "../fetchApi";

const getNotifications = async (token: string) => {
  return await fetchApi({
    service: "notifications",
    method: "GET",
    token,
  });
};

export default getNotifications;
