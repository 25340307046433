import React, { useState } from "react";
import "./styles.scss";

import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import InputText from "components/Inputs/InputText";
import DropDown from "components/Lists/DropDown";

import { useDeskNavigation } from "hooks/useDeskNavigation";

import countryCodes from "../../mocks/countryCodes.json";

import ShieldIcon from "assets/icons/shieldIcons/personal-icon-gray.svg";

const SendMeLink: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();

  const [prefix, setPrefix] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryAndCode, setCountryAndCode] = useState("BR +55");

  const handlerSubmit = () => {
    setPrincipalScreen("/choose-document");
  };

  return (
    <div className="send-me-link send-me-link-wrapper wrapper standard-page-layout">
      <div className="send-me-link-inner inner">
        <div className="send-me-link__top">
          <Header
            headerIcon="back-button"
            title="Send me a link"
            subTitle="Please enter your phone number to receive a link URL that will redirect the process to your mobile phone."
            hasGapBetweenTitleAndSub={false}
            marginTop={0}
            titleClass="send-me-link__title"
            backButtonClick={() => setPrincipalScreen("/identity-verification")}
          />
        </div>

        <div className="send-me-link__information">
          <div className="send-me-link__row">
            <DropDown
              inputValueSet={countryAndCode}
              defaultValueProp={countryAndCode}
              options={countryCodes.map((countryInfo) => {
                const { countryCode, code } = countryInfo;
                return {
                  inputLabel: `${code} ${countryCode}`,
                  inputValue: `${code} ${countryCode}`,
                  groupName: "countryAndCode",
                };
              })}
              dropType="click-select"
              setValueCallBack={(option: string[]) => {
                setCountryAndCode(option[0]);
                setPrefix(option[0]);
              }}
            />

            <InputText
              placeholder="Phone Number"
              textTransform="none"
              inputType="text"
              value={phoneNumber}
              onChangeInput={(e) => setPhoneNumber(e)}
            />
          </div>
          <SubText
            icon={ShieldIcon}
            altIcon="shield icon"
            className="send-me-link__icon-disclaimer"
            classNameIcon="send-me-link__icon"
            children="We never share this with anyone and it won’t be on your profile."
          />
        </div>

        <div className="send-me-link__bottom">
          <Button
            children="Send Link"
            buttonStyle={"primary"}
            onClick={handlerSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default SendMeLink;
