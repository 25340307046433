import React from "react";
import "./styles.scss";

import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";

import { useDeskNavigation } from "hooks/useDeskNavigation";

import QRCode from "assets/images/QR.png";

const IdentityVerificationPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();

  const goTo = () => {
    setPrincipalScreen("/send-me-link");
  };

  return (
    <div className="identity-verification identity-verification-wrapper wrapper standard-page-layout">
      <div className="identity-verification-inner inner">
        <div className="identity-verification__top">
          <Header
            headerIcon="back-button"
            title="Identity verification"
            subTitle="Please continue the process on the mobile phone by scanning QR code or copying the link. Using your phone speeds up and simplifies the verification process."
            hasGapBetweenTitleAndSub={false}
            marginTop={0}
            titleClass="identity-verification__title"
            backButtonClick={() => setPrincipalScreen("/send-photos")}
          />
        </div>

        <div className="identity-verification__body">
          <div className="identity-verification__body-content">
            {/* <img src="" alt="" />
            <h2>Scan QR Code</h2>
            <Paragraph children="Point your device to scan" /> */}
            <Header
              headerIcon={"qr-code"}
              tagTitle="h2"
              title="Scan QR Code"
              subTitle="Point your device to scan"
              marginTop={0}
              hasGapBetweenTitleAndSub={false}
              className="identity-verification__body-gap"
              titleClass="identity-verification__body-title"
            />
          </div>
          <img src={QRCode} alt="QR Code" />
        </div>

        <div className="identity-verification__bottom">
          <Button
            children="Copy Link"
            buttonStyle={"tertiary"}
            onClick={goTo}
          />
          <Button
            children="Did not work?"
            buttonStyle={"quaternary"}
            onClick={goTo}
          />
        </div>
      </div>
    </div>
  );
};

export default IdentityVerificationPage;
