import React, { ReactNode } from "react";
import "./styles.scss";

interface TitleTextProps {
  children: ReactNode;
  size?: "h1" | "h2" | "h3" | "h4" | "title";
  titleClass?: "header" | string;
  modalStyle?: boolean;
}

const TitleText: React.FC<TitleTextProps> = ({
  children,
  size = "h3",
  titleClass,
  modalStyle,
}) => {
  const Tag = size === "title" ? "p" : size;
  return (
    <Tag
      id="title-text"
      className={`${titleClass ?? ""} ${modalStyle ? "modal-style" : ""}`}
    >
      {children}
    </Tag>
  );
};

export default TitleText;
