import React, { useRef, useState } from "react";
import { NavigateFunction, Params as RouterParams } from "react-router-dom";
import "./styles.scss";

import FadeCarousel from "components/Carousels/FadeCarousel";
import Header from "components/Headers/Header";
import SubText from "components/Texts/SubText";
import Button from "components/Buttons/Button";
import InputText from "components/Inputs/InputText";

import LoginModal from "../Login";

import { useToast } from "hooks/useToast";
import { useModal } from "hooks/useModal";
import { Params } from "hooks/useDeskNavigation";

import ResetPassword from "api/auth/postPasswordReset";

import LockIcon from "assets/icons/lock-icon-gray.svg";
import SignUpImage from "assets/images/login&CreateAccount/sign-up.jpg";

interface ForgotPasswordModalProps {
  navigate: NavigateFunction;
  setComponent: (path: string | React.ReactNode, params?: Params) => void;
  paramsUrl: Readonly<RouterParams<string>>;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = (props) => {
  const { navigate, setComponent, paramsUrl } = props;
  const { showToast } = useToast();
  const { setModalContent, setOpenUseModal } = useModal();
  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const modalRef = useRef<HTMLDivElement | null>(null);

  const resetPasswordSubmit = async () => {
    const requestReset = await ResetPassword({ email: currentEmail });

    if (requestReset.res.message) {
      showToast({
        description: requestReset.res.message,
        title: "Reset password",
        type: "success",
      });
    }

    if (!currentEmail) {
      showToast({
        description: "Check your credential and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setEmailInputWarning(true);

      setTimeout(() => {
        setEmailInputWarning(false);
      }, 3000);
    }
  };

  const cancelSubmit = () => {
    setModalContent(
      <LoginModal
        navigate={navigate}
        paramsUrl={paramsUrl}
        setComponent={setComponent}
      />
    );
  };

  return (
    <div
      id="reset-password-modal"
      ref={modalRef}
      className="reset-password-modal-wrapper wrapper"
    >
      <span
        onClick={() => setOpenUseModal(false)}
        className="icon icon-close icon-gray-light
			 		icon-md close-modal-icon"
      />
      <div className="reset-password-modal__content">
        <div className="content__carousel-wrapper">
          <FadeCarousel
            slides={[{ backgroundImage: SignUpImage }]}
            duration={4000}
            borderRadius={[16, 0, 0, 16]}
            showGradient={false}
            showText={false}
          />
        </div>
        <div className="content__form-wrapper">
          <Header
            marginTop={0}
            inlineElements
            modalStyle
            headerIcon="back-button"
            noMarginTopTextAndIcon={true}
            className="reset-password-header"
            backButtonClick={cancelSubmit}
            title={"Reset Password"}
            subTitle={
              <>
                Enter your email, we will send you a link to reset your password
              </>
            }
          />
          <div className="form-wrapper__inputs">
            <InputText
              value={currentEmail}
              placeholder="Your email"
              onChangeInput={(e) => setCurrentEmail(e)}
              inputType="text"
              isWarning={emailInputWarning}
              backgroundColor="charcoal"
            />
            <SubText
              icon={LockIcon}
              altIcon="clock icon"
              classNameIcon="reset-password__icon"
              className="reset-password__subtext"
            >
              Never share your credentials with anyone.
            </SubText>
          </div>

          <div className="reset-password-modal-wrapper__buttons-container">
            <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
              Reset password
            </Button>

            <Button buttonStyle="quaternary" onClick={cancelSubmit}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
