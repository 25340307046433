import "./styles.scss";
import PageTitle from "components/PageTitles";
import Button from "components/Buttons/Button";
import { useEffect, useState } from "react";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputRadio from "components/Inputs/InputRadio";
import getUserLocationHistory from "../../api/userInfo/getUserLocationHistory";
import { useUserInfo } from "../../hooks/userInfo";
import patchUserLocation from "../../api/userInfo/patchUserLocation";
import MapRoute from "components/MapRoute";
import { SearchBox } from "@mapbox/search-js-react";
import deleteProfileLocation from "../../api/userInfo/deleteProfileLocation";
import { useToast } from "hooks/useToast";
const SearchBoxComponent = SearchBox as React.ComponentType<any>;
interface ListDetails {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;
  latitude?: number;
  longitude?: number;
  locationHistoryId?: string;
}

const UserSettingsLocationPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen } = useDeskNavigation();
  const { showToast } = useToast();
  const [residence, setResidence] = useState<ListDetails>();
  const [locationList, setLocationList] = useState<ListDetails[]>();
  const [location, setLocation] = useState<string>();
  const { userInfo } = useUserInfo();
  const mapboxToken = process.env.REACT_APP_MAPBOX_API_KEY;

  const removeLocation = async (e: any) => {
    locationList?.map(async (item, index) => {
      if (index === e) {
        if (item.locationHistoryId) {
          const response = await deleteProfileLocation(
            userInfo.access_token,
            item.locationHistoryId
          );
          const res = await requestLocationHistory();

          if (res === 200) {
            //setModalRemoveIsOpen(false);

            showToast({
              description: `Location was successfully removed`,
              title: "Location removed",
              type: "success",
            });
          } else if (res === 500) {
            showToast({
              description: `Unable to remove location, please try again later`,
              title: "Location was not removed",
              type: "error",
            });
          }
        }
      }
    });
  };

  const updateLocation = async () => {
    if (residence?.place && residence.country) {
      const response = await patchUserLocation(
        userInfo.access_token,
        residence
      );

      if (response.status === 200) {
        await requestLocationHistory();
        //  setModalAddIsOpen(false);
        showToast({
          description: `Location ${residence.place}, ${residence.country} blocked`,
          title: "Location was successfully added",
          type: "success",
        });

        return;
      } else {
        showToast({
          description: `Unable to block location ${residence.place}, ${residence.country}, try again later`,
          title: "Error ",
          type: "error",
        });

        return;
      }
    }
  };

  const requestLocationHistory = async () => {
    const response = await getUserLocationHistory(userInfo.access_token);

    if (response.res.currentLocation) {
      const currentLocation = `${response.res.currentLocation.place}, ${response.res.currentLocation.country}`;
      setLocation(currentLocation);
      setResidence(response.res.currentLocation);
    }

    if (response.res.locationHistory) {
      const history = response.res.locationHistory.map((item: any) => ({
        ...item.location,
        locationHistoryId: item.locationHistoryId,
      }));

      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => ({
            ...item,
          }))
      );
      return 200;
    }
    return 500;
  };
  useEffect(() => {
    requestLocationHistory();
  }, [userInfo]);

  useEffect(() => {
    const searchIcon = document.querySelector('[class*="--SearchIcon"]');
    const actionIcon = document.querySelector('[class*="--ActionIcon"]');
    const results = document.querySelector('[class*="--Results"]');
    const searchBox = document.querySelector("mapbox-search-box");

    if (searchIcon) {
      const icon = searchIcon as HTMLElement;

      icon.style.width = "24px";
      icon.style.margin = "14px 16px";
      icon.style.left = "0";
    }

    if (actionIcon) {
      const action = actionIcon as HTMLElement;

      action.style.width = "24px";
      action.style.margin = "14px 16px";
      action.style.right = "0";
    }

    if (searchBox) {
      const element = searchBox as HTMLElement;
      element.style.width = "90%";
    }
  }, []);

  return (
    <div className="user-settings-location user-settings-location-wrapper wrapper standard-page-layout">
      <div className="user-settings-location-inner inner">
        <PageTitle
          hasCloseIcon={false}
          title={"Add Location"}
          subtitle={"Configuration"}
          onBackClick={() => {
            if (componentFeed) {
              setPrincipalScreen("/user-settings-profile", { btn: "button3" });
            } else {
              setPrincipalScreen("/user-settings-profile", { btn: "button3" });
            }
          }}
        />
      </div>
      <div className="body">
        <div className="map-container">
          {mapboxToken && (
            <div className="class-search ">
              <SearchBoxComponent
                accessToken={mapboxToken}
                options={{
                  language: "en",
                  types: "place",
                }}
                placeholder="Select Location"
                theme={{
                  variables: {
                    colorBackground: "var(--dawn)",
                    borderRadius: "30px",
                    colorText: "white",
                    colorBackgroundActive: "var(--charcoal)",
                    colorBackgroundHover: "var(--charcoal)",
                  },
                }}
                value={location}
                onChange={async (e: any) => {
                  if (e === "") {
                  }
                  //setLocation(e);
                  // const filteredSuggestions = await fetchFilteredSuggestions(e);
                }}
                onRetrieve={(sug: any) => {
                  const dataMock: ListDetails = {
                    place: sug.features[0].properties.context.place?.name,
                    region: sug.features[0].properties.context.region?.name,
                    country: sug.features[0].properties.context.country?.name,
                    country_code:
                      sug.features[0].properties.context.country?.country_code,
                    country_code_alpha_3:
                      sug.features[0].properties.context.country
                        ?.country_code_alpha_3,

                    latitude: sug.features[0].properties.coordinates.latitude,
                    longitude: sug.features[0].properties.coordinates.longitude,
                  };
                  if (
                    dataMock.place &&
                    dataMock.country_code &&
                    dataMock.region &&
                    dataMock.latitude &&
                    dataMock.longitude
                  ) {
                    setLocation(`${dataMock.place}, ${dataMock.country}`);
                    setResidence(dataMock);
                  } else {
                    setLocation(``);
                    //setResidence(``);
                  }
                }}
              />
            </div>
          )}
          {residence?.latitude && residence?.longitude && (
            <MapRoute
              originCoordinates={{
                long: residence?.longitude,
                lat: residence?.latitude,
              }}
            />
          )}
        </div>

        {locationList && (
          <div className="div__content_scroll  ">
            <InputRadio
              elements={
                locationList
                  ? locationList.map((item: any) => {
                      return {
                        groupName: "country",
                        inputLabel: `${item.place}, ${item.country}`,
                        inputValue: `${item.place}, ${item.country}`,
                      };
                    })
                  : []
              }
              onChangeValueCallback={(e) => {
                locationList?.map((item: ListDetails | any, index: number) => {
                  const beforeComma = e[0].split(",")[0].trim();
                  if (item.place === beforeComma) {
                    setResidence(item);
                  }
                });

                setLocation(e[0]);
              }}
              defaultValueProp={location}
              iconRight={true}
              actionIconRight={removeLocation}
            />
          </div>
        )}
      </div>
      <div className="footer">
        <div className="container-button">
          <Button
            children={"ADD LOCATION"}
            buttonStyle={"primary"}
            onClick={updateLocation}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSettingsLocationPage;
