import { forwardRef, useEffect, useRef, useState } from "react";
import "./styles.scss";

interface InputRadioProps {
  elements: InputRadioElement[];
  defaultValueProp?: string | string[];
  className?: string;
  onChangeValueCallback: (a: string[]) => void;
}

interface InputRadioElement {
  inputLabel: string;
  inputValue: string;
}

const InputRadioCarousel = forwardRef<HTMLDivElement, InputRadioProps>(
  (props) => {
    const { elements, defaultValueProp, className, onChangeValueCallback } =
      props;

    const containerRef = useRef<HTMLDivElement>(null);

    // Criar elementos duplicados virtualmente
    const infiniteElements = [...elements, ...elements, ...elements];

    const [selectedIndex, setSelectedIndex] = useState(
      elements.findIndex((el) => el.inputValue === defaultValueProp) +
        elements.length
    );

    const handleSelect = (index: number) => {
      setSelectedIndex(index);
      onChangeValueCallback([infiniteElements[index].inputValue]);
    };

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
      let newIndex = selectedIndex;

      if (event.deltaY > 0) {
        // Scroll down
        newIndex = newIndex + 1;
      } else if (event.deltaY < 0) {
        // Scroll up
        newIndex = newIndex - 1;
      }

      if (newIndex >= infiniteElements.length - elements.length) {
        newIndex = elements.length;
      } else if (newIndex < elements.length) {
        newIndex = infiniteElements.length - elements.length;
      }

      handleSelect(newIndex);
    };

    useEffect(() => {
      if (containerRef.current) {
        const selectedItem = containerRef.current.children[
          selectedIndex
        ] as HTMLDivElement;

        if (selectedItem) {
          selectedItem.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }, [selectedIndex]);

    return (
      <div className="input-radio-carrousel">
        <div className="input-radio-carrousel__bar"></div>

        <div
          ref={containerRef}
          className={`input-radio-carrousel__list ${className ?? ""}`}
          onWheel={handleScroll}
        >
          {infiniteElements.map((item, index) => {
            const isSelected = index === selectedIndex;
            return (
              <div
                key={index}
                className={`input-radio-carrousel__text ${isSelected ? "selected" : ""}`}
                onClick={() => handleSelect(index)}
              >
                {item.inputLabel}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default InputRadioCarousel;
