import { useEffect, useContext } from "react";
import { PullToRefreshContext } from "contexts/pullToRefreshContext";

const usePullToRefreshHandler = (ref: React.RefObject<HTMLElement>) => {
  const { isEnabled, setIsEnabled } = useContext(PullToRefreshContext);

  // Handling pull to refresh when component scrolled
  useEffect(() => {
    setIsEnabled(true);
    const element = ref.current;

    const handleScroll = () => {
      if (element) {
        const currentScrollTop = element.scrollTop;
        const currentScrollBottom = element.scrollHeight - element.clientHeight;

        const isScrolledToBottom =
          Math.round(element.scrollTop) === currentScrollBottom;
        if (isScrolledToBottom) {
          setIsEnabled(true);
          return;
        }

        const isScrolled = currentScrollTop !== 0;
        setIsEnabled(!isScrolled);
      }
    };

    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      setIsEnabled(true);
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [setIsEnabled, ref]);

  return {
    isEnabled,
  };
};

export { usePullToRefreshHandler };
