import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import ListItem from "components/Lists/ListItem";
import TitleText from "components/Texts/TitleText";
import SimpleSelect from "components/Selects/SimpleSelect";

import PatchUserSelfDetailApi from "../../api/patchUserSelfDetail";
import createUser from "../../api/auth/postCreateUser";

import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

const genders = [
  { label: "Male", value: "MAN" },
  { label: "Female", value: "WOMAN" },
  { label: "Non-Binary", value: "NONBINARY" },
  { label: "Trans", value: "TRANS" },
];

const GenderIdentificationPage: React.FC = () => {
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const navigate = useNavigate();

  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [genderConnect, setGenderConnect] = useState([
    { id: 1, label: "Male", value: false },
    { id: 2, label: "Female", value: false },
    { id: 3, label: "Non-Binary", value: false },
    { id: 4, label: "Trans", value: false },
  ]);

  const handleGenderConnect = (id: number) => {
    const updatedCheckboxes = genderConnect.map((gender) =>
      gender.id === id ? { ...gender, value: !gender.value } : gender
    );
    setGenderConnect(updatedCheckboxes);
  };

  const addGenderSubmit = async () => {
    setLoading(true);

    const createdUserData = {
      displayname: userInfo.display_name,
      phone: userInfo.user_phone?.replace("+", ""),
      email: userInfo.user_email,
      password: sessionStorage.getItem("pass") || "",
      gender: selectedGender,
      preferedGenders: genderConnect
        .filter((item) => item.value)
        .map((item) => {
          if (item.label === "Non-Binary") {
            return "NONBINARY";
          } else {
            return item.label.toUpperCase();
          }
        }),
    };

    const createUserResponse = await createUser(createdUserData);

    switch (createUserResponse.status) {
      case 201:
        const accessToken = createUserResponse.res.access_token;

        setUserInfo("access_token", accessToken);
        sessionStorage.setItem("pass", "null");

        const userDetails = {
          isActive: true,
        };

        const activateResponse = await PatchUserSelfDetailApi(
          accessToken,
          userDetails
        );

        switch (activateResponse.status) {
          case 200:
            navigate("/home");

            break;
          default:
            showToast({
              description:
                "Something went wrong on user activate. Please try again.",
              title: "Oops...",
              type: "error",
            });
            break;
        }

        break;

      default:
        showToast({
          description:
            "Something went wrong on user creation. Please try again.",
          title: "Oops...",
          type: "error",
        });

        break;
    }

    setLoading(false);
  };

  return (
    <div className="gender-identification gender-identification-wrapper wrapper">
      <div className="gender-identification gender-identification-inner inner">
        <div>
          <Header
            headerIcon="heart"
            backButtonClick={() => setComponent("/allow-notification")}
            title="I identify as..."
            subTitle={`Everyone is welcomed on our platform.`}
          />
          <SimpleSelect
            value={selectedGender}
            onChange={setSelectedGender}
            options={genders}
            defaultValueText="select a gender"
          />
          <TitleText>
            ...and want to <br />
            connect with
          </TitleText>
          <SubText>You can change this later.</SubText>

          {genderConnect.map((gender, index, arr) => (
            <ListItem
              key={index}
              showCheckboxLeft
              showLabel
              labelContent={gender.label}
              checkBoxLeftHandler={() => handleGenderConnect(gender.id)}
              showSeparator={arr.length > index + 1}
            />
          ))}
        </div>

        <Button buttonStyle={"primary"} onClick={addGenderSubmit}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default GenderIdentificationPage;
