export const localStorageClear = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("account_type");
  localStorage.removeItem("display_name");
  localStorage.removeItem("user_email");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_phone");
  localStorage.removeItem("user_profile_avatar");
  localStorage.removeItem("user-info-past-token");
  localStorage.removeItem("user-info-past-name");
  localStorage.removeItem("user-info-past-avatar");
};
