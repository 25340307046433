import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";

import postCompleteCreatorAccount from "../../api/creatorApplications/postCompleteCreatorAccount";

import { useUserInfo } from "../../hooks/userInfo";

import RosesLogo from "assets/icons/roses-default.svg";
import InitialImage from "assets/images/girl-and-a-bouquet.jpeg";

const WelcomeUser: React.FC = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const navigate = useNavigate();

  const handleConfirm = async () => {
    const response = await postCompleteCreatorAccount(userInfo.access_token);

    switch (response.status) {
      case 201:
        setUserInfo("account_type", "CREATOR");
        navigate("/app");
        break;
    }
  };

  return (
    <div className="welcome-user welcome-user-wrapper wrapper">
      <div className="welcome-user-inner inner">
        <div
          style={{ backgroundImage: `url(${InitialImage})` }}
          className="welcome-roses"
        />

        <div className="bottom-text-button-container">
          <img className="roses-logo" src={RosesLogo} alt="Initial Step logo" />
          <h1 className="confirmation">Welcome to Roses!</h1>
          <SubText>
            Your membership application has been accepted. Complete your account
            setup to start exploring Roses and connecting with other members.
          </SubText>

          <Button buttonStyle="primary" onClick={handleConfirm}>
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeUser;
