import fetchApi from "../fetchApi";

const getSubscriptionById = async (token: string, subscriptionId: string) => {
  return await fetchApi({
    service: "subscribe",
    endPoint: `/${subscriptionId}`,
    method: "GET",
    token,
  });
};

export default getSubscriptionById;
