import React, { ReactNode } from "react";
import "./styles.scss";

interface CategoryProps {
  children: ReactNode;
}

const Category: React.FC<CategoryProps> = (props) => {
  const { children } = props;
  return (
    <div id="category-default" className={"disable-class"}>
      <p className="category__text">{children}</p>
    </div>
  );
};

export default Category;
