import React, { ReactNode } from "react";
import "./styles.scss";
import iconGear from "assets/icons/gear-icon-gray.svg";
import ImageCircleBorder from "components/ImageCircleBorder";

interface PageTitleProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  avatarUrl?: string;
  isBecome?: boolean;
  isChat?: boolean;
  isProducer?: boolean;
  hasCloseIcon?: boolean;
  hasButtonNavigation?: boolean;
  backgroundTransparent?: boolean;
  buttonFeed?: boolean;
  className?: string;
  avatarClick?: (a: any) => void;
  buttonFeedClick?: (a: any) => void;
  mediaButtonClick?: (a: any) => void;
  onBackClick?: () => void;
  onGoToSettings?: () => void;
  degradeMidnight?: boolean;
  buttonTransparent?: boolean;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subtitle,
  avatarUrl,
  isBecome,
  isChat,
  isProducer,
  hasCloseIcon = true,
  hasButtonNavigation = true,
  backgroundTransparent,
  buttonFeed,
  className,
  avatarClick,
  buttonFeedClick,
  mediaButtonClick,
  onBackClick,
  onGoToSettings,
  degradeMidnight,
  buttonTransparent,
}) => {
  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  const classPageTitle = [
    "page-title-container",
    backgroundTransparent ? "background-transparent" : "",
    className ?? "",
    degradeMidnight ? "degrade-midnight" : "",
    isBecome ? "page-title-become" : "",
  ]
    .filter(Boolean)
    .join(" ")
    .trim();

  return (
    <div className={classPageTitle}>
      {hasButtonNavigation && !isProducer && (
        <div
          className={`back-button ${buttonTransparent ? "button-transparent" : ""}`}
          onClick={handleBackClick}
        >
          {hasCloseIcon ? (
            <span className="icon icon-close icon-md icon-gray-light"></span>
          ) : (
            <span className="icon icon-chevron-left icon-md icon-gray-light"></span>
          )}
        </div>
      )}

      {hasButtonNavigation && isProducer && (
        <div
          className={`back-button ${buttonTransparent ? "button-transparent" : ""}`}
          onClick={handleBackClick}
        >
          {hasCloseIcon ? (
            <span className="icon icon-close icon-md icon-gray-light"></span>
          ) : (
            <span className="icon icon-chevron-left icon-md icon-gray-light"></span>
          )}
        </div>
      )}

      {isChat && avatarClick && (
        <ImageCircleBorder
          centerImage={avatarUrl}
          centerImageAlt={`${title} avatar`}
          onClick={avatarClick}
          hasCharcoalBorder
          size={40}
        />
      )}
      <div className="text-wrapper">
        <div className={`title ${isChat ? "chat-title" : ""}`}>{title}</div>
        {subtitle && (
          <div className="subtitle-wrapper">
            {/* TODO */}
            {/* {isChat && (
              <div
                className={`chat-icon-status ${
                  isOnLine ? "interest-online" : "interest-offline"
                }`}
              />
            )} */}
            {isChat && (
              <div
                className={`chat-icon-status
                  interest-online
              `}
              />
            )}

            <div className="subtitle">{subtitle}</div>
          </div>
        )}
      </div>
      {/* TODO */}
      {/* {isChat && !chatLocked && ( */}
      {isChat && (
        <div className="open-extra">
          <button className="open-extra-button" onClick={mediaButtonClick}>
            Media
          </button>
        </div>
      )}
      {buttonFeed && (
        <div className="open-extra">
          <button className="open-extra-button" onClick={buttonFeedClick}>
            Feed
          </button>
        </div>
      )}
      {onGoToSettings && (
        <div className="settings-button" onClick={onGoToSettings}>
          <img
            className="settings-button-icon"
            src={iconGear}
            alt={"settings-icon"}
          />
        </div>
      )}
    </div>
  );
};

export default PageTitle;
