import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Paragraph from "components/Texts/Paragraph";

const createArrayFromNumber = (n: number) =>
  Array.from({ length: n }, (_, i) => i + 1);

const DailyLogin: React.FC = () => {
  const rectWidth = 6.13;
  const paddingLeft = 1;

  const daysRef = useRef(createArrayFromNumber(22));
  const svgRef = useRef<SVGSVGElement>(null);
  const [spaceBetween, setSpaceBetween] = useState(0);

  const numElements = daysRef.current.length;

  useEffect(() => {
    if (svgRef.current) {
      const totalWidth = svgRef.current.clientWidth;
      if (numElements > 1) {
        const calculatedSpace =
          (totalWidth - paddingLeft * 2 - numElements * rectWidth) /
          (numElements - 1);
        setSpaceBetween(calculatedSpace);
      } else {
        setSpaceBetween(0);
      }
    }
  }, [numElements]);

  return (
    <div className="daily-login">
      <main className="daily-login__main">
        <div className="daily-login__top">
          <h2 className="daily-login__title">Daily Login</h2>

          <Paragraph>
            22/<span className="daily-login__highlight">22</span>
          </Paragraph>
        </div>

        <svg
          ref={svgRef}
          width="100%"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          {daysRef.current.map((item, index) => {
            return (
              <rect
                key={index}
                width={rectWidth}
                height="18"
                x={paddingLeft + index * (rectWidth + spaceBetween)}
                y="1"
                rx="4"
                ry="4"
                style={{
                  fill: "var(--color-gold-light-25)",
                  stroke: "var(--color-gold)",
                  strokeWidth: 1,
                  opacity: 1,
                }}
              />
            );
          })}
        </svg>
      </main>
      <footer>
        <Paragraph className="daily-login__text">
          Complete 22 daily-login to win{" "}
          <span className="daily-login__highlight-gold">Golden Rose</span>{" "}
          credit
        </Paragraph>
      </footer>
    </div>
  );
};

export default DailyLogin;
