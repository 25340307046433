import React, { forwardRef, ReactNode } from "react";
import "./styles.scss";

import ImageCircleBorder from "components/ImageCircleBorder";

interface IListItemExtract {
  title?: string;
  description?: string;
  amount?: string;
  value?: string;
  date?: {
    long: string;
    short: string;
  };
  isExtract?: boolean;
  iconRight?: string;
  showAvatar?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const ListItemExtract = forwardRef<HTMLDivElement, IListItemExtract>(
  (props, ref) => {
    const {
      title,
      description,
      amount,
      value,
      date,
      isExtract = false,
      iconRight,
      showAvatar = false,
      children,
      className,
      onClick,
      ...rest
    } = props;

    const isPositive = (n: string) => Number(n) > 0;

    let valueClear: string | number | undefined = value;
    if (value?.search("$") !== -1) {
      valueClear = value?.replace(/[^0-9.]/g, "");
    }

    return (
      <article
        ref={ref}
        className={`list-item-extract ${className ?? ""}`}
        {...rest}
        onClick={onClick}
      >
        {showAvatar && (
          <ImageCircleBorder
            hasCharcoalBorder
            centerImage=""
            centerImageAlt=""
            size={40}
            className="list-item-extract__avatar"
          />
        )}

        {(title || date) && (
          <header className="list-item-extract__top">
            {title && (
              <p data-title className="list-item-extract__title  ">
                {title}
              </p>
            )}
            {date && (
              <time
                data-date
                className="list-item-extract__date"
                dateTime={date?.long}
              >
                {date?.short}
              </time>
            )}
          </header>
        )}

        {(amount || value != null) && (
          <section className="list-item-extract__infos">
            {amount && (
              <span
                data-amount
                className={`
                list-item-extract__info
                ${
                  isExtract && isPositive(amount.replace(",", ""))
                    ? "list-item-extract__info--positive"
                    : "list-item-extract__info--amount"
                }
              `}
              >
                {isPositive(amount.replace(",", "")) &&
                Number(amount.replace(",", ""))
                  ? "+"
                  : ""}{" "}
                {amount.replace("-", "")}
              </span>
            )}
            {value != null && (
              <span
                data-value
                className={`
                list-item-extract__info
                ${isExtract && "list-item-extract__info--extract"}
                ${
                  isExtract && isPositive(String(valueClear))
                    ? "list-item-extract__info--positive"
                    : ""
                }
              `}
              >
                {isPositive(String(valueClear)) && Number(valueClear) !== 0
                  ? "+"
                  : "-"}{" "}
                {value.replace("-", "")}
              </span>
            )}

            {iconRight && (
              <div className="list-item-extract__icon  ">
                <img src={iconRight} alt="" />
              </div>
            )}
          </section>
        )}

        {children}
      </article>
    );
  }
);

export default ListItemExtract;
