import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";
import Progressbar from "components/Progressbar";
import MediaModalCard from "./MediaModalCard";
import Loading from "components/Loading";
import { useUserInfo } from "hooks/userInfo";
import getPublicDetails from "api/publicDetails/getProfileDetails";
import { IUserPublicInfo } from "types/userInfo";
import { ICatalogResponse, PhotosProfile, ProfileProfile } from "types/catalog";
import { useModal } from "hooks/useModal";
import { shortClassNames } from "utils/shortClassNames";
import verifyToken from "utils/verifyToken";
import { CreatorDetailsContext } from "contexts/creatorDetails";

interface MediaModalProps {
  className?: string;
  medias: ICreatorMediaInfo[];
  creatorId: string;
  unlockMediaCallback?: (media: ICreatorMediaInfo) => void;
  initialIndex?: number;
}

export type ICreatorMediaInfo = {
  fileDuration: number;
  type: "photo" | "video";
  mediaSrc: string;
  mediaPlaceholder: string;
  isPaidContent: boolean;
  unlockPrice: number;
  hasPaid: boolean;
};

const MediaModal: React.FC<MediaModalProps> = (props) => {
  const { className, medias, creatorId, unlockMediaCallback, initialIndex } =
    props;
  const { userInfo } = useUserInfo();
  const { setOpenUseModal } = useModal();

  const { creatorDetails } = useContext(CreatorDetailsContext);
  const [mediaIndexCurrent, setMediaIndexCurrent] = useState(initialIndex ?? 0);
  const [isPausedMedia, setIsPausedMedia] = useState(false);
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);
  const [isLoadingMedias, setIsLoadingMedias] = useState(true);
  const [isLoadingMoments, setIsLoadingMoments] = useState(true);
  const [clickTime, setClickTime] = useState(0);

  const [creatorMediaInfo, setCreatorMediaInfo] = useState(
    {} as ICatalogResponse
  );
  const [photoProducer, setPhotoProducer] = useState<PhotosProfile[] | []>([]);
  const [profileAvatar, setProfileAvatar] = useState("");
  const [mediaAbleToShow, setMediaAbleToShow] = useState(false);
  const startX = useRef<number | null>(null);
  const mediaModalRefContent = useRef<HTMLElement>(null);
  const mediaModalRef = useRef<HTMLElement>(null);
  const [mediaIsLoaded, setMediaIsLoaded] = useState(false);

  console.log(initialIndex);

  useEffect(() => {
    setCreatorMediaInfo(creatorDetails as ICatalogResponse);
    if (!verifyToken()) {
      setIsLoadingMoments(false);
      setIsLoadingMedias(false);
      return;
    }
    setMediaIndexCurrent(initialIndex ?? 0);
    setIsLoadingMoments(true);
    Promise.all([creatorDetailsHandler(creatorId)]).finally(() => {
      setIsLoadingMoments(false);
      setIsLoadingMedias(false);
    });
  }, [creatorId, userInfo.access_token]);

  useEffect(() => {
    const media = medias[mediaIndexCurrent];
    if (media.isPaidContent) {
      if (medias[mediaIndexCurrent].hasPaid) {
        setMediaAbleToShow(true);
      } else {
        setMediaAbleToShow(false);
      }
    } else {
      setMediaAbleToShow(true);
    }
    setIsLoadingMedias(false);
  }, [mediaIndexCurrent]);

  // useEffect(() => {
  //   // getListChats();
  //   const handleClick = (event: MouseEvent) => {
  //     if (
  //       mediaModalRef.current &&
  //       !mediaModalRef.current.contains(event.target as Node)
  //     ) {
  //       closeModalHandler();
  //     }
  //   };

  //   document.addEventListener("click", handleClick);

  //   return () => {
  //     document.removeEventListener("click", handleClick);
  //   };
  // }, []);

  const closeModalHandler = () => {
    setOpenUseModal(false);
  };

  const conveyorActionsHandler = (completed: boolean) => {
    setMediaIsLoaded(false);
    setIsLoadingMedias(true);

    if (completed) {
      if (mediaIndexCurrent + 1 < medias.length) {
        setMediaIndexCurrent((prev) => prev + 1);
      } else if (mediaIndexCurrent + 1 === medias.length) {
        closeModalHandler();
      }
    }

    setIsLoadingMedias(false);
  };

  const playPauseMedia = (event: boolean) => {
    setIsPausedMedia(event);
  };

  const creatorDetailsHandler = async (userId: string) => {
    const request = await getPublicDetails(userInfo.access_token, userId);

    setCreatorMediaInfo(request?.res as {} as ICatalogResponse);
    setIsProfileFavorite(request.res?.isFavorite ?? false);
    setPhotoProducer(request.res?.profile?.photos ?? []);

    setProfileAvatar(request.res?.profile?.avatar?.thumbnailUrl ?? "");
  };

  const onMouseDown = (e: React.MouseEvent) => {
    startX.current = e.clientX;
    const screenHeight = window.innerHeight;
    const footerHeight = 120;
    const centralAreaHeight = screenHeight - footerHeight;

    if (e.clientY > 120 && e.clientY < centralAreaHeight) {
      setClickTime(Math.round(e.timeStamp));
    }
  };

  const onMouseUp = (e: React.MouseEvent) => {
    const timeClickUp = Math.round(e.timeStamp);
    if (timeClickUp - clickTime < 500) {
      handleSectionClick(e);
    }

    startX.current = null;
  };

  const markTouchPosition = (e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  };

  const markClickDrag = (e: React.MouseEvent) => {
    if (startX.current === null) return;

    const diffX = e.clientX - (startX.current || 0);
    if (diffX > 100) {
      if (mediaIndexCurrent) {
        setMediaIndexCurrent((prev) => prev - 1);
      }
      startX.current = null;
    } else if (diffX < -100) {
      if (mediaIndexCurrent + 1 < medias.length) {
        setMediaIndexCurrent((prev) => prev + 1);
      }
      startX.current = null;
    }
  };

  const markTouchDrag = (e: React.TouchEvent) => {
    if (startX.current === null) return;

    const diffX = e.touches[0].clientX - (startX.current || 0);
    if (diffX > 100) {
      if (mediaIndexCurrent) {
        setMediaIndexCurrent((prev) => prev - 1);
      }
      startX.current = null;
    } else if (diffX < -100) {
      if (mediaIndexCurrent + 1 < medias.length) {
        setMediaIndexCurrent((prev) => prev + 1);
      }
      startX.current = null;
    }
  };

  const hasClickArea = () => {
    if (medias[mediaIndexCurrent]?.isPaidContent) {
      if (medias[mediaIndexCurrent]?.hasPaid) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSectionClick = (e: React.MouseEvent) => {
    if (!mediaModalRefContent.current) return;

    if (hasClickArea()) {
      const sectionWidth = mediaModalRefContent.current.clientWidth;
      const clickPosition = e.clientX;
      const sectionLeftBoundary =
        mediaModalRefContent.current.getBoundingClientRect().left;

      const relativeClickPosition = clickPosition - sectionLeftBoundary;

      if (relativeClickPosition < sectionWidth / 2) {
        if (mediaIndexCurrent) {
          setMediaIndexCurrent((prev) => prev - 1);
          setMediaIsLoaded(false);
        }
      } else {
        conveyorActionsHandler(true);
      }
    }
  };
  if (isLoadingMoments) {
    return (
      <div className="inner__loading-page-wrapper">
        <Loading />
      </div>
    );
  }

  return (
    <section
      id="media-modal-content"
      className={className ?? ""}
      ref={mediaModalRef}
    >
      <section
        ref={mediaModalRefContent}
        className={shortClassNames([
          "media-modal-content__inner",
          className ?? "",
        ])}
        onMouseDown={onMouseDown}
        onMouseMove={markClickDrag}
        onTouchStart={markTouchPosition}
        onTouchMove={markTouchDrag}
        onMouseUp={onMouseUp}
      >
        <div className="content-wrapper__progressbar-wrapper">
          {Array.from({ length: medias.length }, (_, index) => {
            return (
              <Progressbar
                key={index}
                sizeBar={index < mediaIndexCurrent ? 100 : 0}
                index={index}
                currentIndex={mediaIndexCurrent}
                pause={isPausedMedia || !mediaIsLoaded}
                // time={Infinity}
                time={
                  medias[mediaIndexCurrent]?.type.toUpperCase() === "PHOTO"
                    ? 10000
                    : Math.round(medias[mediaIndexCurrent]?.fileDuration * 1000)
                }
                trigger={index === mediaIndexCurrent && !isLoadingMedias}
                triggerCallback={conveyorActionsHandler}
              />
            );
          })}
        </div>
        <span
          onClick={() => setOpenUseModal(false)}
          className="icon icon-close icon-primary-75
			 		icon-md close-modal-icon"
        />

        <MediaModalCard
          setOnLoadCallback={(e) => setMediaIsLoaded(e)}
          className="media-card-wrapper"
          clickInMediaCallBack={playPauseMedia}
          // clickProfileHandlerCallback={clickUserFeed}
          media={{
            creatorId: creatorMediaInfo?.userId,
            creatorAvatar: creatorMediaInfo?.profile?.avatar?.thumbnailUrl,
            creatorName: creatorMediaInfo?.displayname,
            isPaidContent: mediaAbleToShow,
            media: medias[mediaIndexCurrent]?.mediaSrc as string,
            type: medias[mediaIndexCurrent]?.type.toLowerCase() as
              | "video"
              | "photo",

            unlockPrice: medias[mediaIndexCurrent]?.unlockPrice,
            // mediaIsAbleToShow: mediaAbleToShow,
            mediaIsAbleToShow: mediaAbleToShow,
          }}
          unlockMediaCallback={() =>
            unlockMediaCallback?.(medias[mediaIndexCurrent])
          }
        />
      </section>
      {/* {windowSize >= 768 ? (
        <section className="media-creator-details">
          <NewProfile
            navigate={navigate}
            momentStyle
            isIA={creatorMediaInfo?.profile?.isAI}
            photos={photoProducer}
            age={creatorMediaInfo?.profile?.age}
            isVip={creatorMediaInfo?.isVip}
            idProfile={creatorMediaInfo?.userId}
            name={creatorMediaInfo?.displayname}
            principalPhoto={creatorMediaInfo?.profile?.avatar.url}
            profilePhoto={
              creatorMediaInfo?.profile?.avatar?.thumbnailUrl as string
            }
            heartButtonClick={clickLikeHandler}
            sendMessageClick={openChat}
            buttonStyle={
              isProfileFavorite ? "transparentActive" : "transparent"
            }
            icon={
              isProfileFavorite
                ? "icon-heart-solid"
                : "icon-heart-outline icon-gray-light"
            }
            iconSecondary={"icon-messages icon-gray-light"}
          />
        </section>
      ) : (
        false
      )} */}
    </section>
  );
};

export default MediaModal;
