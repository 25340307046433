import { useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import ListItemLink from "components/Lists/ListItemLink";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal";

import UserInformation from "pages/UserInformation";
import AddReferralsPage from "pages/AddReferrals";

import MembershipUpdateStatusAdminApi from "../../api/patchMembershipApplicationAdminStatus";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";

import { useUserInfo } from "../../hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useModal } from "hooks/useModal";

import InfoIcon from "assets/icons/union-gray.svg";

const WaitListApplicationPage: React.FC = () => {
  const { componentFeed, setPrincipalScreen, setSecondaryScreen } =
    useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();
  const { setOpenUseModal, setModalContent } = useModal();

  const [referrals, setReferrals] = useState(0);
  const [, setIsLoading] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(
    userInfo.creator_application_status
  );

  const breakPoint = windowSize >= 768;

  useEffect(() => {
    const creatorApplyStatus = async () => {
      const responseStatus = await getCreatorStatus(userInfo.access_token);

      if (responseStatus?.status === 200) {
        setUserInfo("referral_code", responseStatus?.res.referralCode);
        setUserInfo("creator_application_status", responseStatus?.res.status);
        setUserInfo(
          "creator_application_id",
          responseStatus?.res.creatorApplicationId
        );
      }
    };

    creatorApplyStatus();
  }, []);

  const approveRegisterSubmit = async () => {
    setIsLoading(true);
    const approveResponse = await MembershipUpdateStatusAdminApi(
      userInfo.user_phone
    );

    switch (approveResponse?.status) {
      case 200:
        setUserInfo("user_id", approveResponse?.res.id);
        setUserInfo("display_name", approveResponse?.res.displayName);
        setUserInfo("user_phone", approveResponse?.res.phone);
        setUserInfo("instagram", approveResponse?.res.instagramUser);
        setUserInfo("account_type", approveResponse?.res.type);

        if (approveResponse.res.status === "APPROVED") {
          setPrincipalScreen("/welcome-user");
        } else {
          console.error(
            "Unexpected response from update operation",
            approveResponse
          );
        }
        break;
      default:
        console.error(
          "Unexpected response from update operation",
          approveResponse
        );
        break;
    }
  };

  const renderApproveButton = () => {
    if (
      userInfo.user_phone === "5511967700452" ||
      userInfo.user_phone === "14074464664" ||
      userInfo.user_phone === "13053704780" ||
      userInfo.user_phone === "5545999640276" ||
      userInfo.user_phone === "5511914013654" ||
      userInfo.user_phone === "5519984484218"
    ) {
      return (
        <>
          <Button buttonStyle="primary" onClick={approveRegisterSubmit}>
            Approve
          </Button>
        </>
      );
    }
  };

  const backHomeHandler = async () => {
    if (componentFeed) {
      setSecondaryScreen("/user-settings");
    } else {
      setPrincipalScreen("/user-settings");
    }
  };

  const handlerBackPage = () => {
    if (breakPoint) {
      setOpenUseModal(false);
      // setModalContent(<UploadDocumentPage />);
    } else {
      setPrincipalScreen("/user-settings");
    }
  };

  const handlerInformation = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<UserInformation />);
    } else {
      setPrincipalScreen("/user-information");
    }
  };

  const handlerAddReferrals = () => {
    if (breakPoint) {
      setOpenUseModal(true);
      setModalContent(<AddReferralsPage />);
    } else {
      setPrincipalScreen("/add-referrals");
    }
  };

  useEffect(() => {
    setApplicationStatus(userInfo.creator_application_status);
  }, [userInfo.creator_application_status]);

  return (
    <div className="wait-list-application wait-list-application-wrapper wrapper standard-page-layout bottom-spacing">
      <div className="wait-list-application-inner inner">
        <ButtonCloseModal />

        <div>
          {!breakPoint ? (
            <PageTitle
              title="Become a Creator"
              subtitle="Start Earning"
              isBecome
              hasCloseIcon={false}
              onBackClick={() => {
                const checkStatus =
                  applicationStatus === "APPROVED" ||
                  applicationStatus === "PENDING";
                if (checkStatus) {
                  setPrincipalScreen("/user-settings");
                } else {
                  setPrincipalScreen("/user-settings");
                }
              }}
            />
          ) : (
            false
          )}
          <Header
            title={
              <>
                Thank you,
                <br />
                Your application is <br />
                currently on our waiting list.
              </>
            }
            subTitle={`We continually review our waiting list and will send 
						a push notification if there are any changes to your application
						status.`}
            marginTop={0}
            backButtonClick={handlerBackPage}
            {...(breakPoint
              ? {
                  headerIcon: "back-button",
                  inlineElements: true,
                  titleClass: "header",
                }
              : {
                  titleClass: "wait-list-application__title",
                })}
          />
        </div>
        <main className="wait-list-application__main">
          <ListItemLink
            highlightText="My Application"
            rightNodeContent={
              <p className="right-info">
                {userInfo.creator_application_status.toLocaleLowerCase()}
              </p>
            }
            hasArrowRight
            hasSeparator
            className="wait-list-application__item"
            onClick={handlerInformation}
          />

          <ListItemLink
            highlightText="Add referrals"
            rightNodeContent={
              <p className="right-info">{`${referrals} referrals`}</p>
            }
            hasArrowRight
            className="wait-list-application__item"
            onClick={handlerAddReferrals}
          />
        </main>

        <div className="wait-list-application__bottom">
          {renderApproveButton()}

          <Button buttonStyle="quaternary" onClick={handlerBackPage}>
            Back to Account
          </Button>

          <SubText
            icon={InfoIcon}
            altIcon="info icon"
            className="subtext-info"
            classNameIcon="wait-list-application__sub-text-icon"
            fontSize="12px"
          >
            If you would like to list members to support your application, you
            can do so by adding referrals or by inviting friends above.
          </SubText>
        </div>
      </div>
    </div>
  );
};

export default WaitListApplicationPage;
