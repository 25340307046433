import React, { createContext, ReactNode, useState } from "react";
import { UserInfoRequest } from "types/userInfoRequest";

interface UserInfoRequestProps {
  userInfoRequest: UserInfoRequest | null;
  setUserInfoRequest: React.Dispatch<
    React.SetStateAction<UserInfoRequest | null>
  >;
}

interface UserInfoRequestProviderProps {
  children: ReactNode;
}

export const ProfileContext = createContext({} as UserInfoRequestProps);

export const ProfileProvider: React.FC<UserInfoRequestProviderProps> = ({
  children,
}) => {
  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);

  return (
    <ProfileContext.Provider value={{ userInfoRequest, setUserInfoRequest }}>
      {children}
    </ProfileContext.Provider>
  );
};
