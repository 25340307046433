import fetchApi from "../fetchApi";

const getCheckSubscription = async (token: string, creatorId: string) => {
  const params = {
    creatorId,
  };
  return await fetchApi({
    service: "subscribe",
    endPoint: "/check-subscription",
    method: "GET",
    token,
    params,
  });
};

export default getCheckSubscription;
