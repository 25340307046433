import fetchApi from "../fetchApi";

interface ITransactionsExtract {
  token: string;
  transactionType?: string[];
  entryType?: string;
  order?: string;
  gte?: string;
  lte?: string;
  take?: number;
  cursor?: string;
}

const getTransactionsExtract = async (props: ITransactionsExtract) => {
  const { token, transactionType, entryType, order, gte, lte, take, cursor } =
    props;

  return await fetchApi({
    service: "transactions",
    endPoint: `/extract`,
    method: "GET",
    token,
  });
};

export default getTransactionsExtract;
