import React, { useRef, useState } from "react";
import { NavigateFunction, Params as RouterParams } from "react-router-dom";
import * as jwtDecode from "jwt-decode";

import "./styles.scss";

import FadeCarousel from "components/Carousels/FadeCarousel";
import Header from "components/Headers/Header";
import SubText from "components/Texts/SubText";
import Button from "components/Buttons/Button";
import InputText from "components/Inputs/InputText";
import LoadingPage from "components/LoadingPage";

import CreateAccountModal from "../CreateAccount";
import ForgotPasswordModal from "../ForgotPassword";
import TermsAndPoliciesModal from "../TermsAndPoliciesModal";

import { useUserInfo } from "hooks/userInfo";
import { useToast } from "hooks/useToast";
import { useModal } from "hooks/useModal";

import getAuthGoogle from "api/auth/getAuthGoogle";
import UserAuth from "api/auth/postLogin";
import getCreatorStatus from "api/creatorApplications/getCreatorStatus";
import getUserInfo from "api/userInfo/getUserInfo";

import { Params } from "hooks/useDeskNavigation";

import { IInfoToken } from "types/infoToken";

import { localStorageClear } from "utils/localStorageClear";

import GoogleIcon from "assets/icons/buttonIcons/google-icon-gray.svg";
import TwitterIcon from "assets/icons/buttonIcons/twitter-icon-gray.svg";
import LoginImage from "assets/images/login&CreateAccount/login-image.jpg";

interface LoginModalProps {
  navigate: NavigateFunction;
  setComponent: (path: string | React.ReactNode, params?: Params) => void;
  paramsUrl: Readonly<RouterParams<string>>;
}

const LoginModal: React.FC<LoginModalProps> = (props) => {
  const { navigate, setComponent, paramsUrl } = props;
  const { creator_name } = paramsUrl;
  const { showToast } = useToast();
  const { setModalContent, setOpenUseModal } = useModal();

  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsWarning, setAcceptTermsWarning] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const alertToast = (description: string, title: string, type: any) => {
    showToast({
      description: description,
      title: title,
      type: type,
    });
  };

  const loginSubmit = async (
    method: "interAuth" | "oauthGoogle" | "authFingerPrint"
  ) => {
    if (method === "oauthGoogle") {
      try {
        const responseAuth = getAuthGoogle();
      } catch (error) {
        console.error(error);
      }
    } else if (method === "interAuth") {
      if (!userEmail && userPassword) {
        setEmailInputWarning(true);
        showToast({
          description: "You need to add your email to continue",
          title: "Email is empty",
          type: "info",
        });

        setTimeout(() => {
          setEmailInputWarning(false);
        }, 4000);

        return;
      }

      if (!userPassword && userEmail) {
        setPasswordInputWarning(true);

        showToast({
          description: "You need to add your password to continue",
          title: "Password is empty",
          type: "info",
        });

        setTimeout(() => {
          setPasswordInputWarning(false);
        }, 4000);

        return;
      }

      if (!userPassword && !userEmail) {
        setPasswordInputWarning(true);

        showToast({
          description: "You need to add your password to continue",
          title: "Password is empty",
          type: "info",
        });

        setTimeout(() => {
          setPasswordInputWarning(false);
        }, 4000);

        setEmailInputWarning(true);
        showToast({
          description: "You need to add your email to continue",
          title: "Email is empty",
          type: "info",
        });

        setTimeout(() => {
          setEmailInputWarning(false);
        }, 4000);

        return;
      }

      if (!acceptTerms) {
        showToast({
          description:
            "You must check the box to confirm you are of legal age and accept the terms.",
          title: "Action Required",
          type: "error",
        });
        setAcceptTermsWarning(true);

        setTimeout(() => {
          setAcceptTermsWarning(false);
        }, 4000);
        return;
      }
      try {
        const prepareData = {
          email: userEmail,
          password: userPassword,
        };

        const responseAuth = await UserAuth(prepareData);
        const access_token = responseAuth.res.access_token;

        setIsLoading(true);

        tokenTreatment(responseAuth?.status, access_token);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const tokenTreatment = async (status: number | null, token: string) => {
    try {
      switch (status) {
        case 200:
          const userInfoToken = (await jwtDecode.jwtDecode(
            token
          )) as IInfoToken;
          localStorageClear();
          localStorage.setItem("has_legal_age", "true");
          setUserInfo("access_token", token);
          setUserInfo("display_name", userInfoToken?.validUser.displayname);
          setUserInfo("account_type", userInfoToken?.validUser.role);
          setUserInfo("user_phone", userInfoToken?.validUser.phone);
          setUserInfo("user_email", userInfoToken?.validUser.email);
          setUserInfo("user_id", userInfoToken?.validUser.userId);

          const responseStatus = await getCreatorStatus(token);
          getUserInfoRequest(token);

          if (responseStatus?.status === 200) {
            setUserInfo("referral_code", responseStatus?.res.referralCode);
            setUserInfo(
              "creator_application_status",
              responseStatus?.res.status
            );
            setUserInfo(
              "creator_application_id",
              responseStatus?.res.creatorApplicationId
            );
          }

          if (
            responseStatus?.res?.status === "APPROVED" &&
            userInfoToken?.validUser.role.toLocaleLowerCase() === "consumer"
          ) {
            setOpenUseModal(false);
            navigate("/login");
            setComponent("/welcome-user");
          } else {
            setIsLoading(false);
            if (!creator_name) {
              navigate("/app");
              if (userInfo.account_type !== "UNVERIFIED") {
                setOpenUseModal(false);
              }
            } else {
              setOpenUseModal(false);
            }
          }

          break;
        case 401:
          alertToast(
            "Check your credentials and, please, try again",
            "Error",
            "error"
          );

          setPasswordInputWarning(true);
          setEmailInputWarning(true);
          setTimeout(() => {
            setPasswordInputWarning(false);
            setEmailInputWarning(false);
          }, 4000);

          break;

        default:
          alertToast(
            "We had a problem making the request. Please try again",
            "Sorry",
            "error"
          );
          break;
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const getUserInfoRequest = async (token: string) => {
    try {
      const response = await getUserInfo(token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            console.error(response.res.statusCode);
          } else {
            setUserInfo(
              "user_profile_avatar",
              response.res?.avatar?.thumbnailUrl
            );
          }
          break;
        case 401:
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginSubmit("interAuth");
    }
  };

  const createAccountModalHandler = () => {
    setModalContent(
      <CreateAccountModal
        navigate={navigate}
        paramsUrl={paramsUrl}
        setComponent={setComponent}
      />
    );
  };

  const forgotPasswordModalHandler = () => {
    setModalContent(
      <ForgotPasswordModal
        navigate={navigate}
        paramsUrl={paramsUrl}
        setComponent={setComponent}
      />
    );
  };

  const setTermsAndPoliciesModalHandler = (
    pageToShow: "terms" | "policies"
  ) => {
    setModalContent(
      <TermsAndPoliciesModal
        pageToShow={pageToShow}
        navigate={navigate}
        paramsUrl={paramsUrl}
        setComponent={setComponent}
      />
    );
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div
      id="login-modal"
      ref={modalRef}
      className="login-modal-wrapper wrapper"
    >
      <span
        onClick={() => setOpenUseModal(false)}
        className="icon icon-close icon-gray-light
			 		icon-md close-modal-icon"
      />
      <div className="login-modal__content">
        <div className="content__carousel-wrapper">
          <FadeCarousel
            slides={[{ backgroundImage: LoginImage }]}
            duration={4000}
            borderRadius={[16, 0, 0, 16]}
            showGradient={false}
            showText={false}
          />
        </div>
        <div className="content__form-wrapper">
          <Header
            marginTop={0}
            title="Welcome!"
            hasGapBetweenTitleAndSub={true}
            titleClass="header"
            headerIcon="roses-logo"
            modalStyle
            subTitle="For your security, please log in to continue accessing our platform."
          />
          <div className="form-wrapper">
            <div className="login__default">
              <InputText
                value={userEmail.toLowerCase()}
                inputType="email"
                placeholder="Email"
                textTransform="none"
                onChangeInput={(e) => setUserEmail(e)}
                isWarning={emailInputWarning}
                backgroundColor="charcoal"
              />
              <InputText
                inputType="password"
                value={userPassword}
                placeholder="Password"
                onChangeInput={(e) => setUserPassword(e)}
                onKeyDown={onKeyDown}
                isWarning={passwordInputWarning}
                backgroundColor="charcoal"
              />
              <SubText
                checkAnswer
                isChecked={acceptTerms}
                isWarning={acceptTermsWarning}
                checkValueCallback={setAcceptTerms}
                className="inputs__subtext"
              >
                I acknowledge that I am over the age of 18 and agree to
                our&nbsp;
                <span
                  onClick={() => setTermsAndPoliciesModalHandler("terms")}
                  className="form-wrapper__link-privacy-policy"
                >
                  Terms of Services
                </span>
                . Learn how we process your data in our&nbsp;
                <span
                  onClick={() => setTermsAndPoliciesModalHandler("policies")}
                  className="form-wrapper__link-privacy-policy"
                >
                  Privacy Policy
                </span>
              </SubText>
              <Button
                disabled={isLoading}
                buttonStyle="primary"
                onClick={() => loginSubmit("interAuth")}
              >
                Login
              </Button>
            </div>

            <div className="login__oauth-buttons">
              <Button
                // disabled={hasError || isLoading}
                buttonStyle="quaternary"
                className="login__oauth-btn"
                onClick={() => {
                  // loginSubmit();
                  alertToast("Feature coming soon!", "Information", "info");
                }}
              >
                {/* TODO change by span in the future */}
                {/* <span className="icon icon-gray icon-md icon-twitter"></span> */}
                <img src={TwitterIcon} alt="x/twitter icon" />
              </Button>
              <Button
                buttonStyle="quaternary"
                className="login__oauth-btn"
                onClick={() => loginSubmit("oauthGoogle")}
              >
                {/* TODO change by span in the future */}
                <img src={GoogleIcon} alt="google icon" />
              </Button>
              <Button
                // disabled={hasError || isLoading}
                buttonStyle="quaternary"
                className="login__oauth-btn"
                onClick={() => {
                  // loginSubmit("authFingerPrint");
                  alertToast("Feature coming soon!", "Information", "info");
                }}
              >
                {/* <img src={digitalIcon} alt="finger print icon" /> */}
                <span className="icon icon-gray icon-md icon-digitalprint"></span>
              </Button>
            </div>

            <Button
              disabled={isLoading}
              buttonStyle="tertiary"
              onClick={createAccountModalHandler}

              // onClick={() => setComponent("/create-account")}/////////////////
            >
              Create account
            </Button>
            <Button
              disabled={isLoading}
              buttonStyle="quaternary"
              onClick={forgotPasswordModalHandler}
            >
              Forgot Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
