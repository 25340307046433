import fetchApi from "../fetchApi";

const patchUnsubscribe = async (
  token: string,
  data: {
    creatorId: string;
  }
) => {
  return await fetchApi({
    service: "subscribe",
    endPoint: `/unsubscribe`,
    method: "PATCH",
    data,
    token,
  });
};

export default patchUnsubscribe;
