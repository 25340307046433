import React, { useEffect, useState } from "react";
import "./styles.scss";
import { IMoments, IMomentsUnseen } from "../../../types/moments";
import ImageCircleBorder from "../../ImageCircleBorder";
import { useUserInfo } from "hooks/userInfo";
import getUserInfo from "api/userInfo/getUserInfo";
import verifyToken from "utils/verifyToken";

interface MomentsCirclesProps {
  clickMoment: (moment: string, index: number) => void;
  addMomentClick?: (a: any) => void;
  className?: string;
  showAddMoment: boolean;
  creatorSelfMoments?: [] | IMoments[];
  momentsDetails: {
    momentsUnseen: IMomentsUnseen[];
    momentsSeen: IMomentsUnseen[];
  };
}

const MomentsCircles: React.FC<MomentsCirclesProps> = (props) => {
  const {
    clickMoment,
    className,
    momentsDetails,
    addMomentClick,
    showAddMoment,
    creatorSelfMoments,
  } = props;
  const { userInfo, setUserInfo } = useUserInfo();
  const [userAvatar, setUserAvatar] = useState("");
  const pastAvatar = localStorage.getItem("user-info-past-avatar");

  useEffect(() => {
    if (!verifyToken()) return;
    setUserAvatar(userInfo.user_profile_avatar);
  }, [userInfo.access_token, userInfo?.user_profile_avatar, pastAvatar]);

  // const getUserInfoRequest = async (token: string) => {
  //   try {
  //     const response = await getUserInfo(token);
  //     switch (response.status) {
  //       case 200:
  //         if (response?.res?.message?.statusCode === 401) {
  //         } else {
  //           setUserAvatar(response.res?.avatar?.thumbnailUrl);
  //           setUserInfo(
  //             "user_profile_avatar",
  //             response.res?.avatar?.thumbnailUrl
  //           );
  //         }
  //         break;
  //       case 401:
  //         break;
  //       default:
  //         break;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div id="moments-circles-component" className={`${className ?? ""}`}>
      <div className="moments-circles-component__wrapper">
        {userInfo.account_type === "CREATOR" ? (
          <div className="moments-circles-component__self-moment">
            {creatorSelfMoments?.length ? (
              <ImageCircleBorder
                hasPinkBorder={creatorSelfMoments.some(
                  (moment) => !moment.hasSeen
                )}
                hasCharcoalBorder={creatorSelfMoments.every(
                  (moment) => moment.hasSeen
                )}
                size={48}
                onClick={() => clickMoment(creatorSelfMoments[0]?.autorId, 0)}
                centerImageAlt="moment"
                centerImage={userAvatar}
              />
            ) : (
              <ImageCircleBorder
                size={48}
                centerImageAlt="moment"
                centerImage={userAvatar}
                onClick={addMomentClick}
              />
            )}

            {addMomentClick && showAddMoment ? (
              <div
                className="moments-circles-component__add-moments-container"
                onClick={addMomentClick}
              >
                <span className="icon icon-plus icon-white icon-sm"></span>
              </div>
            ) : (
              false
            )}
          </div>
        ) : (
          false
        )}

        {momentsDetails?.momentsUnseen.map((moment, index) => (
          <ImageCircleBorder
            hasPinkBorder
            key={index}
            size={48}
            onClick={() => clickMoment(moment.userId, index)}
            centerImageAlt="moment"
            centerImage={moment.profile.avatar?.thumbnailUrl}
          />
        ))}
        {momentsDetails?.momentsSeen.map((moment, index) => (
          <ImageCircleBorder
            key={index}
            hasCharcoalBorder
            size={48}
            onClick={() => clickMoment(moment.userId, index)}
            centerImageAlt="moment"
            centerImage={moment.profile.avatar?.thumbnailUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default MomentsCircles;
