import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import * as jwtDecode from "jwt-decode";

import LoadingPage from "components/LoadingPage";
import VerifyEmailModal from "components/Modals/VerifyEmailModal";
import AccountLoginPage from "pages/AccountLogin";
import Header from "components/Headers/Header";
import LogInSignUpDesk from "components/Wrappers/LoginSignUpDesk";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";

import { useLoginSignUpNavigation } from "hooks/useLoginSignUpNavigation";
import { useUserInfo } from "hooks/userInfo";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";

import getCreatorStatus from "api/creatorApplications/getCreatorStatus";
import getUserInfo from "api/userInfo/getUserInfo";
import postEmailReceivedVerify from "api/verifyEmail/postEmailReceivedVerify";

import { IInfoToken } from "types/infoToken";

import { localStorageClear } from "utils/localStorageClear";

const VerifyEmailPage: React.FC = () => {
  const { component, setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  const { openUseModal, setModalContent, setOpenUseModal } = useModal();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [successVerified, setSuccessVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const token = params.get("token");

    if (token) {
      confirmEmail(token);
    } else {
      setIsLoading(false);
      navigate("/login");
    }
  }, [window.location.search]);

  const tokenTreatment = async (status: number | null, token: string) => {
    try {
      switch (status) {
        case 200:
          const userInfoToken = (await jwtDecode.jwtDecode(
            token
          )) as IInfoToken;
          localStorageClear();

          setUserInfo("access_token", token);
          setUserInfo("display_name", userInfoToken?.validUser.displayname);
          setUserInfo("account_type", userInfoToken?.validUser.role);
          setUserInfo("user_phone", userInfoToken?.validUser.phone);
          setUserInfo("user_email", userInfoToken?.validUser.email);
          setUserInfo("user_id", userInfoToken?.validUser.userId);

          const responseStatus = await getCreatorStatus(token);
          getUserInfoRequest(token);

          if (responseStatus?.status === 200) {
            setUserInfo("referral_code", responseStatus?.res.referralCode);
            setUserInfo(
              "creator_application_status",
              responseStatus?.res.status
            );
            setUserInfo(
              "creator_application_id",
              responseStatus?.res.creatorApplicationId
            );
          }

          if (
            responseStatus?.res?.status === "APPROVED" &&
            userInfoToken?.validUser.role.toLocaleLowerCase() === "consumer"
          ) {
            setComponent("/welcome-user");
          } else {
            navigate("/app");
            setIsLoading(false);
          }

          break;
        case 401:
          setComponent(<AccountLoginPage />);

          break;

        default:
          showToast({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });
          break;
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const getUserInfoRequest = async (token: string) => {
    try {
      const response = await getUserInfo(token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
          } else {
            setUserInfo(
              "user_profile_avatar",
              response.res?.avatar?.thumbnailUrl
            );
          }
          break;
        case 401:
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmEmail = async (token: string) => {
    try {
      const request = await postEmailReceivedVerify(token);

      switch (request.status) {
        case 200:
          // tokenTreatment(200, token);
          setModalContent(
            <VerifyEmailModal
              navigateTo={() => navigate("/app")}
              screenToShow="email-verified-successfully"
            />
          );

          setOpenUseModal(true);

          break;
        case 400:
          localStorageClear();
          navigate("/login");
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="create-email-verify__loading">
        <LoadingPage />
      </div>
    );
  }

  return <div className="create-email-verify"></div>;
};

export default VerifyEmailPage;
