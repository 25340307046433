import postSubscribe from "api/subscribe/postSubscribe";
import getUserSelfDetail from "../api/getUserSelfDetail";

import { useAvailableRoses } from "../contexts/availableRosesContext";
import { useUserInfo } from "./userInfo";

const useSubscribePlan = () => {
  const { updateSetAvailableRoses } = useAvailableRoses();
  const { userInfo } = useUserInfo();

  const subscribePlan = async (creatorId: string, billingCycle: string) => {
    if (!creatorId || !billingCycle) return;

    const request = await postSubscribe(userInfo.access_token, {
      creatorId,
      billingCycle,
    });

    if (request.status === 201) {
      setTimeout(async () => {
        const { res } = await getUserSelfDetail(userInfo.access_token);
        updateSetAvailableRoses(res.quantityRoses);
      }, 1000);

      return true;
    }

    return false;
  };

  return { subscribePlan };
};

export { useSubscribePlan };
