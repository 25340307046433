import React, { ReactNode } from "react";
import "./styles.scss";

interface CenterTextProps {
  icon?: string;
  altIcon?: string;
  value?: ReactNode;
  valueFontSize?: string;
  title: string;
  subtitle: ReactNode;
  titleTag?: "h1" | "h2" | "h3";
  subtitleFontSize?: string;
  imgHeight?: string;
  imgWidth?: string;
  maxWidth?: string | number;
}

const CenterText: React.FC<CenterTextProps> = (props) => {
  const {
    icon,
    altIcon,
    value,
    valueFontSize,
    title,
    subtitle,
    titleTag: TitleTag = "h2",
    subtitleFontSize,
    imgHeight,
    imgWidth,
    maxWidth,
  } = props;

  const titleStyle = { maxWidth: maxWidth };
  const subtitleStyle = { fontSize: subtitleFontSize, maxWidth: maxWidth };
  const valueStyle = { fontSize: valueFontSize };
  const imgStyle = { height: imgHeight, width: imgWidth };

  return (
    <div id="icon-and-text">
      {icon && <img src={icon} alt={altIcon} style={imgStyle} />}
      {value && (
        <div className="value-text" style={valueStyle}>
          {value}
        </div>
      )}
      <div className="text-container">
        <TitleTag className="title-text" style={titleStyle} textkey={title} />
        <p style={subtitleStyle}>{subtitle}</p>
      </div>
    </div>
  );
};

export default CenterText;
